import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ipaddress, ipaddress2 } from '../App';
import Slider from 'react-slick';
import { Modal, OverlayTrigger } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ReactCardFlip from 'react-card-flip';

const Employee_leads_count = ({ id, setRefresh }) => {
  const [emp_data, setemp_data] = useState({});
  const [emp_billing_data, setemp_billing_data] = useState({});
  console.log('MUIZID', id);

  useEffect(() => {
    fetchemp_data();
  }, [id]);

  const fetchemp_data = () => {
    axios
      .get(`${ipaddress}/MyprofileView/${id}/`)
      .then(r => {
        console.log('Employee data found', r.data);
        console.log('MUIZED', r.data);
        setemp_data(r.data);
      })
      .catch(err => {
        console.log('Employee data fetching error', err);
      });

    axios
      .get(`${ipaddress}/BillingHistoryOfEmployee/${id}/`)
      .then(r => {
        console.log('Employee billing data found', r.data);
        setemp_billing_data(r.data);
      })
      .catch(err => {
        console.log('Employee billing data fetching error', err);
      });
  };

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    // initialSlide: 0,
    // arrows:false,
    // autoplay: true,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);

  const [customer_data, setcustomer_data] = useState([]);
  const [client_data, setclient_data] = useState([]);
  const [stage, setstage] = useState('');

  const fetch_data = stage => {
    axios.get(`${ipaddress}/StageWiseRecords/${id}/${stage}/`).then(r => {
      console.log('Particular Data', r.data);
      setstage(stage);
      setcustomer_data(r.data.customer_data);
      setclient_data(r.data.clients_data);
    });
  };

  const [pending_customer_data, setpending_customer_data] = useState([]);

  const fetch_pending_data = stage => {
    axios
      .get(`${ipaddress}/SendPendingAndCompletedRecords/${id}/${stage}/`)
      .then(r => {
        console.log('Particular Pending Data', r.data);
        setstage(stage);
        setpending_customer_data(r.data.data);
      });
  };

  const fetch_total_lead_data = stage => {
    axios
      .get(`${ipaddress}/SendPendingAndCompletedRecords/${id}/${stage}/`)
      .then(r => {
        console.log('THISS', r.data);
        setstage(stage);
        setcustomer_data(r.data.customers_data);
        setclient_data(r.data.clients_data);
      });
  };

  useEffect(() => {
    if (customer_data.length > 0 || client_data.length > 0) {
      setshow(true);
    }
  });

  useEffect(() => {
    if (pending_customer_data.length > 0) {
      setshow2(true);
    }
  });

  const navigate = useNavigate();

  const [type, settype] = useState(false);

  const [flipped, setflipped] = useState(false);

  // Filter the Leads count

  const date = new Date();

  const today_date = date.toISOString().slice(0, 10);
  const [filter_type, setfilter_type] = useState('today');
  const [start_date, setstart_date] = useState('');
  const [end_date, setend_date] = useState('');

  const fetch_lead_count = () => {
    axios.get(`${ipaddress2}/AdminCountDisplay/`).then(r => {
      console.log('Leads Count', r.data);
      // setleads_count(r.data)
    });
  };

  const filter_lead_count = (start_date, end_date) => {
    axios
      .get(
        `${ipaddress2}/MyprofileView/${id}/?start_date=${start_date}&end_date=${end_date}`,
      )
      .then(r => {
        console.log('Filtered Leads Count', r.data);
        setemp_data(r.data);
      });
  };

  return (
    <div>
      <div className="mb-5 ">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <svg
            onClick={() => {
              setflipped(!flipped);
            }}
            style={{ cursor: 'pointer' }}
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class={`bi bi-phone-flip1 ${flipped ? 'text-primary' : ''}`}
            viewBox="0 0 16 16"
          >
            <path
              fill-rule="evenodd"
              d="M11 1H5a1 1 0 0 0-1 1v6a.5.5 0 0 1-1 0V2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v6a.5.5 0 0 1-1 0V2a1 1 0 0 0-1-1m1 13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-2a.5.5 0 0 0-1 0v2a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-2a.5.5 0 0 0-1 0zM1.713 7.954a.5.5 0 1 0-.419-.908c-.347.16-.654.348-.882.57C.184 7.842 0 8.139 0 8.5c0 .546.408.94.823 1.201.44.278 1.043.51 1.745.696C3.978 10.773 5.898 11 8 11q.148 0 .294-.002l-1.148 1.148a.5.5 0 0 0 .708.708l2-2a.5.5 0 0 0 0-.708l-2-2a.5.5 0 1 0-.708.708l1.145 1.144L8 10c-2.04 0-3.87-.221-5.174-.569-.656-.175-1.151-.374-1.47-.575C1.012 8.639 1 8.506 1 8.5c0-.003 0-.059.112-.17.115-.112.31-.242.6-.376Zm12.993-.908a.5.5 0 0 0-.419.908c.292.134.486.264.6.377.113.11.113.166.113.169s0 .065-.13.187c-.132.122-.352.26-.677.4-.645.28-1.596.523-2.763.687a.5.5 0 0 0 .14.99c1.212-.17 2.26-.43 3.02-.758.38-.164.713-.357.96-.587.246-.229.45-.537.45-.919 0-.362-.184-.66-.412-.883s-.535-.411-.882-.571M7.5 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1z"
            />
          </svg>

          <div className="d-flex ms-auto">
            <button
              className="btns text-white py-2 me-3"
              onClick={() => navigate(`/today_leads/${id}`)}
            >
              Today Leads
            </button>

            <select
              name=""
              value={filter_type}
              onChange={e => {
                setfilter_type(e.target.value);
                if (e.target.value === 'current') {
                  fetchemp_data();
                }
                setstart_date('');
                setend_date('');
              }}
              className="d-block ms-auto py-1"
              id=""
            >
              <option value="current">Current Month</option>
              <option value="single_date">Date Filter</option>
              <option value="custom">Custom Filter</option>
            </select>
          </div>

          <div>
            <input
              type="date"
              value={start_date}
              onChange={e => {
                setstart_date(e.target.value);
                filter_lead_count(e.target.value, end_date);
              }}
              className={`rounded border py-1 px-2 ms-3 ${
                filter_type === 'single_date' || filter_type === 'custom'
                  ? ''
                  : 'd-none'
              }`}
              name=""
              id=""
            />
            <input
              type="date"
              value={end_date}
              onChange={e => {
                setend_date(e.target.value);
                filter_lead_count(start_date, e.target.value);
              }}
              className={`rounded border py-1 px-2 ms-3 ${
                filter_type === 'custom' ? '' : 'd-none'
              }`}
              name=""
              id=""
            />
          </div>
        </div>

        <ReactCardFlip
          isFlipped={flipped}
          flipDirection="vertical"
        >
          {/* //! TOTAL LEADS CARD */}
          <div className="row m-0 justify-content-center">
            <div className="p-2 px-3 col-md-4 col-lg-2">
              <div
                className="py-3 py-md-4 px-2 px-lg-3 shadow-sm emp-cards"
                onClick={() => {
                  setRefresh(true);
                  fetch_total_lead_data('total_leads');
                  navigate(`/view_employee_leads/${id}/allleads`);
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Total Leads
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.all_leads_count}
                </p>
              </div>
            </div>

            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div
                className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards"
                onClick={() => {
                  setRefresh(true);

                  fetch_data('newlead');
                  navigate(
                    `/view_employee_leads/${id}/newlead?start_date=${start_date}&end_date=${end_date}`,
                  );
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  New Leads
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.new_leads_count}
                </p>
              </div>
            </div>

            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div
                className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards"
                onClick={() => {
                  setRefresh(true);
                  // fetch_data('followup');
                  navigate(
                    `/view_employee_leads/${id}/followup?start_date=${start_date}&end_date=${end_date}`,
                  );
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Followup Leads
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.followup_leads_count}
                </p>
              </div>
            </div>

            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div
                className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards"
                onClick={() => {
                  setRefresh(true);

                  fetch_data('prospect');
                  navigate(
                    `/view_employee_leads/${id}/prospect?start_date=${start_date}&end_date=${end_date}`,
                  );
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Prospects
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.prospect_leads_count}
                </p>
              </div>
            </div>

            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div
                className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards"
                onClick={() => {
                  setRefresh(true);
                  fetch_data('registered');
                  navigate(
                    `/view_employee_leads/${id}/registered?start_date=${start_date}&end_date=${end_date}`,
                  );
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Registered Leads
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.registered_leads_count}
                </p>
              </div>
            </div>

            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div
                className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards"
                onClick={() => {
                  fetch_data('closed');

                  setRefresh(true);
                  navigate(
                    `/view_employee_leads/${id}/closed?start_date=${start_date}&end_date=${end_date}`,
                  );
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Closed Leads
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.closed_leads_count}
                </p>
              </div>
            </div>

            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div
                className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards"
                onClick={() => {
                  fetch_pending_data('overdue');

                  setRefresh(true);
                  navigate(
                    `/view_employee_leads/${id}/overdue?start_date=${start_date}&end_date=${end_date}`,
                  );
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Pending Followups
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.overdue_followup_count}
                </p>
              </div>
            </div>

            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div
                className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards"
                onClick={() => {
                  setRefresh(true);
                  fetch_pending_data('on_time_followup');
                  navigate(
                    `/view_employee_leads/${id}/on_time_followup?start_date=${start_date}&end_date=${end_date}`,
                  );
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Completed Ontime
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.on_time_followup_count}
                </p>
              </div>
            </div>

            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div
                className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards"
                onClick={() => {
                  setRefresh(true);
                  fetch_pending_data('late_followup');
                  navigate(
                    `/view_employee_leads/${id}/late_followup?start_date=${start_date}&end_date=${end_date}`,
                  );
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Completed After Time
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.late_followup_count}
                </p>
              </div>
            </div>

            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div
                className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards"
                onClick={() => {
                  fetch_pending_data('today_followup');

                  setRefresh(true);
                  navigate(
                    `/view_employee_leads/${id}/today_followup?start_date=${start_date}&end_date=${end_date}`,
                  );
                }}
              >
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Today Followups
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_data.today_followup_count}
                </p>
              </div>
            </div>
          </div>

          <div className="row m-0 justify-content-evenly">
            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards">
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Total Billing
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_billing_data.total_billing}
                </p>
              </div>
            </div>
            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards">
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Current Month Billing
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_billing_data.month_billing}
                </p>
              </div>
            </div>
            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards">
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Today Collection
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_billing_data.today_collection}
                </p>
              </div>
            </div>
            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards">
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Old Collectables
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_billing_data.old_collectables}
                </p>
              </div>
            </div>
            <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
              <div className="py-3 px-2 px-lg-3 py-md-4 shadow-sm emp-cards">
                <img
                  src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                  className="d-block mx-auto"
                  style={{ width: '46%' }}
                  alt=""
                />
                <p
                  className="m-0 text-center mt-2 fw-bold"
                  style={{ color: '#202224', fontSize: '13px' }}
                >
                  Total Collection
                </p>
                <p
                  className="fw-medium text-center m-0"
                  style={{ fontSize: '14px', color: '#202224' }}
                >
                  {emp_billing_data.total_collection}
                </p>
              </div>
            </div>
          </div>
        </ReactCardFlip>
      </div>

      <Modal
        show={show2}
        size="xl"
        onHide={() => setshow2(false)}
      >
        <Modal.Header className="bg-light d-flex align-items-center justify-content-between">
          <Modal.Title className="fs-5">{stage} Data</Modal.Title>
          <svg
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setshow2(false);
              setpending_customer_data([]);
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-x-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
          </svg>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <div className="d-flex justify-content-between align-items-center mb-4">
            {/* <button onClick={()=>settype(false)} className={`btn px-4 border border-primary ${!type ? 'btn-primary text-white':'text-primary'}`}>B2C Leads ({customer_data.length})</button> */}
            {/* <button onClick={()=>settype(true)} className={`btn px-4 border border-primary ${type ? 'btn-primary text-white':'text-primary'}`}>B2B Leads ({client_data.length})</button> */}
          </div>

          {/* Customer Data */}
          <div
            class={`table-responsive mt-2`}
            style={{ minHeight: '100vh' }}
          >
            <h6 className="mb-3">Customer {stage} data</h6>
            <table
              class={`table caption-top shadow-sm ${
                stage === 'today_followup' || stage === 'overdue'
                  ? 'd-none'
                  : ''
              }`}
            >
              <thead>
                <tr>
                  {/* <th scope="col" className={`${permissions.assign_leads || user.designation==='admin' || user.designation===role ? 'border-0':'d-none'}`}></th> */}
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Name
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Created at
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Mobile No
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Email
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Course Enquired
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Source
                      </p>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pending_customer_data.map((x, index) => {
                  // console.log('123ALMUIZ', x.customer_lead_id);
                  return (
                    <tr
                      className={index % 2 == 0 ? 'table-light' : 'table-white'}
                    >
                      <td className="border-0">
                        <p
                          className="text-center m-0 text-primary py-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigate(
                              `/lead_details/${
                                x.customer_lead_id &&
                                x.customer_lead_id.customer_lead_id
                              }`,
                            );
                          }}
                        >
                          {x.customer_lead_id && x.customer_lead_id.name}
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0 py-2">
                          {x.customer_lead_id &&
                            x.customer_lead_id.created_at &&
                            x.customer_lead_id.created_at.slice(0, 10)}
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0  py-2">
                          {x.customer_lead_id &&
                            x.customer_lead_id.contact_number}
                          {'MUIZ'}
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0  py-2">
                          {x.customer_lead_id && x.customer_lead_id.email}
                        </p>
                      </td>
                      <td className="border-0">
                        <p
                          className={
                            x.othercourseenquired === null
                              ? 'text-center m-0 py-2'
                              : 'd-none'
                          }
                        >
                          {x.customer_lead_id &&
                            x.customer_lead_id.course_enquiredd}
                        </p>
                        <p
                          className={
                            x.customer_lead_id &&
                            x.customer_lead_id.othercourseenquired === null
                              ? 'd-none'
                              : 'text-center m-0 py-2'
                          }
                        >
                          {x.customer_lead_id &&
                            x.customer_lead_id.othercourseenquired}{' '}
                          <span
                            style={{
                              fontStyle: 'italic',
                              color: 'orangered',
                              fontSize: '13px',
                            }}
                          >
                            (Other)
                          </span>
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0  py-2">
                          {x.customer_lead_id && x.customer_lead_id.source}
                        </p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>

            <table
              class={`table caption-top shadow-sm ${
                stage === 'today_followup' || stage === 'overdue'
                  ? ''
                  : 'd-none'
              }`}
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Name
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Created at
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Mobile No
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Email
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Course Enquired
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Source
                      </p>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {pending_customer_data.map((x, index) => {
                  return (
                    <tr
                      className={index % 2 == 0 ? 'table-light' : 'table-white'}
                    >
                      <td className="border-0">
                        <p
                          className="text-center m-0 text-primary py-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigate(`/lead_details/${x.customer_lead_id}`);
                          }}
                        >
                          {x.name}
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0 py-2">
                          {x.created_at && x.created_at.slice(0, 10)}
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0  py-2">
                          {'MUIZ'}
                          {/* {x.contact_number} */}
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0  py-2">{x.email}</p>
                      </td>
                      <td className="border-0">
                        <p
                          className={
                            x.othercourseenquired === null
                              ? 'text-center m-0 py-2'
                              : 'd-none'
                          }
                        >
                          {x.course_enquiredd}
                        </p>
                        <p
                          className={
                            x.othercourseenquired === null
                              ? 'd-none'
                              : 'text-center m-0 py-2'
                          }
                        >
                          {x.othercourseenquired}{' '}
                          <span
                            style={{
                              fontStyle: 'italic',
                              color: 'orangered',
                              fontSize: '13px',
                            }}
                          >
                            (Other)
                          </span>
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0  py-2">{x.source}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {/* Client Data */}
          <div
            class={`table-responsive mt-2 d-none ${type ? '' : 'd-none'}`}
            style={{ minHeight: '100vh' }}
          >
            <h6 className="mb-3">Client {stage} data</h6>
            <table class="table caption-top shadow-sm">
              <thead>
                <tr>
                  {/* <th scope="col" className={`${permissions.assign_leads || user.designation==='admin' || user.designation===role ? 'border-0':'d-none'}`}></th> */}
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Name
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Created at
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Mobile No
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Email
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Course Enquired
                      </p>
                    </span>
                  </th>
                  <th
                    scope="col"
                    className="border-0"
                  >
                    <span className="fw-medium">
                      <p className="text-center m-0 fw-bold text-secondary py-2">
                        Source
                      </p>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {client_data.map((x, index) => {
                  return (
                    <tr
                      className={index % 2 == 0 ? 'table-light' : 'table-white'}
                    >
                      <td className="border-0">
                        <p
                          className="text-center m-0 text-primary py-2"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            navigate(`/lead_details/${x.customer_lead_id}`);
                          }}
                        >
                          {x.name}
                          {'MUIZ'}
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0 py-2">
                          {x.created_at && x.created_at.slice(0, 10)}
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0  py-2">
                          {x.contact_number}
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0  py-2">{x.email}</p>
                      </td>
                      <td className="border-0">
                        <p
                          className={
                            x.othercourseenquired === null
                              ? 'text-center m-0 py-2'
                              : 'd-none'
                          }
                        >
                          {x.course_enquiredd}
                        </p>
                        <p
                          className={
                            x.othercourseenquired === null
                              ? 'd-none'
                              : 'text-center m-0 py-2'
                          }
                        >
                          {x.othercourseenquired}{' '}
                          <span
                            style={{
                              fontStyle: 'italic',
                              color: 'orangered',
                              fontSize: '13px',
                            }}
                          >
                            (Other)
                          </span>
                        </p>
                      </td>
                      <td className="border-0">
                        <p className="text-center m-0  py-2">{x.source}</p>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Employee_leads_count;
