import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const Open_group = () => {

    const {id}=useParams()
    const {batch_id}=useParams()

    const navigate=useNavigate()

    const[questions,setQuestions]=useState([])
    const[coding_questions,setcoding_questions]=useState([])

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[test_paper_id,settest_paper_id]=useState()

    const[pdf,setpdf]=useState(null)
    
    useEffect(()=>{
     axios.post(`${ipaddress2}/JoinedGroupView/${state}/`,{
    group_id:id
})
        .then((r)=>{
            console.log("Group Question Paper Fetched",r.data)
            setQuestions(r.data)
            settest_paper_id(r.data[0].testpaper_id)
        })
    },[])

    const fetch_coding_questions=(state)=>{
        axios.post(`${ipaddress2}/JoinedGroupView/${state}/`,{
            group_id:id
        })
                .then((r)=>{
                    console.log("Group Coding Question Paper Fetched",r.data)
                    setcoding_questions(r.data)
                })
    }
    
    const assign_question=(group_id)=>{
        axios.post(`${ipaddress2}/AssignTestPaperView/`,{
            group_id:Number(group_id),
            testpaper_id:Number(id)
        })

        .then((r)=>{
            console.log("assigned",r.data)
            toast.success(`Question assigned successfully`,{
                autoClose:2000,
                theme:'colored',
      position:'top-center'
            })
            window.location.reload()
        })
        .catch((err)=>{
            console.log(err)
        })
    }
    
    const[state,setstate]=useState('mcq')

    const submit_test=()=>{

        const formdata=new FormData()
        formdata.append('student_id',user.id)
        formdata.append('pdf',pdf)
        formdata.append('batch_id',batch_id)

        axios.post(`${ipaddress2}/submit-answer-sheet/${test_paper_id}/`,formdata)
        .then((r)=>{
            console.log("Test submitted",r.data)
            toast.success('Test submitted successfully',{
                autoClose:1000,
                theme:'colored',
      position:'top-center'
            })

            setTimeout(() => {
                navigate('/student_dashboard')
            }, 2000);
        })
        .catch((err)=>{
            console.log(err)
        })
        
    }
    return (
    <div className='d-flex'>
        <Sidebar activevalue={"batches"}/>
        <div className="w-100">
            <Navbar/>

            <div className="bg-light main-container p-3" style={{minHeight:'100vh'}}>
            <div className='d-flex justify-content-center pb-2'>
              <h5 onClick={()=>{
                setstate('mcq')
              }} className='py-2 px-4 d-flex align-items-center' style={{color:state==='mcq' ? '#fff':'#59E4A8',backgroundColor:state==='mcq' ? '#59E4A8':'#fff',borderRadius:'5px 0px 0px 5px',border:'1px solid #59e4a8',cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"/>
</svg> <span className='ms-2'>MCQ Questions</span></h5>
<h5 onClick={()=>{
  setstate('coding')
  fetch_coding_questions('coding')
}} className='py-2 px-4 d-flex align-items-center' style={{color:state==='coding' ? '#fff':'#59E4A8',backgroundColor:state==='coding' ? '#59E4A8':'#fff',borderRadius:'0px 5px 5px 0px',border:'1px solid #59e4a8',cursor:'pointer'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"/>
</svg> <span className='ms-2'>Coding Questions</span></h5>
            </div>
                <div className="row m-0">
                    <div className='d-flex justify-content-between pb-4'>
                        <h6 className='d-flex align-items-center'>
                            {/* <svg onClick={()=>{
                            navigate('/question_papers')
                        }} style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg> */}
<span className=''>{state==='mcq' ? 'MCQ':'Coding'} Questions</span></h6>

<button className='btn btn-primary btn-sm d-flex align-items-center' data-bs-toggle="modal" data-bs-target="#submitModal"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7918C3.47159 14.7918 2.36548 14.3399 1.45269 13.4362C0.539894 12.5324 0.0834961 11.4278 0.0834961 10.1223C0.0834961 8.88837 0.508175 7.81866 1.35753 6.91321C2.20689 6.00778 3.20449 5.521 4.35032 5.45287C4.58403 3.93579 5.28182 2.68245 6.44368 1.69287C7.60554 0.703288 8.9577 0.208496 10.5002 0.208496C12.2412 0.208496 13.7182 0.814893 14.931 2.02769C16.1438 3.24048 16.7502 4.71742 16.7502 6.4585V7.50016H17.3912C18.3888 7.53221 19.2258 7.89846 19.9022 8.59891C20.5786 9.29936 20.9168 10.1484 20.9168 11.146C20.9168 12.1676 20.5713 13.0307 19.8802 13.7351C19.1891 14.4396 18.3327 14.7918 17.3111 14.7918H11.662C11.1826 14.7918 10.7823 14.6312 10.4611 14.3101C10.1399 13.9889 9.97933 13.5886 9.97933 13.1091V7.06347L7.79183 9.23894L7.05464 8.5218L10.5002 5.07628L13.9457 8.5218L13.2085 9.23894L11.021 7.06347V13.1091C11.021 13.2694 11.0878 13.4163 11.2213 13.5498C11.3549 13.6834 11.5018 13.7502 11.662 13.7502H17.271C18.0002 13.7502 18.6165 13.4984 19.12 12.995C19.6234 12.4915 19.8752 11.8752 19.8752 11.146C19.8752 10.4168 19.6234 9.80051 19.12 9.29704C18.6165 8.79357 18.0002 8.54183 17.271 8.54183H15.7085V6.4585C15.7085 5.01752 15.2007 3.78923 14.1851 2.7736C13.1694 1.75798 11.9411 1.25016 10.5002 1.25016C9.05919 1.25016 7.83089 1.75798 6.81527 2.7736C5.79964 3.78923 5.29183 5.01752 5.29183 6.4585H4.73094C3.76405 6.4585 2.92137 6.8144 2.2029 7.5262C1.48441 8.23801 1.12516 9.09738 1.12516 10.1043C1.12516 11.1113 1.48107 11.9706 2.19287 12.6825C2.90468 13.3943 3.76405 13.7502 4.771 13.7502H7.37516V14.7918H4.771Z" fill="#fff"/>
</svg><span className='ms-2'>Upload Answer</span></button>

                    </div>
                
                    <div className="col-md-8 bg-white py-3 px-3">
                        {/* MCQ LAYOUT */}
                     <div className={`${state==='mcq' ? '':'d-none'}`}>
                        {questions.map((x,index)=>{
                        return(
                            <div className='py-4 border-bottom'>
                            <p className='text-primary word-break'><span>{index+1}. </span> <span className='fs-5'>{x.text}</span></p>

                            {x.options && x.options.map((y,index2)=>{
                                return(
                                    <div>
                                <p><span>{index2+1}. </span> <span>jkgjhgh</span></p>
                            </div>
                                )
                            })}
                        </div>  
                        )
                       })}
                     </div>

                     {/* CODING LAYOUT */}
                     <div className={`${state==='coding' ? '':'d-none'}`}>
                     {coding_questions.map((x,index)=>{
                            return(
                                <div className='py-4 border-bottom'>
                                    <p className='text-primary'>Question {index+1}</p>
                                    <p className='fw-medium word-break'>{x.text}</p>
                                </div>
                            )
                        })}
                     </div>
                    </div>
                    <div className="col-md-4" style={{height:'80vh'}}>
                        <div className='bg-white h-100 d-flex align-items-center'>
                        {/* <img src={require('../images/106520-OMXAGH-484-removebg-preview.png')} style={{width:'100%',height:'360px'}} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="submitModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
    <div class="modal-header d-flex justify-content-between">
        <h5 class="modal-title" id="exampleModalLabel">Upload Answer PDF</h5>
        <svg data-bs-dismiss="modal" style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>
      <div class="modal-body">
        <div className='d-flex justify-content-center mt-3'>
        <label htmlFor="file" style={{cursor:'pointer',border:'1px dashed gray'}} className='bg-light d-flex justify-content-center rounded py-2 px-4 w-75'><svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
  <path d="M13.6816 16.4231C13.6816 18.3165 13.0293 19.9276 11.7247 21.2566C10.4203 22.5855 8.825 23.25 6.93875 23.25C5.0525 23.25 3.45313 22.5855 2.14063 21.2566C0.828125 19.9276 0.171875 18.3165 0.171875 16.4231V5.50969C0.171875 4.1876 0.626562 3.06375 1.53594 2.13812C2.44552 1.21271 3.56135 0.75 4.88344 0.75C6.20552 0.75 7.32125 1.21271 8.23063 2.13812C9.14021 3.06375 9.595 4.1876 9.595 5.50969V15.8462C9.595 16.5802 9.33885 17.2124 8.82656 17.7428C8.31448 18.2732 7.69031 18.5384 6.95406 18.5384C6.21781 18.5384 5.58448 18.2774 5.05406 17.7553C4.52365 17.2332 4.25844 16.5969 4.25844 15.8462V5.46156H5.50844V15.8462C5.50844 16.2452 5.645 16.5853 5.91813 16.8666C6.19146 17.1478 6.5276 17.2884 6.92656 17.2884C7.32573 17.2884 7.66188 17.1478 7.935 16.8666C8.20833 16.5853 8.345 16.2452 8.345 15.8462V5.48562C8.34021 4.51437 8.00625 3.69063 7.34313 3.01438C6.68021 2.33813 5.86031 2 4.88344 2C3.91406 2 3.09479 2.34219 2.42563 3.02656C1.75646 3.71073 1.42188 4.53844 1.42188 5.50969V16.4231C1.41708 17.9665 1.95156 19.2818 3.02531 20.3691C4.09885 21.4564 5.40625 22 6.9475 22C8.46667 22 9.75802 21.4564 10.8216 20.3691C11.8851 19.2818 12.4218 17.9665 12.4316 16.4231V5.46156H13.6816V16.4231Z" fill="#5D5FE3"/>
</svg> <span className='ms-3' style={{fontSize:'16px',letterSpacing:'1px',color:'#5d5fe3'}}>Attach your Pdf File...</span></label>
<input type="file" onChange={(e)=>{
  setpdf(e.target.files[0])
}} id='file'/>
        </div>
        <div className='mt-2'>
          <p className='text-center m-0' style={{color:'green'}}>{pdf && pdf.name}</p>
        </div>

      <div className='d-flex justify-content-end mt-4'>
        <button data-bs-dismiss="modal" className='btn btn-primary btn-sm rounded-pill d-flex align-items-center px-3'> <span className='ms-2' onClick={()=>{
  submit_test()
}}>Submit Test</span></button>
      </div>
      </div>
    </div>
  </div>
</div>


    </div>
  )
}

export default Open_group