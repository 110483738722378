import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { ipaddress2 } from '../App'

const Trainer_Offcanvas_navbar = ({designation,activevalue}) => {
    const[open,setOpen]=useState(true)
    const[value,setValue]=useState("")
    useEffect(()=>{
      setValue(activevalue)
      
    },[])

    const navigate=useNavigate()

    const user=JSON.parse(sessionStorage.getItem('user'))

    const handleLogout=()=>{
      axios.post(`${ipaddress2}/TrainerLogout/`,{
        email:user.email
      })
      .then((r)=>{
        sessionStorage.removeItem("user")
        navigate('/trainer_login')
      })
      .catch((err)=>{
        console.log("Logout Error",err)
      })
    }
  

return (
    <div>
        <div class="offcanvas offcanvas-end navbaroffcanvas" tabindex="-1" id="trainer_navbar" aria-labelledby="trainer_navbarLabel">
  <div class="offcanvas-header px-2">
  <div className=''>
                {/* <img src={require('../images/skill_learning_logo-04-removebg-preview.png')} style={{width:'150px'}} alt="" /> */}
            </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body p-0 px-2">
  <ul class={`nav flex-column ${open ? 'w-100' : ''} mx-auto px-2 mt-2`}>
  
  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="dashboard" ? 'sidebar-color':'bg-white'}`}  style={{borderRadius:'5px'}}>
      <Link to='/trainer_dashboard' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="dashboard"  ? 'text-white' : 'text-dark'}`} style={{fontSize:'14px',color:'#202224'}}>Dashboard </span></Link>
    </li>
  
    <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="assigntests" ? 'sidebar-color':'bg-white'}`}  style={{borderRadius:'5px'}}>
      <Link to='/assign_test' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="assigntests"  ? 'text-white' : 'text-dark'}`} style={{fontSize:'14px',color:'#202224'}}>Create Questions </span></Link>
    </li>
  
    <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="your_groups" ? 'sidebar-color':'bg-white'}`}  style={{borderRadius:'5px'}}>
      <Link to='/your_groups' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="your_groups"  ? 'text-white' : 'text-dark'}`} style={{fontSize:'14px',color:'#202224'}}>Groups</span></Link>
    </li>
  
    <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="batches" ? 'sidebar-color':'bg-white'}`}  style={{borderRadius:'5px'}}>
      <Link to='/batches' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="batches"  ? 'text-white' : 'text-dark'}`} style={{fontSize:'14px',color:'#202224'}}>Batches </span></Link>
    </li>
  
    <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="assignmarks" ? 'sidebar-color':'bg-white'}`}  style={{borderRadius:'5px'}}>
      <Link to='/assign_marks' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="assignmarks"  ? 'text-white' : 'text-dark'}`} style={{fontSize:'14px',color:'#202224'}}>Mark Sheets</span></Link>
    </li>
  
    <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="questions" ? 'sidebar-color':'bg-white'}`}  style={{borderRadius:'5px'}}>
      <Link to='/question_papers' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="questions"  ? 'text-white' : 'text-dark'}`} style={{fontSize:'14px',color:'#202224'}}>Assign Test</span></Link>
    </li>
  
    <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="leave_approval" ? 'sidebar-color':'bg-white'}`}  style={{borderRadius:'5px'}}>
      <Link to='/leave_approval' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="leave_approval"  ? 'text-white' : 'text-dark'}`} style={{fontSize:'14px',color:'#202224'}}>Leave Approval</span></Link>
    </li>
  </ul>
  
  
  <ul class={`nav flex-column mt-4 border-top pt-3 ${open ? 'w-100' : ''} mx-auto px-2`}>
  <p className='text-secondary m-0 fw-medium px-2 pb-2 mt-2' style={{fontSize:'13px'}}>OTHERS</p>
  
  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'}`}  style={{borderRadius:'5px'}}>
      <a class={`nav-link d-flex align-items-center justify-content-center`} href="https://das.meridahr.com/" target='_blank'>
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}`} style={{fontSize:'14px',color:'#202224'}}>DAS</span></a>
    </li>
  
    <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'}`}  style={{borderRadius:'5px'}}>
      <Link class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}`} style={{fontSize:'14px',color:'#202224'}}>Settings</span></Link>
    </li>

    <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'}`}  style={{borderRadius:'5px'}}>
      <Link to='/trainer_profile' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}`} style={{fontSize:'14px',color:'#202224'}}>My Profile</span></Link>
    </li>

    <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'}`}  style={{borderRadius:'5px'}}>
      <Link onClick={handleLogout} class={`nav-link d-flex align-items-center justify-content-center`} href="#">
      <span className={`${open ? 'w-75 d-inline' : 'd-none'}`} style={{fontSize:'14px',color:'#202224'}}>Logout</span></Link>
    </li>
  </ul>

  </div>
</div>
    </div>
  )
}

export default Trainer_Offcanvas_navbar