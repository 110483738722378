import React from 'react'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { toast } from 'react-toastify'

const Take_attendance = () => {

    const { id, batch_id } = useParams();

    const navigate=useNavigate()

    const currentDate = new Date();

    // Get the current day of the month
    const currentDay = currentDate.getDate();

      // Format the date for display in the table headers
  const formatDate = (day) => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Month is zero-based
    const paddedMonth = month < 10 ? `0${month}` : month;
    const paddedDay = day < 10 ? `0${day}` : day;
    return `${year}-${paddedMonth}-${paddedDay}`;
  };

  const [students, setStudents] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [log_sheet, setlog_sheet] = useState([]);
  const [attendance, setAttendance] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress2}/SendSyllabusForCourse/by-course/${id}/`)
      .then((r) => {
        console.log("Log sheet details", r.data);
        setSyllabus(r.data.syllabus);
      });

    axios.get(`${ipaddress2}/StudentsFromParticularBatch/${batch_id}/`)
      .then((r) => {
        console.log("log sheet students details", r.data);
        setStudents(r.data);
      });

      fetch_logsheet()
  }, [id, batch_id]);

  const fetch_logsheet=()=>{
    axios.get(`${ipaddress2}/LogSheetView/${batch_id}/`)
    .then((r) => {
      console.log("log sheet attendance details", r.data);
      setlog_sheet(r.data);
    });
  }

  // Handle attendance change
  const handleAttendanceChange = (student, event) => {
    const { value } = event.target;
    setAttendance(prevAttendance => ({
      ...prevAttendance,
      [student]: value
    }));
  };

  const[syllabus_name,setsyllabus_name]=useState()

  // Handle form submission to send data to backend
  const handleSubmit = () => {
    const attendanceData = Object.entries(attendance).map(([studentId, status]) => ({
      student: Number(studentId),
      attendance_status:status
    }));

    console.log(attendanceData)

    axios.post(`${ipaddress2}/LogSheetView/${batch_id}/`, {
      date: formatDate(currentDay),
      syllabus_covered:syllabus_name,
      attendance: attendanceData
    })
      .then(response => {
        console.log("Attendance submitted successfully:", response.data);
        fetch_logsheet()
        setAttendance({})
        setSyllabus('')
        toast.success("Attendance submitted",{
          autoClose:1000
        })
      })
      .catch(error => {
        console.error("Error submitting attendance:", error);
      });
  };

  const[time,settime]=useState('')
  const updateTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    settime(`${hours}:${minutes}`);
};

useEffect(() => {
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
}, []);


return (
    <div className='d-flex'>
        <Sidebar/>
        <div className="w-100">
            <Navbar/>
            <div className="bg-light p-3 py-4 main-container" style={{minHeight:'100vh'}}>
              <div className="container bg-white rounded p-3">
              <h6 className='fs-5' style={{color:'#3C3C3C'}}>Take Attendance</h6>
              
                <div className="m-0">
                    <div className="px-0">
                        <select onChange={(e)=>{
                          setsyllabus_name(Number(e.target.value))
                        }} name="" className='rounded border-secondary-subtle rounded py-2 px-3 my-1' style={{cursor:'pointer'}} id="">
                          <option value="">Select the Syllabus</option>
                          {syllabus && syllabus.map((x)=>{
                            return(
                              <>
                              <option value={x.id}>{x.topic_name}</option>
                              </>
                            )
                          })}
                        </select>
                    </div>

                    <div className='row m-0'>

                    <div className="col-md-9 d-flex align-items-center gap-4 px-0">
                    <p style={{color:'#989898'}} className='d-flex align-items-center m-0'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-event" viewBox="0 0 16 16">
  <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
</svg></span><span className='ms-2'>{formatDate(currentDay)}</span></p>

<p style={{color:'#989898'}} className='d-flex align-items-center m-0'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
</svg></span><span className='ms-2'>{time}</span></p>
                    </div>

<div className="col-md-3 px-0 text-end">
<button className='btns text-white py-2 px-4 btn-sm' style={{fontSize:'13px'}} onClick={()=>{
  handleSubmit()
}}>Submit Attendance</button>
</div>

                    </div>

                </div>

{/* Students list table */}
<div className="table-responsive mt-4">
                <table className="table">
                  <thead className='table-light border'>
                    <tr>
                      <th scope="col" className='py-2 border-end border-bottom border-0'><p className='text-center m-0 fw-medium' style={{color:'#474747'}}>SI No</p></th>
                      <th scope="col" className='py-2 border-end border-bottom border-0' ><p className='text-center m-0 fw-medium' style={{color:'#474747'}}>Student Name</p></th>
                      <th scope="col" className='py-2  border-end border-bottom border-0'><p className='text-center m-0 fw-medium' style={{color:'#474747'}}>Attendance</p></th>
                    </tr>
                  </thead>
                  <tbody className='border-bottom border-0'>
                  {students.map((x,index)=>{
                            return(
                                <tr>
                        
                      <td className='py-3 border-0 border-end border-start'><p className='m-0 text-center'>{index+1}</p></td>
                      <td className='py-3 border-0 border-end'><p className='m-0 text-center'>{x.user && x.user.customer_name}</p></td>
                        <td className='py-3 border-0 border-end' key={`${x.user.id}`}>
                          <div className='d-flex justify-content-center'>
                          <select className={`form-select shadow-none border-secondary-subtle w-50`}
                            value={attendance[x.user.id] || ''}
                            onChange={(event) => handleAttendanceChange(x.user.id, event)}>
                            <option value="">Select</option>
                            <option value="present">Present</option>
                            <option value="absent">Absent</option>
                            <option value="leave">Leave</option>
                          </select>
                          </div>
                        </td>
                    </tr>
                            )
                        })}
                    
                  </tbody>
                </table>
              </div>
              </div>
            </div>
        </div>

{/* Attendance successfull */}
<div className='d-none' style={{backgroundColor:'rgba(0,0,0,0.5',height:'100%',width:'100%',position:'fixed',zIndex:6}}>
<div className='row m-0 h-100'>
    <div className="col-lg-8 bg-white mx-auto p-5 d-flex align-items-center justify-content-center" style={{height:'100%'}}>
    <div className="p-5 w-75">
        <p className='fw-medium text-center' style={{color:'#434343',fontSize:'34px'}}>Attendance Report Submitted Successfuly</p>
    
        <div className="m-0">
                    <div className="px-0">
                        <h6 className='pt-3 text-black fs-5' style={{fontWeight:600}}>Course Name</h6>
                    </div>

                    <div className='row m-0 mt-3'>

                    <div className="col-12 d-flex align-items-center justify-content-between px-0">
                    <p style={{color:'#989898'}} className='d-flex align-items-center m-0'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-event" viewBox="0 0 16 16">
  <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
</svg></span><span className='ms-2'>{formatDate(currentDay)}</span></p>

<p style={{color:'#989898'}} className='d-flex align-items-center m-0'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
</svg></span><span className='ms-2'>12:00 PM</span></p>
<p className='m-0 bg-secondary-subtle rounded py-1 px-3' style={{color:'#6D6D6D'}}><span>Status : </span><span>Completed</span></p>
                    </div>


                    </div>

                </div>

        <div className='mt-5 pt-3 text-center'>
            <button className='btn text-white py-2' onClick={()=>{
                navigate('/trainer_dashboard')
            }} style={{backgroundColor:'#57309C',width:'340px',fontSize:'13px'}}>Go to Dashboard</button>
        </div>
    </div>
    </div>
</div>
</div>
    </div>
  )
}

export default Take_attendance