import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from './Navbar'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'
import Student_details from './Student_details'
import { toast } from 'react-toastify'

const Students_under_batch = () => {

  const {id}=useParams()
  // const {student_id}=useParams()

  const navigate=useNavigate()

  const[students,setstudents]=useState([])

  const[count,setcount]=useState(0)

  const[student_id,setstudent_id]=useState()

  useEffect(()=>{
    axios.get(`${ipaddress2}/StudentsFromParticularBatch/${id}/`)
    .then((r)=>{
      console.log("All Students details",r.data)
      setstudents(r.data)
    })
  },[count])

const[email,setemail]=useState('')
const[state,setstate]=useState(false)


const block_student=(id,status,name)=>{
  axios.post(`${ipaddress2}/BlockStudent/`,{
    customer_id:id,
    status:status
  })

  .then((r)=>{
    setcount(count+1)
    console.log(r.data)
    if(status==true){
      toast.warn(`You blocked ${name}`,{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    }
    else{
      toast.success(`You unblocked ${name}`,{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    }
  })
}


return (
    <div className='d-flex'>
        <Sidebar activevalue={"batches"}/>
        <div className='w-100 main-div'>
            <Navbar/>

            <div style={{minHeight:'80vh'}} className='bg-light p-3 main-container'>
                <div className={`${state ? 'd-none':''}`}>
                <div class="table-responsive mt-4">                    
<table class="w-100">
  <thead>
    <tr style={{backgroundColor:'#034592'}} className='text-white'>
      <th scope="col" className='py-3'><p className='m-0 text-center fw-normal'>SI.No</p></th>
      <th scope="col"><p className='m-0 text-center fw-normal'>Student Name</p></th>
      <th scope="col"><p className='m-0 text-center fw-normal'>Email</p></th>
      <th scope="col"><p className='m-0 text-center fw-normal'>Birthday</p></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    {students.map((x,index)=>{
      return(
        <tr className='table-row'>
      <td scope="row"><p className='m-0 text-center'>{index+1}</p></td>
      <td className='py-3'><p className='m-0 text-center text-primary fw-medium' style={{cursor:'pointer'}} onClick={()=>{
        setemail(x.user.email)
        setstudent_id(x.user.id)
        setstate(true)
      }}>{x.user.customer_name}</p></td>
      <td><p className='m-0 text-center'>{x.user.email}</p></td>
      <td><p className='m-0 text-center'>{x.user.birthday}</p></td>
      <td><div className='text-center'>
        <button className={`btn btn-sm btn-danger px-3 ${x.user.block_status ? 'd-none':''}`} onClick={()=>{
          block_student(x.user.id,true,x.user.customer_name)
        }}>Block</button>
        <button className={`btn btn-sm btn-success px-3 ${x.user.block_status ? '':'d-none'}`} onClick={()=>{
          block_student(x.user.id,false,x.user.customer_name)
        }}>Unblock</button>
        </div></td>
    </tr>
      )
    })}
  </tbody>
</table>
</div>
                </div>


<Student_details email={email} id={id} student_id={student_id} state={state} setstate={setstate}/>
            </div>
        </div>

    </div>
  )
}

export default Students_under_batch