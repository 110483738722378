import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';

const Add_events = () => {

    const[load,setload]=useState()

    const[eventdescription,seteventdescription]=useState('')
const[event_date,setevent_date]=useState('')
const[event_time,setevent_time]=useState('')
const[event_img,setevent_img]=useState(null)
const[event_type,setevent_type]=useState('')
const[event_link,setevent_link]=useState('')
const[amount,setamount]=useState(0)
const[gst_total,setgst_total]=useState(0)

const[allevents,setallevents]=useState([])

// const fetch_events=()=>{
//   axios.get(`${ipaddress2}/upcomming-events/`)
//   .then((r)=>{
//     setallevents(r.data)
//   })
// }

const add_event=()=>{
    setload(true)
    const form=new FormData()
    form.append('description',eventdescription)
    form.append('image',event_img)
    form.append('event_time',event_time)
    form.append('event_date',event_date)
    form.append('link',event_link)
    form.append('free_status',event_type)
    if(event_type==='cost'){
        form.append('fees',amount)
    form.append('gst_total',gst_total)
    }

    axios.post(`${ipaddress2}/upcomming-events/`,form)
    .then((r)=>{
        setload(false)
        toast.success('Event added successfully',{
            autoClose:1000,
            theme:'colored',
      position:'top-center'
        })
        setevent_img(null)
        setevent_date('')
        setevent_time('')
        seteventdescription('')
        setevent_type('')
        setgst_total(0)
        setevent_link('')
        setamount(0)
        // fetch_events()
    })
    .catch((err)=>{
        setload(false)
        console.log(err)
    })

}

return (
    <div className='d-flex'>
        <Sidebar activevalue={"events"}/>
        <div className="w-100">
            <Navbar/>

            <div className="bg-light p-4 main-container">
            <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Event</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Name</label>
                <input type="text" value={eventdescription} onChange={(e)=>{
                    seteventdescription(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Date</label>
                <input type="date" value={event_date} onChange={(e)=>{
    const today = new Date();
    const selectedDate = new Date(e.target.value);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
    else{
        setevent_date(e.target.value)
    }
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Time</label>
                <input type="time" value={event_time} onChange={(e)=>{
                    setevent_time(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Link</label>
                <input type="text" value={event_link} onChange={(e)=>{
                    setevent_link(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="" className='fw-medium'>Event Type</label>
                <select name="" value={event_type} onChange={(e)=>{
                    setevent_type(e.target.value)
                }} id="" className='form-select shadow-none border-secondary-subtle mt-2 py-2'>
                    <option value="">Choose...</option>
                    <option value="cost">Paid Event</option>
                    <option value="free">Free Event</option>
                </select>
                </div>

                <div className={`mb-3 col-md-6 ${event_type==='cost' ? '':'d-none'}`}>
                <label htmlFor="" className='fw-medium'>Amount</label>
                <input type="number" value={amount} onChange={(e)=>{
                    setamount(e.target.value)
                    setgst_total(e.target.value*0.18)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className={`mb-3 col-md-6 ${event_type==='cost' ? '':'d-none'}`}>
                <label htmlFor="" className='fw-medium'>GST Total</label>
                <input type="number" value={gst_total} onChange={(e)=>{
                    setgst_total(e.target.value)
                }} className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                </div>

                <div className='mb-3 col-md-6 d-flex align-items-end mt-2'>
                <label style={{cursor:'pointer'}} htmlFor="event-file" className='bg-primary text-white rounded px-4 py-2'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-cloud-arrow-up" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M7.646 5.146a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 6.707V10.5a.5.5 0 0 1-1 0V6.707L6.354 7.854a.5.5 0 1 1-.708-.708z"/>
  <path d="M4.406 3.342A5.53 5.53 0 0 1 8 2c2.69 0 4.923 2 5.166 4.579C14.758 6.804 16 8.137 16 9.773 16 11.569 14.502 13 12.687 13H3.781C1.708 13 0 11.366 0 9.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383m.653.757c-.757.653-1.153 1.44-1.153 2.056v.448l-.445.049C2.064 6.805 1 7.952 1 9.318 1 10.785 2.23 12 3.781 12h8.906C13.98 12 15 10.988 15 9.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 4.825 10.328 3 8 3a4.53 4.53 0 0 0-2.941 1.1z"/>
</svg><span className='ms-2'>Upload Image</span></label>
                <input type="file" onChange={(e)=>{
                    setevent_img(e.target.files[0])
                }} id='event-file' className='form-control bg-light shadow-none border-secondary-subtle py-2 mt-2' />
                <p className='text-success m-0 ms-2'>{event_img && event_img.name}</p>
                </div>

<div className='text-end mt-3'>
    <button disabled={event_img !==null ? false:true} className='btn btn-primary px-4 py-2' onClick={add_event}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span></button>
</div>
            </div>
        </div>
    </div>
            </div>
        </div>
    </div>
  )
}

export default Add_events