import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { ipaddress2 } from '../App'

const Student_Offcanvas_navbar = ({designation,activevalue}) => {
    const[open,setOpen]=useState(true)
    const[value,setValue]=useState("")
    useEffect(()=>{
      setValue(activevalue)
      
    },[])

    const navigate=useNavigate()

    const user=JSON.parse(sessionStorage.getItem('user'))
  
    
    const handleLogout=()=>{
      axios.post(`${ipaddress2}/StudentLogout/${user.email}/`)
      .then((r)=>{
        sessionStorage.removeItem("user")
        navigate('/student_login')
      })
      .catch((err)=>{
        console.log("Logout Error",err)
      })
    }

return (
    <div>
        <div class="offcanvas offcanvas-end navbaroffcanvas" tabindex="-1" id="student_navbar" aria-labelledby="student_navbarLabel">
  <div class="offcanvas-header px-2">
  <div className=''>
                <img src={require('../images/student/Logo_Blue 1.png')} style={{width:'150px'}} alt="" />
            </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body p-0 px-2">
  <ul class={`nav flex-column px-2 ${open ? 'w-100' : ''} mx-auto px-0 mt-2`}>
  
  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="dashboard" ? 'sidebar-color':'bg-white'}`}  style={{borderRadius:'5px'}}>
    <Link to='/student_dashboard' class={`nav-link d-flex align-items-center justify-content-center`} href="#">
    <span className={`${open ? 'w-75 d-inline' : 'd-none'}  ${value==="dashboard"  ? 'text-white' : 'text-dark'}`} style={{fontSize:'14px',color:'#202224'}}>Dashboard </span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="batches" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/student_batches' class={`nav-link d-flex align-items-center justify-content-center ${value==="batches" ? 'text-white' : 'text-dark'}`} href="#">
  <span className={`${open ? 'd-inline w-75' : 'd-none'}`}>Batches</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="courses" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/student_courses' class={`nav-link d-flex align-items-center justify-content-center ${value==="courses" ? 'text-white' : 'text-dark'}`} href="#">
  <span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Courses</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="marks" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/student_marks' class={`nav-link d-flex align-items-center justify-content-center ${value==="marks" ? 'text-white' : 'text-dark'}`} href="#"><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Marks</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="fees" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/installments' class={`nav-link d-flex align-items-center justify-content-center ${value==="fees" ? 'text-white' : 'text-dark'}`} href="#"><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Fees & Payment</span> </Link>
  </li>

</ul>


<ul class={`nav flex-column mt-3 border-top pt-3 ${open ? 'w-100' : ''} mx-auto px-2`}>
  <p className='text-secondary m-0 fw-medium px-2 pb-2' style={{fontSize:'13px'}}>OTHERS</p>

<li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="complaint" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/complaint' class={`nav-link d-flex align-items-center justify-content-center ${value==="complaint" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Suggestion</span></Link>
  </li>

<li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="leave" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/your_leaves' class={`nav-link d-flex align-items-center justify-content-center ${value==="leave" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Leave Request</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'}`} style={{backgroundColor:value==="invoice" ? '#4880FF' : '#fff',borderRadius:'5px'}}>
  <Link to='/project_dashboard' class={`nav-link d-flex align-items-center justify-content-center ${value==="invoice" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Project</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="interview" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/interview_details' class={`nav-link d-flex align-items-center justify-content-center ${value==="interview" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Interview Details</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="feedback" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/feedback' class={`nav-link d-flex align-items-center justify-content-center ${value==="feedback" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Feedback</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="refer" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/refer' class={`nav-link d-flex align-items-center justify-content-center ${value==="refer" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Refer & Earn</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="refer" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <Link to='/student_profile' class={`nav-link d-flex align-items-center justify-content-center ${value==="refer" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>My Profile</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="refer" ? 'sidebar-color':'bg-white'}`} style={{borderRadius:'5px'}}>
  <a onClick={()=>{
    handleLogout()
  }} class={`nav-link d-flex align-items-center justify-content-center ${value==="refer" ? 'text-white' : 'text-dark'}`}><span className={`${open ? 'w-75 d-inline' : 'd-none'}`}>Logout</span></a>
  </li>

</ul>

  </div>
</div>
    </div>
  )
}

export default Student_Offcanvas_navbar