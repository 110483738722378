import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { ipaddress2 } from '../App';
import { useNavigate } from 'react-router-dom';

const Employee_Notification = ({ setstate, setstate2 }) => {
  const [admin_notifications, setadmin_notifications] = useState([]);
  const [notifications, setnotifications] = useState([]);

  const [count, setcount] = useState(0);
  const user = JSON.parse(sessionStorage.getItem('user'));
  const audioRef = useRef(null);

  useEffect(() => {
    if (user.designation === 'admin') {
      axios.get(`${ipaddress2}/admin-notifications/`).then(r => {
        console.log('Notifications', r.data);
        setadmin_notifications(r.data);
        if (r.data.length > 0) {
          setstate(true);
          if (audioRef.current) {
            audioRef.current.play();
          }
        } else {
          setstate(false);
        }
      });
    } else {
      axios.get(`${ipaddress2}/notifications/${user.emp_id}/`).then(r => {
        console.log('Employee Notifications', r.data);
        setnotifications(r.data);
        if (r.data.length > 0) {
          // setstate2(true);
          if (audioRef.current) {
            audioRef.current.play();
          }
        } else {
          // setstate2(false);
        }
      });
    }
  }, [count]);

  const clear_notification = () => {
    axios.delete(`${ipaddress2}/clear-all-admin-notifications/`).then(r => {
      setcount(count + 1);
    });
  };

  const clear_emp_notification = () => {
    axios
      .delete(`${ipaddress2}/notifications/clear/${user.emp_id}/`)
      .then(r => {
        setcount(count + 1);
      });
  };

  const navigate = useNavigate();

  const move = () => {
    navigate('/approvals');
  };

  return (
    <div className="trainer-notification">
      {/* <audio ref={audioRef} src={require('../images/iphone_sound.mp3')} /> */}
      <div
        class="offcanvas border-top offcanvas-end shadow border-0"
        style={{ marginTop: '70px', borderRadius: '15px 0px 0px 15px' }}
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabindex="-1"
        id="notification"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div class="offcanvas-header border-bottom d-flex align-items-center justify-content-between py-4">
          <h6
            class="offcanvas-title m-0"
            style={{ fontSize: '16px' }}
            id="offcanvasScrollingLabel"
          >
            Notifications
          </h6>

          <svg
            data-bs-dismiss="offcanvas"
            style={{ cursor: 'pointer' }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>

        <div class="border-bottom d-flex align-items-center justify-content-between p-3">
          <p
            className="m-0 rounded bg-secondary-subtle px-3 py-1 text-secondary"
            style={{ fontSize: '13px' }}
          >
            Unread
          </p>
          <p
            onClick={clear_emp_notification}
            className="m-0 fw-medium"
            style={{ fontSize: '13px', color: '#57309C', cursor: 'pointer' }}
          >
            Clear all
          </p>
        </div>

        <div class="offcanvas-body">
          <div>
            {/* <div className='py-2 px-2 mb-4' style={{backgroundColor:'#EAEAEA',borderRadius:'15px'}}>
        <div className='d-flex align-items-center my-1'>
        <img src={require('../images/man-438081_960_720.png')} alt="" />
        <div className='ms-3'>
          <p className='m-0 word-break fw-medium' style={{color:'#414141',fontSize:'15px'}}>Mani <span className='ms-2 fw-normal' style={{color:'#8E949F',fontSize:'14px'}}>Send you a leave request</span></p>
          <span style={{color:'#9DA2AC',fontSize:'13px'}}>3 hours ago</span>
        </div>
        </div>
        <div className='d-flex mt-3 ms-5 ps-3'>
          <button className='btn text-white btn-sm d-flex align-items-center px-2 pe-3 py-1 justify-content-center' style={{borderRadius:'6px',backgroundColor:'#57309C',width:'85px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
</svg> <span className='ms-1' style={{fontSize:'11px'}}>Accept</span></button>
<button className='ms-3 btn text-white btn-sm d-flex align-items-center px-2 pe-3 py-1 justify-content-center' style={{borderRadius:'6px',backgroundColor:'#8E949F',width:'85px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg> <span className='ms-1' style={{fontSize:'11px'}}>Reject</span></button>
        </div>
      </div> */}

            <h6
              className={`${
                notifications.length > 0 ? 'd-none' : ''
              } text-center py-3`}
            >
              No Notifications available...
            </h6>
            <div className={`${notifications.length > 0 ? '' : 'd-none'}`}>
              {notifications.map((x, idx) => {
                return (
                  <div
                    key={idx}
                    className="d-flex py-3 px-2 mb-3 align-items-center"
                    style={{ backgroundColor: '#EAEAEA', borderRadius: '15px' }}
                  >
                    {/* <img src={require('../images/man-438081_960_720.png')} alt="" /> */}
                    <div className="ms-3">
                      <p
                        className="m-0"
                        style={{ color: '#414141', fontSize: '15px' }}
                      >
                        <span
                          className=""
                          style={{ color: '#8E949F', fontSize: '14px' }}
                        >
                          {x.notification}
                        </span>
                      </p>
                      <span style={{ color: '#9DA2AC', fontSize: '13px' }}>
                        3 hours ago
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div
        class="offcanvas border-top offcanvas-end shadow border-0"
        style={{ marginTop: '70px', borderRadius: '15px 0px 0px 15px' }}
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabindex="-1"
        id="admin_notification"
        aria-labelledby="offcanvasScrollingLabel"
      >
        <div class="offcanvas-header border-bottom d-flex align-items-center justify-content-between py-4">
          <h6
            class="offcanvas-title m-0"
            style={{ fontSize: '16px' }}
            id="offcanvasScrollingLabel"
          >
            Notifications
          </h6>

          <svg
            data-bs-dismiss="offcanvas"
            style={{ cursor: 'pointer' }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-x"
            viewBox="0 0 16 16"
          >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
          </svg>
        </div>

        <div class="border-bottom d-flex align-items-center justify-content-between p-3">
          <p
            className="m-0 rounded bg-secondary-subtle px-3 py-1 text-secondary"
            style={{ fontSize: '13px' }}
          >
            Unread
          </p>
          <p
            className="m-0 fw-medium"
            onClick={clear_notification}
            style={{ fontSize: '13px', color: '#57309C', cursor: 'pointer' }}
          >
            Clear all
          </p>
        </div>

        <div class="offcanvas-body">
          <div>
            {/* <div className='py-2 px-2 mb-4' style={{backgroundColor:'#EAEAEA',borderRadius:'15px'}}>
        <div className='d-flex align-items-center my-1'>
        <img src={require('../images/man-438081_960_720.png')} alt="" />
        <div className='ms-3'>
          <p className='m-0 word-break fw-medium' style={{color:'#414141',fontSize:'15px'}}>Mani <span className='ms-2 fw-normal' style={{color:'#8E949F',fontSize:'14px'}}>Send you a leave request</span></p>
          <span style={{color:'#9DA2AC',fontSize:'13px'}}>3 hours ago</span>
        </div>
        </div>
        <div className='d-flex mt-3 ms-5 ps-3'>
          <button className='btn text-white btn-sm d-flex align-items-center px-2 pe-3 py-1 justify-content-center' style={{borderRadius:'6px',backgroundColor:'#57309C',width:'85px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2" viewBox="0 0 16 16">
  <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0"/>
</svg> <span className='ms-1' style={{fontSize:'11px'}}>Accept</span></button>
<button className='ms-3 btn text-white btn-sm d-flex align-items-center px-2 pe-3 py-1 justify-content-center' style={{borderRadius:'6px',backgroundColor:'#8E949F',width:'85px'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg> <span className='ms-1' style={{fontSize:'11px'}}>Reject</span></button>
        </div>
      </div> */}
            <h6
              className={`${
                admin_notifications.length > 0 ? 'd-none' : ''
              } text-center py-3`}
            >
              No Notifications available...
            </h6>
            <div
              className={`${admin_notifications.length > 0 ? '' : 'd-none'}`}
            >
              {admin_notifications.map((x, idx) => {
                return (
                  <div
                    key={idx}
                    className="d-flex py-3 px-2 align-items-center mb-3"
                    style={{ backgroundColor: '#EAEAEA', borderRadius: '15px' }}
                  >
                    {/* <img src={require('../images/man-438081_960_720.png')} alt="" /> */}
                    <div className="ms-3">
                      <p
                        className="m-0"
                        style={{ color: '#414141', fontSize: '15px' }}
                      >
                        {x.customer_id && x.customer_id.customer_name}
                        <span
                          className="ms-2"
                          style={{
                            color: '#8E949F',
                            fontSize: '14px',
                            cursor:
                              x.notification.includes('certificate') ||
                              x.notification.includes('discount')
                                ? 'pointer'
                                : '',
                          }}
                          onClick={() => {
                            if (x.notification.includes('certificate')) {
                              move();
                            }
                            if (x.notification.includes('discount')) {
                              move();
                            }
                          }}
                        >
                          {x.notification}
                        </span>
                        <span
                          className={
                            x.notification.includes('feed') ? '' : 'd-none'
                          }
                        >
                          {' '}
                          on {x.employee && x.employee.first_name}
                        </span>
                      </p>
                      <span style={{ color: '#9DA2AC', fontSize: '13px' }}>
                        3 hours ago
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee_Notification;
