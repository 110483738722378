import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { ipaddress2 } from '../App';
import View_document from './View_document';
import Footer from './Footer';
import Preloader from '../Components/Preloader';

const Explore_project = () => {

  const {id}=useParams()
  const {category_id}=useParams()

    // const date=new Date()
    // const monthName = date.toLocaleString('default', {month: 'long'});

    const navigate=useNavigate()

    const[project_details,setproject_details]=useState({})
    const[date,setdate]=useState('')
    const[month,setmonth]=useState('')
    const[year,setyear]=useState('')
    const[projects,setprojects]=useState([])
    const[loading,setloading]=useState()

    useEffect(()=>{
      setloading(true)
      axios.get(`${ipaddress2}/DisplaySpecificProject/${id}/`)
      .then((r)=>{
        console.log("Project Details",r.data)
        setproject_details(r.data)
        getdate(r.data.created_at)
      })

      axios.get(`${ipaddress2}/DisplayProjectsBasedOnCategory/${category_id}/?data=${"beginner"}`)
      .then((r) => {
        console.log("Without ids", r.data);
        setprojects(r.data.slice(0,3));
      })

      setloading(false)
    },[id])

    const getdate=(date)=>{
      const currentDate = new Date(date);
      const day = currentDate.getDate();
      const month = currentDate.toLocaleString('default', { month: 'long' });
      const year = currentDate.getFullYear();

      return `${day} ${month} ${year}`
    }

    const[state,setstate]=useState(false)

return (
    <div>
      {loading ? (<Preloader/>):(
        <div className='project-dash' style={{backgroundColor:'#0C1017'}}>
        <div className={`row m-0 p-4 pb-5 mb-5 ${state ? 'd-none':''}`} style={{minHeight:'100vh'}}>
            <div className="col-md-8">
            <h6 className='text-white fw-bold d-flex align-items-center'><svg style={{cursor:'pointer'}} onClick={()=>{
              navigate('/view_category/1')
            }} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg> <span className='fs-4 ms-2'>{project_details.category && project_details.category.category_name}</span></h6>
            <h1 className='text-white fw-medium m-0 mt-3 word-break' style={{fontSize:'40px'}}>{project_details.project_name}</h1>
            <p className="text-white fs-5">Published On : {getdate(project_details.created_at)}</p>

            <div className='mt-5'>
                <p className='text-white fs-3 fw-medium m-0'>Description</p>
                <hr className='mt-1' style={{border:'1px solid #ffff'}}/>
                <p className='text-white fs-5 word-break'>{project_details.description}</p>
            </div>

            <div className='mt-5'>
                <p className='text-white fs-3 fw-medium m-0'>Project Link</p>
                <hr className='mt-1' style={{border:'1px solid #ffff'}}/>
                <button onClick={()=>{
                  setstate(true)
                }} className='btn text-white py-3 px-5 fs-5 d-flex align-items-center mt-4' style={{backgroundColor:'#6C63FF'}}><span className='fs-5 me-2'>View Project Documentation</span><svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-box-arrow-up-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5"/>
  <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0z"/>
</svg></button>
            </div>
            </div>

            <div className="col-md-4 px-4">
              <h3 className='text-white mb-4 ps-4'>More Projects</h3>
               {projects.map((x)=>{
                return(
                  <div className='p-4 rounded shadow-sm mx-auto mb-4' style={{backgroundColor:'#3E2866',minHeight:'230px',width:'90%'}}>
                 <h5 className='text-white fs-4' style={{cursor:'pointer'}} onClick={()=>{
                  navigate(`/explore_project/${x.category && x.category.id}/${x.id}`)
                 }}>{x.project_name}</h5>
                 <p className='text-white m-0 mt-3'>Date Published</p>
                 <p className="text-white fs-6">{getdate(x.created_at)}</p>
           <div className='mt-5'>
             <p className='text-white'>Top Technologies : </p>
             <div className='row m-0'>
              {x.languages
 && x.languages.slice(0,3)
.map((y)=>{
                return(
                  <div className='col-lg-4 col-md-6 px-2'>
                     <p className='w-100 text-white border border-white rounded text-center py-1'>{y.subcategoryname}</p>
                  </div>
                )
              })}

             </div>
           </div>

               </div>
                )
               })}
            </div>
        </div>
  
<View_document url={project_details.document && project_details.document} state={state} setstate={setstate}/>

<div className={`${state ? 'd-none':''}`}>
<Footer/>
</div>
    </div>
      )}
    </div>
  )
}

export default Explore_project