import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ipaddress2 } from '../App';
import Footer from './Footer';
import Preloader from '../Components/Preloader';
import { Modal } from 'react-bootstrap';


const View_category = () => {
  const { category_id } = useParams();

  const navigate=useNavigate()
    

  const [categories, setCategories] = useState([]);
  const [level, setLevel] = useState("beginner");
  const [selectedLogos, setSelectedLogos] = useState([]);
  const [selectednames, setSelectednames] = useState([]);
  const [projects, setProjects] = useState([]);
  const[loading,setloading]=useState()
  const[count,setcount]=useState(0)

  useEffect(() => {
    setloading(true)
    axios.get(`${ipaddress2}/DisplaySubCourseBasedOnCategory/${category_id}/`)
      .then((r) => {
        console.log("Categories", r.data);
        setCategories(r.data);
      });

    fetchProjectsUsingLevels('beginner')
    setloading(false)
  }, [category_id,count]);

  const user=JSON.parse(sessionStorage.getItem('user'))

  const fetchProjectsUsingLevels = (value) => {
    console.log("LOGOS", selectedLogos);
    if (selectedLogos.length > 0) {
      axios.post(`${ipaddress2}/DisplayProjectsBasedOnCategory/${category_id}/`, {
        subcategories: selectedLogos,
        difficulty_level: value
      })
        .then((r) => {
          console.log("With ids", r.data);
          setProjects(r.data);
        })
    } 
    else {
      axios.get(`${ipaddress2}/DisplayProjectsBasedOnCategory/${category_id}/?data=${value}&customer_id=${user.id}`)
        .then((r) => {
          console.log("Without ids", r.data);
          setProjects(r.data);
        })
    }
  };

  const handleLogoClick = (logo) => {
    setSelectedLogos(prevSelectedLogos => {
      const updatedLogos = prevSelectedLogos.includes(logo)
        ? prevSelectedLogos.filter(l => l !== logo)
        : [...prevSelectedLogos, logo];

      // Trigger the API call with the updated selected logos
      if(updatedLogos.length>0){
        axios.post(`${ipaddress2}/DisplayProjectsBasedOnCategory/${category_id}/`, {
            subcategories: updatedLogos,
            difficulty_level: level
          })
            .then((r) => {
              console.log("Projects fetched", r.data);
              setProjects(r.data);
            });
      }

      else{
        setcount(count+1)
      }

      return updatedLogos;
    });
  };

  const handleLogoClick2 = (logo) => {
    setSelectednames(prevSelectedNames => {
      const updatednames = prevSelectedNames.includes(logo)
        ? prevSelectedNames.filter(l => l !== logo)
        : [...prevSelectedNames, logo];
      return updatednames;
    });

    console.log(selectednames)
  };


  // Scroll color change
  const [scrolledProjects, setScrolledProjects] = useState([]);

  const projectRefs = useRef([]);

  useEffect(() => {
    const handleScroll = () => {
      const newScrolledProjects = projectRefs.current.map((ref, index) => {
        if (ref) {
          const rect = ref.getBoundingClientRect();
          return rect.top <= 120; // Check if the top of the element is 100px from the top of the viewport
        }
        return false;
      });
      setScrolledProjects(newScrolledProjects);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

// CSS
const style1 = {
  // backgroundImage: `linear-gradient(180deg, rgba(28,139,225,1) 22%, rgba(255,255,255,1) 100%))`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundBlendMode: 'overlay',
};

const style2 = {
  background: 'linear-gradient(180deg, rgba(28,139,225,0.7) 1%, rgba(0,0,0,0.8) 50%), rgb(28,139,225)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};


// Payment for projects

const[show,setshow]=useState(false)
const[show2,setshow2]=useState(false)
const[cost,setcost]=useState()
const[project_id,setproject_id]=useState()
const[terms_status,setterms_status]=useState(false)
const[load2,setload2]=useState(false)
const[btn_state,setbtn_state]=useState(false)
const[url,seturl]=useState('')

const pay_for_project=()=>{
  setload2(true)
  setTimeout(() => {
    send()
  }, 1000);
  }
  
  const send=()=>{
    const total=Number(cost)
    axios.get(`${ipaddress2}/EventsPayment/${total}/?customer_id=${user.id}&project_id=${project_id}&grand_total=${cost}&grand_gst_total=${cost*0.18}`)
    .then((r)=>{
      console.log("Completed",r.data);
      setload2(false)
      setbtn_state(true)
      seturl(r.data.redirect_url)
      setcount(count+1)
    })
    .catch((err)=>{
      setload2(false)
      console.log("Performa generation error");
      
    })
  }

  const[preview_project,setpreview_project]=useState({})
  const[state,setstate]=useState(false)

  const filter_project=(id)=>{
    const data=projects.find(x=>x.id===id)
    console.log("Filtered project",data);
    setpreview_project(data)
    setstate(true)
  }

  useEffect(()=>{
    if(state==true){
      setshow(true)
    }
  },[preview_project])

return (
   <div style={{minHeight:'300vh',backgroundColor:'#0C1017'}}>
    {loading ? (<Preloader/>):(
       <div style={{minHeight:'100%',backgroundColor:'#0C1017'}}>
        <div className='' style={{ height: '100%',backgroundColor:'#0C1017'}}>
        <div className="m-0 sub-div2 row pt-3 pb-4" 
        style={{width:'100%',top:0,position:'fixed',height:'150px'}}
        >
          <div className="col-md-3">
          <div className='d-flex pt-2 justify-content-center'>
             <img style={{cursor:'pointer'}} onClick={()=>{
                   navigate('/student_dashboard')
                 }} src={require('../images/Logo_Blue 1.png')} width={150} alt="" />
           </div>
          </div>
          <div className="col-md-9" style={{zIndex:10}}>
          <div className='row m-0 pb-5'>
                 <div className="col-md-8 pt-4">
                     <h6 className='text-white fw-bold d-flex align-items-center'><svg style={{cursor:'pointer'}} onClick={()=>{
                       navigate('/project_dashboard')
                     }} xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
 </svg> <span className='fs-4 ms-2'>Web Development</span></h6>
                     <p className='text-white fs-5'>{projects.length} Projects</p>
 
                 </div>
                 <div className="col-md-4 pt-4">
                 <div class="input-group bg-transparent rounded py-1 ps-2 pe-1" style={{border:'1px solid #F4F4F4'}}>
  <input type="text" class="project-input form-control bg-transparent border-0" placeholder="Search Projects" aria-label="Recipient's username" aria-describedby="basic-addon2"/>
  <span class="input-group-text bg-transparent border-0" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg></span>
</div>
                 </div>
             </div>
          </div>
        </div>
        
       <div className='row m-0 p-0'>
         <div className="col-md-3 px-2">
 
 <div className='mt-4 d-flex gap-3 align-items-center' style={{width:'100%',overflowX:selectednames.length>3 ? 'scroll':'none',position:'fixed',width:'340px',top:'150px'}}>
     {selectednames.map((x)=>{
         return(
             <>
             <p className='text-white border rounded-pill px-3 mb-2 d-flex align-items-center' style={{fontSize:'13px'}}>{x}</p>
             </>
         )
     })}
 </div>
 
 <div className='mt-4 shadow shadow-white' style={{borderRadius:'22px',backgroundColor:'#222222',border:'1px solid #443a5a',position:'fixed',width:'340px',top:selectednames.length>3 ? '220px' : selectednames.length>0 && selectednames.length<4 ? '200px':'170px'}}>
 <p className="py-2 m-0 px-3 d-flex align-items-center justify-content-between" style={{ backgroundColor: '#313036',borderRadius:'22px'}} type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
             <div>
               {/* <img src={require('../images/Group 48095802.png')} alt="" /> */}
               <span className='text-white ms-2'>Select Technologies</span>
             </div>
             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-chevron-down" viewBox="0 0 16 16">
               <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708" />
             </svg>
           </p>
 
           <div className="collapse pt-4" style={{backgroundColor:'#222222',borderRadius:'0px 0px 22px 22px'}} id="collapseExample">
             <div className="row m-0">
               {categories.map((x) => (
                 <div className="col-lg-4 col-md-6 mb-4 px-4" key={x.id}>
                   <div className={`d-flex align-items-center justify-content-center ${selectedLogos.includes(x.id) ? 'selected' : ''}`} onClick={() => {
                     handleLogoClick(x.id)
                     handleLogoClick2(x.subcategoryname)
                     console.log(selectedLogos);
                   }} style={{ borderRadius: '15px', height: '60px', cursor: 'pointer',position:'relative'}}>
                     <img src={x.logo} style={{ width: '80%', height: '80%' }} alt="" />
                     <svg style={{position:'absolute',right:0,top:0}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="orange" class={`bi bi-x-circle-fill ${selectedLogos.includes(x.id) ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
                   </div>
                 </div>
               ))}
             </div>
           </div>
           </div>
         </div>

         <div className="col-md-9 p-0" style={{position:'relative'}}>

           <div className="row m-0 w-100 px-3" style={{position:'absolute',top:'120px'}}>
           
             <h6 className={`text-white fs-5 text-center py-3 ${projects.length > 0 ? 'd-none' : ''}`}>No Projects Available...</h6>
             {projects.map((x,index) => (
                <div className={`col-lg-3 col-md-6 ${scrolledProjects[index] ? 'scrolled' : ''}`} style={{ marginBottom: '44px' }} key={x.id} ref={(el) => projectRefs.current[index] = el}>
                <div className={`p-3 rounded ${scrolledProjects[index] ? 'projects2':'projects'}`} style={scrolledProjects[index] ? style2:style1}>
                <div className='d-flex justify-content-between'>
                 {/* <img src={require('../images/project/img.png')} style={{width:'30%'}} alt="" /> */}
                  <div className='d-flex align-items-top'>
                    <span className='text-white border-0 d-flex align-items-center shadow-sm rounded px-2' style={{backgroundColor:'#1F87FF',fontSize:'11px',height:'20px'}}>React</span>
                    <span className='text-white border-0 d-flex align-items-center shadow-sm rounded px-2 ms-2' style={{backgroundColor:'#1F87FF',fontSize:'11px',height:'20px'}}>Node</span>
                  </div>
                 </div>
                  <div className='mt-4' style={{height:'74px'}}>
                  <p className='m-0 text-white fw-medium word-break' style={{fontSize:'13px'}} >{x.project_name.slice(0,36)}</p>
                  <p className='m-0 text-white mt-2 word-break' style={{fontSize:'11px'}}>Build web applications using HTML, CSS, JavaScript, React and more....</p>
                  </div>
                  <div className='d-flex mt-4 pt-2 align-items-center justify-content-between'>
                  <button onClick={()=>{
                 navigate(`/explore_project/${category_id}/${x.id}`)
               }} className={`rounded-pill px-3 py-2 fw-medium text-white d-flex align-items-center ${x.premium ? 'd-none':''}`} style={{backgroundColor:'transparent',border:'1px solid #1494F1',fontSize:'14px'}}><span className='me-2' style={{fontSize:'13px'}}>Explore now</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
    </svg></button>

    <button onClick={()=>{
      setproject_id(x.id)
      setcost(x.cost)
                filter_project(x.id)
               }} className={`rounded-pill px-3 py-2 fw-medium text-white d-flex align-items-center ${x.premium ? '':'d-none'}`} style={{backgroundColor:'transparent',border:'1px solid #1494F1',fontSize:'14px'}}><span className='me-2' style={{fontSize:'13px'}}>Pay Now</span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"/>
    </svg></button>

    <img className={x.premium ? '':'d-none'} src={require('../images/premium.png')} width={24} height={24} alt="" />
                  </div>
                </div>
                </div>
             ))}
           </div>
         </div>
       </div>
     </div>
        {/* <Footer/> */}


{/* Preview project modal */}
<Modal
      show={show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className='d-flex align-items-center justify-content-between'>
        <Modal.Title id="contained-modal-title-vcenter" className='fs-5'>
          Project Overview
        </Modal.Title>
        <svg style={{cursor:'pointer'}} onClick={()=>{
          setstate(false)
          setshow(false)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </Modal.Header>
      <Modal.Body>
       <div className='row m-0'>
        <div className="col-12 mb-3">
          <h5>Project Title</h5>
          <p>{preview_project.project_name}</p>
        </div>
        <div className="col-12 mb-3">
          <h5>Project Description</h5>
          <p>{preview_project.description}</p>
        </div>
        <p className='fw-bold text-success text-center m-0 mb-2'>Make Payment to get the complete project!</p>
        <div className='d-flex justify-content-end mt-4'>
          <button onClick={()=>{
            setshow(false)
            setshow2(true)
          }} className={`btn btn-primary px-4`}>Make Payment
          </button>
        </div>
       </div>
      </Modal.Body>
      
    </Modal>

{/* Paid events registration modal */}
<Modal
      show={show2}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className='d-flex align-items-center justify-content-between'>
        <Modal.Title id="contained-modal-title-vcenter" className='fs-5'>
          Make Payment
        </Modal.Title>
        <svg style={{cursor:'pointer'}} onClick={()=>{
          setshow2(false)
          setstate(false)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </Modal.Header>
      <Modal.Body>
       <div className='row m-0'>
        <div className="col-12 mb-3">
          <label htmlFor="" className='fw-medium'>Project Cost</label>
          <input type="number" value={cost} disabled='true' className='form-control py-2 mt-2 bg-light' />
        </div>
        <div className="col-12">
          <label htmlFor="" className='fw-medium'>GST Amount</label>
          <input type="number" value={cost*0.18} disabled='true' className='form-control py-2 mt-2 bg-light' />
        </div>
        <div className="col-12 mt-1">
          <label htmlFor="" className='fw-medium'><span className='text-danger'>*</span> You have to pay 2% additional charges also</label>
          {/* <input type="number" value={gst_amount} disabled='true' className='form-control py-2 mt-2 bg-light' /> */}
        </div>
        <p className='mt-3'><svg style={{cursor:'pointer'}} onClick={()=>{
          setterms_status(true)
        }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class={`bi bi-check-square ${terms_status ? 'd-none':''}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg style={{cursor:'pointer'}} onClick={()=>{
          setterms_status(false)
        }} xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#0B5ED7" class={`bi bi-check-square-fill ${terms_status ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
 <span className='ms-2' style={{fontSize:'13px'}}>Agree to our <span className='text-primary text-decoration-underline' style={{fontSize:'13px',cursor:'pointer'}} onClick={()=>{
  // setshow2(true)
  setshow(false)
}}>Terms & Conditions</span></span></p>
        <div className='d-flex justify-content-end mt-4'>
          <button onClick={pay_for_project} disabled={terms_status ? false:true} className={`btn btn-primary px-4 ${btn_state ? 'd-none':''}`}>
          <span class={`spinner-border spinner-border-sm ${load2 ? '':'d-none'}`} aria-hidden="true"></span>
          <span className={`${load2 ? 'ms-2':'d-none'}`} role="status">Proceeding...</span>
          <span className={`${load2 ? 'd-none':''}`}><span className='ms-2'>Proceed</span></span>
          </button>

           <a href={url && url} target="_self" onClick={pay_for_project} disabled={terms_status ? false:true} className={`btn btn-primary px-4 ${btn_state ? '':'d-none'}`}>
          <span class={`spinner-border spinner-border-sm ${load2 ? '':'d-none'}`} aria-hidden="true"></span>
          <span className={`${load2 ? 'ms-2':'d-none'}`} role="status">Proceeding...</span>
          <span className={`${load2 ? 'd-none':''}`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paypal" viewBox="0 0 16 16">
          <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.35.35 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91q.57-.403.993-1.005a4.94 4.94 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.7 2.7 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.7.7 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016q.326.186.548.438c.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.87.87 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.35.35 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32z"/>
        </svg><span className='ms-2'>Pay Now</span></span>
          </a>
        </div>
       </div>
      </Modal.Body>
      
    </Modal>
       </div>

       
    )}
   </div>
  );
};

export default View_category;
