import axios from 'axios';
import React, { useState } from 'react';
import { ipaddress } from '../App';
import { useNavigate } from 'react-router-dom';

const Globalsearch = () => {
  const [students, setstudents] = useState([]);

  const search = value => {
    if (value.length > 0) {
      axios.get(`${ipaddress}/SendCustomer/?data=${value}`).then(r => {
        console.log('Searched Students', r.data);
        setstudents(r.data);
      });
    }
  };

  const navigate = useNavigate();

  return (
    <div className="">
      <div
        class="offcanvas offcanvas-top"
        tabindex="-1"
        id="searchoffcanvas"
        aria-labelledby="searchoffcanvasLabel"
        style={{ height: '40vh' }}
      >
        <div class="offcanvas-body">
          <div className="d-flex justify-content-between border-bottom pb-3 pt-2 px-1 px-lg-5 align-items-center">
            <div class="input-group">
              <span
                class="input-group-text bg-white border-end-0 border-0"
                style={{ color: '#AAB0B0' }}
                id="basic-addon1"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 16"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                </svg>
              </span>
              <input
                onChange={e => {
                  search(e.target.value);
                }}
                type="text"
                class="form-control nav-input border-start-0 border-0 shadow-none"
                id="globalsearch"
                placeholder="Search leads here..."
                aria-label="Username"
                aria-describedby="basic-addon1"
              />
            </div>
            <svg
              type="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="#FF845D"
              class="bi bi-x-circle"
              viewBox="0 0 16 16"
            >
              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
              <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
            </svg>
          </div>

          <div className="px-2 px-md-5 mt-4">
            {students.map(x => {
              return (
                <div className="border-bottom pb-3 mb-3">
                  <p
                    style={{ cursor: 'pointer', fontSize: '16px' }}
                    onClick={() => {
                      navigate(`/leave_payments/${x.email}`);
                    }}
                    className="m-0 text-primary"
                  >
                    {x.customer_name}
                  </p>
                  <span
                    className="text-secondary"
                    style={{ fontSize: '13px' }}
                  >
                    {x.email}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Globalsearch;
