import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import ClientForms from './ClientForms';
import { ipaddress, ipaddress2, role } from '../App';
import axios from 'axios';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Preloader from './Preloader';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import * as XLSX from 'xlsx';
import Slider from 'react-slick';
import Slick_button_right from '../Slick_button_right';
import Slick_button_left from '../Slick_button_left';
import Modal from 'react-bootstrap/Modal';
import Small_preloader from '../Small_preloader';
import Lead_details from './Employee_leads/Lead_details';
import B2C_allleads from './Customerleads/B2C_allleads';
import B2C_newleads from './Customerleads/B2C_newleads';
import B2C_followupleads from './Customerleads/B2C_followupleads';
import B2C_prospectleads from './Customerleads/B2C_propsectleads';
import B2C_walkinleads from './Customerleads/B2C_walkinleads';
import B2C_registeredleads from './Customerleads/B2C_registeredleads';
import B2C_closedleads from './Customerleads/B2C_closedleads';
import B2C_otherleads from './Customerleads/B2C_otherleads';
import Excel_svg from '../Svgs/Excel_svg';
import Download_svg from '../Svgs/Download_svg';
import Plus_svg from '../Svgs/Plus_svg';

const Customerleads = ({ designation }) => {
  const [show, setshow] = useState(false);

  const renderTooltip3 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      View Invoice
    </Tooltip>
  );

  const [tab, setTab] = useState('');
  const navigate = useNavigate();

  const renderTooltip = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Upload Excel
    </Tooltip>
  );
  const renderTooltip2 = props => (
    <Tooltip
      id="button-tooltip"
      {...props}
    >
      Download Template
    </Tooltip>
  );

  const renderTooltip4 = value => (
    <Tooltip id="button-tooltip">{value}</Tooltip>
  );

  const [loading, setLoading] = useState(true);

  const [leads, setleads] = useState([]);
  const [referredleads, setreferredleads] = useState([]);
  const [allleads, setallleads] = useState([]);
  const [employees, setemployees] = useState([]);

  const user = JSON.parse(sessionStorage.getItem('user'));
  const [count, setCount] = useState(0);

  // -------------------------------------------------Fetch all the assigned employees--------------------------------------------
  const fetchemployees = () => {
    axios
      .get(`${ipaddress}/AllprofileView/`)
      .then(r => {
        console.log('Employees names', r.data);
        const data = r.data.filter(x => x.user.blockstatus == false);
        setemployees(data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // ------------------------------------------------Fetch leads-----------------------------------------------
  const [original_leads, setoriginal_leads] = useState({});

  const fetchleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerNewleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer new Leads', r.data);
        setoriginal_leads(r.data);
        setleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [leadapi, setleadapi] = useState('');

  useEffect(() => {
    if (leadapi !== '') {
      axios
        .get(`${leadapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_leads(r.data);
          setleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [leadapi]);

  // ------------------------------------------------Fetch referred leads-----------------------------------------------
  const [original_refferedleads, setoriginal_referredleads] = useState({});

  const fetchreferred_leads = () => {
    axios
      .get(`${ipaddress}/AllReferalCustomerLeads/`)
      .then(r => {
        console.log('Customer referred Leads', r.data);
        setoriginal_leads(r.data);
        setreferredleads(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [refferedapi, setrefferedapi] = useState('');

  useEffect(() => {
    if (refferedapi !== '') {
      axios
        .get(`${refferedapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_referredleads(r.data);
          setrefferedapi(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [refferedapi]);

  const [original_allleads, setoriginalallleads] = useState({});

  const fetchallleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/AllCustomerleads/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('All Customer Leads', r.data);
        setoriginalallleads(r.data);
        setallleads(r.data.results);
      })
      .catch(err => {
        console.log('all leads error', err);
      });
  };

  const downloadallleads = () => {
    // setload(true)
    axios
      .get(`${ipaddress}/AllCustomerleads/${user.emp_id}/?download=file`)
      .then(r => {
        //     const worksheet = XLSX.utils.json_to_sheet(value);
        // const workbook = XLSX.utils.book_new();
        // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        // XLSX.writeFile(workbook, `${name}.xlsx`);

        console.log('Excel data', r.data);
      })
      .catch(err => {
        console.log('all leads error', err);
      });
  };

  const [allleadapi, setallleadapi] = useState('');

  useEffect(() => {
    if (allleadapi !== '') {
      axios
        .get(`${allleadapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginalallleads(r.data);
          setallleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [allleadapi]);

  const [load2, setload2] = useState(false);

  useEffect(() => {
    setload(true);
    fetchleads();
    fetchallleads();
    fetchfollowupleads();
    fetchprospectsleads();
    fetchregisteredleads();
    fetchclosedleads();
    fetchemployees();
    fetchwalkinleads();
  }, [count]);

  const [lead_id, setlead_id] = useState('');
  const [client_lead_id, setclient_lead_id] = useState('');
  const [stage, setstage] = useState('');

  // --------------------------------------Fetch Walkin Leads----------------------------------------------------
  const [walkinleads, setwalkinleads] = useState([]);
  const [scanned_walkinleads, setscanned_walkinleads] = useState([]);

  const fetchwalkinleads = () => {
    axios
      .get(`${ipaddress}/ManagerWalkinleadView/${user.emp_id}/`)
      .then(r => {
        console.log('Customer walkin Leads', r.data);

        setwalkinleads(r.data.walkin_leads);
        setscanned_walkinleads(r.data.scanned_walkin_leads);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // --------------------------------------Fetch Followup Leads----------------------------------------------------
  const [followupleads, setfollowupleads] = useState([]);
  const [original_followupleads, setoriginal_followupleads] = useState({});

  const fetchfollowupleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerfollowupleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [followupapi, setfollowupapi] = useState('');

  useEffect(() => {
    if (followupapi !== '') {
      axios
        .get(`${followupapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_followupleads(r.data);
          setfollowupleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [followupapi]);

  // Fetch today followup leads
  const fetch_today_followupleads = () => {
    axios
      .post(`${ipaddress}/ManagerfollowupleadView/${user.emp_id}/`)
      .then(r => {
        console.log('Customer today followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results.today_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // Fetch pending followup leads
  const fetch_pending_followupleads = () => {
    axios
      .put(`${ipaddress}/ManagerfollowupleadView/${user.emp_id}/`)
      .then(r => {
        console.log('Customer pending followup Leads', r.data);
        setoriginal_followupleads(r.data);
        setfollowupleads(r.data.results.overdue_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // --------------------------------------Fetch Registered Leads----------------------------------------------------
  const [registeredleads, setregisteredleads] = useState([]);
  const [original_registeredleads, setoriginal_registeredleads] = useState({});
  const fetchregisteredleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerRegisteredleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer registered Leads', r.data);
        setoriginal_registeredleads(r.data);
        setregisteredleads(r.data);
      })
      .catch(err => {
        console.log('registered leads', err);
      });
  };

  const [registeredapi, setregisteredapi] = useState('');

  useEffect(() => {
    if (registeredapi !== '') {
      axios
        .get(`${registeredapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_registeredleads(r.data);
          setregisteredleads(r.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [registeredapi]);

  // Fetch pending registered leads
  const fetch_pending_registeredleads = () => {
    axios
      .get(`${ipaddress}/TodayRegistrationCandidates/${user.emp_id}/`)
      .then(r => {
        console.log('Customer pending registered Leads', r.data);
        setoriginal_registeredleads(r.data);
        setregisteredleads(r.data.today_followups);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // --------------------------------------Fetch Prospects Leads----------------------------------------------------
  const [prospectsleads, setprospectsleads] = useState([]);
  const [original_prospectleads, setoriginal_prospectleads] = useState({});

  const fetchprospectsleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerProspectleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer prospects Leads', r.data);
        setoriginal_prospectleads(r.data);
        setprospectsleads(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [prospectapi, setprospectapi] = useState('');

  useEffect(() => {
    if (prospectapi !== '') {
      axios
        .get(`${prospectapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_prospectleads(r.data);
          setprospectsleads(r.data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [prospectapi]);

  // Fetch pending prospect leads
  const fetch_pending_prospectleads = () => {
    axios
      .post(`${ipaddress}/ManagerProspectleadView/${user.emp_id}/`)
      .then(r => {
        console.log('Customer pending prospect Leads', r.data);
        setoriginal_prospectleads(r.data);
        setprospectsleads(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // --------------------------------------Fetch Closed Leads----------------------------------------------------
  const [closedleads, setclosedleads] = useState([]);
  const [original_closed, setoriginal_closed] = useState({});

  const fetchclosedleads = () => {
    setload(true);
    axios
      .get(`${ipaddress}/ManagerClosedleadView/${user.emp_id}/`)
      .then(r => {
        setload(false);
        console.log('Customer closed Leads', r.data);
        setoriginal_closed(r.data);
        setclosedleads(r.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const [closedapi, setclosedapi] = useState('');

  useEffect(() => {
    if (closedapi !== '') {
      axios
        .get(`${closedapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_closed(r.data);
          setclosedleads(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [closedapi]);

  // -------------------------------------Select the Leads----------------------------------------
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [selecetdemp, setselectedemp] = useState('');

  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (e, id) => {
    if (e.target.checked) {
      setSelectedLeads(prev => [...prev, id]);
    } else {
      setSelectedLeads(prev => prev.filter(leadId => leadId !== id));
      setSelectAll(false);
    }
  };

  const handleSelectAll = stage => {
    let allleads = [];

    if (!selectAll) {
      if (stage === 'newlead') {
        allleads = leads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'walkin') {
        allleads = walkinleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'followup') {
        allleads = followupleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'prospect') {
        allleads = prospectsleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'registered') {
        allleads = registeredleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'closed') {
        allleads = closedleads.map(lead => lead.customer_lead_id);
      }
      if (stage === 'other') {
        allleads = otherdepartment.map(lead => lead.customer_lead_id);
      }
      setSelectedLeads(allleads);
    } else {
      setSelectedLeads([]);
    }
    setSelectAll(!selectAll);
  };

  const assign_leads = value => {
    const emp_name = employees.find(x => x.user.emp_id === value);
    axios
      .post(`${ipaddress}/AssignToView/`, {
        loggedin_emp_id: user.emp_id,
        emp_id: value,
        customer_lead_ids: selectedLeads,
      })

      .then(r => {
        console.log('Successfully assigned', r.data);
        setselectedemp('');
        setSelectedLeads([]);
        toast.success(`Lead assigned to ${emp_name.user.first_name}`, {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setSelectAll(false);

        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch(err => {
        console.log('Assigning error', err);
      });
  };

  // -------------------------------------------------Search leads from all leads table-----------------------------------

  const [searchvalue, setsearchvalue] = useState('');
  const [category_name, setcategory_name] = useState('select');
  const [category_name2, setcategory_name2] = useState('select');
  const [category_name3, setcategory_name3] = useState('select');
  const [category_name4, setcategory_name4] = useState('select');
  const [category_name5, setcategory_name5] = useState('select');
  const [category_name6, setcategory_name6] = useState('select');
  const [category_name7, setcategory_name7] = useState('select');
  const [category_name8, setcategory_name8] = useState('select');
  const [category_name9, setcategory_name9] = useState('select');
  const [category_name10, setcategory_name10] = useState('select');

  const [category_state, setcategory_state] = useState(false);

  // Search leads
  const search_all_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchCategoryCustomerView/${user.emp_id}/${category_name}/${value}/`,
        )
        .then(r => {
          console.log('Customer search Leads', r.data);
          setallleads(r.data);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetchallleads();
    }
  };

  const search_new_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name2}/${value}/?level=newlead`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchleads();
    }
  };

  const search_referred_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name3}/${value}/?level=referred`,
        )
        .then(r => {
          console.log('Searched referred Leads', r.data);
          setreferredleads(r.data);
        })
        .catch(err => {
          console.log(err);
          // console.log(``)
        });
    } else {
      fetchreferred_leads();
    }
  };

  const search_walkin1_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name4}/${value}/?level=walkin`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setwalkinleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchwalkinleads();
    }
  };

  const search_walkin2_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name9}/${value}/?level=walkin`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setscanned_walkinleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchwalkinleads();
    }
  };

  const search_followup_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name5}/${value}/?level=followup`,
        )
        .then(r => {
          console.log('Searched followup Leads', r.data);
          // setoriginal_followupleads(r.data)
          setfollowupleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchfollowupleads();
    }
  };

  const search_prospect_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name6}/${value}/?level=prospect`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setprospectsleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchprospectsleads();
    }
  };

  const search_registered_leads = value => {
    if (value.length > 0) {
      console.log(value);
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name7}/${value}/?level=registered`,
        )
        .then(r => {
          console.log('Searched registered Leads', r.data);
          setregisteredleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchregisteredleads();
    }
  };

  const search_closed_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name8}/${value}/?level=closed`,
        )
        .then(r => {
          console.log('Searched new Leads', r.data);
          setclosedleads(r.data);
        })
        .catch(err => {
          console.log(err);
          console.log(
            `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`,
          );
          // console.log(``)
        });
    } else {
      fetchclosedleads();
    }
  };

  const search_other_leads = value => {
    if (value.length > 0) {
      axios
        .get(
          `${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name10}/${value}/?level=Other Department Lead`,
        )
        .then(r => {
          console.log('Searched other Leads', r.data);
          setotherdepartment(r.data);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      fetch_otherDepartmentleads();
    }
  };

  const search_closed_leads2 = () => {
    axios
      .get(
        `${ipaddress}/SearchStageWiseCustomerView/only_job/true/?level=closed`,
      )
      .then(r => {
        console.log('Searched new Leads', r.data);
        setclosedleads(r.data);
      })
      .catch(err => {
        console.log(err);
        // console.log(`${ipaddress}/SearchStageWiseCustomerView/${user.emp_id}/${category_name}/${value}/?level=newlead`)
        // console.log(``)
      });
  };

  // Upload Excel
  const [excel, setexcel] = useState(null);

  const uploadexcel = file => {
    const formdata = new FormData();
    formdata.append('file', excel);

    axios
      .post(`${ipaddress}/import-excel/?emp_id=${user.emp_id}`, formdata)
      .then(r => {
        console.log('Uploaded successfully', r.data);
        toast.success('File uploaded successfully', {
          autoClose: 2000,
          theme: 'colored',
          position: 'top-center',
        });
        setshow(false);
        setCount(count + 1);
        setexcel(null);
      })
      .catch(err => {
        console.log('Uploading error', err);
      });
  };

  // ---------------------------------------------------Delete New laeds-----------------------------------------------------
  const delete_lead = id => {
    axios.put(`${ipaddress}/DeleteView/${id}/`).then(r => {
      console.log('Deleted successfully', r.data);
      setCount(count + 1);
      toast.success('B2C lead deleted successfully', {
        autoClose: 2000,
        theme: 'colored',
        position: 'top-center',
      });
    });
  };

  // --------------------------------------------------Download Excel Template----------------------------------------------------
  const download_template = () => {
    axios.get(`${ipaddress}/DownloadCustomerExcel/`).then(r => {
      console.log('Triggered', r.data);
      const pdfUrl = r.data.url;
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = pdfUrl;
      link.download = r.data.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  // To view the assigned permissions

  const [permissions, setpermissions] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress2}/PermissionsView/${user.emp_id}/`).then(r => {
      console.log('Permissions', r.data);
      setpermissions(r.data);
    });
  }, []);

  const [walkin_status, setwalkin_status] = useState(true);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <Slick_button_right />,
    prevArrow: <Slick_button_left />,
    // initialSlide: 0,
    // autoplay: true,
    speed: 500,
    // autoplaySpeed: 3000,
    cssEase: 'linear',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [course_name, setcourse_name] = useState('');
  const [course_id, setcourse_id] = useState();
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);

  // Combination of search

  const fields = [
    'Name',
    'Email',
    'Contact_Number',
    'Course_Enquired',
    'Source',
    'Date',
    'Lead_Stage',
  ];

  const [fields_array, setfields_array] = useState({
    Course_Enquired: false,
    Source: false,
    Lead_Stage: false,
    Employee: false,
    Email: false,
    Name: false,
    Date: false,
    Contact_Number: false,
  });

  const handleFieldChange = field => {
    setfields_array(prev => ({
      ...prev,
      [field]: !prev[field], // Toggle the state of the specific field dynamically
    }));
  };

  const [formdata, setformdata] = useState({
    course_enquired: '',
    source: '',
    level_lead: '',
    email: '',
    lead_name: '',
    Date: '',
    contact_number: '',
  });

  const combine_fields = e => {
    const { name, value } = e.target;
    setformdata(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  const [load, setload] = useState(false);

  const cancel_combined_search = () => {
    setshow(false);
    setformdata({
      course_enquired: '',
      source: '',
      level_lead: '',
      emp_id: '',
      email: '',
      lead_name: '',
      Date: '',
      contact_number: '',
    });
    setfields_array({
      Course_Enquired: false,
      Source: false,
      Lead_Stage: false,
      Employee: false,
      Email: false,
      Name: false,
      Date: false,
      Contact_Number: false,
    });
  };

  const combined_search = () => {
    formdata.emp_id = Number(user.id);

    axios
      .post(`${ipaddress}/CombinationSearchCustomerView/`, formdata)
      .then(r => {
        console.log('Combined filter data', r.data);
        if (tab === 'all') {
          setallleads(r.data);
        }
        if (tab === 'newleads') {
          setleads(r.data);
        }
        if (tab === 'walkin') {
          setwalkinleads(r.data);
        }
        if (tab === 'referred') {
          setallleads(r.data);
        }
        if (tab === 'followup') {
          setfollowupleads(r.data);
        }
        if (tab === 'prospects') {
          setprospectsleads(r.data);
        }
        if (tab === 'registered') {
          setregisteredleads(r.data);
        }
        if (tab === 'closed') {
          setclosedleads(r.data);
        }
        cancel_combined_search();
      })
      .catch(err => {
        console.log('Combined search error', err);
      });
  };

  // Move to Other department

  const [otherdepartment, setotherdepartment] = useState([]);
  const [original_others, setoriginal_others] = useState({});

  const fetch_otherDepartmentleads = () => {
    setload(true);
    axios
      .get(
        `${ipaddress}/MoveToOtherDepartmentLead/?employee_id=${user.emp_id}&type=customer`,
      )
      .then(r => {
        setload(false);
        console.log('Other department leads', r.data);
        setoriginal_others(r.data);
        setotherdepartment(r.data.results);
      });
  };

  const [otherapi, setotherapi] = useState('');

  useEffect(() => {
    if (otherapi !== '') {
      axios
        .get(`${otherapi}`)
        .then(r => {
          console.log('Customer Registered Leads', r.data);
          setoriginal_others(r.data);
          setotherdepartment(r.data.results);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [otherapi]);

  const movetotherDepartmentleads = id => {
    axios
      .post(`${ipaddress}/MoveToOtherDepartmentLead/`, {
        customer_lead_id: id,
        employee_id: user.emp_id,
      })
      .then(r => {
        console.log('Other department leads', r.data);
        setCount(count + 1);
        toast.success('Moved to Other Department Leads', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
      });
  };

  const downloadExcel = (value, name) => {
    const worksheet = XLSX.utils.json_to_sheet(value);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${name}.xlsx`);
  };

  const [lead_detail_modal, setlead_detail_modal] = useState(false);

  const leadtypes = [
    {
      name: 'All Leads',
      type: 'all',
      slug: '/customerleads/',
    },
    {
      name: 'New Leads',
      type: 'newlead',
    },
    {
      name: 'Walkin Leads',
      type: 'walkin',
    },
    {
      name: 'Referred Leads',
      type: 'referred',
    },
    {
      name: 'Followup Leads',
      type: 'followup',
    },
    {
      name: 'Prospect Leads',
      type: 'prospect',
    },
    {
      name: 'Registered Leads',
      type: 'registered',
    },
    {
      name: 'Closed Leads',
      type: 'closed',
    },
    {
      name: 'Other Department Leads',
      type: 'other',
    },
  ];

  const [lead_stage, setlead_stage] = useState('all');

  const navigation = value => {
    const data = leadtypes.find(x => x.type === value);
    navigate(`/customerleads/${data.type}`);
    setTab(value);
  };

  return (
    <div>
      <div className="d-flex">
        <Sidebar
          designation={designation}
          activevalue={'customerleads'}
        />
        <div className="w-100">
          <Navbar
            designation={designation}
            pagename={'B2C Leads'}
          />

          <div
            className="pt-2 pt-md-3 main-container"
            style={{ minHeight: '80vh', backgroundColor: '#E8E8E8' }}
          >
            <div className="">
              {/* <div className='d-none d-lg-block'>
            <div className='lead-dashboard pt-3' style={{height:'100vh',backgroundColor:'#034592'}}>
              {leadtypes.map((x)=>{
                return(
                  <>
                  <p onClick={()=>{
                    // setTab(x.type)
                    navigate(`/customerleads/${x.type}`)
                  }} className={`py-3 leads-sidebar px-2 ${tab===x.type ? 'border-start border-4 border-primary':''}`} style={{backgroundColor:tab===x.type ? 'rgba(0,119,255,0.4)':''}}>{x.name}</p>
                  </>
                )
              })}
              </div>
           </div> */}
              <div className="">
                <div className="px-1 px-lg-3">
                  <div className="d-flex justify-content-between align-items-center bg-white p-4">
                    <div className="">
                      <select
                        name=""
                        onChange={e => {
                          navigation(e.target.value);
                        }}
                        value={tab}
                        className="form-control text-white fw-bold shadow-none text-center"
                        id=""
                        style={{
                          backgroundColor: '#002147',
                          cursor: 'pointer',
                          border: '1px solid #002147',
                        }}
                      >
                        {leadtypes.map(x => {
                          return (
                            <>
                              <option
                                style={{ lineHeight: '2.5em', padding: '10px' }}
                                value={x.type}
                              >
                                {x.name}
                              </option>
                            </>
                          );
                        })}
                      </select>
                    </div>
                    <div
                      className={`d-flex align-items-center justify-content-end`}
                      style={{ width: '40%' }}
                    >
                      <button
                        className="btn text-white px-4 me-3 d-flex align-items-center"
                        style={{ backgroundColor: '#002147', borderRadius: 0 }}
                        onClick={() => {
                          navigate('/customerregistrationform');
                        }}
                      >
                        <Plus_svg /> <span className="ms-2">Add New</span>
                      </button>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        overlay={renderTooltip}
                      >
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => setshow(true)}
                        >
                          <Excel_svg />
                        </span>
                      </OverlayTrigger>

                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 250, hide: 250 }}
                        overlay={renderTooltip2}
                      >
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            download_template();
                          }}
                          className="ms-3"
                        >
                          <Download_svg />
                        </span>
                      </OverlayTrigger>
                    </div>
                  </div>

                  <div id="myTabContent">
                    <Routes>
                      <Route
                        path="/*"
                        element={
                          <B2C_allleads
                            count1={count}
                            settab={setTab}
                          />
                        }
                      />
                      <Route
                        path="/newlead"
                        element={
                          <B2C_newleads
                            count1={count}
                            settab={setTab}
                          />
                        }
                      />
                      <Route
                        path="/followup"
                        element={
                          <B2C_followupleads
                            count1={count}
                            settab={setTab}
                          />
                        }
                      />
                      <Route
                        path="/prospect"
                        element={
                          <B2C_prospectleads
                            count1={count}
                            settab={setTab}
                          />
                        }
                      />
                      <Route
                        path="/walkin"
                        element={
                          <B2C_walkinleads
                            count1={count}
                            settab={setTab}
                          />
                        }
                      />
                      <Route
                        path="/registered"
                        element={
                          <B2C_registeredleads
                            count1={count}
                            settab={setTab}
                          />
                        }
                      />
                      <Route
                        path="/closed"
                        element={
                          <B2C_closedleads
                            count1={count}
                            settab={setTab}
                          />
                        }
                      />
                      <Route
                        path="/other"
                        element={
                          <B2C_otherleads
                            count1={count}
                            settab={setTab}
                          />
                        }
                      />
                    </Routes>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Upload Excel Modal */}
      <Modal
        show={show}
        onHide={() => setshow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Upload Excel Sheet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="modal-body">
            <div className="d-flex justify-content-center mt-3">
              <label
                htmlFor="file"
                style={{ cursor: 'pointer', border: '1px dashed gray' }}
                className="bg-light d-flex justify-content-center rounded py-2 px-4 w-75"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="24"
                  viewBox="0 0 14 24"
                  fill="none"
                >
                  <path
                    d="M13.6816 16.4231C13.6816 18.3165 13.0293 19.9276 11.7247 21.2566C10.4203 22.5855 8.825 23.25 6.93875 23.25C5.0525 23.25 3.45313 22.5855 2.14063 21.2566C0.828125 19.9276 0.171875 18.3165 0.171875 16.4231V5.50969C0.171875 4.1876 0.626562 3.06375 1.53594 2.13812C2.44552 1.21271 3.56135 0.75 4.88344 0.75C6.20552 0.75 7.32125 1.21271 8.23063 2.13812C9.14021 3.06375 9.595 4.1876 9.595 5.50969V15.8462C9.595 16.5802 9.33885 17.2124 8.82656 17.7428C8.31448 18.2732 7.69031 18.5384 6.95406 18.5384C6.21781 18.5384 5.58448 18.2774 5.05406 17.7553C4.52365 17.2332 4.25844 16.5969 4.25844 15.8462V5.46156H5.50844V15.8462C5.50844 16.2452 5.645 16.5853 5.91813 16.8666C6.19146 17.1478 6.5276 17.2884 6.92656 17.2884C7.32573 17.2884 7.66188 17.1478 7.935 16.8666C8.20833 16.5853 8.345 16.2452 8.345 15.8462V5.48562C8.34021 4.51437 8.00625 3.69063 7.34313 3.01438C6.68021 2.33813 5.86031 2 4.88344 2C3.91406 2 3.09479 2.34219 2.42563 3.02656C1.75646 3.71073 1.42188 4.53844 1.42188 5.50969V16.4231C1.41708 17.9665 1.95156 19.2818 3.02531 20.3691C4.09885 21.4564 5.40625 22 6.9475 22C8.46667 22 9.75802 21.4564 10.8216 20.3691C11.8851 19.2818 12.4218 17.9665 12.4316 16.4231V5.46156H13.6816V16.4231Z"
                    fill="#5D5FE3"
                  />
                </svg>{' '}
                <span
                  className="ms-3"
                  style={{
                    fontSize: '16px',
                    letterSpacing: '1px',
                    color: '#5d5fe3',
                  }}
                >
                  Attach your Excel File...
                </span>
              </label>
              <input
                type="file"
                onChange={e => {
                  setexcel(e.target.files[0]);
                }}
                id="file"
              />
            </div>
            <div className="mt-2">
              <p
                className="text-center m-0"
                style={{ color: 'green' }}
              >
                {excel && excel.name}
              </p>
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button
                data-bs-dismiss="modal"
                className="btn btn-primary btn-sm rounded-pill d-flex align-items-center px-3"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="15"
                  viewBox="0 0 21 15"
                  fill="none"
                >
                  <path
                    d="M4.771 14.7918C3.47159 14.7918 2.36548 14.3399 1.45269 13.4362C0.539894 12.5324 0.0834961 11.4278 0.0834961 10.1223C0.0834961 8.88837 0.508175 7.81866 1.35753 6.91321C2.20689 6.00778 3.20449 5.521 4.35032 5.45287C4.58403 3.93579 5.28182 2.68245 6.44368 1.69287C7.60554 0.703288 8.9577 0.208496 10.5002 0.208496C12.2412 0.208496 13.7182 0.814893 14.931 2.02769C16.1438 3.24048 16.7502 4.71742 16.7502 6.4585V7.50016H17.3912C18.3888 7.53221 19.2258 7.89846 19.9022 8.59891C20.5786 9.29936 20.9168 10.1484 20.9168 11.146C20.9168 12.1676 20.5713 13.0307 19.8802 13.7351C19.1891 14.4396 18.3327 14.7918 17.3111 14.7918H11.662C11.1826 14.7918 10.7823 14.6312 10.4611 14.3101C10.1399 13.9889 9.97933 13.5886 9.97933 13.1091V7.06347L7.79183 9.23894L7.05464 8.5218L10.5002 5.07628L13.9457 8.5218L13.2085 9.23894L11.021 7.06347V13.1091C11.021 13.2694 11.0878 13.4163 11.2213 13.5498C11.3549 13.6834 11.5018 13.7502 11.662 13.7502H17.271C18.0002 13.7502 18.6165 13.4984 19.12 12.995C19.6234 12.4915 19.8752 11.8752 19.8752 11.146C19.8752 10.4168 19.6234 9.80051 19.12 9.29704C18.6165 8.79357 18.0002 8.54183 17.271 8.54183H15.7085V6.4585C15.7085 5.01752 15.2007 3.78923 14.1851 2.7736C13.1694 1.75798 11.9411 1.25016 10.5002 1.25016C9.05919 1.25016 7.83089 1.75798 6.81527 2.7736C5.79964 3.78923 5.29183 5.01752 5.29183 6.4585H4.73094C3.76405 6.4585 2.92137 6.8144 2.2029 7.5262C1.48441 8.23801 1.12516 9.09738 1.12516 10.1043C1.12516 11.1113 1.48107 11.9706 2.19287 12.6825C2.90468 13.3943 3.76405 13.7502 4.771 13.7502H7.37516V14.7918H4.771Z"
                    fill="#fff"
                  />
                </svg>{' '}
                <span
                  className="ms-2"
                  onClick={() => {
                    uploadexcel();
                  }}
                >
                  Upload
                </span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Customerleads;
