import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { toast } from 'react-toastify'

const Ask_doubt = () => {

    const {id}=useParams()

    const navigate=useNavigate()

    const[query,setquery]=useState("")

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[batch_details,setBatch_details]=useState({})

    useEffect(()=>{
        axios.get(`${ipaddress2}/BatchDetails/${id}/`)
        .then((r)=>{
            console.log("Particular batch details",r.data)
            setBatch_details(r.data)
        })
    },[])


    const send_query=()=>{
        axios.post(`${ipaddress2}/QuerriesView/${user.email}/`,{
            batch_id:id,
            querry:query
        })
        .then((r)=>{
            console.log("Query sent",r.data)
            setquery("")
            setstate(true)
        })
    }

    const[state,setstate]=useState(false)

  return (
    <div className='d-flex animate__animated animate__fadeIn'>
        <Sidebar activevalue={"batches"}/>

        <div className="w-100">
            <Navbar/>

            <div className='bg-light p-3 py-5 main-container' style={{minHeight:'100vh'}}>
                <div className="row m-0 ask-doubt-div pb-4 justify-content-center w-75 mx-auto" style={{position:'relative',borderRadius:'15px',boxShadow:'2px 2px 10px 10px lightgray'}}>
                    <img src={require('../images/student/illustration.png')} style={{width:'220px',position:'absolute',bottom:0,left:0}} alt="" />

{/* Container 4 */}
                    <div className={`col-md-7 mt-5 ${state ? 'd-none':''}`}>
                        <h6 className='fw-bold text-center pb-4' style={{fontSize:'16px'}}>Have a doubt? I can help !</h6>
                        <div className='w-100 bg-white py-5' style={{borderRadius:'15px'}}>
                            <div className='w-75 mx-auto'>
                            <div className=''>
                            <label htmlFor="" className='fw-medium'>Trainer Name : </label>
                            <input type="text" 
                            value={batch_details.trainer && batch_details.trainer.first_name && batch_details.trainer.first_name} 
                            disabled='true' className='form-control bg-white mt-3 shadow-none' style={{border:'2px solid #002147'}} />
                        </div>
                        <div className='mt-4'>
                            <label htmlFor="" className='fw-medium'>Subject Name : </label>
                            <input type="text" 
                            value={batch_details.name && batch_details.name.sub_course_name && batch_details.name.sub_course_name}  
                            disabled='true' className='form-control bg-white mt-3 shadow-none' style={{border:'2px solid #002147'}}/>
                        </div>

                        <div className='mt-4'>
                            <label htmlFor="" className='fw-medium'>Ask your Query : </label>
                            <textarea rows={6} value={query} onChange={(e)=>{
                                setquery(e.target.value)
                            }} type="text" className='form-control bg-white mt-3 shadow-none' style={{border:'2px solid #002147'}} />
                        </div>
                            </div>
                        </div>
                        <div className='mt-4 text-end'>
                            <button className='btn btn-sm rounded-0 px-4 text-white' style={{backgroundColor:'#002147',height:'36px'}} onClick={send_query}>Submit</button>
                        </div>
                    </div>

{/* Conatiner 5 */}
<div className={`${state ? '':'d-none'} animate__animated animate__bounceIn col-md-7 mt-5`}>
                        <h6 className='fw-bold text-center pb-4' style={{fontSize:'16px'}}>Have a doubt? I can help !</h6>
                        <div className='w-100 bg-white py-5' style={{borderRadius:'15px'}}>
                            <div className='w-75 mx-auto d-flex flex-column align-items-center'>
                          <img src={require('../images/student/Group 37301.png')} style={{width:'100px'}} alt="" />
                          <p className='fw-bold mt-3' style={{color:'#170F49'}}>Submit your quote request</p>
                          <p className='text-center' style={{color:'#6F6C90'}}>Your Query got submitted successfully!</p>
                          <button className='btn btn-sm px-4 text-white mt-3' style={{backgroundColor:'#57309C',height:'36px'}} onClick={()=>{
                            navigate('/student_dashboard')
                          }}>Go to Dashboard</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Ask_doubt