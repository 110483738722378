import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { toast } from 'react-toastify'

const Add_batch = () => {

    const[batchtypes,setbatchtypes]=useState([])
const[classrooms,setclassrooms]=useState([])
const[trainers,settrainers]=useState([])
const[subcourses,setsubcourses]=useState([])

useEffect(()=>{
    fetch_details()
},[])

const user=JSON.parse(sessionStorage.getItem('user'))
const[main_courses,setmain_courses]=useState([])
const[main_course,setmain_course]=useState()

const fetch_details=()=>{
    axios.get(`${ipaddress2}/AddBatchType/${user.email}/`)
    .then((r)=>{
        console.log("Batch types",r.data)
        setbatchtypes(r.data)
    })

    axios.get(`${ipaddress2}/SendCourses/`)
    .then((r)=>{
      console.log("Courses successfully fetched",r.data)
      setmain_courses(r.data)
    })

    axios.get(`${ipaddress2}/Classrooms/`)
    .then((r)=>{
        console.log("Class rooms",r.data)
        setclassrooms(r.data)
    })

    axios.get(`${ipaddress2}/Trainers/`)
    .then((r)=>{
        console.log("Trainers",r.data)
        settrainers(r.data)
    })
}

const[filtered_sub_courses,setfiltered_sub_courses]=useState([])

const fetch_subcourses=(id)=>{
    axios.get(`${ipaddress2}/SubCoursesView/${user.email}/?id=${id}`)
    .then((r)=>{
        console.log("Filtered Sub courses",r.data)
        setsubcourses(r.data)
    })

}


const[batch_type,setbatch_type]=useState('')
const[sub_course,setsub_course]=useState('')
const[classroom,setclassroom]=useState('')
const[trainer,settrainer]=useState('')
const[start_date,setstart_date]=useState('')
const[start_time,setstart_time]=useState('')
const[end_date,setend_date]=useState('')
const[end_time,setend_time]=useState('')
const[registration_date,setregistration_date]=useState('')


const add_batch=()=>{
    axios.post(`${ipaddress2}/AddBatch/${user.email}/`,{
        'batch_type':Number(batch_type),
        'sub_course_id':Number(sub_course),
        'classroom':Number(classroom),
        'trainer':Number(trainer),
        'start_date':start_date,
        'start_time':start_time,
        'end_time':end_time,
    })
    .then((r)=>{
        console.log("Successfully batch added",r.data)
        setbatch_type('')
        setclassroom('')
        settrainer('')
        setsub_course('')
        setstart_date('')
        setstart_time('')
        setend_time('')

        toast.success(`New Batch added successfully`,{
            autoClose:1000,
            theme:'colored',
            position:'top-center'
        })
    })
    .catch((err)=>{
        console.log(err)
        console.log({
            'batch_type':Number(batch_type),
            'sub_course_id':Number(sub_course),
            'classroom':Number(classroom),
            'trainer':Number(trainer),
            'start_date':start_date,
            'start_time':start_time,
            'end_time':end_time
        })
    })
  }
  
  return (
    <div className='d-flex'>
      <Sidebar activevalue={"add_batch"}/>
      <div className="w-100">
        <Navbar/>

        <div className="bg-light p-4 main-container">
        <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Add New Batch</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
                <label htmlFor="">Batch Type</label>
                <select type="date" value={batch_type} onChange={(e)=>{
                    setbatch_type(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                    <option value="">Select Batch Type</option>
                    {batchtypes.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.batch_type_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

                <div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
                <label htmlFor="">Course Name</label>
                <select type="" value={main_course} onChange={(e)=>{
                    setmain_course(e.target.value)
                    fetch_subcourses(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                      <option value="">Select Course</option>
                    {main_courses.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.course_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

                <div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
                <label htmlFor="">Sub Course Name</label>
                <select type="date" value={sub_course} onChange={(e)=>{
                    setsub_course(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
                      <option value="">Select Sub Course</option>
                    {subcourses.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.sub_course_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Trainer Name</label>
<select type="date" value={trainer} onChange={(e)=>{
                    settrainer(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-secondary-subtle'>
<option value="">Select the Trainer</option>
                    {trainers.map((x)=>{
                        return(
                            <>
                            <option value={x.trainer_name.id}>{x.trainer_name && x.trainer_name.first_name && x.trainer_name.first_name}</option>
                            </>
                        )
                    })}
                    </select>
                    </div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Batch Start Date</label>
<input type="date" value={start_date} onChange={(e)=>{
    const today = new Date();
    const selectedDate = new Date(e.target.value);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
    else{
        setstart_date(e.target.value)
    }
                }} className='form-control shadow-none border-secondary-subtle bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter the amount' />
</div>

<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Batch Start Time</label>
<input type="time" value={start_time} onChange={(e)=>{
                    setstart_time(e.target.value)
                }} className='form-control bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter number of hours' />
</div>
<div className='mb-3 col-lg-4 col-md-6 border-bottom pb-3'>
<label htmlFor="">Batch End Date</label>
<input type="date" value={end_date} onChange={(e)=>{
    const today = new Date();
    const selectedDate = new Date(e.target.value);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
    else{
        setend_date(e.target.value)
    }
                }} className='form-control shadow-none border-secondary-subtle bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter the amount' />
</div>
<div className='mb-3 col-lg-4 col-md-6'>
<label htmlFor="">Batch End Time</label>
<input type="time" value={end_time} onChange={(e)=>{
                    setend_time(e.target.value)
                }} className='form-control bg-secondary-subtle py-2 add-option mt-2' placeholder='Enter number of hours' />
</div>


<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' onClick={add_batch}>Submit</button>

</div>
            </div>
        </div>
    </div>
        </div>
      </div>
    </div>
  )
}

export default Add_batch
