import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import { Zoom } from 'react-toastify';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { ipaddress } from '../../App';
import { useNavigate } from 'react-router-dom';

const Follow_up_form = ({ show, setShow, form_value, type }) => {
  console.log('Object', form_value);

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [availablecourses, setAvailablecourses] = useState([]);
  const [classtypes, setclasstypes] = useState([]);
  const [batchtypes, setbatchtypes] = useState([]);
  const [particular_lead_details, setparticular_lead_details] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress}/Allclasstypes/`).then(r => {
      console.log('Class types', r.data);
      setclasstypes(r.data.classtypes);
    });

    axios.get(`${ipaddress}/Allbatchtypes/`).then(r => {
      console.log('Batch types', r.data);
      setbatchtypes(r.data.batchtypes);
    });
  }, []);

  // To get current date
  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  const [errorMessage, setErrorMessage] = useState('');
  // Back Date Validation
  const handleDateValidation = (date, name) => {
    const today = new Date();
    const selectedDate = new Date(date);

    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert('You can only select today’s date or future dates.');
      return false;
    }

    setFormData({
      ...formData,
      [name]: date,
    });

    return true;
  };

  // Prospect Back date validation
  const handleDateValidation2 = (date, name) => {
    const today = new Date();
    const selectedDate = new Date(date);

    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert('You can only select today’s date or future dates.');
      return false;
    }

    setFormData({
      ...formData,
      [name]: date,
    });

    return true;
  };

  const [formData, setFormData] = useState({
    emp_id: user.emp_id,
    level_lead: 'followup',
    Followup_Date: getCurrentDate(),
    Expected_Registration_Walkin_Date: '',
    expected_walkin_date: '',
    expected_followup_date: '',
    Expected_Registration_Walkin_Time: '',
    expected_walkin_time: '',
    Need_Supervisor_Attention: false,
    Remarks: '',
  });

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const navigate = useNavigate();
  const [load, setload] = useState(false);

  const followupdata = e => {
    e.preventDefault();
    setload(true);

    const data = { ...formData, ...form_value };
    console.log('Merged object', data);

    data.emp_id = user.emp_id;

    let apiurl = '';

    if (type === 'customer') {
      apiurl = 'CustomerLeadsView';
    }
    if (type === 'client') {
      apiurl = 'ClientLeadsView';
    }

    axios
      .post(`${ipaddress}/${apiurl}/`, data)
      .then(r => {
        setShow(false);
        toast.success('Moved to Followup', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setload(false);
        console.log('Moved to Followup', r.data);
        console.log('Moved to Followup form', formData);
        setFormData({
          customer_lead_id: '',
          client_lead_id: '',
          emp_id: user.emp_id,
          level_lead: 'followup',
          Followup_Date: getCurrentDate(),
          Expected_Registration_Walkin_Date: '',
          expected_walkin_date: '',
          expected_followup_date: '',
          Expected_Registration_Walkin_Time: '',
          expected_walkin_time: '',
          Need_Supervisor_Attention: false,
          created_at: '',
          Remarks: '',
          priority: '',
        });

        setTimeout(() => {
          if (type === 'customer') {
            navigate('/customerleads');
          }
          if (type === 'client') {
            navigate('/leads');
          }
        }, 2000);
      })
      .catch(err => {
        setload(false);
        console.log('Moved to followup error', err);
        console.log('Moved to Followup form', formData);
      });
  };

  return (
    <div>
      {/* ------------------------------------FOLLOWUP FORM----------------------------------------------------------- */}

      <Modal
        size="md"
        aria-labelledby="example-modal-sizes-title-md"
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Body>
          <div class="modal-body">
            <div className="d-flex justify-content-between px-3 mb-4 align-items-center">
              <h5 className="text-primary">Followup Form </h5>
              <button
                onClick={() => {
                  setFormData({ ...formData, level_lead: 'newlead' });
                  setShow(false);
                }}
                className="bg-transparent border-0 ms-auto"
              >
                <i class="fa-solid fa-circle-xmark fs-5"></i>
              </button>
            </div>
            <form
              action=""
              onSubmit={followupdata}
            >
              <div className="row m-0">
                <div className="col-md-6">
                  <div class="form-floating mb-3">
                    <input
                      required
                      type="date"
                      class="form-control shadow-none"
                      id="floatingInput"
                      disabled="true"
                      placeholder="name@example.com"
                      name="Followup_Date"
                      value={formData.Followup_Date}
                      onChange={handleInputChange}
                    />
                    <label for="floatingInput">
                      Followup Date <span className="required-field">*</span>
                    </label>
                  </div>
                </div>

                <div className="col-md-6">
                  <div class={`form-floating mb-3`}>
                    <input
                      type="datetime-local"
                      class="form-control shadow-none"
                      id="floatingInput"
                      placeholder="name@example.com"
                      name="expected_followup_date"
                      value={formData.expected_followup_date}
                      onChange={e => {
                        handleDateValidation2(
                          e.target.value,
                          'expected_followup_date',
                        );
                      }}
                    />
                    <label for="floatingInput">
                      Expected Followup Date{' '}
                      <span className="required-field">*</span>
                    </label>
                  </div>
                </div>

                <div className="col-12">
                  <div class="mb-3">
                    <textarea
                      required
                      className="p-2 rounded border-secondary"
                      id=""
                      placeholder="Remarks..."
                      rows="3"
                      style={{ width: '100%' }}
                      name="Remarks"
                      value={formData.Remarks}
                      onChange={handleInputChange}
                    ></textarea>
                  </div>
                </div>
                <div className="col-12 d-flex align-items-center">
                  <input
                    name="Need_Supervisor_Attention"
                    type="checkbox"
                    id="checkbox1"
                    onClick={e => {
                      const { checked } = e.target;
                      setFormData(prev => ({
                        ...prev,
                        Need_Supervisor_Attention: checked,
                      }));
                    }}
                  />
                  <label
                    htmlFor="checkbox1"
                    className="ms-2 text-primary"
                    style={{ fontSize: '14px', fontWeight: 600 }}
                  >
                    Need Supervisor Attention
                  </label>
                </div>
                <div className="col-12 text-end mt-4">
                  <button
                    type="submit"
                    disabled={load}
                    className="btn btn-sm px-5 py-2 btn-primary text-white fw-medium"
                  >
                    {load ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Follow_up_form;
