import axios from 'axios';
import React, { useState } from 'react';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';

const Leave_payment_cash_modal = ({email,invoice_id,due_date,amount,fetchinvoice,setcount}) => {
  const [paymentType, setPaymentType] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);
  console.log(invoice_id)

  // State to hold denomination data
  const [denominations, setDenominations] = useState({
    500: 0,
    200: 0,
    100: 0,
    50: 0,
    20: 0,
    10: 0,
  });

  const [totalAmount, setTotalAmount] = useState(0);

  const calculateTotalAmount = (newDenominations) => {
    return Object.entries(newDenominations).reduce((sum, [key, count]) => sum + (key * count), 0);
  };

  const handleDenominationChange = (amount, count) => {
    setDenominations((prevDenominations) => {
      const newDenominations = { ...prevDenominations, [amount]: count };
      setTotalAmount(calculateTotalAmount(newDenominations));
      return newDenominations;
    });
  };

  const[url,seturl]=useState('')

  const handlePaymentTypeChange = (e) => {
    const selectedType = e.target.value;
    setPaymentType(selectedType);
    setBtnStatus(selectedType !== "Select");

    if (selectedType !== "Cash") {
      setDenominations({ 500: 0, 200: 0, 100: 0, 50: 0, 20: 0, 10: 0 });
      setTotalAmount(0);
    }
    if(selectedType==='upi'){
      axios.get(`${ipaddress2}/leave_pay/${amount}/${invoice_id}/`)
      .then((r)=>{
        console.log("API Triggered",r.data)
        seturl(r.data.redirect_url)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  };

  const user=JSON.parse(sessionStorage.getItem('user'))

  const [chequedata,setChequedata]=useState({
    'cheque_number':'',
    'cheque_date':'',
    'bank_name':'',
    'branch':'',
    'payee_name':'',
    'invoice_id':'',
    'person_name':'',
  })

  const send_data=()=>{
    if(paymentType==='Cash'){
      axios.post(`${ipaddress2}/OfflinePaymentForLeave/${invoice_id}/${amount}/${user.emp_id}/`,denominations)
      .then((r)=>{
        console.log("Cash paid",r.data)
        setcount(prev=>prev+1)
        console.log(invoice_id,amount)
        toast.success('Paid successfully',{
          autoClose:2000,
          theme:'colored',
      position:'top-center'
        })
        setDenominations({ 500: 0, 200: 0, 100: 0, 50: 0, 20: 0, 10: 0 });
        // fetchinvoice(invoice_id)
      })
      .catch((err)=>{
        console.log(err)
      })
    }

    if(paymentType==='Cheque'){
      chequedata.invoice_id=invoice_id
      chequedata.person_name=user.emp_id
      // chequedata.DD=due_date

      axios.post(`${ipaddress2}/ChequePaymentForLeave/`,chequedata)
      .then((r)=>{
        console.log("Cheque data sent",r.data)
        setcount(prev=>prev+1)
        setChequedata({
          'cheque_number':'',
          'cheque_date':'',
          'bank_name':'',
          'branch':'',
          'payee_name':'',
        })
        toast.success('Cheque is under processing',{
          autoClose:2000
        })
      })
      .catch((err)=>{
        console.log("Cheque errpr",err)
        console.log(invoice_id)
      })
    }
  }

const handleChequedata=(e)=>{
  const {name,value}=e.target
  setChequedata((prev)=>({
    ...prev,
    [name]:value
  }))
}
  return (
    <div>
      <div className="modal fade" id="leave_payment" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className='d-flex justify-content-between px-3 mb-4 align-items-center'>
                <h5 className='text-primary'>Mode of Payment</h5>
                <button data-bs-dismiss="modal" className='bg-transparent border-0 ms-auto'><i className="fa-solid fa-circle-xmark fs-5"></i></button>
              </div>
              <form>
                <div className="row m-0">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <input type="text" className="form-control shadow-none py-2 bg-light" value={email} disabled placeholder="Lead Id" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <select onChange={handlePaymentTypeChange} className='form-select py-2 bg-light shadow-none'>
                      <option value="Select">Select Payment type</option>
                      <option value="Cash">Cash</option>
                      {/* <option value="Cheque">Cheque</option> */}
                      {/* <option value="Bank_transfer">Bank Transfer</option> */}
                      <option value="upi">UPI</option>
                    </select>
                  </div>
                  <div className="col-12 border-top pt-2">

                    {/* Cash Denomination Layout */}
                    <div className={`row m-0 ${paymentType === "Cash" ? '' : 'd-none'}`}>
                      <h6 className='ps-0 mt-2 pb-2 text-primary'>Cash Denomination</h6>
                      <div className="col-md-10 mx-auto">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Rupees</th>
                              <th scope="col"></th>
                              <th scope="col">Count</th>
                              <th scope="col"></th>
                              <th scope="col">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[500, 200, 100, 50, 20, 10].map((amount) => (
                              <tr key={amount}>
                                <td>{amount}</td>
                                <td className='text-center'><i className="bi bi-x-lg"></i></td>
                                <td>
                                  <input
                                    type="number"
                                    value={denominations[amount]}
                                    onChange={(e) => handleDenominationChange(amount, parseInt(e.target.value))}
                                    className='form-control border-0 shadow-none'
                                  />
                                </td>
                                <td className='text-center'><i className="fa-solid fa-equals"></i></td>
                                <td>{amount * denominations[amount]}</td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan="4" className='text-end'>
                                <span className='fw-medium text-primary' style={{ cursor: 'pointer' }}>
                                  Total Amount
                                </span>
                              </td>
                              <td>
                                <i className="bi bi-currency-rupee"></i> <span>{totalAmount}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/* Cheque Layout */}
                    <div className={`row m-0 ${paymentType === "Cheque" ? '' : 'd-none'}`}>
                      <h6 className='ps-0 mt-2 pb-2 text-primary'>Cheque Payment</h6>
                      <div className="col-md-6 ps-0">
                        <div className="mb-3">
                          <label htmlFor="chequeNumber">Cheque Number</label>
                          <input type="text" value={chequedata.cheque_number} name='cheque_number' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="chequeNumber" />
                        </div>
                      </div>
                      <div className="col-md-6 pe-0">
                        <div className="mb-3">
                          <label htmlFor="bankName">Bank Name</label>
                          <input type="text" value={chequedata.bank_name} name='bank_name' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="bankName" />
                        </div>
                      </div>
                      <div className="col-md-6 ps-0">
                        <div className="mb-3">
                          <label htmlFor="bankName">Branch Name</label>
                          <input type="text" value={chequedata.branch} name='branch' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="bankName" />
                        </div>
                      </div>
                      <div className="col-md-6 pe-0">
                        <div className="mb-3">
                          <label htmlFor="chequeDate">Cheque Date</label>
                          <input type="date" value={chequedata.cheque_date} name='cheque_date' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="chequeDate" />
                        </div>
                      </div>
                      <div className="col-md-6 ps-0">
                        <div className="mb-3">
                          <label htmlFor="bankName">Payee Name</label>
                          <input type="text" value={chequedata.payee_name} name='payee_name' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="bankName" />
                        </div>
                      </div>
                      <div className="col-12 ps-0 mt-2">
                        <div className="mb-3 d-flex align-items-center">
                          <label htmlFor="cheque_realization" style={{fontSize:'13px'}}><span className='me-1 ms-2' style={{color:'red'}}>*</span>For Every Bounce Charges, Bank Charges Will Be Applied</label>
                        </div>
                      </div>
                    </div>

                    {/* Bank Transfer Layout */}
                    <div className={`row m-0 ${paymentType === "Bank_transfer" ? '' : 'd-none'}`}>
                      <h6 className='ps-0 mt-2 pb-2 text-primary'>Bank Transfer</h6>
                      <div className="col-md-6 ps-0">
                        <div className="mb-3">
                          <label htmlFor="transferBankName">Bank Name</label>
                          <input type="text" className="form-control shadow-none py-2 bg-light" id="transferBankName" />
                        </div>
                      </div>
                      <div className="col-md-6 pe-0">
                        <div className="mb-3">
                          <label htmlFor="accountNumber">Account Number</label>
                          <input type="text" className="form-control shadow-none py-2 bg-light" id="accountNumber" />
                        </div>
                      </div>
                      <div className="col-md-6 ps-0">
                        <div className="mb-3">
                          <label htmlFor="ifscCode">IFSC Code</label>
                          <input type="text" className="form-control shadow-none py-2 bg-light" id="ifscCode" />
                        </div>
                      </div>
                    </div>

                    {/* UPI Layout */}
                    {/* <div className={`row m-0 ${paymentType === "upi" ? '' : 'd-none'}`}>
                      <h6 className='ps-0 mt-2 pb-2 text-primary'>UPI Transaction</h6>
                      <div className="col-md-6 ps-0">
                        <div className="mb-3">
                          <label htmlFor="upiNumber">UPI Number</label>
                          <input type="text" className="form-control shadow-none py-2 bg-light" id="upiNumber" />
                        </div>
                      </div>
                      <div className="col-md-6 pe-0">
                        <div className="mb-3">
                          <label htmlFor="transactionId">Transaction Id</label>
                          <input type="text" className="form-control shadow-none py-2 bg-light" id="transactionId" />
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <div className="col-12 text-end mt-3">
                    <button data-bs-dismiss="modal"  disabled={!btnStatus} onClick={(e)=>{
                      e.preventDefault()
                      send_data()
                    }} className={`btn btn-sm px-5 py-2 btn-primary text-white fw-medium ${url===''?'':'d-none'}`}>
                      Submit
                    </button>
                    <a href={url} target='_self' className={`btn btn-sm px-5 py-2 btn-primary text-white fw-medium ${url===''?'d-none':''}`}>
                      Pay
                    </a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leave_payment_cash_modal
