import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { ipaddress2 } from '../App';

export default function EditPriceModal({
  show2,
  setshow2,
  current_invoidedata,
  total,
  setTotal,
  gst,
  installments,
  setInstallments,
  id,
}) {
  const [numInstallments, setNumInstallments] = useState([]);

  const [dueDates, setDueDates] = useState();
  // console.log('DUE DATES', total, gst, installments);
  console.log('INSTALLMENTS', installments);

  useEffect(() => {
    if (current_invoidedata?.due_dates) {
      console.log(current_invoidedata.due_dates);
      setInstallments(
        current_invoidedata.due_dates.map(due => ({
          ...due,
          fees: parseFloat(due.fees),
        })),
      );
      setNumInstallments(installments.length);
    }
  }, [current_invoidedata]);

  const updatePrice = id => {
    axios
      .put(`${ipaddress2}/ShowInvoice/${id}/`, {
        grand_total: total,
        grand_gst_total: gst,
        invoice_number: current_invoidedata.invoice_number,
      })
      .then(res => {
        console.log(res.data);
        updateInstallments(id);
      })
      .catch(err => console.log(err));
  };

  const updateInstallments = id => {
    console.log({ due_dates: [...installments] });
    const updatedInstalls = installments.map(each => ({
      fees: each.fees,
      due_date: each.due_date,
    }));

    console.log('UPDATED', updatedInstalls);

    axios
      .patch(`${ipaddress2}/ShowInvoice/${id}/`, updatedInstalls)
      .then(res => {
        console.log(res.data);
        setshow2(false);
      })
      .catch(err => console.log(err));
  };

  const handleInstallmentCountChange = e => {
    const count = parseInt(e.target.value) || 0;
    setNumInstallments(count);

    const newInstallments = Array(count)
      .fill()
      .map((_, idx) => ({
        fees: installments[idx]?.fees || 0,
        due_date: installments[idx]?.due_date || '',
      }));
    setInstallments(newInstallments);
  };

  const handleInstallmentFeeChange = (index, value) => {
    const updatedInstallments = installments.map((installment, idx) =>
      idx === index
        ? { ...installment, fees: parseFloat(value) || 0 }
        : installment,
    );
    setInstallments(updatedInstallments);
  };

  const handleInstallmentDueDate = (index, value) => {
    const updatedInstallmentDues = installments.map((installment, idx) =>
      idx === index ? { ...installment, due_date: value } : installment,
    );
    setInstallments(updatedInstallmentDues);
  };

  const installmentsTotal = installments?.reduce(
    (sum, installment) => sum + +installment.fees,
    0,
  );

  return (
    <Modal
      show={show2}
      onHide={() => setshow2(false)}
      size="lg"
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header className="d-flex align-items-center justify-content-between p-4">
        <Modal.Title className="text-success fs-5">Edit Price</Modal.Title>
        <svg
          style={{ cursor: 'pointer' }}
          onClick={() => setshow2(false)}
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="currentColor"
          className="bi bi-x-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
        </svg>
      </Modal.Header>
      <Modal.Body>
        <div className="p-4">
          <label
            htmlFor="grandTotal"
            className="form-label"
          >
            Grand Total
          </label>
          <input
            id="grandTotal"
            type="number"
            className="form-control mb-3"
            value={total}
            onChange={e => setTotal(parseFloat(e.target.value) || 0)}
          />

          <div className="mb-3">
            <p className="fw-bold">GST:</p>

            <p>
              {(gst || 0).toLocaleString('en-US', { maximumFractionDigits: 2 })}
            </p>
          </div>

          <div className="mb-3">
            <label
              htmlFor="numInstallments"
              className="form-label"
            >
              Number of Installments
            </label>
            <input
              id="numInstallments"
              type="number"
              className="form-control mb-3"
              value={numInstallments}
              onChange={handleInstallmentCountChange}
            />
          </div>

          <div>
            <h6>Installments</h6>
            {installments?.map((installment, index) => (
              <div
                key={index}
                className="mb-2"
              >
                <label>Installment {index + 1} Amount</label>
                <input
                  type="number"
                  className="form-control"
                  value={installment.fees}
                  onChange={e =>
                    handleInstallmentFeeChange(index, e.target.value)
                  }
                />
                <label>Installment {index + 1} Due Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={installment.due_date}
                  onChange={e =>
                    handleInstallmentDueDate(index, e.target.value)
                  }
                />
              </div>
            ))}
          </div>

          <div className="mt-3">
            <p>Total of Installments: {installmentsTotal}</p>
            <p className="text-danger">
              {installmentsTotal !== total &&
                'Total installments do not match Grand Total.'}
            </p>
          </div>

          <button
            type="button"
            className="btn btn-primary w-100"
            onClick={() => updatePrice(current_invoidedata.invoice_number)}
            disabled={installmentsTotal !== total}
          >
            Submit
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
