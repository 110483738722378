import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import axios from 'axios';
import { ipaddress } from '../App';
import { useNavigate, useParams } from 'react-router-dom';
import Lead_details from './Employee_leads/Lead_details';
import * as XLSX from 'xlsx';
import Arrow_svg from '../Svgs/Arrow_svg';

const Employee_today_leads = () => {
  const { id } = useParams();

  const [today_leads, settoday_leads] = useState({});
  const [emp_data, setemp_data] = useState({});
  const [today_all_leads, settoday_all_leads] = useState([]);
  const [lead_type, setlead_type] = useState('today_added_leads');

  const today = new Date();
  const todaysDate = today.getDate();
  const todaysMonth = today.getMonth();
  const todaysYear = today.getFullYear();

  const todayDate = `${todaysYear}-${todaysMonth + 1}-${todaysDate}`;

  const [dateWise, setDateWise] = useState();

  useEffect(() => {
    axios.get(`${ipaddress}/TodayEntireRecordsOfEmployee/${id}/`).then(r => {
      console.log('Today leads count', r.data);
      settoday_leads(r.data);
    });

    axios.get(`${ipaddress}/MyprofileView/${id}/`).then(r => {
      console.log('Employee data', r.data);
      setemp_data(r.data);
    });
  }, [id]);

  useEffect(() => {
    fetch_leads();
  }, [lead_type]);

  const fetch_leads = () => {
    console.log('ISTODAY', todayDate);
    const IPPADDRESS =
      todayDate === dateWise
        ? `${ipaddress}/DetailsOfRecords/${id}/?today_added_leads=${lead_type}`
        : `${ipaddress}/DetailsOfRecords/${id}/?today_added_leads=${lead_type}&date=${dateWise}`;

    axios.get(IPPADDRESS).then(r => {
      // setLoading(false)
      console.log('DetailsOfRecords', IPPADDRESS);
      console.log('All today leads DetailsOfRecords', r.data);
      const data =
        r.data?.customer_data?.concat(r.data?.client_data) ||
        r.data?.customer_leads?.concat(r.data?.client_leads);
      settoday_all_leads(data);
      //   setleads(r.data.customers_data)
      //   setclient_newleads(r.data.clients_data)
    });
  };

  const [lead_detail_modal, setlead_detail_modal] = useState(false);
  const [lead_id, setlead_id] = useState();

  useEffect(() => {
    if (lead_id) {
      setlead_detail_modal(true);
    }
  }, [lead_id]);

  const [category_name4, setcategory_name4] = useState('');

  const search_scanned_walkin_leads = value => {
    console.log(value);
    if (value) {
      let data = [];
      if (category_name4 === 'created_at') {
        console.log(value);
        data = today_all_leads.filter(x =>
          x.created_at.slice(0, 10).includes(value),
        );
        settoday_all_leads(data);
      } else {
        data = today_all_leads.filter(
          x =>
            x[category_name4] &&
            x[category_name4].toLowerCase().includes(value.toLowerCase()),
        );
        settoday_all_leads(data);
      }
      console.log('Filtered scanned leads', data);
    } else {
      fetch_leads();
    }
  };

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(today_all_leads);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${emp_data.firstname}'s_${lead_type}.xlsx`);
  };

  const navigate = useNavigate();

  const getDateWise = () => {
    const IPADDRESS =
      todayDate === dateWise
        ? `${ipaddress}/TodayEntireRecordsOfEmployee/${id}/`
        : `${ipaddress}/TodayEntireRecordsOfEmployee/${id}/?date=${dateWise}`;

    if (dateWise) {
      axios
        .get(IPADDRESS)
        .then(r => {
          console.log('DATEWISE DATA FROM BACKEND', r.data);
          settoday_leads(r.data);
        })
        .catch(error => {
          console.error('Error fetching today’s leads:', error);
        });

      axios
        .get(`${ipaddress}/MyprofileView/${id}/`)
        .then(r => {
          console.log('Employee data', r.data);
          setemp_data(r.data);
        })
        .catch(error => {
          console.error('Error fetching employee data:', error);
        });
    }
  };

  useEffect(() => {
    getDateWise();
  }, [dateWise]); // Trigger API call when dateWise changes

  // const today = new Date();
  // const todaysDate = today.toLocaleDateString('en-GB').replace(/\//g, '-'); // Formats as "DD-MM-YYYY"

  // console.log('DATENOW', todaysDate);

  return (
    <div className="d-flex">
      <Sidebar activevalue={'employees'} />
      <div className="w-100 main-div">
        <Navbar pagename={'Employees'} />
        <div
          className="p-2 p-md-3 main-container"
          style={{ backgroundColor: '#E8E8E8' }}
        >
          <h5
            className="d-flex align-items-center text-color fw-bold mb-3 fs-6"
            style={{ letterSpacing: '1px' }}
          >
            <span
              className="me-2"
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(`/employee_profile/${id}`)}
            >
              <Arrow_svg />
            </span>
            {emp_data.firstname && emp_data.firstname.toUpperCase()}'S TODAY
            PROGRESS
          </h5>

          <div className="tw-mb-2  tw-text-end tw-mr-6">
            <input
              className="tw-text-black tw-p-2 tw-mr-3 tw-border tw-rounded-lg"
              type="date"
              name="dateWise"
              id="dateWise"
              value={dateWise}
              onChange={e => {
                // console.log('DATEWISE', todayDate === e.target.value);
                // console.log('DATEWISE', e.target.value, todayDate);
                setDateWise(e.target.value);
              }}
            />
          </div>
          <div
            className="bg-white p-3"
            style={{ minHeight: '100vh' }}
          >
            <div className="row m-0 ">
              <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
                <div
                  className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
                    lead_type === 'today_added_leads'
                      ? 'seleceted_empcard'
                      : 'emp-cards'
                  }`}
                  onClick={() => {
                    setlead_type('today_added_leads');
                  }}
                >
                  <img
                    src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                    className="d-block mx-auto"
                    style={{ width: '46%' }}
                    alt=""
                  />
                  <p
                    className="m-0 text-center mt-2 fw-bold"
                    style={{ fontSize: '13px' }}
                  >
                    Added Leads
                  </p>
                  <p
                    className="fw-medium text-center m-0"
                    style={{ fontSize: '14px' }}
                  >
                    {today_leads.today_added_leads}
                  </p>
                </div>
              </div>

              <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
                <div
                  className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
                    lead_type === 'today_added_followups'
                      ? 'seleceted_empcard'
                      : 'emp-cards'
                  }`}
                  onClick={() => {
                    setlead_type('today_added_followups');
                  }}
                >
                  <img
                    src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                    className="d-block mx-auto"
                    style={{ width: '46%' }}
                    alt=""
                  />
                  <p
                    className="m-0 text-center mt-2 fw-bold"
                    style={{ fontSize: '13px' }}
                  >
                    Followup Leads
                  </p>
                  <p
                    className="fw-medium text-center m-0"
                    style={{ fontSize: '14px' }}
                  >
                    {today_leads.today_added_followups}
                  </p>
                </div>
              </div>

              <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
                <div
                  className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
                    lead_type === 'today_added_prospects'
                      ? 'seleceted_empcard'
                      : 'emp-cards'
                  }`}
                  onClick={() => {
                    setlead_type('today_added_prospects');
                  }}
                >
                  <img
                    src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                    className="d-block mx-auto"
                    style={{ width: '46%' }}
                    alt=""
                  />
                  <p
                    className="m-0 text-center mt-2 fw-bold"
                    style={{ fontSize: '13px' }}
                  >
                    Prospect Leads
                  </p>
                  <p
                    className="fw-medium text-center m-0"
                    style={{ fontSize: '14px' }}
                  >
                    {today_leads.today_added_prospects}
                  </p>
                </div>
              </div>

              <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
                <div
                  className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
                    lead_type === 'today_walkin_leads'
                      ? 'seleceted_empcard'
                      : 'emp-cards'
                  }`}
                  onClick={() => {
                    setlead_type('today_walkin_leads');
                  }}
                >
                  <img
                    src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                    className="d-block mx-auto"
                    style={{ width: '46%' }}
                    alt=""
                  />
                  <p
                    className="m-0 text-center mt-2 fw-bold"
                    style={{ fontSize: '13px' }}
                  >
                    Walkin/Meeting Leads
                  </p>
                  <p
                    className="fw-medium text-center m-0"
                    style={{ fontSize: '14px' }}
                  >
                    {today_leads.today_walkin_leads}
                  </p>
                </div>
              </div>

              <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
                <div
                  className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
                    lead_type === 'today_closed_leads'
                      ? 'seleceted_empcard'
                      : 'emp-cards'
                  }`}
                  onClick={() => {
                    setlead_type('today_closed_leads');
                  }}
                >
                  <img
                    src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                    className="d-block mx-auto"
                    style={{ width: '46%' }}
                    alt=""
                  />
                  <p
                    className="m-0 text-center mt-2 fw-bold"
                    style={{ fontSize: '13px' }}
                  >
                    Closed Leads
                  </p>
                  <p
                    className="fw-medium text-center m-0"
                    style={{ fontSize: '14px' }}
                  >
                    {today_leads.today_closed_leads}
                  </p>
                </div>
              </div>

              <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
                <div
                  className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
                    lead_type === 'registration_leads'
                      ? 'seleceted_empcard'
                      : 'emp-cards'
                  }`}
                  onClick={() => {
                    setlead_type('registration_leads');
                  }}
                >
                  <img
                    src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                    className="d-block mx-auto"
                    style={{ width: '46%' }}
                    alt=""
                  />
                  <p
                    className="m-0 text-center mt-2 fw-bold"
                    style={{ fontSize: '13px' }}
                  >
                    Registration Leads
                  </p>
                  <p
                    className="fw-medium text-center m-0"
                    style={{ fontSize: '14px' }}
                  >
                    {today_leads.registration_leads}
                  </p>
                </div>
              </div>

              <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
                <div
                  className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
                    lead_type === 'today_pending_followups'
                      ? 'seleceted_empcard'
                      : 'emp-cards'
                  }`}
                  onClick={() => {
                    setlead_type('today_pending_followups');
                  }}
                >
                  <img
                    src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                    className="d-block mx-auto"
                    style={{ width: '46%' }}
                    alt=""
                  />
                  <p
                    className="m-0 text-center mt-2 fw-bold"
                    style={{ fontSize: '13px' }}
                  >
                    Pending Followups
                  </p>
                  <p
                    className="fw-medium text-center m-0"
                    style={{ fontSize: '14px' }}
                  >
                    {today_leads.today_pending_followups}
                  </p>
                </div>
              </div>
              <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
                <div
                  className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
                    lead_type === 'billing_amount'
                      ? 'seleceted_empcard'
                      : 'emp-cards'
                  }`}
                  // onClick={() => {
                  //   setlead_type('billing_amount');
                  // }}
                >
                  <img
                    src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                    className="d-block mx-auto"
                    style={{ width: '46%' }}
                    alt=""
                  />
                  <p
                    className="m-0 text-center mt-2 fw-bold"
                    style={{ fontSize: '13px' }}
                  >
                    Billing Amount
                  </p>
                  <p
                    className="fw-medium text-center m-0"
                    style={{ fontSize: '14px' }}
                  >
                    {today_leads.billing_amount}
                  </p>
                </div>
              </div>
              <div className="p-2 px-3 col-md-4 col-lg-2 mb-3">
                <div
                  className={`py-3 px-2 px-lg-3 py-md-4 shadow-sm ${
                    lead_type === 'total_collection_today'
                      ? 'seleceted_empcard'
                      : 'emp-cards'
                  }`}
                  // onClick={() => {
                  //   setlead_type('total_collection_today');
                  // }}
                >
                  <img
                    src={require('../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png')}
                    className="d-block mx-auto"
                    style={{ width: '46%' }}
                    alt=""
                  />
                  <p
                    className="m-0 text-center mt-2 fw-bold"
                    style={{ fontSize: '13px' }}
                  >
                    Collection Today
                  </p>
                  <p
                    className="fw-medium text-center m-0"
                    style={{ fontSize: '14px' }}
                  >
                    {today_leads.total_collection_today}
                  </p>
                </div>
              </div>
            </div>

            <div>
              <div className="d-flex justify-content-between align-items-center px-2 mb-3">
                <div className="d-flex align-items-center w-50 justify-content-end">
                  {/* <select value={category_name4} onChange={(e)=>{
          setcategory_name4(e.target.value)
            fetch_leads()
        }}  name="" id="" className='ms-3 form-select shadow-none' style={{width:'30%',cursor:'pointer'}}>
            <option value="select">None</option>
            <option value="name">Name</option>
            <option value="email">Email</option>
            <option value="course_enquiredd">Course</option>
            <option value="contact_number">Phone Number</option>
            <option value="employee">Employee</option>
            <option value="created_at">Date</option>
            <option value="custom">Combined search</option>
          </select> */}
                  {/* <div class={`input-group rounded bg-white border ms-3 ${category_name4==="created_at" || category_name4==='custom' || category_name4==='mobile_number' | category_name4==='employee' || category_name4==='level_lead' || category_name4==='select' ? 'd-none':''}`} style={{width:'50%'}}>
      <span class="input-group-text bg-transparent border-0" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 19 18" fill="none">
      <path d="M17.297 17.9936L10.7745 11.4712C10.2537 11.9145 9.65471 12.2577 8.97763 12.5008C8.30055 12.7439 7.62012 12.8654 6.93635 12.8654C5.26795 12.8654 3.85593 12.2879 2.70029 11.1329C1.54463 9.97784 0.966797 8.56658 0.966797 6.89908C0.966797 5.23158 1.54431 3.81924 2.69932 2.66205C3.85434 1.50486 5.2656 0.92627 6.9331 0.92627C8.60061 0.92627 10.013 1.50409 11.1701 2.65973C12.3273 3.81538 12.9059 5.22741 12.9059 6.89583C12.9059 7.61965 12.7777 8.3201 12.5213 8.99718C12.2649 9.67427 11.9284 10.2532 11.5117 10.734L18.0341 17.2564L17.297 17.9936ZM6.93635 11.8237C8.31857 11.8237 9.48544 11.348 10.437 10.3964C11.3885 9.4449 11.8642 8.27803 11.8642 6.89583C11.8642 5.51362 11.3885 4.34676 10.437 3.39523C9.48544 2.4437 8.31857 1.96794 6.93635 1.96794C5.55415 1.96794 4.38728 2.4437 3.43575 3.39523C2.48424 4.34676 2.00849 5.51362 2.00849 6.89583C2.00849 8.27803 2.48424 9.4449 3.43575 10.3964C4.38728 11.348 5.55415 11.8237 6.93635 11.8237Z" fill="#8E9696"/>
    </svg></span>
      <input type="text" onChange={(e)=>{
        search_scanned_walkin_leads(e.target.value)
      }} class="form-control bg-transparent border-0 ps-0 shadow-none" placeholder="Search here..." aria-label="Username" aria-describedby="basic-addon1"/>
    </div> */}

                  {/* Filter based on Date */}
                  <input
                    type="date"
                    onChange={e => {
                      search_scanned_walkin_leads(e.target.value);
                    }}
                    className={`form-control w-50 ms-3 ${
                      category_name4 === 'created_at' &&
                      category_name4 !== 'select'
                        ? ''
                        : 'd-none'
                    }`}
                  />

                  {/* Filter based on Date */}
                  <input
                    type="tel"
                    maxLength={10}
                    onChange={e => {
                      search_scanned_walkin_leads(e.target.value);
                    }}
                    className={`form-control w-50 ms-3 ${
                      category_name4 === 'mobile_number' &&
                      category_name4 !== 'select'
                        ? ''
                        : 'd-none'
                    }`}
                  />

                  {/* <button className='btn ms-3 border-0' onClick={()=>{
         downloadExcel(scanned_walkinleads,'scanned_walkinleads')
      }}><Excel_download/></button> */}
                </div>
              </div>
              <h6
                className={`text-color text-center ${
                  today_all_leads?.length > 0 ? 'd-none' : 'py-3'
                }`}
              >
                No Leads available...
              </h6>
              <div
                class={`table-responsive mt-2 ${
                  today_all_leads?.length > 0 ? '' : 'd-none'
                }`}
                style={{ minHeight: '100vh' }}
              >
                <button
                  className="btn btn-sm px-4 py-1 mb-3 btn-success d-block ms-auto"
                  onClick={() => {
                    downloadExcel();
                  }}
                >
                  Download
                </button>
                <table class="w-100 caption-top">
                  <thead>
                    <tr style={{ backgroundColor: '#034592' }}>
                      <th
                        scope="col"
                        className="border-0 py-3"
                      >
                        <span className="fw-medium">
                          <p className="ps-2 m-0 fw-normal text-white py-2">
                            Name
                          </p>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="border-0"
                      >
                        <span className="fw-medium">
                          <p className=" m-0 fw-normal text-white py-2">
                            Created at
                          </p>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="border-0"
                      >
                        <span className="fw-medium">
                          <p className=" m-0 fw-normal text-white py-2">
                            Mobile No
                          </p>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="border-0"
                      >
                        <span className="fw-medium">
                          <p className=" m-0 fw-normal text-white py-2">
                            Email
                          </p>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="border-0"
                      >
                        <span className="fw-medium">
                          <p className=" m-0 fw-normal text-white py-2">
                            Course Enquired
                          </p>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="border-0"
                      >
                        <span className="fw-medium">
                          <p className=" m-0 fw-normal text-white py-2">
                            Purpose
                          </p>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="border-0"
                      >
                        <span className="fw-medium">
                          <p className=" m-0 fw-normal text-white py-2">
                            Lead Status
                          </p>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="border-0"
                      >
                        <span className="fw-medium">
                          <p className=" m-0 fw-normal text-white py-2">
                            Source
                          </p>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className={`border-0`}
                      >
                        <p className=" m-0 fw-normal text-white py-2">
                          Assigned by
                        </p>
                      </th>
                      {/* <th className='border-0'></th> */}
                      <th className="border-0"></th>
                      <th className="border-0"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {today_all_leads?.map((x, index) => {
                      return (
                        <tr className="table-row">
                          <td className="">
                            <p
                              className="m-0 text-primary py-2 ps-2"
                              style={{
                                cursor: 'pointer',
                                position: 'relative',
                              }}
                              onClick={() => {
                                if (x.customer_lead_id) {
                                  setlead_id(x.customer_lead_id);
                                } else if (x.client_lead_id) {
                                  setlead_id(x.client_lead_id);
                                }
                              }}
                            >
                              {x.name}
                              {console.log('UPP', x)}
                              {x.followup_notification &&
                                x.level_lead_name === 'followup' && (
                                  <span
                                    className={`tw-w-full font-medium px-1 tw-capitalize rounded-md ${
                                      x.followup_notification.includes('today')
                                        ? 'tw-text-yellow-300'
                                        : x.followup_notification.includes(
                                            'pending',
                                          )
                                        ? 'tw-text-red-500'
                                        : x.followup_notification.includes(
                                            'upcoming',
                                          )
                                        ? 'tw-text-green-500'
                                        : 'tw-text-black'
                                    }`}
                                    style={{
                                      fontSize: '0.7em',
                                      position: 'absolute',
                                      top: '-0.1em',
                                      right: '-2.2em',
                                      padding: '2px 4px',
                                      transform: 'translateY(-50%)',
                                    }}
                                  >
                                    {x.followup_notification}
                                  </span>
                                )}
                            </p>
                          </td>
                          <td className="py-3">
                            <p
                              className=" m-0 py-2"
                              style={{ width: '200px' }}
                            >
                              {x.created_at
                                ? `${x.created_at.slice(
                                    0,
                                    10,
                                  )} (${x.created_at.slice(11, 19)})`
                                : ''}
                            </p>
                          </td>
                          <td className="">
                            <p className=" m-0  py-2">{x.contact_number}</p>
                          </td>
                          <td className="">
                            <p className=" m-0  py-2">{x.email}</p>
                          </td>
                          <td className="">
                            <p
                              className={
                                x.othercourseenquired === null
                                  ? ' m-0 py-2'
                                  : 'd-none'
                              }
                            >
                              {x.course_enquiredd}
                            </p>
                            <p
                              className={
                                x.othercourseenquired === null
                                  ? 'd-none'
                                  : ' m-0 py-2'
                              }
                            >
                              {x.othercourseenquired}{' '}
                              <span
                                style={{
                                  fontStyle: 'italic',
                                  color: 'orangered',
                                  fontSize: '13px',
                                }}
                              >
                                (Other)
                              </span>
                            </p>
                          </td>
                          <td className="">
                            <p className=" m-0  py-2">
                              {x.purpose ? x.purpose : '-'}
                            </p>
                          </td>
                          <td className="">
                            <p className=" m-0  py-2">{x.level_lead_name}</p>
                          </td>
                          <td className="">
                            <p className=" m-0  py-2">{x.source}</p>
                          </td>
                          <td className={``}>
                            <p className=" m-0  py-2">
                              {x.assigned_byy ? x.assigned_byy : '-'}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>

                {/* <div className='d-flex align-items-center justify-content-between'>
    <button className={`btn btn-sm px-3 btn-success`} disabled={original_leads.previous!==null ? false:true} onClick={()=>{
                        setleadapi(original_leads && original_leads.previous)
                    }}>Previous</button>
                     <button className={`btn btn-sm px-3 btn-success`} disabled={original_leads.next!==null ? false : true} onClick={()=>{
                        setleadapi(original_leads && original_leads.next)
                    }}>Next</button>
    </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Lead_details
        show={lead_detail_modal}
        setshow={setlead_detail_modal}
        id={lead_id}
      />
    </div>
  );
};

export default Employee_today_leads;
