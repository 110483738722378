import { useNavigate } from 'react-router-dom';

function LeadStatus({
  movetotherDepartmentleads,
  setcourse_name,
  setcourse_id,
  setlead_id,
  setShow1,
  setShow2,
  setstage,
  x,
}) {
  const navigate = useNavigate();

  return (
    <div className=" py-2 ">
      {/* <div className=" py-2 tw-bg-blue-500"> */}
      {/* /MUIZWORK STATUS */}
      <div class="dropdown tw-bg-pink-600">
        <button
          class="btn btn-sm text-white dropdown-toggle rounded-0 px-3"
          style={{ backgroundColor: '#002147' }}
          type="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Status
        </button>
        <div className="dropdown-menu dropdown-menu-end p-0 pt-3">
          <ul
            className="ps-0 justify-content-center p-0 d-flex flex-column align-items-center gap-2"
            style={{ listStyleType: 'none' }}
          >
            <li className={``}>
              <button
                style={{ width: '100px' }}
                className={`btn btn-sm text-white btn-primary`}
                onClick={() => {
                  setlead_id(x.customer_lead_id);
                  setstage(x.level_lead_name);
                  setShow2(true);
                }}
              >
                Walkin
              </button>
            </li>
            <li
              className={`${
                x.level_lead_name === 'registered' ? 'd-none' : ''
              }`}
            >
              <button
                style={{ width: '100px' }}
                className={`btn btn-sm text-white btn-primary`}
                onClick={() => {
                  setlead_id(x.customer_lead_id);
                  setstage(x.level_lead_name);
                  setcourse_name(x.course_enquiredd);
                  setcourse_id(x.course_enquired);
                  setShow1(true);
                }}
              >
                Followup
              </button>
            </li>
            <li
              className={`${
                x.level_lead_name === 'registered' ||
                x.level_lead_name === 'closed'
                  ? 'd-none'
                  : ''
              }`}
            >
              <button
                style={{ width: '100px' }}
                className={`btn btn-sm text-white btn-primary ${
                  x.level_lead_name === 'closed' ? 'd-none' : ''
                }`}
                data-bs-toggle="modal"
                data-bs-target="#prospectsform"
                onClick={() => {
                  setlead_id(x.customer_lead_id);
                  setcourse_name(x.course_enquiredd);
                  setcourse_id(x.course_enquired);
                }}
              >
                Prospects
              </button>
            </li>
            <li
              className={`${
                x.level_lead_name === 'registered' ||
                x.level_lead_name === 'closed'
                  ? 'd-none'
                  : ''
              }`}
            >
              <button
                style={{ width: '100px' }}
                className={`btn btn-sm text-white btn-primary ${
                  x.level_lead_name === 'registered' ||
                  x.level_lead_name === 'closed'
                    ? 'd-none'
                    : ''
                }`}
                onClick={() => {
                  navigate(`/mark_as_register_form/${x.customer_lead_id}`);
                }}
              >
                Register
              </button>
            </li>
            <li
              className={`${
                x.level_lead_name === 'registered' ||
                x.level_lead_name === 'closed'
                  ? 'd-none'
                  : ''
              }`}
            >
              <button
                style={{ width: '100px' }}
                className={`btn btn-sm text-white btn-primary ${
                  x.level_lead_name === 'registered' ||
                  x.level_lead_name === 'closed'
                    ? 'd-none'
                    : ''
                }`}
                data-bs-toggle="modal"
                data-bs-target="#closedform"
                onClick={() => {
                  setlead_id(x.customer_lead_id);
                  setstage(x.level_lead_name);
                }}
              >
                Closed
              </button>
            </li>
            <li className={``}>
              <button
                style={{ width: '100px' }}
                className={`btn btn-sm text-white btn-primary`}
                onClick={() => {
                  movetotherDepartmentleads(x.customer_lead_id);
                }}
              >
                Other Department Lead
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default LeadStatus;
