import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ipaddress } from '../../App';
import Preloader from '../Preloader';
import { Modal } from 'react-bootstrap';
import Arrow_svg from '../../Svgs/Arrow_svg';

const Lead_details = ({ show, setshow, id }) => {
  const [followup, setfollowup] = useState([]);
  const [prospect, setprospect] = useState([]);
  const [closed, setclosed] = useState([]);
  const [registered, setregistered] = useState({});
  const [lead_details, setlead_details] = useState({});
  const [load, setload] = useState(false);

  console.log(id);

  useEffect(() => {
    setload(true);
    if (id) {
      axios.get(`${ipaddress}/EntireData/${id}/`).then(r => {
        setload(false);
        console.log('Entire lead history', r.data);
        setfollowup(r.data.followup_history);
        setprospect(r.data.prospect_history);
        setregistered(r.data.registration_details);
        setlead_details(r.data.customer_lead_data);
        setclosed(r.data.closed_history);
      });
    }
  }, [id]);

  return (
    <Modal
      show={show}
      fullscreen={true}
      onHide={() => setshow(false)}
    >
      <Modal.Body className="p-0 ">
        <div
          className="p-2 p-md-3"
          style={{ backgroundColor: '#E8E8E8', minHeight: '100vh' }}
        >
          <div className="bg-white p-2 mb-3 p-md-4 d-flex align-items-center justify-content-between">
            <h5 className="d-flex align-items-center m-0">
              <span
                style={{ cursor: 'pointer' }}
                onClick={() => setshow(false)}
              >
                <Arrow_svg />
              </span>{' '}
              <span
                className="ms-2 ms-md-4"
                style={{ fontWeight: 700 }}
              >
                Lead Profile
              </span>
            </h5>
            <svg
              style={{ cursor: 'pointer' }}
              onClick={() => setshow(false)}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
            </svg>
          </div>
          <div className="bg-white shadow-sm p-md-4 lead_details_div">
            <div className="p-3 p-md-4">
              {/* Lead History */}
              {lead_details && (
                <div className="">
                  <h5 className="py-3 fw-bold fs-6">Lead Details</h5>
                  <div className="row m-0 justify-content-between">
                    <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                      <p className="">
                        <span className=" fw-medium fs-6">Name : </span>
                        {lead_details.name}
                      </p>
                    </div>

                    <div className={`col-md-6 col-lg-5 mb-3 pe-md-0`}>
                      <p>
                        <span className=" fw-medium fs-6">Email : </span>
                        {lead_details.email ? lead_details.email : '-'}
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                      <p>
                        <span className=" fw-medium fs-6">
                          Contact Number :{' '}
                        </span>
                        {lead_details.contact_number}
                      </p>
                    </div>
                    <div className={`col-md-6 col-lg-5 mb-3 pe-md-0`}>
                      <p
                        className={`${
                          lead_details.othercourseenquired == null
                            ? ''
                            : 'd-none'
                        }`}
                      >
                        <span className={` fw-medium fs-6`}>
                          Course Enquired :{' '}
                        </span>
                        {lead_details.course_enquiredd &&
                          lead_details.course_enquiredd}
                      </p>
                      <p
                        className={`${
                          lead_details.othercourseenquired == null
                            ? 'd-none'
                            : ''
                        }`}
                      >
                        <span className={` fw-medium fs-6`}>
                          Course Enquired :{' '}
                        </span>
                        {lead_details.othercourseenquired &&
                          lead_details.othercourseenquired}
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                      <p>
                        <span className=" fw-medium fs-6">
                          Level of Lead :{' '}
                        </span>
                        {lead_details.level_lead_name}
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                      <p>
                        <span className=" fw-medium fs-6">Created on : </span>
                        {lead_details?.created_at?.slice(0, 10)}
                        <span className="tw-pl-3">
                          {lead_details?.created_at?.slice(11, 16)}
                        </span>
                      </p>
                    </div>

                    <div className={`col-md-6 col-lg-5 mb-3 ps-md-0`}>
                      <p>
                        <span className=" fw-medium fs-6">
                          Employee Name :{' '}
                        </span>
                        {lead_details.emp_name ? lead_details.emp_name : '-'}
                      </p>
                    </div>

                    <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                      <p>
                        <span className=" fw-medium fs-6">Assigned By : </span>
                        {lead_details.assigned_byy
                          ? lead_details.assigned_byy
                          : '-'}
                      </p>
                    </div>

                    <div className={`col-md-6 col-lg-5 mb-3 ps-md-0`}>
                      <p>
                        <span className=" fw-medium fs-6">Source : </span>
                        {lead_details.source}
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                      <p>
                        <span className=" fw-medium fs-6">Priority : </span>
                        {lead_details.priority && lead_details.priority}
                      </p>
                    </div>

                    <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                      <p>
                        <span className=" fw-medium fs-6">
                          Reason For Contact :{' '}
                        </span>
                        {lead_details.reason_for_contact &&
                          lead_details.reason_for_contact}
                      </p>
                    </div>

                    {lead_details.noofdays && (
                      <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                        <p>
                          <span className=" fw-medium fs-6 me-md-2">
                            Number Of Days :{' '}
                          </span>
                          {lead_details.noofdays && lead_details.noofdays}
                        </p>
                      </div>
                    )}

                    {lead_details.purpose && (
                      <div className="col-12 mb-3 px-md-0">
                        <p>
                          <span
                            className=" fw-medium fs-6 me-md-2"
                            style={{ width: '16%' }}
                          >
                            Purpose :{' '}
                          </span>
                          {lead_details.purpose && lead_details.purpose}
                        </p>
                      </div>
                    )}

                    <div className="col-12 mb-3 px-md-0">
                      <p>
                        <span
                          className=" fw-medium fs-6 me-md-2"
                          style={{ width: '16%' }}
                        >
                          Remarks :{' '}
                        </span>
                        {lead_details.remarks ? lead_details.remarks : '-'}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* Followup History */}
              {followup.length > 0 && (
                <div className="pt-3">
                  <h6 className="py-3 fw-bold">Followup History</h6>
                  {followup.map(x => {
                    return (
                      <div className="row m-0 justify-content-between">
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Last Followup Date :{' '}
                            </span>
                            {x.Followup_Date}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Expected Followup Date :{' '}
                            </span>
                            {x.expected_followup_date &&
                              x.expected_followup_date}
                          </p>
                        </div>
                        <div className="col-12 mb-3 px-md-0">
                          <p>
                            <span
                              className=" fw-medium fs-6 me-md-2"
                              style={{ width: '16%' }}
                            >
                              Remarks :{' '}
                            </span>
                            {x.Remarks}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Need Supervisor Attention :{' '}
                            </span>
                            {x.Need_Supervisor_Attention ? 'Yes' : 'No'}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Prospects History */}

              {prospect.length > 0 && (
                <div className="pt-3">
                  <h6 className="py-3 fw-bold">Prospect History</h6>
                  {prospect.map(x => {
                    return (
                      <div className="row m-0 justify-content-between">
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Created at :{' '}
                            </span>
                            {x.created_at && x.created_at.slice(0, 10)}
                            <span className="tw-pl-3">
                              {x?.created_at?.slice(11, 16)}
                            </span>
                          </p>
                        </div>
                        <div className={`col-md-6 col-lg-5 mb-3 pe-md-0`}>
                          <p>
                            <span className=" fw-medium fs-6">
                              Expected Registration Date :{' '}
                            </span>
                            {x.expected_registration_date
                              ? x.expected_registration_date
                              : '-'}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Preferred Course :{' '}
                            </span>
                            {x.preferred_course ? x.preferred_course : '-'}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Billing Amount :{' '}
                            </span>
                            {x.billing_amount ? x.billing_amount : '-'}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Expected Collection :{' '}
                            </span>
                            {x.expected_collection
                              ? x.expected_collection
                              : '-'}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Mode of Payment :{' '}
                            </span>
                            {x.mode_of_payment ? x.mode_of_payment : '-'}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Date of Payment :{' '}
                            </span>
                            {x.date_of_payment ? x.date_of_payment : '-'}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Time of Payment :{' '}
                            </span>
                            {x.time_of_payment ? x.time_of_payment : '-'}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Tentative Batch Start Date :{' '}
                            </span>
                            {x.tentative_batch_start_date
                              ? x.tentative_batch_start_date
                              : '-'}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Preferred Batch Start Time :{' '}
                            </span>
                            {x.preferred_batch_start_time
                              ? x.preferred_batch_start_time
                              : '-'}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">Remarks : </span>
                            {x.remarks}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Registered History */}
              {registered && (
                <div className="pt-3">
                  <h6 className="py-3 fw-bold">Registration History</h6>
                  <div className="row m-0 justify-content-between">
                    <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                      <p>
                        <span className=" fw-medium fs-6">
                          Registration date :{' '}
                        </span>
                        {registered.Registration_Date &&
                          registered.Registration_Date.slice(0, 10)}
                        <span className="tw-pl-3">
                          {registered?.Registration_Date?.slice(11, 16)}
                        </span>
                      </p>
                    </div>
                    <div className={`col-md-6 col-lg-5 mb-3 pe-md-0`}>
                      <p>
                        <span className=" fw-medium fs-6">Remarks : </span>
                        {registered.Remarks}
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                      <p>
                        <span className=" fw-medium fs-6">Door Number : </span>
                        {registered.Door_Number && registered.Door_Number}
                      </p>
                    </div>
                    <div className={`col-md-6 col-lg-5 mb-3 pe-md-0`}>
                      <p>
                        <span className=" fw-medium fs-6">Street Name : </span>
                        {registered.Street_Name && registered.Street_Name}
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                      <p>
                        <span className=" fw-medium fs-6">Area : </span>
                        {registered.Area && registered.Area}
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                      <p>
                        <span className=" fw-medium fs-6">City : </span>
                        {registered.City && registered.City}
                      </p>
                    </div>
                    <div className={`col-md-6 col-lg-5 mb-3 ps-md-0`}>
                      <p>
                        <span className=" fw-medium fs-6">State : </span>
                        {registered.State && registered.State}
                      </p>
                    </div>
                    <div className="col-md-6 col-lg-5 mb-3 pe-md-0">
                      <p>
                        <span className=" fw-medium fs-6">Pincode : </span>
                        {registered.Pincode && registered.Pincode}
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {/* Closed History */}
              {closed.length > 0 && (
                <div className="pt-3">
                  <h6 className="py-3 fw-bold">Closed History</h6>
                  {closed.map(x => {
                    return (
                      <div className="row m-0 justify-content-between">
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Closed On :{' '}
                            </span>
                            {x.created_at && x.created_at.slice(0, 10)}
                            <span className="tw-pl-3">
                              {x?.created_at?.slice(11, 16)}
                            </span>
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Stage Of Closure :{' '}
                            </span>
                            {x.stage_of_closure}
                          </p>
                        </div>
                        <div className="col-12 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Reason For Closure :{' '}
                            </span>
                            {x.reason_for_closure}
                          </p>
                        </div>
                        <div className="col-12 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">Remarks : </span>
                            {x.remarks}
                          </p>
                        </div>
                        <div className="col-md-6 col-lg-5 mb-3 ps-md-0">
                          <p>
                            <span className=" fw-medium fs-6">
                              Opportunities In Future :{' '}
                            </span>
                            {x.opportunities_in_future ? 'Yes' : 'No'}
                          </p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default Lead_details;
