import React from 'react'

const Global_search_trainer = () => {
  return (
    <div>
        <div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
  <div class="offcanvas-header border-bottom  border-0 px-4">
    <input class="form-control border-0 navbar-input1 shadow-none" id="offcanvasTopLabel" placeholder='Search course, student or instructor...'/>
    <svg data-bs-dismiss="offcanvas" style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#0D6EFD" class="bi bi-x-circle" viewBox="0 0 16 16">
  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
  </div>
  <div class="offcanvas-body">
    
  </div>
</div>
    </div>
  )
}

export default Global_search_trainer