import React, { useEffect } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { useState } from 'react'
import axios from 'axios'
import { ipaddress } from '../App'
import { toast } from 'react-toastify'
import Arrow_svg from '../Svgs/Arrow_svg'
import { useNavigate } from 'react-router-dom'

const Add_Employees = () => {
    const user=JSON.parse(sessionStorage.getItem('user'))

    const [formData, setFormData] = useState({
      first_name:'',
      last_name:'',
      email:'',
      username:'',
      password:'',
      designation: '',
      supervisor: '',
      image: null,
      contact_number:'',
      gender:''
    });

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };

    const[designations_data,setdesignation_data]=useState([])
    const[supervisor_data,setsupervisor_data]=useState([])

    const[count,setcount]=useState(0)

    useEffect(()=>{
      axios.get(`${ipaddress}/UsersDropdown/`)
      .then((r)=>{
        console.log("Supervisors",r.data)
        setsupervisor_data(r.data)
      })

      axios.get(`${ipaddress}/DesignationDropdown/`)
      .then((r)=>{
        console.log("Designations",r.data)
        setdesignation_data(r.data)
      })
    },[count])


    const add_employee=(e)=>{
      const form=new FormData()
      form.append('first_name',formData.first_name)
      form.append('last_name',formData.last_name)
      form.append('email',formData.email)
      form.append('username',formData.username)
      form.append('password',formData.password)
      form.append('designation',formData.designation)
      form.append('supervisor',formData.supervisor)
      form.append('image',formData.image)

      e.preventDefault()
      axios.post(`${ipaddress}/AddEmployeeView/`,form)
      .then((r)=>{
        setcount(count+1)
        console.log("Successfully addedd",r.data)
        toast.success('Employee added successfully',{
          autoClose:2000,
          theme:'colored',
      position:'top-center'
        })
        setFormData({
          first_name:'',
      last_name:'',
      email:'',
      username:'',
      password:'',
      designation: '',
      supervisor: '',
      image: null
        })

        // console.log(formData)
      })
      .catch((err)=>{
        console.log("Employee adding error",err)
      })
    }

    const navigate=useNavigate()

  return (
    <div className='d-flex'>
    <Sidebar designation={user.designation} activevalue={"employees"}/>
    <div className='w-100 main-div'>
        <Navbar designation={user.designation}/>

        <div className='h-100 main-container' style={{backgroundColor:'#E8E8E8',minHeight:'90vh'}}>
          <div className='d-flex justify-content-between align-items-center px-4'>
          <h6 className='text-center py-4 d-flex align-items-center text-color fw-bold'><span className='me-2 me-md-3' style={{cursor:'pointer'}} onClick={()=>{
            navigate('/view_employees')
          }}><Arrow_svg/></span>Add New Employee</h6>
          </div>
          <form action="" onSubmit={add_employee}>
          <div className="row m-0 px-1 px-md-4">
            <div className="col-12 bg-white py-lg-5 p-2 p-md-4">
            <div className='row m-0 pb-3'>
              <p className='text-color fw-bold'>Personal Details</p>
          <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">First Name <span style={{color:'red'}}>*</span></label>
    <input required type="text" className="form-control shadow-none  py-2" id="inputPassword4" onChange={handleInputChange} value={formData.first_name} name='first_name'/>
  </div>

  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Last Name <span style={{color:'red'}}>*</span></label>
    <input required type="text" className="form-control shadow-none  py-2" id="inputPassword4" onChange={handleInputChange} value={formData.last_name} name='last_name'/>
  </div>

  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Contact Number <span style={{color:'red'}}>*</span></label>
    <input required type="text" className="form-control shadow-none  py-2" id="inputPassword4" onChange={handleInputChange} value={formData.contact_number} name='contact_number'/>
  </div>

  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Gender <span style={{color:'red'}}>*</span></label>
    <select required type="text" className="form-select shadow-none  py-2" id="inputPassword4" onChange={handleInputChange} value={formData.gender} name='gender'>
    <option value="">Select the Gender</option>
    <option value="male">Male</option>
    <option value="female">Female</option>
    <option value="transgender">Trans-gender</option>
    </select>
  </div>

  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Personal Email <span style={{color:'red'}}>*</span></label>
    <input required type="email" className="form-control shadow-none  py-2" id="inputPassword4" onChange={handleInputChange} value={formData.email} name='email'/>
  </div>
          </div>

          <div className='row m-0 border-top pt-4'>
          <p className='text-color fw-bold'>Employee Details</p>

  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Username <span style={{color:'red'}}>*</span></label>
    <input required type="text" className="form-control shadow-none  py-2" id="inputPassword4" onChange={handleInputChange} value={formData.username} name='username'/>
  </div>

  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Password <span style={{color:'red'}}>*</span></label>
    <input required type="text" className="form-control shadow-none  py-2" id="inputPassword4" onChange={handleInputChange} value={formData.password} name='password'/>
  </div>

  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Designation <span style={{color:'red'}}>*</span></label>
    <select required className='form-select shadow-none  py-2' id="" onChange={handleInputChange} value={formData.designation} name='designation'>
    <option value="">Select Designation</option>
    {designations_data.map((x)=>{
      return(
        <>
        <option value={x.id}>{x.designation}</option>
        </>
      )
    })}
  </select>
  
  </div>

  
  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Supervisor <span style={{color:'red'}}>*</span></label>
   <select required className='form-select shadow-none  py-2' id="" onChange={handleInputChange} value={formData.supervisor} name='supervisor'>
    <option value=""></option>
    {supervisor_data.map((x)=>{
      return(
        <>
      <option value={x.id}>{x.first_name}</option>
      </>
      )
    })}
  </select>
  
  </div>

  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Work Email <span style={{color:'red'}}>*</span></label>
    <input required type="email" className="form-control shadow-none  py-2" id="inputPassword4" onChange={handleInputChange} value={formData.email} name='email'/>
  </div>

  <div className="col-md-6 col-lg-4 d-flex mb-4 align-items-end">
  <input
                  id="fileInput"
                  type="file"
                  // accept=".pdf"
                  name='image'
                  onChange={(e)=>{
                    const {name,files}=e.target
                    setFormData((prev)=>({
                      ...prev,
                      [name]:files[0]
                    }))
                  }}
                  className=" text-center "
                />
                <label style={{cursor:'pointer'}}
                  for="fileInput"
                  class="d-flex btns align-items-center border-0 text-white px-4 py-2 fw-normal"
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z" fill="white"/>
</svg> <span className='ms-2'>Upload Profile Image</span>
                </label>
                <span className='d-block text-success ms-2'>{formData.image && formData.image.name!=undefined && formData.image.name.slice(0,16)}</span>

  </div>

  <div className="col-12 mt-3 text-end px-3">
 <button className='btns text-white px-4 px-lg-5 py-2 fs-6' type='submit'>Submit</button>
  </div>
          </div>
            </div>

            <div className="col-lg-4">
              {/* <img src={require('../images/Banner4 3.png')} style={{width:'100%'}} alt="" /> */}
            </div>
          </div>
          </form>
         
        </div>
        </div>

    </div>
  )
}

export default Add_Employees