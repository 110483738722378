import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from './Navbar'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { toast } from 'react-toastify'

const Trainers = () => {


  const navigate=useNavigate()

  const[trainers,settrainers]=useState([])

  const[count,setcount]=useState(0)

  useEffect(()=>{
    axios.get(`${ipaddress2}/AllTrainersView/`)
    .then((r)=>{
      console.log("All Trainers",r.data)
      settrainers(r.data)
    })
  },[count])

const[email,setemail]=useState('')
const[state,setstate]=useState(false)

const user=JSON.parse(sessionStorage.getItem('user'))

const block_trainer=(email,status,name)=>{
  axios.post(`${ipaddress2}/BlockTrainer/${user.email}/`,{
    email:email,
    status:status
  })

  .then((r)=>{
    setcount(count+1)
    console.log(r.data)
    if(status==true){
      toast.warn(`You blocked ${name}`,{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    }
    else{
      toast.success(`You unblocked ${name}`,{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    }
  })
}


return (
    <div className='d-flex'>
        <Sidebar activevalue={"trainers"}/>
        <div className='w-100 main-div'>
            <Navbar/>

            <div style={{backgroundColor:'#E8E8E8'}} className='p-3 main-container'>
                <div className='bg-white p-2 p-md-4' style={{minHeight:'90vh'}}>
                <h5 className='fw-bold ps-md-2'>Trainers Details</h5>
                <div className={`${state ? 'd-none':''}`}>

<div className="pt-4 mt-3 row m-0 mx-auto">
             {trainers.map((x)=>{
               return(
                 <div className={`col-md-6 col-lg-4 mb-4 ${trainers.length>0 ? '':'d-none'}`} style={{borderRadius:'14px'}}>
                 <div className='bg-white p-2 p-md-3 border py-md-4'>
                 <div className='d-flex align-items-center' style={{color:'#002147'}}>
                   <div className='text-center'>
                   <img className={`rounded-circle ${x.image ? '':'d-none'}`} src={x.image} width={110} height={110} alt="" />
                   <img className={`rounded-circle ${x.image ? 'd-none':''}`} src={require('../images/user.jpg')} width={110} height={110} alt="" />
                   <p className='m-0 fw-bold mt-3' style={{color:'#000'}}>{x.trainer_name && x.trainer_name.first_name && x.trainer_name.first_name}</p>
                   </div>
                   <div className='ms-3 ms-md-4'>
                   <p className='mt-1 fw-bold mb-2' style={{fontSize:'12px'}}>Specialization : {x.specialization}</p>
                   <p className='fw-bold' style={{fontSize:'12px'}}>Email : {x.trainer_name && x.trainer_name.email && x.trainer_name.email}</p>
                   <div className='d-flex align-items-center'>
                   <button className='border btn fw-bold' style={{color:'#002147',fontSize:'13px'}} onClick={()=>{
                   navigate(`/trainer_details/${x.trainer_name && x.trainer_name.emp_id}`)
                 }} >View Profile</button>
                  <button style={{fontSize:'13px'}} className={`btn btn-danger ms-3 fw-bold px-3 ${x.trainer_name.blockstatus ? 'd-none':''}`} onClick={()=>{
          block_trainer(x.trainer_name.email,true,x.trainer_name.first_name)
        }}>Block</button>
        <button style={{fontSize:'13px'}} className={`btn btn-success px-3 ms-3 fw-bold ${x.trainer_name.blockstatus ? '':'d-none'}`} onClick={()=>{
          block_trainer(x.trainer_name.email,false,x.trainer_name.first_name)
        }}>Unblock</button>
                   </div>
                   </div>
                 </div>
                 </div>
               </div>
               )
             })}
             </div>
                </div>
                </div>

            </div>
        </div>

    </div>
  )
}

export default Trainers