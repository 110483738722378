import React, { useEffect, useState } from 'react'
import Sidebar from '../Components/Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import * as XLSX from 'xlsx';


const Student_details = ({email,id,state,setstate,student_id}) => {

    const[queries,setqueries]=useState([])
    const[marks,setmarks]=useState([])
    const[leave_requests,setleave_requests]=useState([])
    const[student_logsheet,setstudent_logsheet]=useState([])

    useEffect(()=>{
        if(email && id){
            
        }
        if(state==true){
            axios.get(`${ipaddress2}/StudentMarksDisplayBasedOnCourse/${email}/${id}/`)
        .then((r)=>{
            console.log("Student marks",r.data)
            setmarks(r.data)
        })
        .catch((err)=>{
            console.log("marks error",err);
        })

        axios.get(`${ipaddress2}/LeaveRequest/${email}/`)
        .then((r)=>{
            console.log("Leave Requests",r.data)
            setleave_requests(r.data.reverse())
        })


        axios.post(`${ipaddress2}/StudentBatches/${email}/`,{
            batch_id:id
        })
        .then((r)=>{
            console.log("Logsheet details",r.data)
            setstudent_logsheet(r.data)
        })
        .catch((err)=>{
            console.log("Logsheet error",err);
        })

        fetch_queries()

        }  
    },[email])

    const fetch_queries=()=>{
        axios.put(`${ipaddress2}/QuerriesView/${email}/`,{
            batch_id:id
        })
        .then((r)=>{
            console.log("Students Queries fetched",r.data)
            setqueries(r.data)
        })
    }

    const[type,settype]=useState('logsheet')

    const[suggestions,setsuggestions]=useState([])
    const fetch_suggestions=()=>{
        axios.get(`${ipaddress2}/ParticularStudentSuggestionsView/${student_id}/`)
        .then((r)=>{
            console.log("Suggestions",r.data)
            setsuggestions(r.data)
        })
    }

    const[details,setdetails]=useState({})
    const[details2,setdetails2]=useState({})

    const fetch_details=()=>{
        axios.get(`${ipaddress2}/lead-history/${student_id}/`)
        .then((r)=>{
            console.log("Details",r.data)
            setdetails(r.data.customer_lead)
            setdetails2(r.data.registrations[0])
        })
    }

    const downloadCertificate = () => {
        const input = document.getElementById(`student_details`);
        html2canvas(input, { scale: 3, useCORS: true }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('landscape', 'pt', [canvas.width, canvas.height]); // Set PDF dimensions based on the canvas
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const imgWidth = canvas.width;
          const imgHeight = canvas.height;
          const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
          const imgX = 0; // Align to the left
          const imgY = 0; // Align to the top
    
          pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio);
          pdf.save(`${details.name}_details.pdf`);
        }).catch(error => {
          console.error('Error generating PDF:', error)
        })
      }

      const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(student_logsheet);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');
        XLSX.writeFile(workbook, 'Student_logsheet.xlsx');
      };


return (
    <div className={`${state ? '':'d-none'}`}>
        <h6 className='d-flex align-items-center'><svg onClick={()=>{
            setstate(false)
        }} style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg> <span className='ms-2'>Student Details</span></h6>


            <div className="">

                <div className='d-flex mt-4 pb-3 gap-4'>
                    <p onClick={()=>{
                        settype('logsheet')
                    }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:type==='logsheet' ? '#57309C':'',cursor:'pointer',borderBottom:type==='logsheet' ? '2px solid #57309C':'none'}}>Log Sheet</p>
                    <p onClick={()=>{
                        settype('marks')
                    }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:type==='marks' ? '#57309C':'',cursor:'pointer',borderBottom:type==='marks' ? '2px solid #57309C':'none'}}>Marks</p>
                    <p onClick={()=>{
                        settype('queries')
                    }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:type==='queries' ? '#57309C':'',cursor:'pointer',borderBottom:type==='queries' ? '2px solid #57309C':'none'}}>Queries</p>
                    <p onClick={()=>{
                        settype('leave')
                    }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:type==='leave' ? '#57309C':'',cursor:'pointer',borderBottom:type==='leave' ? '2px solid #57309C':'none'}}>Leave Requests</p>
                    <p onClick={()=>{
                        settype('suggestions')
                        fetch_suggestions()
                    }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:type==='suggestions' ? '#57309C':'',cursor:'pointer',borderBottom:type==='suggestions' ? '2px solid #57309C':'none'}}>Suggestions</p>
                    <p onClick={()=>{
                        settype('details')
                        fetch_details()
                    }} className='py-2 px-4 d-flex align-items-center pb-3' style={{color:type==='details' ? '#57309C':'',cursor:'pointer',borderBottom:type==='details' ? '2px solid #57309C':'none'}}>Student Details</p>

                </div>

{/* Log sheet */}
<div className={`${type==='logsheet' ? '':'d-none'}`}>
<h6 className={`text-secondary py-3 text-center ${student_logsheet.length>0 ? 'd-none':''}`}>No Data Found...</h6>

<div className={`table-responsive ${student_logsheet.length>0 ? '':'d-none'}`}>
    <div className='text-end mb-3'>
        <button className='btn btn-sm btn-success px-3' onClick={exportToExcel}>Download</button>
    </div>
    
                <table class="table">
  <thead>
    <tr>
      <th scope="col" className='py-3'><p className="text-center m-0 fw-medium">Date</p></th>
      <th scope="col" className='py-3'><p className="text-center m-0 fw-medium">Syllabus Covered</p></th>
      <th scope="col" className='py-3'><p className="text-center m-0 fw-medium">Attendance Status</p></th>
    </tr>
  </thead>
  <tbody>
   {student_logsheet.map((x)=>{
    return(
        <tr>
        <td><p className="text-center m-0">{x.date}</p></td>
        <td><p className="text-center m-0">{x.syllabus_covered}</p></td>
        <td><p className="text-center m-0">{x.attendance_status}</p></td>
    </tr>
    )
   })}
  </tbody>
</table>
                </div>
</div>


{/* Marks */}

<div className={`${type==='marks' ? '':'d-none'}`}>
    <h6 className={`text-secondary py-3 text-center ${marks.length>0 ? 'd-none':''}`}>No Data Found...</h6>
    <div className={`table-responsive ${marks.length>0 ? '':'d-none'}`}>
    <table class="table">
  <thead>
    <tr>
      <th scope="col" className='fw-normal text-secondary py-3'>SI.No</th>
      <th scope="col" className='fw-normal text-secondary py-3'>Test Name</th>
      <th scope="col" className='fw-normal text-secondary py-3'>Conducted On</th>
      <th scope="col" className='fw-normal text-secondary py-3'>Test Time</th>
      <th scope="col" className='fw-normal text-secondary py-3'>Total Marks</th>
      <th scope="col" className='fw-normal text-secondary py-3'>Marks Scored</th>
    </tr>
  </thead>
  <tbody>
  {marks.map((x,index)=>{
      return(
        <tr>
      <td className='py-3' scope="row">{index+1}</td>
      <td className='py-3'>{x.test_name}</td>
      <td className='py-3'>{x.test_date && x.test_date.slice(0,10)}</td>
      <td className='py-3'>{x.test_date.split('T')[1].split('Z')[0]}</td>
      <td className='py-3'>{x.out_of}</td>
      <td className='py-3'>{x.marks_gained}</td>

    </tr>
      )
    })}
  </tbody>
</table>
    </div>
</div>


{/* Queries */}

<div className={`row m-0 w-100 ${type==='queries' ? '':'d-none'}`}>

<h6 className={`text-secondary py-3 text-center ${queries.length>0 ? 'd-none':''}`}>No Data Found...</h6>

                    <div className={`col-md-8 ${queries.length>0 ? '':'d-none'}`}>
                    {queries.map((x,index)=>{
                    return(
                        <div className='bg-white rounded py-3 mb-4 shadow-sm'>
                        <div className="row m-0">
                            <div className="col-1 text-center">
                                {/* <img src={require('../images/man-438081_960_720.png')} alt="" /> */}
                            </div>
                            <div className='col-10 ps-5 ps-md-4 ps-lg-1'>
                                <div>
                                    <p className='m-0'>{x.customer_id.customer_name}</p>
                                    <span className='text-secondary' style={{fontSize:'13px'}}>{x.created_at.slice(0,10)}</span>
                                </div>
                            </div>
                        </div>
                        <div className='ps-3 mt-3 border-bottom pb-3 mx-3'>
                            <p>{x.querry}</p>
                        </div>

{/* Rely for query */}

{x.answers.map((y,index)=>{
    return(
        <div className='ms-5 mt-4'>
<div className="row m-0">
                            <div className="col-1 text-center">
                                {/* <img src={require('../images/man-438081_960_720.png')} alt="" /> */}
                            </div>
                            <div className='col-10 ps-5 ps-md-4 ps-lg-1'>
                                <div>
                                    {/* <p className='m-0'>{user.name}</p> */}
                                    <span className='text-secondary' style={{fontSize:'13px'}}>{y.creatd_at.slice(0,10)}</span>
                                </div>
                            </div>
                        </div>
                        <div className='ps-3 mt-3'>
                            <p>{y.solution}</p>
                        </div>
</div>
    )
})}
                    </div>
                    )
                })}
                    </div>
                </div>

{/* Leave Details */}

<div className={`row m-0 ${type==='leave' ? '':'d-none'}`}>
<h6 className={`text-secondary py-3 text-center ${leave_requests.length>0 ? 'd-none':''}`}>No Data Found...</h6>

<div className={`col-md-8 ps-0 ${leave_requests.length>0 ? '':'d-none'}`}>
                       {leave_requests.map((x,index)=>{
                        return(
                            <div className="bg-white p-3 py-4 mb-4">
                            <div className="row m-0">
                                <div className="col-md-6">
                                    <p className='text-primary'>Student Name : <span className='text-dark'>{x.customer_id && x.customer_id.customer_name && x.customer_id.customer_name}</span></p>
                                </div>
                                <div className="col-md-6">
                                <p className='text-primary'>Batch Name : <span className='text-dark'>{x.batch_id.name && x.batch_id.name.sub_course_name && x.batch_id.name.sub_course_name}</span></p>

                                </div>
                                <div className="col-md-6">
                                <p className='text-primary'>Requested On : <span className='text-dark'>{x.created_at && x.created_at.slice(0,10)}</span></p>

                                </div>
                                <div className="col-md-6">
                                <p className='text-primary'>Trainer Name : <span className='text-dark'>{x.batch_id.trainer && x.batch_id.trainer.first_name && x.batch_id.trainer.first_name}</span></p>

                                </div>
                                <div className="col-12 mt-3">
                                    <h6>Reason for the Leave</h6>
                                    <p>{x.leave}</p>
                                </div>
                                <div className="col-12 text-end">
                                    <p className={`${x.responded && x.status ? 'text-success fw-medium':'d-none'}`}>Approved</p>
                                    <p className={`${x.responded && x.status==false ? 'text-danger fw-medium':'d-none'}`}>Rejected</p>
                                    <p className={`${x.responded ==false ? 'text-warning fw-medium':'d-none'}`}>Pending</p>
                                </div>
                            </div>
                        </div>
                        )
                       })}
                    </div>
</div>

{/* Suggestions */}
<div className={`${type==='suggestions' ? '':'d-none'}`}>
<h6 className={`text-secondary py-3 text-center ${suggestions.length>0 ? 'd-none':''}`}>No Data Found...</h6>

<div className={`table-responsive ${suggestions.length>0 ? '':'d-none'}`}>
    
                <table class="table">
  <thead>
    <tr>
      <th scope="col" className='py-3'><p className="text-center m-0 fw-medium">Name</p></th>
      <th scope="col" className='py-3'><p className="text-center m-0 fw-medium">Date</p></th>
      <th scope="col" className='py-3'><p className="text-center m-0 fw-medium">Suggestion</p></th>
    </tr>
  </thead>
  <tbody>
   {suggestions.map((x)=>{
    return(
        <tr>
        <td><p className="text-center m-0">{x.customer_id && x.customer_id.customer_name && x.customer_id.customer_name}</p></td>
        <td><p className="text-center m-0">{x.created_at && x.created_at.slice(0,10)}</p></td>
        <td><p className="text-center m-0">{x.suggestion}</p></td>
    </tr>
    )
   })}
  </tbody>
</table>
                </div>
</div>

<div className={`${type==='details' ? '':'d-none'}`}>
<div className='d-flex align-items-center justify-content-end'>
<button className='btn btn-sm btn-success px-3' onClick={downloadCertificate}>Download Profile</button>
</div>
<div id='student_details' className='p-3 bg-white rounded mt-3'>
<div className='row m-0 bg-white rounded shadow-sm pt-3 px-4 border'>
                    <div className="col-lg-6 mb-4 mb-md-5 d-flex align-items-center">
                        <p className='fw-bold' style={{fontSize:'20px'}}>{details.name && details.name}</p>
                    </div>
                    <div className="col-lg-6 mb-4 mb-md-5">
                        <img src={details2.Photograph} width={100} height={100} alt="" />
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Contact Number : </span>{details.contact_number}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                        <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Email : </span>{details.email}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Course Enquired : </span>{details.course_enquired}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Registered on : </span>{details2.Registration_Date && details2.Registration_Date.slice(0,10)}</p>
                    </div>

                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Area : </span>{details2.Area}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> City : </span>{details2.City}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> State : </span>{details2.State}, {details2.Pincode}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Date of Birth : </span>{details2.Date_of_Birth}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Aadhar Number : </span>{details2.Aadhar_Number}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Remarks : </span>{details2.Remarks}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Source : </span>{details.source}</p>
                    </div>
                    <div className="col-lg-6 mb-4">
                    <p style={{fontSize:'16px'}}><span className='fw-medium text-primary' style={{fontSize:'16px'}}> Priority : </span>{details.priority}</p>
                    </div>
                    
                </div>
</div>

               
</div>


            </div>
    </div>
  )
}

export default Student_details