import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { ipaddress, ipaddress2 } from '../App'

const Admin_Offcanvas_navbar = ({designation,activevalue}) => {
    const[open,setOpen]=useState(true)
    const[value,setValue]=useState("")
    useEffect(()=>{
      setValue(activevalue)
      
    },[])

    const user=JSON.parse(sessionStorage.getItem('user'))

  // To view the assigned permissions

const[permissions,setpermissions]=useState({})

useEffect(()=>{
  axios.get(`${ipaddress}/PermissionsView/${user.emp_id}/`)
  .then((r)=>{
    console.log("Permissions",r.data)
    setpermissions(r.data)
  })
},[])


const navigate=useNavigate()

const handleLogout=()=>{
    axios.post(`${ipaddress2}/User_Logout/${user.emp_id}/`)
    .then((r)=>{
      sessionStorage.removeItem("user")
      navigate('/admin_panel')
    })
    .catch((err)=>{
      console.log("Logout Error",err)
    })
  }

return (
    <div>
        <div class="offcanvas offcanvas-end navbaroffcanvas" style={{backgroundColor:'#251F5E'}} tabindex="-1" id="admin_navbar" aria-labelledby="admin_navbarLabel">
  <div class="offcanvas-header px-2">
  <div className=''>
  <img className={open ? 'd-inline' : 'd-none'} src={require('../images/Logo_Blue 1.png')} style={{width:'150px'}} alt="" />
              
               </div>
    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body p-0 px-2">
  <ul class={`nav flex-column ${open ? 'w-100' : ''} mx-auto px-2 mt-2`}>
  
  {/* <Sidebar_component/> */}
  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${value==="dashboard" ? 'sidebar-valid2':'sidebar-valid'}`}>
    <Link to='/dashboard' class={`nav-link d-flex align-items-center text-white`} href="#">
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-speedometer2" viewBox="0 0 16 16">
  <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4M3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707M2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10m9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5m.754-4.246a.39.39 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.39.39 0 0 0-.029-.518z"/>
  <path fill-rule="evenodd" d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A8 8 0 0 1 0 10m8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3"/>
</svg> <span className={`ms-3`}>Dashboard </span></Link>
  </li>
  

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100  ${open ? '' : 'd-flex justify-content-center'} ${value==="details" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/add_details' class={`nav-link d-flex align-items-center ${value==="details" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart" viewBox="0 0 16 16">
  <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Add Details</span> </Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="batches" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/all_batches' class={`nav-link d-flex align-items-center ${value==="batches" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" class="bi bi-list-check" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M5 11.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M3.854 2.146a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 3.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 1 1 .708-.708L2 7.293l1.146-1.147a.5.5 0 0 1 .708 0m0 4a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0l-.5-.5a.5.5 0 0 1 .708-.708l.146.147 1.146-1.147a.5.5 0 0 1 .708 0"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>All Batches</span> </Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100  ${open ? '' : 'd-flex justify-content-center'} ${value==="activities" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/activities' class={`nav-link d-flex align-items-center ${value==="activities" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bar-chart" viewBox="0 0 16 16">
  <path d="M4 11H2v3h2zm5-4H7v7h2zm5-5v12h-2V2zm-2-1a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h2a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM6 7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1zm-5 4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1z"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Activity</span> </Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="leads" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/leads' class={`nav-link d-flex align-items-center ${value==="leads" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>B2B Leads</span> </Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="customerleads" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/customerleads' class={`nav-link d-flex align-items-center ${value==="customerleads" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>B2C Leads</span> </Link>
  </li>
  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="allleads" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/all_leads' class={`nav-link d-flex align-items-center ${value==="allleads" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>All Leads</span> </Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="assignedleads" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/assigned_leads' class={`nav-link d-flex align-items-center ${value==="assignedleads" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Assigned Leads</span> </Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="approvals" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/approvals' class={`nav-link d-flex align-items-center ${value==="approvals" ? 'text-white' : 'text-white'}`}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-task" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"/>
  <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z"/>
  <path fill-rule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Approvals</span></Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="trainers" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/trainers' class={`nav-link d-flex align-items-center ${value==="trainers" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>View Trainers</span> </Link>
  </li>
 
  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="employees" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/view_employees' class={`nav-link d-flex align-items-center ${value==="employees" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>View Employees</span></Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="batch_details" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/batch_details' class={`nav-link d-flex align-items-center ${value==="batch_details" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Batch Details</span> </Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="list" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/applied_list' class={`nav-link d-flex align-items-center ${value==="list" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Applied List</span> </Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="students_list" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/students_list' class={`nav-link d-flex align-items-center ${value==="students_list" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-people" viewBox="0 0 16 16">
  <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1zm-7.978-1L7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002-.014.002zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0M6.936 9.28a6 6 0 0 0-1.23-.247A7 7 0 0 0 5 9c-4 0-5 3-5 4q0 1 1 1h4.216A2.24 2.24 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816M4.92 10A5.5 5.5 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275ZM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0m3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>All Students</span> </Link>
  </li>
  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="request" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/payments' class={`nav-link d-flex align-items-center ${value==="request" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-paypal" viewBox="0 0 16 16">
  <path d="M14.06 3.713c.12-1.071-.093-1.832-.702-2.526C12.628.356 11.312 0 9.626 0H4.734a.7.7 0 0 0-.691.59L2.005 13.509a.42.42 0 0 0 .415.486h2.756l-.202 1.28a.628.628 0 0 0 .62.726H8.14c.429 0 .793-.31.862-.731l.025-.13.48-3.043.03-.164.001-.007a.35.35 0 0 1 .348-.297h.38c1.266 0 2.425-.256 3.345-.91q.57-.403.993-1.005a4.94 4.94 0 0 0 .88-2.195c.242-1.246.13-2.356-.57-3.154a2.7 2.7 0 0 0-.76-.59l-.094-.061ZM6.543 8.82a.7.7 0 0 1 .321-.079H8.3c2.82 0 5.027-1.144 5.672-4.456l.003-.016q.326.186.548.438c.546.623.679 1.535.45 2.71-.272 1.397-.866 2.307-1.663 2.874-.802.57-1.842.815-3.043.815h-.38a.87.87 0 0 0-.863.734l-.03.164-.48 3.043-.024.13-.001.004a.35.35 0 0 1-.348.296H5.595a.106.106 0 0 1-.105-.123l.208-1.32z"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Payments</span> </Link>
  </li>

  <li data-bs-dismiss="offcanvas" class={`nav-item py-2 mb-3 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="das" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <a class={`nav-link d-flex align-items-center ${value==="das" ? 'text-white' : 'text-white'}`} target='__blank' href="https://das.meridahr.com/"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clipboard-data" viewBox="0 0 16 16">
  <path d="M4 11a1 1 0 1 1 2 0v1a1 1 0 1 1-2 0zm6-4a1 1 0 1 1 2 0v5a1 1 0 1 1-2 0zM7 9a1 1 0 0 1 2 0v3a1 1 0 1 1-2 0z"/>
  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1z"/>
  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0z"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>DAS</span> </a>
  </li>

</ul>

  </div>
</div>
    </div>
  )
}

export default Admin_Offcanvas_navbar