import axios from 'axios';
import React from 'react'
import { useState,useEffect } from 'react';
import { Zoom } from 'react-toastify';
import { ipaddress } from '../../App';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

const Closed_form = ({show1,setshow1,form_value,type}) => {


  const user=JSON.parse(sessionStorage.getItem('user'))

  const[availablecourses,setAvailablecourses]=useState([])
  const[classtypes,setclasstypes]=useState([])
  const[batchtypes,setbatchtypes]=useState([])
  const[particular_lead_details,setparticular_lead_details]=useState({})

  useEffect(()=>{

    axios.get(`${ipaddress}/Allclasstypes/`)
    .then((r)=>{
      console.log("Class types",r.data)
      setclasstypes(r.data.classtypes)
    })

    axios.get(`${ipaddress}/Allbatchtypes/`)
    .then((r)=>{
      console.log("Batch types",r.data)
      setbatchtypes(r.data.batchtypes)
    })
  },[])


// To get current date
  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  const [errorMessage, setErrorMessage] = useState('');
  

  // Prospect Back date validation
  const handleDateValidation2 = (date, name) => {
    const today = new Date();
    const selectedDate = new Date(date);
  
    if (selectedDate < today.setHours(0, 0, 0, 0)) {
      alert("You can only select today’s date or future dates.");
      return false;
    }
  
    setcloseddata({
      ...closeddata,
      [name]: date
    });
  
    return true;
  };



// -----------------------------------------------Closed Leads----------------------------------------------------
const [closeddata, setcloseddata] = useState({
  emp_id:user.emp_id,
  customer_lead_id:'',
  client_lead_id:'',
  level_lead:'closed',
  reason_for_closure:'',
  stage_of_closure:'newlead',
  remarks:'',
  opportunities_in_future:false,
  only_job:false
});

const handlecloasedChange = (e) => {
  const { name, value } = e.target;
  setcloseddata({
    ...closeddata,
    [name]: value
  });
};

const navigate=useNavigate()
const[load,setload]=useState(false)

const move_to_closed=(e)=>{
    e.preventDefault()

    setload(true)

    const data={...closeddata,...form_value}
    data.emp_id=user.emp_id

    let apiurl=''

    if(type==='customer'){
      apiurl='CustomerLeadsView'
    }
    if(type==='client'){
      apiurl='ClientLeadsView'
    }

  axios.post(`${ipaddress}/${apiurl}/`,data)
  .then((r)=>{
    toast.success('Moved to Closed',{
      autoClose: 1000,
      theme:'colored',
      position:'top-center'
    })
    setload(false)
    console.log("Moved to closed",r.data)
    console.log("Moved to closed form",closeddata)
    setcloseddata({
      emp_id:user.emp_id,
      customer_lead_id:'',
      client_lead_id:'',
      level_lead:'closed',
      reason_for_closure:'',
      stage_of_closure:'newlead',
      remarks:'',
      opportunities_in_future:false,
      only_job:false
    })

    setTimeout(() => {
      if(type==='customer'){
        navigate('/customerleads')
      }
      if(type==='client'){
        navigate('/leads')
      }
    }, 2000);
  })
  .catch((err)=>{
    setload(false)
    console.log("Moved to closed error",err)
    console.log("Moved to closed form",closeddata)

  })
}

const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  

return (
    <div>

<Modal backdrop="static"
        keyboard={false} size="md"
        aria-labelledby="example-modal-sizes-title-md" show={show1} onHide={()=>{
  setshow1(false)
}}>
        <Modal.Body>
        <div class="modal-body">
        <div className='d-flex justify-content-between px-3 mb-4 align-items-center'>
            <h5 className='text-primary'>Closed Form</h5>
        <button onClick={()=>setshow1(false)} className='bg-transparent border-0 ms-auto'><i class="fa-solid fa-circle-xmark fs-5"></i></button>

        </div>
        <form action="" onSubmit={move_to_closed}>
            <div className="row m-0">
                <div className="col-12">
                <div className="col-12">
                <textarea required onChange={handlecloasedChange} value={closeddata.reason_for_closure} name='reason_for_closure' className='p-2 rounded border-secondary' id="" placeholder='Reason for Closure...' rows="3" style={{width:'100%'}}></textarea>
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="">Stage of Closure</label>
                <input value="New Lead" disabled='true' className='form-control p-2 mt-2 bg-white rounded border-secondary shadow-none' id="" placeholder='stage1 for Closure...'/>
                </div>
                <div class="mb-3 mt-3">
                    <textarea required onChange={handlecloasedChange} value={closeddata.remarks} name='remarks' className='p-2 rounded border-secondary' id="" placeholder='Remarks...' rows="3" style={{width:'100%'}}></textarea>
</div>
                </div>
                <div className='col-12 d-flex align-items-center mt-3'>
                    <label htmlFor="checkbox1" className='ms-2 text-primary fw-medium'>Opportunities in Future</label>
                    <input type="radio" name='opportunities' className='me-1 ms-3' id='yes'/><label htmlFor="yes">Yes</label>
                    <input type="radio" name='opportunities' className='ms-3 me-1' id='no'/><label htmlFor="no">No</label>
                    <input type="checkbox" onChange={(e)=>{
                      if(e.target.checked){
                        closeddata.only_job=true
                      }
                      else{
                        closeddata.only_job=false
                      }
                    }} name='only_job' className='ms-3 me-1'/><label htmlFor="only_job">Only Job</label>
                </div>
                <div className="col-12 text-end mt-4">
                    <button data-bs-dismiss="modal" type='submit' className='btn btn-sm px-5 py-2 btn-primary text-white fw-medium'>{load ? 'Submitting...':'Submit'}</button>
                </div>

            </div>
        </form>
      </div>
        </Modal.Body>

      </Modal>
    </div>
  )
}

export default Closed_form