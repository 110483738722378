import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'

const Students = () => {

  const {id}=useParams()

  const[students,setstudents]=useState([])

  useEffect(()=>{
    axios.get(`${ipaddress2}/StudentsFromParticularBatch/${id}/`)
    .then((r)=>{
      console.log("Students details",r.data)
      setstudents(r.data)
    })
  },[])


return (
    <div className='d-flex'>
        <Sidebar activevalue={"batches"}/>
        <div className='w-100 main-div'>
            <Navbar/>

            <div style={{minHeight:'80vh'}} className='bg-light p-3 main-container'>
                <div>
                <div class="table-responsive mt-4">                    
<table class="w-100">
  <thead>
    <tr style={{backgroundColor:'#034592'}} className='text-white'>
      <th scope="col" className='py-3 ps-2'>SI.No</th>
      <th scope="col">Student Name</th>
      <th scope="col">Email</th>
      <th scope="col">Birthday</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    {students.map((x,index)=>{
      return(
        <tr className='table-row'>
      <th scope="row" className='ps-2 py-3'>{index+1}</th>
      <td>{x.user.customer_name}</td>
      <td>{x.user.email}</td>
      <td>{x.user.birthday}</td>
      <td><svg style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="green" class="bi bi-award" viewBox="0 0 16 16">
  <path d="M9.669.864 8 0 6.331.864l-1.858.282-.842 1.68-1.337 1.32L2.6 6l-.306 1.854 1.337 1.32.842 1.68 1.858.282L8 12l1.669-.864 1.858-.282.842-1.68 1.337-1.32L13.4 6l.306-1.854-1.337-1.32-.842-1.68zm1.196 1.193.684 1.365 1.086 1.072L12.387 6l.248 1.506-1.086 1.072-.684 1.365-1.51.229L8 10.874l-1.355-.702-1.51-.229-.684-1.365-1.086-1.072L3.614 6l-.25-1.506 1.087-1.072.684-1.365 1.51-.229L8 1.126l1.356.702z"/>
  <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z"/>
</svg></td>
    </tr>
      )
    })}
    
  </tbody>
</table>
</div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="certificateModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        ...
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Students