import React from 'react'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const Cretificate2 = () => {

    const downloadCertificate = () => {
        const input = document.getElementById('certificate')
        html2canvas(input, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png')
          const pdf = new jsPDF('landscape', 'pt', 'a4')
          const pdfWidth = pdf.internal.pageSize.getWidth()
          const pdfHeight = pdf.internal.pageSize.getHeight()
          const imgWidth = canvas.width
          const imgHeight = canvas.height
          const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
          const imgX = (pdfWidth - imgWidth * ratio) / 10
          const imgY = (pdfHeight - imgHeight * ratio) / 10
    
          pdf.addImage(imgData, 'PNG', imgX, imgY, imgWidth * ratio, imgHeight * ratio)
          pdf.save('certificate.pdf')
        }).catch(error => {
          console.error('Error generating PDF:', error)
        })
      }


  return (
    <div className='py-4'>
         <div className="text-center mb-3">
        <button onClick={downloadCertificate} className="btn btn-primary">Download</button>
      </div>

        <div className='cetrificate2-1 container py-5 px-5' id='certificate' style={{minHeight:'100vh'}}>
            <div className='cetrificate2-2 row m-0' style={{height:'100%'}}>
                <div className='col-9 py-5 mt-4'>
                <div className='text-center mb-4'>
                    {/* <img src={require('../images/skill_learning_logo-04-removebg-preview.png')} width={200} alt="" /> */}
                </div>
                <div className=''>
                    <p className='certificate1-head text-center m-0 ps-4' style={{color:'#12143D'}}>CERTIFICATE</p>
                    <p className='text-center'>
                        {/* <img src={require('../images/Rectangle 2.png')} width={130} height={2} alt="" /> */}
                        <span className='mx-4 certificate1-text' style={{fontWeight:500}}>OF ACHIEVEMENT</span>
                        {/* <img src={require('../images/Rectangle 2.png')} width={130} height={2} alt="" /> */}
                        </p>
                </div>

                <div className='mt-5'>

                    <div className='px-5'>
                        <p className='certificate2-head2 text-center'>Madhavan N</p>

                        <p className=' text-center certificate2-para' style={{fontSize:'17px'}}>

Lorem ipsum dolor sit amet consectetur adipisicing elit. Consequuntur eos provident non repudiandae ipsa, vitae ullam dicta corrupti nisi nobis est voluptatibus ea temporibus laudantium, veritatis, consequatur debitis omnis blanditiis?

                        </p>

                    </div>


                </div>

                <div className='row m-0 justify-content-between mt-5 pt-4'>
                    <div className="col-6">
                        <div>
                        <p className='text-center fw-medium' style={{fontSize:'20px',letterSpacing:'4px',color:'#1d1d5a'}}>DATE</p>
                            <p className='m-0 text-center'>03 June 2024</p>
                        </div>
                    </div>
                    <div className="col-6">
                        <div>
                        <p className='text-center fw-medium' style={{fontSize:'20px',letterSpacing:'4px',color:'#1d1d5a'}}>SIGNATURE</p>
                            <div className='text-center'>
                            {/* <img src={require('../images/Layer 2.png')} width={100} height={48} alt="" /> */}
                            <p className='m-0' style={{fontSize:'10px'}}>MERIDA TECH MINDS (OPC) PVT.LTD</p>
                            </div>

                        </div>
                    </div>
                </div>

                </div>

                <div className='col-3 ' style={{position:'relative'}}>
                    {/* <img src={require('../images/2.png')} style={{width:'80%',position:'absolute',top:'-30px',right:'24%'}} alt="" /> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default Cretificate2