import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ipaddress } from '../App'
import axios from 'axios'

const Sidebar = ({activevalue}) => {
 
  const[open,setOpen]=useState(true)
  const[value,setValue]=useState("")
  useEffect(()=>{
    setValue(activevalue)
  },[])


//   // Logout

//   const navigate=useNavigate()

//   const handleLogout=()=>{
//     axios.post(`${ipaddress}/User_Logout/${user.emp_id}/`)
//     .then((r)=>{
//       sessionStorage.removeItem("user")
//       navigate('/')
//     })
//     .catch((err)=>{
//       console.log("Logout Error",err)
//     })
//   }
  return (
    <div style={{width:'80px',backgroundColor:'#002147',height:'100vh',overflowY:'scroll'}} className='shadow-sm sidebar d-none d-lg-block'>
            {/* <div className='px-4 d-flex align-items-center justify-content-center' style={{position:'relative',height:'70px'}}>
                <img className={open ? 'd-inline' : 'd-none'} src={require('../images/student/Logo_Blue 1.png')} style={{width:'86%'}} alt="" />
              
            </div> */}


<ul class={`nav flex-column ${open ? 'w-100' : ''} mx-auto mt-2`}>

  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="dashboard" ? 'sidebar-valid2':'sidebar-valid'}`}  >
   <Link to='/trainer_dashboard' class={`nav-link navbar-link ${value==="dashboard"  ? 'text-white' : 'text-white'}`} href="#">
   <img src={require('../images/ImporvisedUI/stash_dashboard-light.png')} alt="" />
   <span className='navbar-link-text'>Dashboard </span></Link>
  </li>

  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="assigntests" ? 'sidebar-valid2':'sidebar-valid'}`}  >
   <Link to='/assign_test' class={`nav-link navbar-link ${value==="assigntests"  ? 'text-white' : 'text-white'}`} href="#">
   <img src={require('../images/ImporvisedUI/formkit_add.png')} alt="" />
   <span className='navbar-link-text'>Create Questions</span></Link>
  </li>
  
  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="your_groups" ? 'sidebar-valid2':'sidebar-valid'}`}  >
   <Link to='/your_groups' class={`nav-link navbar-link ${value==="your_groups"  ? 'text-white' : 'text-white'}`} href="#">
   <img src={require('../images/ImporvisedUI/mdi_people.png')} alt="" />
   <span className='navbar-link-text'>Groups</span></Link>
  </li>

  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="batches" ? 'sidebar-valid2':'sidebar-valid'}`}  >
   <Link to='/batches' class={`nav-link navbar-link ${value==="batches"  ? 'text-white' : 'text-white'}`} href="#">
   <img src={require('../images/ImporvisedUI/ic_round-list.png')} alt="" />
   <span className='navbar-link-text'>Batches </span></Link>
  </li>

  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="assignmarks" ? 'sidebar-valid2':'sidebar-valid'}`}  >
   <Link to='/assign_marks' class={`nav-link navbar-link ${value==="assignmarks"  ? 'text-white' : 'text-white'}`} href="#">
   <img src={require('../images/ImporvisedUI/clarity_list-line.png')} alt="" />
   <span className='navbar-link-text'>Mark Sheets</span></Link>
  </li>

  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="questions" ? 'sidebar-valid2':'sidebar-valid'}`}  >
   <Link to='/question_papers' class={`nav-link navbar-link ${value==="questions"  ? 'text-white' : 'text-white'}`} href="#">
   <img src={require('../images/ImporvisedUI/mdi_teacher.png')} alt="" />
   <span className='navbar-link-text'>Assign Test</span></Link>
  </li>

  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="leave_approval" ? 'sidebar-valid2':'sidebar-valid'}`}  >
   <Link to='/leave_approval' class={`nav-link navbar-link ${value==="leave_approval"  ? 'text-white' : 'text-white'}`} href="#">
   <img src={require('../images/ImporvisedUI/fluent_approvals-app-20-filled.png')} alt="" />
   <span className='navbar-link-text'>Leave Approvals</span></Link>
  </li>

  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value=="" ? 'sidebar-valid2':'sidebar-valid'}`}  >
   <a class={`nav-link navbar-link`} href="https://das.meridahr.com/" target='_blank'>
   <img src={require('../images/ImporvisedUI/clarity_list-line.png')} alt="" />
   <span className='navbar-link-text text-white'>DAS</span></a>
  </li>

</ul>

    </div>
  )
}

export default Sidebar