import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress, ipaddress2 } from '../App'
import { useNavigate, useParams } from 'react-router-dom'
import Preloader from '../Components/Preloader'

const Interview_details = () => {
    const user=JSON.parse(sessionStorage.getItem('user'))

    const {id}=useParams()

    const navigate=useNavigate()

    const[interviews,setinterviews]=useState([])
    const[loading,setloading]=useState()
    const[count,setcount]=useState(0)
    const[index1,setindex1]=useState(-1)

    useEffect(()=>{
      setloading(true)
        axios.get(`${ipaddress2}/Studentinterviews/${user.id}/`)
        .then((r)=>{
            const data=r.data.filter(x=>x.applied_status==false)
            setinterviews(data)
            console.log("Interview details",data)
            setloading(false)
        })
    },[count])

      // To get the time in hours and minutes format
      const formatTime = (timeString) => {
       if(timeString!=null){
        const [hours, minutes] = timeString.split('T')[1].split(':');
        return `${hours}:${minutes}`;
       }
      };

const[load,setload]=useState()

const apply=(id)=>{
  setload(true)
  setTimeout(() => {
    send(id)
  }, 1000);
}

  const send=(id)=>{
        axios.post(`${ipaddress}/apply/`,{
          interview:id,
          student:user.id
        })

        .then((r)=>{
          console.log("Applied",r.data)
          setcount(count+1)
          setload(false)
          setindex1(-1)
        })
        .catch((err)=>{
          console.log("Apply error",err)
        })
      }

      const[applied_interviews,setapplied_interviews]=useState([])

      const fetch_applied_interviews=()=>{
        axios.get(`${ipaddress}/student-applied-interviews/${user.id}/`)
        .then((r)=>{
          console.log("Applied interviews",r.data);
          setapplied_interviews(r.data)
        })
      }

const[type,settype]=useState('available')

return (
    <div>
      {loading ? (<Preloader/>):(
        <div className='d-flex animate__animated animate__fadeIn'>
        <Sidebar activevalue={"interview"}/>
        <div className='w-100'>
            <Navbar/>

            <div className='bg-light p-3 main-container' style={{minHeight:'100vh'}}>
           <div className='d-flex align-items-center pb-2'>
           <button onClick={()=>{
        settype("available")
    }} className={`btn btn-sm fw-medium py-2 rounded-0 px-3`} style={{fontSize:'14px',backgroundColor:type==='available' ? '#002147':'#ffff',color:type==='available' ? '#ffff':'#002147',border:'1px solid #002147'}}>Available Interviews</button>
    <button onClick={()=>{
        settype("applied")
        fetch_applied_interviews()
    }} className={`btn btn-sm fw-medium py-2 rounded-0 px-3`} style={{fontSize:'14px',backgroundColor:type==='applied' ? '#002147':'#ffff',color:type==='applied' ? '#ffff':'#002147',border:'1px solid #002147'}}>Applied Interviews</button>

           </div>

{/* Available Interviews */}
  <div className={`${type==='available' ? '':'d-none'}`}>
  <h6 className={`text-center text-secondary py-4 ${interviews.length>0 ? 'd-none':''}`}>No Data Available...🧐</h6>
                <div className={`table-responsive mt-3 bg-white ${interviews.length>0 ? '':'d-none'}`}>
                <table class="w-100">
  <thead className='table-secondary'>
    <tr style={{backgroundColor:'#034592'}}>
      {/* <th scope="col"><p className="text-center m-0 fw-medium">Company Name</p></th> */}
      <th scope="col" className='py-3'><p className="text-white  m-0 fw-medium ps-2">Location</p></th>
      <th scope="col"><p className="text-white m-0 fw-medium">Position</p></th>
      <th scope="col"><p className="text-white m-0 fw-medium">Interview Type</p></th>
      <th scope="col"><p className="text-white m-0 fw-medium">Interview Date</p></th>
      <th scope="col"><p className="text-white m-0 fw-medium">Interview Time</p></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
   {interviews.map((x,index)=>{
    return(
        <tr className='table-row'>
        {/* <td className='py-3'><p className="text-center m-0">{x.company_name}</p></td> */}
        <td className='py-3'><p className="m-0 ps-2">{x.interview_location}</p></td>
        <td className='py-3'><p className="m-0">{x.position}</p></td>
        <td className='py-3'><p className="m-0">{x.interview_type}</p></td>
        <td className='py-3'><p className="m-0">{x.interview_date && x.interview_date.slice(0,10)}</p></td>
        <td className='py-3'><p className="m-0">{formatTime(x.interview_date)}</p></td>
        <td className='py-3'><div className=''>
          <p className={`fw-medium text-success ${x.applied_status ? '':'d-none'}`}>Applied</p>
          <button className={`btns text-white py-2 px-3 ${x.applied_status ? 'd-none':''}`} onClick={()=>{
            setindex1(index)
            apply(x.id)
          }}>
             <span class={`spinner-border spinner-border-sm ${load && index===index1 ? '':'d-none'}`} aria-hidden="true"></span>
             <span class={`${load && index===index1 ? 'ms-2':'d-none'}`} role="status">Applying...</span>
             <span className={`${load && index===index1 ? 'd-none':''}`}>Apply</span>
             </button></div></td>
    </tr>
    )
   })}
  </tbody>
</table>
                </div>
  </div>

{/* Applied Interviews */}
<div className={`${type==='applied' ? '':'d-none'}`}>
  <h6 className={`text-center text-secondary py-4 ${applied_interviews.length>0 ? 'd-none':''}`}>No Data Available...🧐</h6>
                <div className={`table-responsive mt-3 bg-white ${applied_interviews.length>0 ? '':'d-none'}`}>
                <table class="w-100">
  <thead className='table-secondary'>
    <tr style={{backgroundColor:'#034592'}}>
      {/* <th scope="col"><p className="text-center m-0 fw-medium">Company Name</p></th> */}
      <th scope="col" className='py-3'><p className="text-white fw-normal m-0 ps-2">Location</p></th>
      <th scope="col"><p className="text-white fw-normal m-0">Position</p></th>
      <th scope="col"><p className="text-white fw-normal m-0">Interview Type</p></th>
      <th scope="col"><p className="text-white fw-normal m-0">Interview Date</p></th>
      <th scope="col"><p className="text-white fw-normal m-0">Interview Time</p></th>
    </tr>
  </thead>
  <tbody>
   {applied_interviews.map((x,index)=>{
    return(
        <tr className='table-row'>
        {/* <td className='py-3'><p className="text-center m-0">{x.company_name}</p></td> */}
        <td className='py-3'><p className="m-0 ps-2">{x.interview_location}</p></td>
        <td className='py-3'><p className="m-0">{x.position}</p></td>
        <td className='py-3'><p className="m-0">{x.interview_type}</p></td>
        <td className='py-3'><p className="m-0">{x.interview_date && x.interview_date.slice(0,10)}</p></td>
        <td className='py-3'><p className="m-0">{formatTime(x.interview_date)}</p></td>
    </tr>
    )
   })}
  </tbody>
</table>
                </div>
  </div>
            </div>

        </div>
    </div>
      )}
    </div>
  )
}

export default Interview_details