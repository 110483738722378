import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { ipaddress } from '../App'
import Preloader from './Preloader'

const View_lead_details = () => {

  const {email}=useParams()

  const[followup,setfollowup]=useState([])
  const[prospect,setprospect]=useState([])
  const[registered,setregistered]=useState({})
  const[lead_details,setlead_details]=useState({})
  const[load,setload]=useState(false)

  useEffect(()=>{
    setload(true)
    axios.get(`${ipaddress}/EntireData/${email}/`)
    .then((r)=>{
      setload(false)
      console.log("Entire lead history",r.data);
      setfollowup(r.data.followup_history)
      setprospect(r.data.prospect_history)
      setregistered(r.data.registration_details)
      setlead_details(r.data.customer_lead_data)
    })
  },[])


return (
    <div className='d-flex'>
        <Sidebar/>
        <div className="w-100">
            <Navbar/>
            {load ? (<Preloader/>):(
              <div className="p-3 bg-light main-container">
              <div className='bg-white rounded shadow-sm p-4'>
                <h6 className='text-center fs-4 border-bottom pb-3'>Lead History</h6>
                  {/* <div className='row m-0'>
                    <div className="col-md-6">
                      <p><span className='text-primary fw-medium fs-5'>Name : </span>Madhavan</p>
                    </div>
                  </div> */}

{/* Lead History */}
{lead_details && (
                    <div className=''>
                    <h5 className='py-3'>Lead Details</h5>
                     <div className="row m-0">
                       
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Name : </span>{lead_details.name}</p>
                    </div>
                    <div className={`col-md-6 mb-3 pe-md-0`}>
                      <p><span className='text-primary fw-medium fs-6'>Email : </span>{lead_details.email}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Contact Number : </span>{lead_details.contact_number}</p>
                    </div>
                    <div className={`col-md-6 mb-3 pe-md-0`}>
                      <p className={`${lead_details.othercourseenquired==null ? '':'d-none'}`}><span className={`text-primary fw-medium fs-6`}>Course Enquired : </span>{lead_details.course_enquiredd && lead_details.course_enquiredd}</p>
                      <p className={`${lead_details.othercourseenquired==null ? 'd-none':''}`}><span className={`text-primary fw-medium fs-6`}>Course Enquired : </span>{lead_details.othercourseenquired && lead_details.othercourseenquired}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Level of Lead : </span>{lead_details.level_lead_name}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Created on : </span>{lead_details.created_at && lead_details.created_at.slice(0,10)}</p>
                    </div>
                    <div className={`col-md-6 mb-3 ps-md-0`}>
                      <p><span className='text-primary fw-medium fs-6'>Source : </span>{lead_details.source}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Priority : </span>{lead_details.priority && lead_details.priority}</p>
                    </div>
                    <div className="col-12 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Remarks : </span>{lead_details.remarks && lead_details.remarks}</p>
                    </div>
                   
                     </div>
                  </div>
                  )}

{/* Followup History */}
                  {followup.length>0 && (
                    <div className=''>
                    <h5 className='py-3'>Followup History</h5>
                  {followup.map((x)=>{
                    return(
                     <div className="row m-0">
                       <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Followup Date : </span>{x.Followup_Date}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Expected Walkin Date : </span>{x.expected_walkin_datetime && x.expected_walkin_datetime.slice(0,10)}</p>
                    </div>
                    <div className={`col-md-6 mb-3 ps-md-0`}>
                      <p><span className='text-primary fw-medium fs-6'>Expected Registration Date : </span>{x.Expected_Registration_Walkin_Date && x.Expected_Registration_Walkin_Date}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Expected Registration Time : </span>{x.Expected_Registration_Walkin_Time && x.Expected_Registration_Walkin_Time}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Expected Followup Date : </span>{x.expected_followup_date && x.expected_followup_date}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Remarks : </span>{x.Remarks}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Need Supervisor Attention : </span>{x.Need_Supervisor_Attention}</p>
                    </div>
                     </div>
                    )
                  })}
                  </div>
                  )}

{/* Prospects History */}

{prospect.length>0 && (
                    <div className=''>
                    <h5 className='py-3'>Prospect History</h5>
                  {prospect.map((x)=>{
                    return(
                     <div className="row m-0">
                       
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Created at : </span>{x.created_at && x.created_at.slice(0,10)}</p>
                    </div>
                    <div className={`col-md-6 mb-3 pe-md-0`}>
                      <p><span className='text-primary fw-medium fs-6'>Expected Registration Date : </span>{x.expected_registration_date && x.expected_registration_date}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Preferred Course : </span>{x.preferred_course && x.preferred_course}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Billing Amount : </span>{x.billing_amount && x.billing_amount}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Expected Collection : </span>{x.expected_collection && x.expected_collection}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Mode of Payment : </span>{x.mode_of_payment && x.mode_of_payment}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Date of Payment : </span>{x.date_of_payment && x.date_of_payment}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Time of Payment : </span>{x.time_of_payment && x.time_of_payment}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Tentative Batch Start Date : </span>{x.tentative_batch_start_date && x.tentative_batch_start_date}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Preferred Batch Start Time : </span>{x.preferred_batch_start_time && x.preferred_batch_start_time}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Remarks : </span>{x.remarks}</p>
                    </div>
                     </div>
                    )
                  })}
                  </div>
                  )}

{/* Registered History */}
{registered && (
                    <div className=''>
                    <h5 className='py-3'>Registration History</h5>
                     <div className="row m-0">
                       
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Registration date : </span>{registered.Registration_Date && registered.Registration_Date.slice(0,10)}</p>
                    </div>
                    <div className={`col-md-6 mb-3 pe-md-0`}>
                      <p><span className='text-primary fw-medium fs-6'>Remarks : </span>{registered.Remarks}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Door Number : </span>{registered.Door_Number && registered.Door_Number}</p>
                    </div>
                    <div className={`col-md-6 mb-3 pe-md-0`}>
                      <p><span className='text-primary fw-medium fs-6'>Street Name : </span>{registered.Street_Name && registered.Street_Name}</p>
                    </div>
                    <div className="col-md-6 mb-3 ps-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Area : </span>{registered.Area && registered.Area}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>City : </span>{registered.City && registered.City}</p>
                    </div>
                    <div className={`col-md-6 mb-3 ps-md-0`}>
                      <p><span className='text-primary fw-medium fs-6'>State : </span>{registered.State && registered.State}</p>
                    </div>
                    <div className="col-md-6 mb-3 pe-md-0">
                      <p><span className='text-primary fw-medium fs-6'>Pincode : </span>{registered.Pincode && registered.Pincode}</p>
                    </div>
                   
                     </div>
                  </div>
                  )}

              </div>
          </div>
            )}
        </div>
    </div>
  )
}

export default View_lead_details