import React from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { toast } from 'react-toastify'
import { useState,useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ipaddress2 } from '../App'
import axios from 'axios'

const Your_queries = () => {
    const {id} = useParams()

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[queries,setqueries]=useState([])

    const navigate=useNavigate()

    useEffect(()=>{
        fetch_queries()
    },[])

    const fetch_queries=()=>{
        axios.put(`${ipaddress2}/QuerriesView/${user.email}/`,{
            batch_id:id
        })
        .then((r)=>{
            console.log("Students Queries fetched",r.data)
            setqueries(r.data)
        })
    }

    const[query_id,setquery_id]=useState()

    const[reply,setreply]=useState("")

    const post_reply=()=>{
        axios.post(`${ipaddress2}/SendQuerries/${user.email}/${query_id}/`,{
            solution:reply
        })
        .then((r)=>{
            console.log("Successfully replied")
            setreply("")
            toast.success('Successfully replied',{
                autoClose:2000,
                theme:'colored',
      position:'top-center'
            })
            fetch_queries()
        })
        
    }

  return (
    <div className='d-flex animate__animated animate__fadeIn'>
        <Sidebar activevalue={"batches"}/>

        <div className='w-100'>
            <Navbar/>

            <div className='bg-light p-3 main-container' style={{minHeight:'100vh'}}>
                <h6 className='pb-3 pt-2 m-0 d-flex align-items-center'><svg style={{cursor:'pointer'}} onClick={()=>{
                    navigate('/student_batches')
                }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg> <span className='ms-2 fs-5 text-dark'>Your Queries</span></h6>

                <div className='row m-0 w-100'>
                    <div className={`col-md-8 ${queries.length>0 ? '':'d-none'}`}>
                    {queries.map((x,index)=>{
                    return(
                        <div className='bg-white rounded py-3 mb-4 shadow-sm'>
                        <div className="row m-0">
                            <div className="col-1 text-center">
                                {/* <img src={require('../images/man-438081_960_720.png')} alt="" /> */}
                            </div>
                            <div className='col-10 ps-5 ps-md-4 ps-lg-1'>
                                <div>
                                    <p className='m-0'>{x.customer_id.customer_name}</p>
                                    <span className='text-secondary' style={{fontSize:'13px'}}>{x.created_at.slice(0,10)}</span>
                                </div>
                            </div>
                        </div>
                        <div className='ps-3 mt-3 border-bottom pb-3 mx-3'>
                            <p>{x.querry}</p>
                        </div>

{/* Rely for query */}

{x.answers.map((y,index)=>{
    return(
        <div className='ms-5 mt-4'>
<div className="row m-0">
                            <div className="col-1 text-center">
                                {/* <img src={require('../images/man-438081_960_720.png')} alt="" /> */}
                            </div>
                            <div className='col-10 ps-5 ps-md-4 ps-lg-1'>
                                <div>
                                    <p className='m-0'>{user.name}</p>
                                    <span className='text-secondary' style={{fontSize:'13px'}}>{y.creatd_at.slice(0,10)}</span>
                                </div>
                            </div>
                        </div>
                        <div className='ps-3 mt-3'>
                            <p>{y.solution}</p>
                        </div>
</div>
    )
})}
                    </div>
                    )
                })}
                    </div>

                <div className={`col-md-4 bg-white pb-3 ${queries.length>0 ? '':'mx-auto'}`} style={{minHeight:'70vh'}}>
                    <img src="https://img.freepik.com/free-vector/three-man-having-business-meeting-office_74855-4583.jpg?t=st=1717832698~exp=1717836298~hmac=c04ac2bd9d6d55633c648247c673f7ef4c91fa419a1075e9e49f87e912d31025&w=740" style={{width:'100%'}} alt="" />
                 
                 <div className='px-3 mt-2'>
                 <button onClick={()=>{
                    navigate(`/ask_doubt/${id}`)
                 }} className='w-100 btn btn-sm rounded-0 text-color py-2 px-5 fs-5' style={{border:'2px solid #002147'}}>Ask Your Queries</button>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Your_queries