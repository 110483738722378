import React from 'react'
import Student_login from './Student_login'

const Protect3 = ({Child}) => {
    const user=sessionStorage.getItem('user')
    const verify=()=>{
        if(user !=null){
            return true
        }
        else{
            return false
        }
    }
  return (
    <div>
        {
            verify() ? <Child/> : <Student_login/>
        }
    </div>
  )
}

export default Protect3