import React, { useEffect } from 'react'

const Protect = ({Child,setDesignation,Child2}) => {
    const user=JSON.parse(sessionStorage.getItem('user'))
    const data=user.designation

console.log("Designation",user.designation);

    const verify=()=>{
        if(user.designation==='admin'){
            return true
        }
        else{
            return false
        }
    }
  return (
    <div>
        {
            verify() ? <Child2/> : <Child/>
        }
    </div>
  )
}

export default Protect