import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress, ipaddress2 } from '../App'
import { useNavigate, useParams } from 'react-router-dom'

const Students_Logsheet = () => {
    const user=JSON.parse(sessionStorage.getItem('user'))

    const {id}=useParams()

    const navigate=useNavigate()

    const[student_logsheet,setstudent_logsheet]=useState([])

    // useEffect(()=>{
    //     axios.post(`${ipaddress2}/StudentBatches/${user.email}/`,{
    //         batch_id:id
    //     })
    //     .then((r)=>{
    //         console.log("Logsheet details",r.data)
    //         setstudent_logsheet(r.data)
    //     })
    // },[])


  return (
    <div className='d-flex'>
        <Sidebar/>
        <div className='w-100'>
            <Navbar/>

            <div className='bg-light p-3 main-container' style={{minHeight:'100vh'}}>
            <svg onClick={()=>{
              navigate('/students_under_batch/2')
            }} style={{cursor:'pointer'}}  xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>
              {/* <h6 className={`text-center py-4 ${student_logsheet.length>0 ? 'd-none':''}`}>No Data Available...🧐</h6> */}
                <div className={`table-responsive mt-3`}>
                <table class="table">
  <thead>
    <tr>
      <th scope="col"><p className='text-center m-0 fw-medium'>Date</p></th>
      <th scope="col"><p className='text-center m-0 fw-medium'>Syllabus Covered</p></th>
      <th scope="col"><p className='text-center m-0 fw-medium'>Attendance Status</p></th>
    </tr>
  </thead>
  <tbody>
   {/* {student_logsheet.map((x)=>{
    return(
        <tr>
        <td>{x.date}</td>
        <td>{x.syllabus_covered}</td>
        <td>{x.attendance_status}</td>
    </tr>
    )
   })} */}
  </tbody>
</table>
                </div>
            </div>

        </div>
    </div>
  )
}

export default Students_Logsheet