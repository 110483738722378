import { daysArray, formatDate } from './utils';

function THead({ typeWise }) {
  return (
    <thead className="tw-bg-gray-100">
      <tr>
        <th className="tw-py-3 tw-px-3 tw-text-left tw-text-sm tw-font-semibold">
          S.No
        </th>
        <th className="tw-py-3 tw-px-3 tw-text-left tw-text-sm tw-font-semibold">
          {typeWise === 'source' ? 'Sources' : 'Courses'}
        </th>
        <th className="tw-py-3 tw-px-3 tw-text-left tw-text-sm tw-font-semibold">
          Achieved
        </th>
        {daysArray.map(day => (
          <th
            key={day}
            className="tw-py-3 tw-px-3 tw-text-center tw-text-sm tw-font-semibold"
          >
            {formatDate(day)}
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default THead;
