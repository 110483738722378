import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ipaddress, ipaddress2 } from '../App'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import { toast } from 'react-toastify'

const Sidebar = ({activevalue}) => {
 
  const[open,setOpen]=useState(true)
  const[value,setValue]=useState("")
  useEffect(()=>{
    setValue(activevalue)
  },[])

  const user=JSON.parse(sessionStorage.getItem('user'))


  // Logout

  const navigate=useNavigate()

  const handleLogout=()=>{
    axios.post(`${ipaddress2}/StudentLogout/${user.email}/`)
    .then((r)=>{
      sessionStorage.removeItem("user")
      navigate('/student_login')
    })
    .catch((err)=>{
      console.log("Logout Error",err)
    })
  }

  const[show,setshow]=useState(false)

  const[test_name,settest_name]=useState('')

  const book_test=()=>{
axios.post(`${ipaddress}/StudentTestView/`,{
  student_id:user.id,
  test_name:test_name
})
.then((r)=>{
  window.open('https://skillassessmenttest.meridahr.com/', '_blank');
  setshow(false)
})
  }


  return (
    <div style={{width:'80px',backgroundColor:'#002147',height:'100vh',overflowY:'scroll'}} className='shadow-sm sidebar d-none d-lg-block'>
            {/* <div className='px-4 d-flex align-items-center justify-content-center' style={{position:'relative',height:'70px'}}>
                <img className={open ? 'd-inline' : 'd-none'} src={require('../images/student/Logo_Blue 1.png')} style={{width:'86%'}} alt="" />
            </div> */}


<ul class={`nav flex-column ${open ? 'w-100' : ''} mx-auto mt-2`}>
  
  <li class={`nav-item py-2  w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="dashboard" ? 'sidebar-valid2':'sidebar-valid'}`}  >
   <Link to='/student_dashboard' class={`nav-link navbar-link ${value==="dashboard"  ? 'text-white' : 'text-white'}`} href="#">
   <img src={require('../images/ImporvisedUI/stash_dashboard-light.png')} alt="" />
   <span className='navbar-link-text'>Dashboard </span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="batches" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/student_batches' class={`nav-link navbar-link ${value==="batches" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/ic_round-list.png')} alt="" />
  <span className={`navbar-link-text`}>Batches</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="courses" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/student_courses' class={`nav-link navbar-link ${value==="courses" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/icons8_student.png')} alt="" />
  <span className={`navbar-link-text`}>Courses</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="marks" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/student_marks' class={`nav-link navbar-link ${value==="marks" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/ic_round-list.png')} alt="" />
  <span className={`navbar-link-text`}>Marks</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="fees" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/installments' class={`nav-link navbar-link ${value==="fees" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/ic_baseline-paypal.png')} alt="" />
  <span className={`navbar-link-text`}>Payments</span> </Link>
  </li>


  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="complaint" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/complaint' class={`nav-link navbar-link ${value==="complaint" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/mdi_teacher.png')} alt="" />
  <span className={`navbar-link-text`}>Suggestion</span> </Link>
  </li>
  
  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="leave" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/your_leaves' class={`nav-link navbar-link ${value==="leave" ? 'text-white' : 'text-white'}`}>
  <img src={require('../images/ImporvisedUI/fluent_approvals-app-20-filled.png')} alt="" />
  <span className={`navbar-link-text`}>Leave Request</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/project_dashboard' class={`nav-link navbar-link ${value==="" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/clarity_list-line.png')} alt="" />
  <span className={`navbar-link-text`}>Projects</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="interview" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/interview_details' class={`nav-link navbar-link ${value==="interview" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/ic_round-list.png')} alt="" />
  <span className={`navbar-link-text`}>Interviews</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="feedback" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/feedback' class={`nav-link navbar-link ${value==="feedback" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/mdi_teacher.png')} alt="" />
  <span className={`navbar-link-text`}>Feedback</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="refer" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/refer' class={`nav-link navbar-link ${value==="refer" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/leader.png')} alt="" />
  <span className={`navbar-link-text`}>Refer & Earn</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${value==="" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link onClick={()=>setshow(true)} class={`nav-link navbar-link ${value==="" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/clarity_list-line.png')} alt="" />
  <span className={`navbar-link-text`}>Book Test</span> </Link>
  </li>

</ul>

<Modal show={show} onHide={()=>{
  setshow(false)
}}>
        <Modal.Header closeButton>
          <Modal.Title>Book the Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label htmlFor="">Student Name</label>
            <input type="text" value={user.student_name} className='form-control bg-white py-3 mt-2 mb-4' disabled name="" id="" />
            <label htmlFor="">Test Name</label>
            <input value={test_name} onChange={(e)=>settest_name(e.target.value)} type="text" className='shadow-none border-secondary-subtle form-control py-3 mt-2 mb-4' name="" id="" />

            <button className='btn btn-primary px-4 d-block ms-auto mb-3 py-2' onClick={book_test}>Book the Test</button>
          </div>
        </Modal.Body>
      </Modal>

    </div>
  )
}

export default Sidebar