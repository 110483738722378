import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { ipaddress } from '../App';
import { toast } from 'react-toastify';

const Add_activity_targets = ({ show, setshow, setshow2, id, name }) => {
  const [modals, setmodals] = useState([]);

  useEffect(() => {
    axios.get(`${ipaddress}/AllModules/`).then(r => {
      console.log('Module Names', r.data);
      setmodals(r.data);
    });
  }, []);

  const [fields, setFields] = useState([{ Activity_Name: '', targets: 0 }]);

  const handleAddMore = () => {
    setFields([...fields, { Activity_Name: '', targets: 0 }]);
  };

  const handleRemove = index => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newFields = [...fields];
    newFields[index][name] = value;
    setFields(newFields);
  };

  const user = JSON.parse(sessionStorage.getItem('user'));
  // To assign the Target count

  const [load, setload] = useState(false);

  const [employees, setemployees] = useState();

  const fetchemployees = type => {
    setload(true);
    axios
      .get(`${ipaddress}/AllprofileView/`)
      .then(r => {
        console.log('Employees data', r.data);
        const active_data = r.data.filter(x => x.user.blockstatus == false);
        const inactive_data = r.data.filter(x => x.user.blockstatus == true);
        if (type === 'active') {
          setemployees(active_data);
        } else {
          setemployees(inactive_data);
        }
        setload(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchemployees('active');
  }, []);

  const [seletedEmp, setSelectedEmp] = useState([]);

  const assigntarget = () => {
    setload(true);

    axios
      .post(`${ipaddress}/AddBulkActivityNames/`, {
        module_ids: selected_modules,
        emp_id: seletedEmp,
        assigned_emp: user.emp_id,
        activity_names: fields,
      })
      .then(r => {
        console.log('Activity Assigned', r.data);
        setload(false);
        toast.success('Activity Assigned Successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        //   setTimeout(() => {
        //     window.location.reload()
        //   }, 2000);
        setshow(false);
        setFields([{ Activity_Name: '', targets: 0 }]);
        setselected_modules([]);
      })

      .catch(err => {
        setload(false);
        console.log('Assigning activity error', err);
      });
  };

  const [selected_modules, setselected_modules] = useState([]);

  const handle_modules = id => {
    const data = selected_modules.includes(id)
      ? selected_modules.filter(x => x !== id)
      : [...selected_modules, id];

    setselected_modules(data);
    if (data.length < 1) {
      setFields([{ Activity_Name: '', targets: 0 }]);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        fullscreen={true}
        onHide={() => {
          setshow(false);
        }}
      >
        <Modal.Body>
          <div class="d-flex justify-content-between align-items-center">
            <h6
              class="modal-title fs-5 text-primary"
              id="exampleModalLabel"
            >
              Select the Modules and Add Activity!
            </h6>
            <svg
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setshow(false);
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-x-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
            </svg>
          </div>

          <div
            className="row m-0"
            style={{
              minHeight: '100vh',
              overflowY: fields.length > 2 ? 'scroll' : 'none',
            }}
          >
            <div className="col-12 pb-5 pt-3">
              <div className="w-100">
                <div className="row m-0 bg-light shadow-sm p-3">
                  <h6
                    className="text-center border-bottom pb-3 mb-3"
                    style={{ fontSize: '16px' }}
                  >
                    Activity Modules
                  </h6>
                  {modals.map(x => {
                    return (
                      <div className={`col-md-4 mb-3`}>
                        <p
                          className="d-flex align-items-start"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            handle_modules(Number(x.id));
                          }}
                        >
                          <span>
                            {' '}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class={`bi bi-check-square ${
                                selected_modules.includes(x.id) ? 'd-none' : ''
                              }`}
                              viewBox="0 0 16 16"
                            >
                              <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                              <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="#0B5ED7"
                              class={`bi bi-check-square-fill ${
                                selected_modules.includes(x.id) ? '' : 'd-none'
                              }`}
                              viewBox="0 0 16 16"
                            >
                              <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z" />
                            </svg>{' '}
                          </span>
                          <span className="ms-2">{x.module_name}</span>
                        </p>
                      </div>
                    );
                  })}
                </div>

                <div
                  className={`row m-0 mt-4 pt-2 ${
                    selected_modules.length > 0 ? '' : 'd-none'
                  }`}
                >
                  {fields.map((field, index) => (
                    <div
                      key={index}
                      className={`col-md-6 mb-4 pb-2 ${
                        index % 2 == 0 ? 'pe-4' : 'ps-4'
                      }`}
                    >
                      <h6 className="text-primary mb-3">
                        Activity {index + 1}
                      </h6>
                      <div className="mx-auto p-0">
                        <p className="m-0 text-end">
                          <svg
                            style={{ cursor: 'pointer' }}
                            onClick={() => handleRemove(index)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="22"
                            fill="currentColor"
                            class="bi bi-x"
                            viewBox="0 0 16 16"
                          >
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708" />
                          </svg>
                        </p>
                        {/* <label htmlFor="">Activity Name</label> */}
                        <input
                          className="activity form-control shadow-none border-0 border-bottom rounded-0 border-secondary-subtle"
                          type="text"
                          name="Activity_Name"
                          value={field.Activity_Name}
                          placeholder="Activity Name"
                          onChange={e => handleChange(index, e)}
                        />
                      </div>
                      <div className="mx-auto border-0 mt-4">
                        {/* <label htmlFor="">Target</label> */}
                        <input
                          className="activity form-control shadow-none border-0 border-bottom border-dark-subtle rounded-0"
                          type="number"
                          name="targets"
                          placeholder="Target"
                          // value={field.targets}
                          onChange={e => {
                            const { name, value } = e.target;
                            const newFields = [...fields];
                            newFields[index][name] = Number(value);
                            setFields(newFields);
                          }}
                        />
                      </div>
                    </div>
                  ))}

                  <div
                    className={`text-end ${
                      fields.length > 1 ? 'pt-2 mt-4' : ''
                    }`}
                  >
                    <button
                      type="button"
                      className="btn btn-sm text-white px-4 sidebar-color mt-1 rounded-pill"
                      onClick={handleAddMore}
                    >
                      Add More
                    </button>
                    <button
                      className="btn btn-sm text-white px-4 sidebar-color mt-1 rounded-pill ms-3"
                      data-bs-dismiss="modal"
                      onClick={assigntarget}
                    >
                      <span
                        class={`spinner-border spinner-border-sm ${
                          load ? '' : 'd-none'
                        }`}
                        aria-hidden="true"
                      ></span>
                      <span
                        className={`${load ? 'ms-2' : 'd-none'}`}
                        role="status"
                      >
                        Assigning...
                      </span>
                      <span className={`${load ? 'd-none' : ''}`}>Assign</span>
                    </button>
                  </div>
                </div>

                <div>
                  <div className="pt-4 mt-3 row m-0 mx-auto">
                    {employees?.map(x => {
                      return (
                        <div
                          className={`col-md-6 col-lg-4 mb-4 ${
                            employees.length > 0 ? '' : 'd-none'
                          }`}
                          style={{ borderRadius: '14px' }}
                        >
                          <div className="bg-white p-2 p-md-3 border py-md-4">
                            <div
                              className="d-flex align-items-center"
                              style={{ color: '#002147' }}
                            >
                              <div className="text-center">
                                <img
                                  className={`rounded-circle ${
                                    x.image ? '' : 'd-none'
                                  }`}
                                  src={x.image}
                                  width={110}
                                  height={110}
                                  alt=""
                                />
                                <img
                                  className={`rounded-circle ${
                                    x.image ? 'd-none' : ''
                                  }`}
                                  src={require('../images/user.jpg')}
                                  width={110}
                                  height={110}
                                  alt=""
                                />
                                <p
                                  className={`m-0 fw-bold mt-3 ${
                                    seletedEmp.includes(x.user.first_name)
                                      ? 'text-success'
                                      : 'text-danger'
                                  }`}
                                  style={{ color: '#000' }}
                                >
                                  {x.user.first_name}
                                </p>
                              </div>
                              <div className="ms-3 ms-md-4">
                                <p
                                  className="mt-1 fw-bold mb-2"
                                  style={{ fontSize: '12px' }}
                                >
                                  Designation : {x.designatin}
                                </p>
                                <p
                                  className="fw-bold"
                                  style={{ fontSize: '12px' }}
                                >
                                  Email : {x.user.email}
                                </p>
                                {/* <button
                                  type="button"
                                  className="border btn fw-bold mt-2"
                                  style={{ color: '#002147', fontSize: '13px' }}
                                  onClick={() => {
                                    setSelectedEmp(
                                      selectedEmp =>
                                        selectedEmp.includes(x.user.emp_id)
                                          ? selectedEmp.filter(
                                              empId =>
                                                empId.user.emp_id !==
                                                x.user.emp_id,
                                            ) // Remove from the array
                                          : [...selectedEmp, x.user.emp_id], // Add to the array
                                    );
                                  }}
                                >
                                  Set Target
                                </button> */}

                                <input
                                  type="checkbox"
                                  name=""
                                  id=""
                                  onChange={() =>
                                    seletedEmp.includes(x.user.emp_id)
                                      ? setSelectedEmp(emp =>
                                          emp.filter(id => id != x.user.emp_id),
                                        )
                                      : setSelectedEmp(emp => [
                                          ...emp,
                                          x.user.emp_id,
                                        ])
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Add_activity_targets;
