const currentDate = new Date();

// Get the current day of the month
const currentDay = currentDate.getDate();

// Get the number of days in the current month
const daysInMonth = new Date(
  currentDate.getFullYear(),
  currentDate.getMonth() + 1,
  0,
).getDate();

// Generate an array of days in the month
export const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

// Format the date for display in the table headers
export const formatDate = day => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Month is zero-based
  const paddedMonth = month < 10 ? `0${month}` : month;
  const paddedDay = day < 10 ? `0${day}` : day;
  return `${year}-${paddedMonth}-${paddedDay}`;
};

export const getLeadsCountForDay = (items, day) => {
  const formattedDate = formatDate(day);
  return items.filter(item => {
    const leadDate = new Date(item.created_at);
    const leadFormattedDate = formatDate(leadDate.getDate());
    return leadFormattedDate === formattedDate;
  }).length;
};
