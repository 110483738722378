import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ipaddress, ipaddress2 } from '../App'
import { toast } from 'react-toastify'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { useNavigate } from 'react-router-dom'
import Preloader from '../Components/Preloader'

const Student_leave_requests = () => {

    const[leave_requests,setleave_requests]=useState([])

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[loading3,setloading3]=useState()

    const[count,setCount]=useState(0)

    const navigate=useNavigate()

    const[loading,setloading]=useState()
    const[loading2,setloading2]=useState()


    useEffect(()=>{
        setloading3(true)
        axios.get(`${ipaddress2}/LeaveRequest/${user.email}/`)
        .then((r)=>{
            console.log("Leave Requests",r.data)
            setleave_requests(r.data.reverse())
            setloading3(false)
        })
    },[count])

    const leave_processing=(id,status)=>{
        if(status==='True'){
            setloading(true)
        }
        else{
            setloading2(true)
        }

        axios.patch(`${ipaddress2}/DisplayLeaveRequests/${user.email}/`,{
            status:status,
            leave_request_id:id
        })
        .then(((r)=>{
            setCount(count+1)
            console.log(r.data)
            if(status==='True'){
                toast.success('Leave Request Approved',{
                    autoClose:2000,
                    theme:'colored',
      position:'top-center'
                })
            setloading(false)
            }
            else{
                toast.warn('Leave Request Rejected',{
                    autoClose:2000,
                    theme:'colored',
      position:'top-center'
                })
            setloading2(false)
            }
        }))
    }

  return (
   <div>
    {loading3 ? (<Preloader/>):(
         <div className='d-flex animate__animated animate__fadeIn'>
         <Sidebar activevalue={"leave"}/>
         <div className="w-100">
             <Navbar/>
 
             <div className="bg-light main-container p-3" style={{minHeight:'100vh'}}>
                 <div className='row m-0'>
                     <h5 className='pb-3'>View Your Leave Details</h5>

                     {/* <h6 className={`text-center text-secondary py-4 ${leave_requests.length>0 ? 'd-none':''}`}>No data available...🧐</h6> */}
                     <div className={`col-md-8 ps-0 ${leave_requests.length>0 ? '':'d-none'}`}>
                        {leave_requests.map((x,index)=>{
                         return(
                             <div className="bg-white p-3 py-4 mb-4">
                             <div className="row m-0">
                                 <div className="col-md-6">
                                     <p className='text-color'>Student Name : <span className='text-dark'>{x.customer_id && x.customer_id.customer_name && x.customer_id.customer_name}</span></p>
                                 </div>
                                 <div className="col-md-6">
                                 <p className='text-color'>Batch Name : <span className='text-dark'>{x.batch_id.name && x.batch_id.name.sub_course_name && x.batch_id.name.sub_course_name}</span></p>
 
                                 </div>
                                 <div className="col-md-6">
                                 <p className='text-color'>Requested On : <span className='text-dark'>{x.created_at && x.created_at.slice(0,10)}</span></p>
 
                                 </div>
                                 <div className="col-md-6">
                                 <p className='text-color'>Trainer Name : <span className='text-dark'>{x.batch_id.trainer && x.batch_id.trainer.first_name && x.batch_id.trainer.first_name}</span></p>
 
                                 </div>
                                 <div className="col-12 mt-3">
                                     <h6>Reason for the Leave</h6>
                                     <p>{x.leave}</p>
                                 </div>
                                 <div className="col-12 text-end">
                                     <p className={`${x.responded && x.status ? 'text-success fw-medium':'d-none'}`}>Approved</p>
                                     <p className={`${x.responded && x.status==false ? 'text-danger fw-medium':'d-none'}`}>Rejected</p>
                                     <p className={`${x.responded ==false ? 'text-warning fw-medium':'d-none'}`}>Pending</p>
                                 </div>
                             </div>
                         </div>
                         )
                        })}
                     </div>
 
                     <div className={`col-md-4 bg-white ${leave_requests.length>0 ? '':'mx-auto'}`}>
                     <img src="https://img.freepik.com/free-vector/three-man-having-business-meeting-office_74855-4583.jpg?t=st=1717832698~exp=1717836298~hmac=c04ac2bd9d6d55633c648247c673f7ef4c91fa419a1075e9e49f87e912d31025&w=740" style={{width:'100%'}} alt="" />
                  
                  <div className='px-3 mt-2'>
                  <button onClick={()=>{
                     navigate(`/leave_request`)
                  }} className='w-100 btn btn-sm rounded-0 text-color py-2 px-5 fs-5' style={{border:'2px solid #002147'}}>Apply for Leave</button>
                 </div>
                     </div>
                 </div>
             </div>
         </div>
     </div>
    )}
   </div>
  )
}

export default Student_leave_requests