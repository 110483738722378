import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import axios from 'axios';
import { ipaddress } from '../App';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

const Emp_profile = () => {
  const user = JSON.parse(sessionStorage.getItem('user'));

  const [count, setcount] = useState(0);
  const [data, setdata] = useState({});
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [img, setimg] = useState(null);

  useEffect(() => {
    axios.get(`${ipaddress}/AddEmployeeView/?empid=${user.emp_id}`).then(r => {
      console.log('User data', r.data);
      // setdata(r.data)
      setfirstname(r.data.first_name);
      setlastname(r.data.last_name);
      setemail(r.data.email);
      setfirstname(r.data.first_name);
      setpassword(r.data.password);
      setimg(r.data.profile_details.image);

      // toast.success('Updated successfully!',{
      //     autoClose:1000,
      //     position:'top-center',
      //     theme:'colored'
      // })
    });
  }, [count]);

  const update_profile = img => {
    const form = new FormData();
    form.append('image', img);
    axios
      .patch(`${ipaddress}/AddEmployeeView/?empid=${user.emp_id}`, form)
      .then(r => {
        setcount(count + 1);
        toast.success('Updated successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
      });
  };

  const [show, setshow] = useState(false);
  const [pass_state, setpass_state] = useState(false);
  const [backendotp, setbackendotp] = useState('');
  const [otp, setotp] = useState('');
  const [newpassword, setnewpassword] = useState('');
  const [retypepassword, setretypepassword] = useState('');

  const [load3, setload3] = useState(false);

  const sendotp = () => {
    setload3(true);
    axios
      .post(`${ipaddress}/send_otp/`, {
        email: email,
      })
      .then(r => {
        console.log('OTP', r.data);
        setload3(false);
        setbackendotp(r.data.otp);
        toast.success('OTP Sent Successfully', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setTimeout(() => {
          setshow(true);
        }, 1000);
      });
  };

  const [load2, setload2] = useState(false);

  const validate_otp = () => {
    setload2(true);
    console.log('BACKEND OTP', backendotp);

    if (otp === backendotp) {
      toast.success('OTP Validated successfully', {
        autoClose: 1000,
        theme: 'colored',
        position: 'top-center',
      });
      setpass_state(true);
      setload2(false);
      setotp('');
    } else {
      toast.error('Invalid OTP', {
        autoClose: 1000,
        theme: 'colored',
        position: 'top-center',
      });
      setpass_state(false);
      setload2(false);
    }
  };

  const [load, setload] = useState(false);

  const update_password = () => {
    setload(true);
    setTimeout(() => {
      update();
    }, 1000);
  };

  const update = () => {
    if (newpassword === retypepassword) {
      axios
        .put(`${ipaddress}/AddEmployeeView/?empid=${user.emp_id}`, {
          password: newpassword,
        })
        .then(r => {
          toast.success('Password updated successfully!', {
            autoClose: 1000,
            theme: 'colored',
            position: 'top-center',
          });
          setshow(false);
          setcount(count + 1);
          setload(false);
        });
    } else {
      toast.error('Password Mismatching', {
        autoClose: 1000,
        theme: 'colored',
        position: 'top-center',
      });
      setload(false);
    }
  };

  return (
    <div className="d-flex">
      <Sidebar />
      <div className="w-100">
        <Navbar />
        <div
          className="main-container bg-light d-flex align-items-center"
          style={{ minHeight: '100vh' }}
        >
          <div className="container row m-0">
            <div className="col-lg-8 col-md-8 mx-auto">
              <div className="d-flex flex-column align-items-center mb-5">
                <div
                  className="rounded-circle"
                  style={{ width: '90px', height: '90px', overflow: 'hidden' }}
                >
                  <img
                    className={img == null ? '' : 'd-none'}
                    src={require('../images/user.jpg')}
                    style={{ width: '100%' }}
                    alt=""
                  />
                  <img
                    className={img !== null ? '' : 'd-none'}
                    src={img}
                    style={{ width: '100%' }}
                    alt=""
                  />
                </div>
                <label
                  className="m-0 mt-2 fw-medium"
                  htmlFor="img-file"
                  style={{ color: '#4379EE', cursor: 'pointer' }}
                >
                  Update Profile Image
                  <input
                    type="file"
                    id="img-file"
                    onChange={e => {
                      setimg(e.target.files[0]);
                      update_profile(e.target.files[0]);
                    }}
                  />
                </label>
              </div>
              <div className="row m-0 justify-content-between">
                <div className="col-lg-6 px-2 px-md-4 ps-0 mb-5">
                  <label
                    htmlFor=""
                    className="fw-medium fs-5"
                  >
                    First Name
                  </label>
                  <input
                    disabled="true"
                    type="text"
                    value={firstname}
                    onChange={e => {
                      setfirstname(e.target.value);
                    }}
                    className="form-control shadow-none border-secondary-subtle py-3 mt-2"
                  />
                </div>
                <div className="col-lg-6 px-2 px-md-4 ps-0 mb-5">
                  <label
                    htmlFor=""
                    className="fw-medium fs-5"
                  >
                    Last Name
                  </label>
                  <input
                    disabled="true"
                    type="text"
                    value={lastname}
                    onChange={e => {
                      setlastname(e.target.value);
                    }}
                    className="form-control shadow-none border-secondary-subtle py-3 mt-2"
                  />
                </div>
                <div className="col-lg-6 px-2 px-md-4 ps-0 mb-5">
                  <label
                    htmlFor=""
                    className="fw-medium fs-5"
                  >
                    Email
                  </label>
                  <input
                    disabled="true"
                    type="email"
                    value={email}
                    onChange={e => {
                      setemail(e.target.value);
                    }}
                    className="form-control shadow-none border-secondary-subtle py-3 mt-2"
                  />
                </div>
                <div className="col-lg-6 px-2 px-md-4 ps-0 mb-5">
                  <label
                    htmlFor=""
                    className="fw-medium fs-5"
                  >
                    Password
                  </label>
                  <input
                    disabled="true"
                    type="password"
                    value={password}
                    onChange={e => {
                      setpassword(e.target.value);
                    }}
                    className="form-control shadow-none border-secondary-subtle py-3 mt-2"
                  />
                </div>

                <div className="text-end">
                  <button
                    className="btn btn-primary px-4"
                    onClick={() => {
                      sendotp();
                    }}
                  >
                    {load3 ? 'Processing...' : 'Change Password'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Update password */}
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row m-0">
            <div className="col-12 d-flex align-items-end">
              <div className="w-75">
                <label htmlFor="">Enter your OTP</label>
                <input
                  type="number"
                  value={otp}
                  onChange={e => {
                    setotp(Number(e.target.value));
                  }}
                  className="form-control shadow-none border-secondary-subtle mt-2 py-3"
                />
              </div>
              <button
                className="ms-3 btn btn-sm btn-primary"
                onClick={() => {
                  validate_otp();
                }}
              >
                {load2 ? 'Validating...' : 'Validate OTP'}
              </button>
            </div>
          </div>

          <div className={`row m-0 mt-4 ${pass_state ? '' : 'd-none'}`}>
            <div className="col-12">
              <div className="">
                <label htmlFor="">Enter your Password</label>
                <input
                  type="text"
                  value={newpassword}
                  onChange={e => {
                    setnewpassword(e.target.value);
                  }}
                  className="form-control shadow-none border-secondary-subtle mt-2 py-3"
                />
              </div>
            </div>
            <div className="col-12 mt-3">
              <div className="">
                <label htmlFor="">Retype Password</label>
                <input
                  type="text"
                  value={retypepassword}
                  onChange={e => {
                    setretypepassword(e.target.value);
                  }}
                  className="form-control shadow-none border-secondary-subtle mt-2 py-3"
                />
              </div>
            </div>

            <div className="text-end mt-4">
              <button
                className="btn btn-primary px-4"
                onClick={() => {
                  update_password();
                }}
              >
                {load ? 'Updating...' : 'Update'}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Emp_profile;
