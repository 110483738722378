import React from 'react'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'

const Trainer_profile = () => {
  return (
    <div className='d-flex'>
        <Sidebar/>
        <div className="w-100">
            <Navbar/>
            <div className="main-container bg-light d-flex align-items-center" style={{minHeight:'100vh'}}>
                <div className="container row m-0">
                    <div className="col-lg-8 col-md-8 mx-auto">
                    <div className='d-flex flex-column align-items-center mb-5'>
                        <div className='rounded-circle' style={{width:'90px',height:'90px',overflow:'hidden'}}>
                        {/* <img src='https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png' style={{width:'100%'}} alt="" /> */}
                        </div>
                        <p className='m-0 mt-2 fw-medium' style={{color:'#4379EE',cursor:'pointer'}}>Upload Profile Image</p>
                </div>
                <div className="row m-0 justify-content-between">
                    <div className="col-lg-6 px-2 px-md-4 ps-0 mb-5">
                        <label htmlFor="">First Name</label>
                        <input type="text" className='form-control shadow-none border-secondary-subtle py-3 mt-2' />
                    </div>
                    <div className="col-lg-6 px-2 px-md-4 ps-0 mb-5">
                        <label htmlFor="">Last Name</label>
                        <input type="text" className='form-control shadow-none border-secondary-subtle py-3 mt-2' />
                    </div>
                    <div className="col-lg-6 px-2 px-md-4 ps-0 mb-5">
                        <label htmlFor="">Designation</label>
                        <input type="text" className='form-control shadow-none border-secondary-subtle py-3 mt-2' />
                    </div>
                    <div className="col-lg-6 px-2 px-md-4 ps-0 mb-5">
                        <label htmlFor="">Email</label>
                        <input type="text" className='form-control shadow-none border-secondary-subtle py-3 mt-2' />
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Trainer_profile