import React from 'react'
import { useNavigate } from 'react-router-dom'

const Project_navbar = () => {
  const navigate=useNavigate()
  
  return (
    <div className='' style={{backgroundColor:'#202737'}}>
        <div className='container d-flex py-3'>
            <div className="w-25">
                <img style={{cursor:'pointer'}} onClick={()=>{
                  navigate('/student_dashboard')
                }} src={require('../images/Logo_Blue 1.png')} width={140} alt="" />
            </div>

            <div className="w-75 d-flex align-items-center justify-content-end gap-5">
                <span className='text-white'>Projects
                </span>
                <span className='text-white'>About us</span>
                <span className='text-white'>Guidance</span>
                <button className='nav-btn btn rounded-pill text-white'><span className='me-2' style={{fontSize:'13px'}}>Contact us</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="14" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg></button>
            </div>
        </div>
    </div>
  )
}

export default Project_navbar