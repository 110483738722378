import React from 'react'

const Birthday = () => {
  return (
    <div className='' style={{height:'100vh',backgroundColor:'rgba(0,0,0,0.4)'}}>
        <div class="card1">
      <div class="outside">
        <div class="front">
          <p>Happy Birthday</p>
          <div class="cake">
            <div class="top-layer"></div>
            <div class="middle-layer"></div>
            <div class="bottom-layer"></div>
            <div class="candle"></div>
          </div>
        </div>
        <div class="back"></div>
      </div>
      <div class="inside">
        <p>Wishing you a very happy birthday filled with love and laughter</p>
        <h1>&#127873;</h1>
      </div>
    </div>
    </div>
  )
}

export default Birthday