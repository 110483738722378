function ActivityAllData({ result }) {
  const TableHead = [
    { HName: 'Name', BName: 'name' },
    { HName: 'Created at', BName: 'created_at' },
    { HName: 'Whatsapp No', BName: 'whatsapp' },
    { HName: 'Mobile No', BName: 'contact_number' },
    { HName: 'Email', BName: 'email' },
    { HName: 'Course Enquired', BName: 'course_enquiredd' },
  ];

  return (
    <div className="tw-mt-6">
      {result?.length !== 0 ? (
        <div className="tw-overflow-x-auto">
          <table className="tw-w-full tw-table-auto tw-rounded-lg tw-shadow-md">
            <thead>
              <tr className="tw-bg-gray-200">
                {TableHead.map((item, idx) => (
                  <th
                    key={idx}
                    className="tw-py-3 tw-px-3 tw-text-left tw-text-sm tw-font-semibold"
                  >
                    {item.HName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.map((item, idx) => (
                <tr
                  key={idx}
                  className="tw-bg-white hover:tw-bg-gray-100"
                >
                  {TableHead.map((head, idx2) => (
                    <td
                      key={idx2}
                      className="tw-py-3 tw-px-3"
                    >
                      {item[head.BName] || '-----'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="tw-text-center tw-py-6 tw-text-gray-500">
          {/* Select The Field */}
        </div>
      )}
    </div>
  );
}

export default ActivityAllData;
