import React from 'react'
import Admin_login from './Admin_login'

const Protect4 = ({Child}) => {
    const user=sessionStorage.getItem('user')
    
    const verify=()=>{
        if(user !=null || user !=="undefined"){
            return true
        }
        else{
            return false
        }
    }
  return (
    <div>
        {
            verify() ? <Child/> : <Admin_login/>
        }
    </div>
  )
}

export default Protect4