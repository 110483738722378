import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ipaddress2 } from '../App';
import { Document, Page } from 'react-pdf';
// import { Document, Page } from 'react-pdf';


const View_document = ({url,state,setstate}) => {

console.log(url)
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageScale, setPageScale] = useState(1.2);
  
  const handleZoomIn = () => {
    if (pageScale < 1.8) {
      setPageScale((prevScale) => prevScale + 0.2);
    }
  };
  
  const handleZoomOut = () => {
    if (pageScale > 0.8) {
      setPageScale((prevScale) => prevScale - 0.2);
    }
  };

  const [isSticky, setIsSticky] = useState(false);
useEffect(() => {
  const handleScroll = () => {
    setIsSticky(window.scrollY > 0);
  };

  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
}, []);

//   function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
//     setNumPages(numPages);
//   }

useEffect(() => {
    const handleContextMenu = (event) => {
      event.preventDefault();
    };

    document.addEventListener('contextmenu', handleContextMenu);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
    };
  }, []);

return (
        <div className={` project-dash pt-4 pb-5 animate__animated animate__fadeIn ${state ? '':'d-none'}`} style={{minHeight:'100vh'}}>
            <h6 className='container text-white pb-4 d-flex align-items-center'><svg onClick={()=>{
                setstate(false)
            }} style={{cursor:'pointer'}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg> <span className='ms-2 fs-5'>View Project Documentation</span></h6>
            <div className="row m-0 justify-content-center">
            <div className='col-lg-8'>
            <div className="">
<div className={`button-container rounded d-flex justify-content-center align-items-center border border-bottom-0 py-1 ${isSticky ? 'sticky2':''}`} style={{backgroundColor:'#6C63FF'}}>
           <button className="btn text-white btn-sm border-0 me-2" onClick={handleZoomIn} disabled={pageScale >= 1.4}>
           <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
  <path d="M28.415 29.3908L19.2835 20.2594C18.5544 20.8802 17.7158 21.3606 16.7679 21.7009C15.82 22.0412 14.8674 22.2113 13.9101 22.2113C11.5743 22.2113 9.59752 21.4028 7.97962 19.7858C6.3617 18.1688 5.55273 16.193 5.55273 13.8585C5.55273 11.524 6.36125 9.54674 7.97827 7.92668C9.59529 6.30661 11.5711 5.49658 13.9056 5.49658C16.2401 5.49658 18.2174 6.30553 19.8374 7.92343C21.4575 9.54133 22.2675 11.5182 22.2675 13.854C22.2675 14.8673 22.088 15.8479 21.729 16.7959C21.3701 17.7438 20.8989 18.5543 20.3156 19.2274L29.447 28.3588L28.415 29.3908ZM13.9101 20.753C15.8452 20.753 17.4788 20.0869 18.811 18.7548C20.1431 17.4227 20.8092 15.7891 20.8092 13.854C20.8092 11.9189 20.1431 10.2853 18.811 8.95313C17.4788 7.62099 15.8452 6.95492 13.9101 6.95492C11.975 6.95492 10.3414 7.62099 9.00928 8.95313C7.67716 10.2853 7.0111 11.9189 7.0111 13.854C7.0111 15.7891 7.67716 17.4227 9.00928 18.7548C10.3414 20.0869 11.975 20.753 13.9101 20.753ZM13.1809 17.3315V14.5831H10.4326V13.1248H13.1809V10.3764H14.6393V13.1248H17.3877V14.5831H14.6393V17.3315H13.1809Z" fill="white"/>
</svg>
           </button>
           <span className="border border-white rounded text-white py-2 px-2" style={{fontSize:'14px',width:'50px'}}><span className={pageScale===1.2 ? '':'d-none'}>100</span>
           <span className={pageScale>1.2 ? '':'d-none'}>125</span>
           <span className={pageScale<1.2 && pageScale>0.8 ? '':'d-none'}>75</span>
           <span className={pageScale===0.8 ? '':'d-none'}>50</span>
            %</span>
           <button className="btn text-white btn-sm ms-2 border-0" onClick={handleZoomOut} disabled={pageScale < 0.8}>
           <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none">
  <path d="M28.415 29.3908L19.2835 20.2594C18.5544 20.8802 17.7158 21.3606 16.7679 21.7009C15.82 22.0412 14.8674 22.2113 13.9101 22.2113C11.5743 22.2113 9.59752 21.4028 7.97962 19.7858C6.3617 18.1688 5.55273 16.193 5.55273 13.8585C5.55273 11.524 6.36125 9.54674 7.97827 7.92668C9.59529 6.30661 11.5711 5.49658 13.9056 5.49658C16.2401 5.49658 18.2174 6.30553 19.8374 7.92343C21.4575 9.54133 22.2675 11.5182 22.2675 13.854C22.2675 14.8673 22.088 15.8479 21.729 16.7959C21.3701 17.7438 20.8989 18.5543 20.3156 19.2274L29.447 28.3588L28.415 29.3908ZM13.9101 20.753C15.8452 20.753 17.4788 20.0869 18.811 18.7548C20.1431 17.4227 20.8092 15.7891 20.8092 13.854C20.8092 11.9189 20.1431 10.2853 18.811 8.95313C17.4788 7.62099 15.8452 6.95492 13.9101 6.95492C11.975 6.95492 10.3414 7.62099 9.00928 8.95313C7.67716 10.2853 7.0111 11.9189 7.0111 13.854C7.0111 15.7891 7.67716 17.4227 9.00928 18.7548C10.3414 20.0869 11.975 20.753 13.9101 20.753ZM10.6008 14.5831V13.1248H17.2194V14.5831H10.6008Z" fill="white"/>
</svg>
           </button>
                 </div>
</div>
            <div id="pdf-container" className='d-flex justify-content-center bg-white rounded shadow-sm' style={{overflowY:'scroll',height:'100vh'}}>
                <Document file={url} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
         {Array.from({ length: numPages }, (_, index) => (
                <Page key={index + 1} pageNumber={index + 1} scale={pageScale} style={{ display: index + 1 === currentPage ? 'block' : 'none' }} />
              ))}
      </Document>
                </div>
                
            </div>
            </div>
        </div>
    );
};

export default View_document
