import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import { Chart as ChartJS } from 'chart.js/auto'
import { Line } from 'react-chartjs-2'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'

const Trainer_dashboard = () => {

  const navigate=useNavigate()

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:false,
    // initialSlide: 0,
    // autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const user=JSON.parse(sessionStorage.getItem('user'))

  const employeecomparision = {
    labels: ['New Leads', 'Followup', 'Prospects','Registered','Closed'],
    datasets: [
      {
        label: 'Target',
        data: [65, 59, 80,60,50],
        fill: false,
        backgroundColor: '#1814F3',
        tension: 0.1,
        barThickness: 8,
      },
      {
        label: 'Achieved',
        data: [60, 50, 70,40,36],
        fill: false,
        backgroundColor: '#74C228',
        tension: 0.1,
        barThickness: 8,
      },
    ],
  };

  
  const options = {
    plugins: {
      legend: {
        position: 'bottom', // Set legend position to bottom
        labels: {
          boxWidth: 20, 
          maxWidth: 350, // Adjust this value as needed
        },
      },
    },
    // Other chart options
  };

  const[value_state,setvalue_state]=useState('all')

  const[courses,setcourses]=useState([])
  const[batches,setbatches]=useState([])
  const[employee_batches,setemployee_batches]=useState([])
  const[trainer_details,settrainer_details]=useState({})

  useEffect(()=>{
    axios.get(`${ipaddress2}/upcoming-courses/`)
    .then((r)=>{
      console.log("Upcoming Courses",r.data)
      setcourses(r.data)
    })

    axios.get(`${ipaddress2}/TrainerView/${user.id}/`)
    .then((r)=>{
      console.log("Batch details",r.data)
      setbatches(r.data)
      fetch_students(r.data[0].id)
    })

    axios.get(`${ipaddress2}/employee_batches/${user.emp_id}/`)
    .then((r)=>{
      console.log("Employee Batch details",r.data)
      setemployee_batches(r.data.datasets)
    })

    axios.get(`${ipaddress2}/trainer-panel_counts/${user.emp_id}/`)
    .then((r)=>{
      console.log("Trainer panel details",r.data)
      settrainer_details(r.data)
    })

  },[])

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split('T')[1].split(':');
    return `${hours}:${minutes}`;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const getdate=()=>{
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.toLocaleString('default', { month: 'long' });
    const year = currentDate.getFullYear();

    return `${day} ${month}`
  }

  const[students,setstudents]=useState([])

  const fetch_students=(id)=>{
    axios.get(`${ipaddress2}/TopStudents/${id}/`)
    .then((r)=>{
      console.log("Top students",r.data);
      setstudents(r.data.datasets)
    })
  }


return (
    <div className='d-flex'>
        <Sidebar activevalue={"dashboard"}/>
        <div className='w-100'>
            <Navbar/>
            <div className='main-container pt-3 px-2 pb-4' style={{minHeight:'100vh',backgroundColor:'#E8E8E8'}}>
            <div className="bg-white p-3">
            <div className="row m-0">
              <div className="col-lg-8 ps-md-0">
              <div className="row m-0" style={{minHeight:'180px',border:'1px solid #002147'}}>
              <div className='col-md-8 d-flex flex-column justify-content-center ps-lg-4'>
                  <p className='text-color fw-bold fw-medium m-0' style={{fontSize:'30px'}}>Welcome Back, {user.first_name}</p>
                  <p className='text-color fw-bold fw-medium' style={{fontSize:'20px'}}>You have {trainer_details.Total_students} Students</p>
                  </div>
                
              </div>

              <div className="mt-4 row m-0">
        <div className='col-md-4 col-lg-3 ps-md-0 mb-3'>
          <div className='position-relative' style={{border:'1px solid #000'}}>
            <img src={require('../images/4672585_2471838 2.png')} style={{width:'100%'}} alt="" />
            <div className='py-3 text-center position-absolute w-100' style={{backgroundColor:'rgba(255,255,255,1)',bottom:'0px',opacity:'0.9'}}>
            <p className='m-0 fw-bold'>Total Students</p>
            <span className='text-color fw-bold' style={{fontSize:'15px'}}>{trainer_details.Total_students}</span>
            </div>
          </div>
        </div>

        <div className='col-md-4 col-lg-3 ps-md-0 mb-3'>
          <div className='position-relative' style={{border:'1px solid #000'}}>
            <img src={require('../images/9175118_6461 1.png')} style={{width:'100%'}} alt="" />
            <div className='py-3 text-center position-absolute w-100' style={{backgroundColor:'rgba(255,255,255,1)',bottom:'0px',opacity:'0.9'}}>
            <p className='m-0 fw-bold'>Total Classes</p>
            <span className='text-color fw-bold' style={{fontSize:'15px'}}>{trainer_details.Total_classes}</span>
            </div>
          </div>
        </div>

        <div className='col-md-4 col-lg-3 ps-md-0 mb-3'>
          <div className='position-relative' style={{border:'1px solid #000'}}>
            <img src={require('../images/7766409_3757762 1.png')} style={{width:'100%'}} alt="" />
            <div className='py-3 text-center position-absolute w-100' style={{backgroundColor:'rgba(255,255,255,1)',bottom:'0px',opacity:'0.9'}}>
            <p className='m-0 fw-bold'>Tests Conducted</p>
            <span className='text-color fw-bold' style={{fontSize:'15px'}}>10</span>
            </div>
          </div>
        </div>

        <div className='col-md-4 col-lg-3 ps-md-0 mb-3'>
          <div className='position-relative' style={{border:'1px solid #000'}}>
            <img src={require('../images/7140422_3500305 1.png')} style={{width:'100%'}} alt="" />
            <div className='py-3 text-center position-absolute w-100' style={{backgroundColor:'rgba(255,255,255,1)',bottom:'0px',opacity:'0.9'}}>
            <p className='m-0 fw-bold'>Total Projects</p>
            <span className='text-color fw-bold' style={{fontSize:'15px'}}>25</span>
            </div>
          </div>
        </div>
    </div>

{/* Layout 2 */}
<div className="row m-0">
<div className="col-12 px-md-0">

<div className="row m-0 mt-4">
  <div className="col-12 ps-0">
    <h5 style={{color:'#2B3674'}} className='pb-3'>Recently Added Courses</h5>
    <div className="row m-0">
    {courses && courses.slice(0,2).map((x,index)=>{
      return(
        <div className={`col-md-6 ${index%2==0 ? 'ps-md-0':'pe-md-0'}`}>
        <div class="card p-3 rounded-0" style={{border:'1px solid #002147'}}>
    {/* <img style={{borderRadius:'15px',height:'200px'}} src={x.image} class="card-img-top" alt="..."/> */}
    <img style={{height:'200px'}} src={require('../images/269257867_11260839.png')} class="card-img-top rounded-0" alt="..."/>
    <div class="card-body py-0 px-1">
      <div className='pt-3'>
      <h5 class="card-title m-0 text-color fw-bold">{x.coursename}</h5>
      <p class="card-text" style={{color:'#002147',fontSize:'13px'}}>Added by Admin</p>
      </div>
     <div className='py-0 mt-3 d-flex justify-content-between align-items-center'>
      <p className='m-0 text-color'><span style={{fontSize:'12px'}} className='fw-medium'>{x.description} </span> <span style={{fontSize:'12px'}} className='fw-medium'>{x.Start_date}</span></p>
     {/* <button className='btn text-white btn-sm rounded-pill px-3 py-1' style={{backgroundColor:'#57309c'}}>Attendance</button> */}
     </div>
    </div>
  </div>
        </div>
      )
    })}
    </div>
  </div>
</div>
</div>

</div>
              </div>

              <div className="col-md-4 px-0">

<div className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3`} style={{height:'430px',border:'1px solid #002147'}}>
<div className='d-flex justify-content-between align-items-center pb-3'>
<h5 style={{color:'#2B3674'}} className='m-0'>Students List</h5>
<select onChange={(e)=>{
  fetch_students(e.target.value)
}} name="" id="" className='rounded-pill px-2 py-1' style={{fontSize:'10px',cursor:'pointer'}}>
  {batches.map((x)=>{
    return(
      <>
      <option value={x.id}>{x.name && x.name.sub_course_name}</option>
      </>
    )
  })}
</select>
</div>
<div className="table-responsive">
<table class="table">
  <thead>
    <tr>
      <th scope="col" style={{color:'#65708D'}} className='fw-medium'>Name</th>
      <th scope="col" style={{color:'#65708D'}} className='fw-medium text-center'>Marks</th>
      <th scope="col" style={{color:'#65708D'}} className='fw-medium text-center'>Atendance</th>
    </tr>
  </thead>
  <tbody>
   {students.map((x)=>{
    return(
      <tr>
      <td className='py-3'><div>
        <p className='m-0 fw-medium d-flex align-items-center' style={{color:'#021442'}}>
            {/* <img src={require('../images/Rectangle 346.png')} /> */}
        <span style={{fontSize:'12px'}} className='ms-2'>{x.student_name}</span>
        </p>
        </div></td>
      <td className='py-3'><div>
        <p className='fw-medium m-0 text-center' style={{color:'#5855D6',fontSize:'12px'}}>{x.total_marks_percentage}</p>
        </div></td>
      <td className='py-3'>
        <p className='m-0 fw-medium text-center' style={{color:'#57309C',fontSize:'12px'}}>{x.attendance_percentage}%</p>
      </td>
    </tr>
    )
   })}
  </tbody>
</table>
</div>
                  </div>

<div className={`d-flex flex-column mb-5 mb-lg-0 bg-white p-3 py-3 mt-4`} style={{height:'358px',border:'1px solid #002147'}}>
<div className='d-flex justify-content-between align-items-center pb-3'>
<h5 style={{color:'#2B3674'}} className='m-0'>Scheduled Classes</h5>
</div>
<div className="table-responsive">
<table class="table">
  <tbody>
   {employee_batches.map((x)=>{
    return(
      <tr>
      <td className='py-3'><div>
        <p className='m-0 fw-medium' style={{fontSize:'12px',color:'#1B2559'}}>{x.class}</p>
        </div></td>
      <td className='py-3'><div>
        <p className='fw-medium m-0 d-flex justify-content-center align-items-center' style={{color:'#2B3674',fontSize:'12px'}}><svg xmlns="http://www.w3.org/2000/svg" width="11" height="11" fill="currentColor" class="bi bi-calendar-event" viewBox="0 0 16 16">
  <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
</svg> <span style={{fontSize:'11px'}} className='ms-1'>{getdate()}</span></p>
        </div></td>
      <td className='py-3'>
        <p className='m-0 fw-medium text-center' style={{color:'#A3AED0',fontSize:'12px'}}>{x.start_time} - {x.end_time}</p>
      </td>
    </tr>
    )
   })}
  </tbody>
</table>
</div>
                  </div>
</div>
              </div>

              <div className="row m-0 mt-2">
<div className="col-12 px-lg-0">

  
{/* Charts and Tables */}

<div className="row m-0 mt-4">
  <div className="col-12 ps-0">
    <div className='d-flex justify-content-between align-items-center pb-3'>
    <h5 style={{color:'#2B3674'}} className='m-0'>Your Batches</h5>
    <p className='fw-medium m-0' style={{color:'#57309C',cursor:'pointer'}} onClick={()=>{
                navigate('/batches')
            }}>View All</p>
    </div>
    <div className="row m-0">
      {batches.slice(0,2).map((x)=>{
        return(
          <div className="col-md-6 col-lg-3">
      <div class="card p-2 rounded-0" style={{border:'1px solid #002147'}}>
  <img src={require('../images/269257867_11260839.png')} class="card-img-top rounded-0" alt="..."/>
  <div class="card-body py-0 px-1">
    <div className='pt-3'>
    <h5 class="card-title m-0 text-color fw-bold">{x.name && x.name.sub_course_name}</h5>
    <p class="card-text text-color" style={{fontSize:'13px'}}>By {capitalizeFirstLetter(user.first_name)}</p>
    </div>
   <div className='py-0 mt-3 d-flex justify-content-between align-items-center'>
    <p className='m-0' style={{color:'#002147'}}><span style={{fontSize:'12px'}} className='fw-medium'>Class Time : </span> <span style={{fontSize:'12px'}} className='fw-medium'>{formatTime(x.start_time)}</span></p>
   <button onClick={()=>{
        navigate(`/logsheet/${x.name.id}/${x.id}`)
      }} className='btn text-white btn-sm rounded-pill px-3 py-1' style={{backgroundColor:'#002147'}}>Attendance</button>
   </div>
  </div>
</div>
      </div>
        )
      })}
    </div>
  </div>
</div>
</div>

</div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default Trainer_dashboard