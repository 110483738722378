import React, { act, useState } from 'react';
import Navbar from './Navbar';
import Sidebar from '../Components/Sidebar';
import axios from 'axios';
import { useEffect } from 'react';
import { ipaddress } from '../App';
import { useNavigate } from 'react-router-dom';
import Preloader from '../Components/Preloader';

const View_Employees = ({ designation }) => {
  const [employees, setemployees] = useState([]);

  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem('user'));
  const [load, setload] = useState();

  const fetchemployees = type => {
    setload(true);
    axios
      .get(`${ipaddress}/AllprofileView/`)
      .then(r => {
        console.log('Employees data', r.data);
        const active_data = r.data.filter(x => x.user.blockstatus == false);
        const inactive_data = r.data.filter(x => x.user.blockstatus == true);
        if (type === 'active') {
          setemployees(active_data);
        } else {
          setemployees(inactive_data);
        }
        setload(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchemployees('active');
  }, []);

  const [employee_type, setemployee_type] = useState(true);

  return (
    <div>
      {load ? (
        <Preloader />
      ) : (
        <div className="d-flex">
          <Sidebar
            designation={designation}
            activevalue={'employees'}
          />
          <div
            className="w-100 main-div"
            style={{ backgroundColor: '#E8E8E8' }}
          >
            <Navbar
              designation={designation}
              pagename={'Employees'}
            />
            <div className="p-2 p-md-3 main-container">
              <div
                className="bg-white p-2 p-md-4"
                style={{ minHeight: '90vh' }}
              >
                <div className="container row m-0 d-flex justify-content-between">
                  <div className="col-md-8 ps-md-0">
                    <h5
                      onClick={() => {
                        setemployee_type(true);
                        fetchemployees('active');
                      }}
                      className={`d-inline px-4 py-2 ${
                        employee_type ? 'btns text-white' : 'text-color'
                      }`}
                      style={{
                        fontSize: '13px',
                        cursor: 'pointer',
                        border: '1px solid #002147',
                      }}
                    >
                      Active Employees
                    </h5>
                    <h5
                      onClick={() => {
                        setemployee_type(false);
                        fetchemployees('inactive');
                      }}
                      className={`d-inline px-4 py-2 ${
                        !employee_type ? 'btns text-white' : 'text-color'
                      }`}
                      style={{
                        fontSize: '13px',
                        cursor: 'pointer',
                        border: '1px solid #002147',
                      }}
                    >
                      Inactive Employees
                    </h5>
                  </div>
                  <div
                    className="col-md-4 d-flex align-items-center justify-content-end"
                    style={{}}
                  >
                    <button
                      onClick={() => {
                        navigate('/add_employees');
                      }}
                      className="px-3 me-4 btns py-1 text-white"
                    >
                      Add New
                    </button>
                    <span className="border-start border-secondary-subtle ps-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-search"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                      </svg>
                    </span>
                  </div>
                </div>

                <h6
                  className={`text-secondary text-center py-5 ${
                    employees.length > 0 ? 'd-none' : ''
                  }`}
                >
                  No Employees assigned under you...🧐
                </h6>
                <div className="pt-4 mt-3 row m-0 mx-auto">
                  {employees.map(x => {
                    return (
                      <div
                        className={`col-md-6 col-lg-4 mb-4 ${
                          employees.length > 0 ? '' : 'd-none'
                        }`}
                        style={{ borderRadius: '14px' }}
                      >
                        <div className="bg-white p-2 p-md-3 border py-md-4">
                          <div
                            className="d-flex align-items-center"
                            style={{ color: '#002147' }}
                          >
                            <div className="text-center">
                              <img
                                className={`rounded-circle ${
                                  x.image ? '' : 'd-none'
                                }`}
                                src={x.image}
                                width={110}
                                height={110}
                                alt=""
                              />
                              <img
                                className={`rounded-circle ${
                                  x.image ? 'd-none' : ''
                                }`}
                                src={require('../images/user.jpg')}
                                width={110}
                                height={110}
                                alt=""
                              />
                              <p
                                className="m-0 fw-bold mt-3"
                                style={{ color: '#000' }}
                              >
                                {x.user.first_name}
                              </p>
                            </div>
                            <div className="ms-3 ms-md-4">
                              <p
                                className="mt-1 fw-bold mb-2"
                                style={{ fontSize: '12px' }}
                              >
                                Designation : {x.designatin}
                              </p>
                              <p
                                className="fw-bold"
                                style={{ fontSize: '12px' }}
                              >
                                Email : {x.user.email}
                              </p>
                              <button
                                className="border btn fw-bold mt-2"
                                style={{ color: '#002147', fontSize: '13px' }}
                                onClick={() => {
                                  navigate(
                                    `/employee_profile/${x.user.emp_id}`,
                                  );
                                }}
                              >
                                View Activity
                              </button>
                              {/* <button
                                className="border btn fw-bold mt-2 tw-ml-3"
                                style={{ color: '#002147', fontSize: '13px' }}
                                onClick={() => {
                                  navigate(
                                    `/employee_profile/${x.user.emp_id}`,
                                  );
                                }}
                              >
                                View Profile
                              </button> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default View_Employees;
