import React from 'react'
import Trainer_login from './Trainer_login'

const Protect2 = ({Child}) => {
    const user=sessionStorage.getItem('user')
    const verify=()=>{
        if(user !=null || user !=undefined){
            return true
        }
        else{
            return false
        }
    }
  return (
    <div>
        {
            verify() ? <Child/> : <Trainer_login/>
        }
    </div>
  )
}

export default Protect2