import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { toast } from 'react-toastify'

const Reply_Queries = () => {
    const {id} = useParams()

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[queries,setqueries]=useState([])

    useEffect(()=>{
        fetch_queries()
    },[])

    const fetch_queries=()=>{
        axios.get(`${ipaddress2}/SendQuerries/${user.email}/${id}/`)
        .then((r)=>{
            console.log("Queries fetched",r.data)
            setqueries(r.data)
        })
    }

    const[query_id,setquery_id]=useState()

    const[reply,setreply]=useState("")

    const post_reply=()=>{
        axios.post(`${ipaddress2}/SendQuerries/${user.email}/${query_id}/`,{
            solution:reply
        })
        .then((r)=>{
            console.log("Successfully replied")
            setreply("")
            toast.success('Successfully replied',{
                autoClose:2000
            })
            fetch_queries()
        })
        
    }

return (
    <div  className='d-flex'>
        <Sidebar activevalue={"batches"}/>

        <div className='w-100'>
            <Navbar/>

            <div className='bg-light p-3 main-container' style={{minHeight:'100vh'}}>
                <h6 className='pb-3 pt-2 text-primary'>Send Reply for the Queries</h6>

                <div className='row m-0 w-100'>
                    <div className="col-md-8">
                    {queries.map((x,index)=>{
                    return(
                        <div className='bg-white rounded py-3 mb-4 shadow-sm'>
                        <div className="row m-0">
                            <div className="col-1 text-center">
                                {/* <img src={require('../images/man-438081_960_720.png')} alt="" /> */}
                            </div>
                            <div className='col-10 ps-5 ps-md-4 ps-lg-1'>
                                <div>
                                    <p className='m-0'>{x.customer_id.customer_name}</p>
                                    <span className='text-secondary' style={{fontSize:'13px'}}>{x.created_at.slice(0,10)}</span>
                                </div>
                            </div>
                        </div>
                        <div className='ps-3 mt-3'>
                            <p>{x.querry}</p>
                        </div>
                        <div className='pt-2 d-flex justify-content-end pe-2 border-bottom pb-3 mx-4'>
                            <span onClick={()=>{
                                setquery_id(x.id)
                            }} data-bs-toggle="modal" data-bs-target="#reply_modal" style={{cursor:'pointer'}} className='d-flex align-items-center'><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-reply-all" viewBox="0 0 16 16">
  <path d="M8.098 5.013a.144.144 0 0 1 .202.134V6.3a.5.5 0 0 0 .5.5c.667 0 2.013.005 3.3.822.984.624 1.99 1.76 2.595 3.876-1.02-.983-2.185-1.516-3.205-1.799a8.7 8.7 0 0 0-1.921-.306 7 7 0 0 0-.798.008h-.013l-.005.001h-.001L8.8 9.9l-.05-.498a.5.5 0 0 0-.45.498v1.153c0 .108-.11.176-.202.134L4.114 8.254l-.042-.028a.147.147 0 0 1 0-.252l.042-.028zM9.3 10.386q.102 0 .223.006c.434.02 1.034.086 1.7.271 1.326.368 2.896 1.202 3.94 3.08a.5.5 0 0 0 .933-.305c-.464-3.71-1.886-5.662-3.46-6.66-1.245-.79-2.527-.942-3.336-.971v-.66a1.144 1.144 0 0 0-1.767-.96l-3.994 2.94a1.147 1.147 0 0 0 0 1.946l3.994 2.94a1.144 1.144 0 0 0 1.767-.96z"/>
  <path d="M5.232 4.293a.5.5 0 0 0-.7-.106L.54 7.127a1.147 1.147 0 0 0 0 1.946l3.994 2.94a.5.5 0 1 0 .593-.805L1.114 8.254l-.042-.028a.147.147 0 0 1 0-.252l.042-.028 4.012-2.954a.5.5 0 0 0 .106-.699"/>
</svg><span className='ms-2'>Reply</span></span>
                        </div>

{/* Rely for query */}

{x.answers.map((y,index)=>{
    return(
        <div className='ms-5 mt-4'>
<div className="row m-0">
                            <div className="col-1 text-center">
                                {/* <img src={require('../images/man-438081_960_720.png')} alt="" /> */}
                            </div>
                            <div className='col-10 ps-5 ps-md-4 ps-lg-2'>
                                <div>
                                    <p className='m-0'>{user.first_name.toUpperCase()}</p>
                                    <span className='text-secondary' style={{fontSize:'13px'}}>{y.creatd_at.slice(0,10)}</span>
                                </div>
                            </div>
                        </div>
                        <div className='ps-3 mt-3'>
                            <p>{y.solution}</p>
                        </div>
</div>
    )
})}
                    </div>
                    )
                })}
                    </div>

                <div className="col-md-4">
                    <img src="https://img.freepik.com/free-vector/three-man-having-business-meeting-office_74855-4583.jpg?t=st=1717832698~exp=1717836298~hmac=c04ac2bd9d6d55633c648247c673f7ef4c91fa419a1075e9e49f87e912d31025&w=740" style={{width:'100%'}} alt="" />
                </div>
                </div>
            </div>
        </div>

{/* Reply for query modal */}

<div class="modal fade" id="reply_modal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center">
        <h1 class="modal-title fs-6 text-primary m-0" id="exampleModalLabel">Send Reply for the Query</h1>
        <svg style={{cursor:'pointer'}} data-bs-dismiss="modal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>
      <div class="modal-body">
        <div className='mt-2'>
            <textarea value={reply} onChange={(e)=>{
                setreply(e.target.value)
            }} name="" className='form-control py-2 shadow-none border-secondary-subtle' id=""></textarea>
            <div className='text-end mt-3'>
                <button data-bs-dismiss="modal" className='btn btn-sm btn-primary px-4 rounded-pill' onClick={post_reply}>Post</button>
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default Reply_Queries