import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { ipaddress } from '../App';

const Employee_details = () => {
  const { id } = useParams();

  const [designations_data, setdesignation_data] = useState([]);
  const [supervisor_data, setsupervisor_data] = useState([]);

  const [count, setcount] = useState(0);

  useEffect(() => {
    axios.get(`${ipaddress}/UsersDropdown/`).then(r => {
      console.log('Supervisors', r.data);
      setsupervisor_data(r.data);
    });

    axios.get(`${ipaddress}/DesignationDropdown/`).then(r => {
      console.log('Designations', r.data);
      setdesignation_data(r.data);
    });
  }, []);

  useEffect(() => {
    fetchemp_data();
  }, []);

  const [emp_data, setemp_data] = useState({});
  const [designation, setdesignation] = useState('');

  const fetchemp_data = () => {
    axios
      .get(`${ipaddress}/MyprofileView/${id}/`)
      .then(r => {
        console.log('Employee data found', r.data);
        setemp_data(r.data);
        setdesignation(r.data.designation);
      })
      .catch(err => {
        console.log('Employee data fetching error', err);
      });
  };

  const [edit_designation, setedit_designation] = useState(false);

  const change_designation = value => {
    const val = designations_data.find(x => x.designation === value);
    setdesignation(val.designation);
  };

  return (
    <div className="d-flex">
      <Sidebar activevalue={'employees'} />
      <div className="w-100 main-div">
        <Navbar pagename={'Employees'} />
        <div
          className="bg-light py-3 px-1 px-md-3 pt-4 main-container"
          style={{ minHeight: '100vh' }}
        >
          <div className="bg-white p-3 p-lg-5">
            <div className="row m-0">
              <div className="col-lg-6 px-1 px-md-4">
                <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout">
                  <p className="m-0 text-secondary">Employee ID</p>
                  <p className="m-0 fw-medium">{emp_data.emp_id}</p>
                </div>
              </div>
              <div className="col-lg-6 px-1 px-md-4">
                <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout">
                  <p className="m-0 text-secondary">Employee Name</p>
                  <p className="m-0 fw-medium">{emp_data.firstname}</p>
                </div>
              </div>
              <div className="col-lg-6 px-1 px-md-4">
                <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout align-items-end">
                  <p className="m-0 text-secondary">Email ID</p>
                  <p className="m-0 fw-medium word-break">{emp_data.email}</p>
                </div>
              </div>
              <div className="col-lg-6 px-1 px-md-4">
                <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout align-items-end">
                  <p className="m-0 text-secondary">Designation</p>
                  <select
                    disabled={!edit_designation}
                    onChange={e => {
                      change_designation(e.target.value);
                    }}
                    className={`m-0 bg-transparent fw-medium border-0 p-0 shadow-none ${
                      edit_designation ? 'form-select' : 'form-control'
                    }`}
                    value={designation}
                  >
                    {designations_data.map(x => {
                      return (
                        <>
                          <option value={x.designation}>{x.designation}</option>
                        </>
                      );
                    })}
                  </select>
                  <svg
                    onClick={() => setedit_designation(!edit_designation)}
                    style={{ cursor: 'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class={`bi bi-pencil-fill ${
                      edit_designation ? 'text-dark' : 'text-secondary'
                    }`}
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                  </svg>
                </div>
              </div>
              <div className="col-lg-6 px-1 px-md-4">
                <div className="border-bottom border-0 d-flex pb-1 mb-4 emp_profile_layout">
                  <p className="m-0 text-secondary">Supervisor</p>
                  <p className="m-0 fw-medium">
                    {emp_data.supervisor ? emp_data.supervisor : 'Not Assigned'}
                  </p>
                  <svg
                    style={{ cursor: 'pointer' }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil-fill text-secondary"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.5.5 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11z" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee_details;
