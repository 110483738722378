import React, { useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { ipaddress, ipaddress2 } from '../App'
import axios from 'axios'

const Registration_form = () => {

  const {id}=useParams()

  const user=JSON.parse(sessionStorage.getItem('user'))

  const navigate=useNavigate()

  const[states,setstates]=useState([])
  const[cities,setcities]=useState([])

  const sourcedata=(e)=>{
    if(e.target.value==="walkin"){
      document.getElementById('contactedpersondiv').style.display="block"
    }
    if(e.target.value==="other"){
      document.getElementById('othersource').style.display="block"
    }
  }

  const[availablecourses,setAvailablecourses]=useState([])
  const[lead_levels,setlead_levels]=useState([])
  const[particularlead_data,setparticularlead_data]=useState({})
  const[message,setmessage]=useState(true)

  useEffect(()=>{
    axios.get(`${ipaddress}/checktoken/${id}/`)
    .then((r)=>{
      console.log("Message",r.data)
      setmessage(r.data.message)
    })

    // To fetch particular lead details
    axios.get(`${ipaddress}/LeadDetailsView/${id}/`)
    .then((r)=>{
      console.log("Particular Lead Data fetched",r.data)
      setparticularlead_data(r.data)
    })
    .catch((err)=>{
      console.log("Particular lead details fetching Error",err)
    })
  },[id])

  const[classtypes,setclasstypes]=useState([])
  const[batchtypes,setbatchtypes]=useState([])

  useEffect(()=>{
    axios.get(`${ipaddress}/Allclasstypes/`)
    .then((r)=>{
      console.log("Class types",r.data)
      setclasstypes(r.data.classtypes)
    })

    axios.get(`${ipaddress}/Allbatchtypes/`)
    .then((r)=>{
      console.log("Batch types",r.data)
      setbatchtypes(r.data.batchtypes)
    })
  },[])


  const [formData, setFormData] = useState({
    // emp_id:user.emp_id,
    customer_lead_id:'',
    client_lead_id:'',
    Door_Number:'',
    Street_Name:'',
    Area:'',
    State:'',
    City:'',
    Pincode:'',
    Date_of_Birth:'',
    Aadhar_Number:'',
    Photograph:null,
    Alternative_Address:'',
    Alternative_Email:'',
    Alternative_Contact_Number:'',
    position:'',
    position_name:'',
    position_contact:'',
    adhar_document:null,
    pan_document:null,
    passport_document:null,
    dl_document:null,
    attested_photo_copy:null,
    rental_agreement:null
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    setFormData((prevState) => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };


  const senddetails=(e)=>{
    e.preventDefault()
    setloading2(true)

    if(id.slice(0,3)==="CLI"){
      formData.client_lead_id=id
      }
      else{
        formData.customer_lead_id=id
      }
      const form=new FormData()

      form.append('Door_Number',formData.Door_Number)
      form.append('Street_Name',formData.Street_Name)
      form.append('Area',area)
      form.append('City',city)
      form.append('State',state)
      form.append('Pincode',pincode)
      form.append('Date_of_Birth',formData.Date_of_Birth)
      form.append('Aadhar_Number',formData.Aadhar_Number)
      form.append('Photograph',formData.Photograph)
      form.append('Alternative_Address',formData.Alternative_Address)
      form.append('Alternative_Email',formData.Alternative_Email)
      form.append('Alternative_Contact_Number',formData.Alternative_Contact_Number)
      form.append('performa_status',performa_status)
      form.append('position',formData.position)
      form.append('position_name',formData.position_name)
      form.append('position_contact',formData.position_contact)
      form.append('adhar_document',formData.adhar_document)
      form.append('pan_document',formData.pan_document)
      form.append('dl_document',formData.dl_document)
      form.append('passport_document',formData.passport_document)
      form.append('rental_agreement',formData.rental_agreement)
      form.append('attested_photo_copy',formData.attested_photo_copy)



      console.log(formData)
    axios.post(`${ipaddress}/RegistrationVia_user/${id}/`,form)
    .then((r)=>{
      console.log("Data Submitted",r.data)

      setshowstate(true)

      setloading2(false)

      setPincode('')
      setcity('')
      setstate('')
      setarea('')

      setperforma_status(false)

      setFormData({
        // emp_id:user.emp_id,
        customer_lead_id:'',
        client_lead_id:'',
        Door_Number:'',
        Street_Name:'',
        Area:'',
        State:'',
        City:'',
        Pincode:'',
        Date_of_Birth:'',
        Aadhar_Number:'',
        Photograph:null,
        Alternative_Address:'',
        Alternative_Email:'',
        Alternative_Contact_Number:'',
        position:'',
        position_name:'',
        position_contact:'',
        adhar_document:null,
        pan_document:null,
        passport_document:null,
        dl_document:null,
        attested_photo_copy:null,
        rental_agreement:null
      })
    })
    .catch((err)=>{
      console.log("Error",err)
      setloading2(false)
    })
  }

  const[formstate,setformstate]=useState(false)
  const[otpstate,setotpstate]=useState(false)
  const[backendotp,setbackendotp]=useState('')
  const[otp,setotp]=useState('')

  const sendotp=()=>{
    // e.preventDefault()
    axios.post(`${ipaddress}/send-otp/`,{
      email:formData.email
    })
    .then((r)=>{
      console.log("Otp sent suceessfully",r.data)
      setbackendotp(r.data.otp)
    })
    .catch((err)=>{
      console.log("Otp sending error",err)
    })
  }

  const verifyotp=()=>{
    console.log(otp,backendotp)
    if(otp===backendotp){
      toast.success('OTP verified successfully',{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
      setotpstate(false)
      setformstate(true)
      setotp('')
    }
    else{
      toast.error('Invalid OTP',{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    }
  }


  // To fetch the address details using pincode
  const[pincode,setPincode]=useState('')
  const[city,setcity]=useState("")
  const[state,setstate]=useState('')
  const[area,setarea]=useState('')
  const[areas,setAreas]=useState([])

  const fetch_address=()=>{
    setloading3(true)
    axios.get(`${ipaddress2}/pincode_details_api_view/${pincode}/`)
    .then((r)=>{
      console.log("Address",r.data)
      setstate(r.data[0].State)
      setAreas(r.data)
      setloading3(false)
      const uniqueDivisions = [...new Set(r.data.map((x) => x.Division))];
      console.log(uniqueDivisions)
      setcities(uniqueDivisions)

    })
    .catch((err)=>{
      console.log(err)
      setloading3(false)
    })
  }

const[performa_status,setperforma_status]=useState('False')
const[loading2,setloading2]=useState()
const[loading3,setloading3]=useState()

const[document_type,setdocument_type]=useState('')
const[showstate,setshowstate]=useState(false)


return (
    <div className='animate__animated animate__fadeIn bg-light'>
        <div className={`container-fluid row m-0 pb-4 pt-3  ${message ? '':'d-none'}`}>
          <div className='mb-2'>
       
            <h5 className={`text-success text-center ${showstate ? 'd-none':''}`}>REGISTRATION FORM</h5>
          </div>
            <div className={`col-12 bg-white py-3 ${showstate ? 'd-none':''}`}>
            <form className="" onSubmit={senddetails}>

<div className={`${formstate ? '':''}`}>
  {/* --------------------------------------PERSONAL ADDRESS----------------------------------------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary mb-2 fs-5 mb-3'>Personal Address</h6>
                <div className="col-md-6 col-lg-3 mb-4">
    <label for="inputEmail4" className="form-label">Door Number <span className='required-field'>*</span></label>
    <input required type="text" className="form-control py-3 shadow-none bg-light" id="inputEmail4" value={formData.Door_Number} name='Door_Number' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Street Name <span className='required-field'>*</span></label>
    <input required type="text" className="form-control py-3 shadow-none bg-light" id="inputPassword4" value={formData.Street_Name} name='Street_Name' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-4">
  <label for="inputPassword4" className="form-label">Pincode <span className='required-field'>*</span></label>
    <input required type="tel" value={pincode} className="form-control py-3 shadow-none bg-light" maxLength={6} id="inputPassword4" name='Pincode' onChange={(e)=>{
      setPincode(e.target.value)
    }}/>
  </div>

  <div className='col-md-6 col-lg-2 mb-4 d-flex align-items-end'>
  <button className='btn btn-sm btn-primary' onClick={()=>{
    fetch_address()
  }}>{loading3 ? 'Verifying...':'Verify Pincode'}</button>
  </div>

  <div className="col-md-6 col-lg-4 mb-4">
    <label for="inputPassword4" className="form-label">Area <span className='required-field'>*</span></label>
    <select value={area} type="text" onChange={(e)=>{
    setarea(e.target.value)
  }} className="form-select py-3 shadow-none bg-light" id="inputPassword4" name='Area'>
      <option value="">Select the Area</option>
      {areas.map((x)=>{
        return(
          <>
          <option value={x.Name}>{x.Name}</option>
          </>
        )
      })}
    </select>
  </div>
  <div className="col-md-6 col-lg-4 mb-4">
  <label for="inputState" className="form-label shadow-none">State <span className='required-field'>*</span></label>
  <input type="text" className='form-control py-3 shadow-none bg-light' value={state} onChange={(e)=>{
    setstate(e.target.value)
  }}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-4">
  <label for="inputState" className="form-label shadow-none">City <span className='required-field'>*</span></label>
  <select value={city} type="text" className="form-select py-3 shadow-none bg-light" id="inputPassword4" name='Area' onChange={(e)=>{
    setcity(e.target.value)
  }}>
      <option value="">Select the City</option>
      {cities.map((x)=>{
        return(
          <>
          <option value={x}>{x}</option>
          </>
        )
      })}
    </select>
  </div>
                </div>

{/* ----------PROOF DETAILS----------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary fs-5 mb-3'>Proof Documents</h6>
  <div className="col-md-6 col-lg-4 mb-4">
  <label for="inputState" className="form-label shadow-none">Date of birth <span className='required-field'>*</span></label>
  <input required type="date" className="form-control py-3 shadow-none bg-light" id="inputPassword4" value={formData.Date_of_Birth} name='Date_of_Birth' onChange={handleInputChange}/>

  </div>
  <div className="col-md-6 col-lg-4 mb-4">
  <label for="inputPassword4" className="form-label">Aadhar Number</label>
    <input type="tel" maxLength={12} className="form-control py-3 shadow-none bg-light" id="inputPassword4" value={formData.Aadhar_Number} name='Aadhar_Number' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-4 d-flex align-items-end justify-content-center">
  <input
                  id="fileInput"
                  type="file"
                  // accept=".pdf"
                  name='Photograph'
                  onChange={(e)=>{
                    const {name,files}=e.target
                    setFormData((prev)=>({
                      ...prev,
                      [name]:files[0]
                    }))
                  }}
                  className="bg-light text-center p-3 btn"
                />
                <label
                  for="fileInput"
                  class="d-flex align-items-center custom-file-input rounded-pill border-0 text-white px-4 py-2 fw-normal" style={{backgroundColor:'#5D5FE3'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z" fill="white"/>
</svg> <span className='ms-2'>Upload Profile Image</span>
                </label>
                <span className='d-block text-success ms-2'>{formData.Photograph && formData.Photograph.name!=undefined && formData.Photograph.name.slice(0,16)}</span>

  </div>

  <div className="col-md-6 col-lg-4 mb-4">
  <label for="inputState" className="form-label shadow-none">Select the Document Type<span className='required-field'>*</span></label>
  <select value={document_type} type="text" className="form-select py-3 shadow-none bg-light" id="inputPassword4" name='Area' onChange={(e)=>{
    setdocument_type(e.target.value)
  }}>
      <option value="">Select Document Type</option>
      <option value="Aadhar Card">Aadhar Card</option>
      <option value="Pan Card">PAN Card</option>
      <option value="Driving License">Driving License</option>
      <option value="Passport">Passport</option>
      <option value="Rental Agreement">Rental Agreement</option>
      <option value="Attested Photo Copy">Notry Attested Copy</option>
    </select>
  </div>

  <div className={`col-md-6 col-lg-4 mb-4 d-flex align-items-end justify-content-center ${document_type==='Aadhar Card' ? '':'d-none'}`}>
  <input
                  id="fileInput2"
                  type="file"
                  accept=".pdf"
                  name='adhar_document'
                  onChange={(e)=>{
                    const {name,files}=e.target
                    setFormData((prev)=>({
                      ...prev,
                      [name]:files[0]
                    }))
                  }}
                  className="bg-light text-center p-3 btn"
                />
                <label
                  for="fileInput2"
                  class="d-flex align-items-center custom-file-input rounded-pill border-0 text-white px-4 py-2 fw-normal" style={{backgroundColor:'#5D5FE3'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z" fill="white"/>
</svg> <span className='ms-2'>Upload {document_type}</span>
                </label>
                <span className='d-block text-success ms-2'>{formData.adhar_document && formData.adhar_document.name!=undefined && formData.adhar_document.name.slice(0,16)}</span>

  </div>

  <div className={`col-md-6 col-lg-4 mb-4 d-flex align-items-end justify-content-center ${document_type==='Pan Card' ? '':'d-none'}`}>
  <input
                  id="fileInput3"
                  type="file"
                  accept=".pdf"
                  name='pan_document'
                  onChange={(e)=>{
                    const {name,files}=e.target
                    setFormData((prev)=>({
                      ...prev,
                      [name]:files[0]
                    }))
                  }}
                  className="bg-light text-center p-3 btn"
                />
                <label
                  for="fileInput3"
                  class="d-flex align-items-center custom-file-input rounded-pill border-0 text-white px-4 py-2 fw-normal" style={{backgroundColor:'#5D5FE3'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z" fill="white"/>
</svg> <span className='ms-2'>Upload {document_type}</span>
                </label>
                <span className='d-block text-success ms-2'>{formData.pan_document && formData.pan_document.name!=undefined && formData.pan_document.name.slice(0,16)}</span>

  </div>

  <div className={`col-md-6 col-lg-4 mb-4 d-flex align-items-end justify-content-center ${document_type==='Passport' ? '':'d-none'}`}>
  <input
                  id="fileInput4"
                  type="file"
                  accept=".pdf"
                  name='passport_document'
                  onChange={(e)=>{
                    const {name,files}=e.target
                    setFormData((prev)=>({
                      ...prev,
                      [name]:files[0]
                    }))
                  }}
                  className="bg-light text-center p-3 btn"
                />
                <label
                  for="fileInput4"
                  class="d-flex align-items-center custom-file-input rounded-pill border-0 text-white px-4 py-2 fw-normal" style={{backgroundColor:'#5D5FE3'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z" fill="white"/>
</svg> <span className='ms-2'>Upload {document_type}</span>
                </label>
                <span className='d-block text-success ms-2'>{formData.passport_document && formData.passport_document.name!=undefined && formData.passport_document.name.slice(0,16)}</span>

  </div>

  <div className={`col-md-6 col-lg-4 mb-4 d-flex align-items-end justify-content-center ${document_type==='Driving License' ? '':'d-none'}`}>
  <input
                  id="fileInput5"
                  type="file"
                  accept=".pdf"
                  name='dl_document'
                  onChange={(e)=>{
                    const {name,files}=e.target
                    setFormData((prev)=>({
                      ...prev,
                      [name]:files[0]
                    }))
                  }}
                  className="bg-light text-center p-3 btn"
                />
                <label
                  for="fileInput5"
                  class="d-flex align-items-center custom-file-input rounded-pill border-0 text-white px-4 py-2 fw-normal" style={{backgroundColor:'#5D5FE3'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z" fill="white"/>
</svg> <span className='ms-2'>Upload {document_type}</span>
                </label>
                <span className='d-block text-success ms-2'>{formData.dl_document && formData.dl_document.name!=undefined && formData.dl_document.name.slice(0,16)}</span>

  </div>

  <div className={`col-md-6 col-lg-4 mb-4 d-flex align-items-end justify-content-center ${document_type==='Rental Agreement' ? '':'d-none'}`}>
  <input
                  id="fileInput6"
                  type="file"
                  accept=".pdf"
                  name='rental_agreement'
                  onChange={(e)=>{
                    const {name,files}=e.target
                    setFormData((prev)=>({
                      ...prev,
                      [name]:files[0]
                    }))
                  }}
                  className="bg-light text-center p-3 btn"
                />
                <label
                  for="fileInput6"
                  class="d-flex align-items-center custom-file-input rounded-pill border-0 text-white px-4 py-2 fw-normal" style={{backgroundColor:'#5D5FE3'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z" fill="white"/>
</svg> <span className='ms-2'>Upload {document_type}</span>
                </label>
                <span className='d-block text-success ms-2'>{formData.rental_agreement && formData.rental_agreement.name!=undefined && formData.rental_agreement.name.slice(0,16)}</span>

  </div>

  <div className={`col-md-6 col-lg-4 mb-4 d-flex align-items-end justify-content-center ${document_type==='Attested Photo Copy' ? '':'d-none'}`}>
  <input
                  id="fileInput7"
                  type="file"
                  accept=".pdf"
                  name='attested_photo_copy'
                  onChange={(e)=>{
                    const {name,files}=e.target
                    setFormData((prev)=>({
                      ...prev,
                      [name]:files[0]
                    }))
                  }}
                  className="bg-light text-center p-3 btn"
                />
                <label
                  for="fileInput7"
                  class="d-flex align-items-center custom-file-input rounded-pill border-0 text-white px-4 py-2 fw-normal" style={{backgroundColor:'#5D5FE3'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z" fill="white"/>
</svg> <span className='ms-2'>Upload {document_type}</span>
                </label>
                <span className='d-block text-success ms-2'>{formData.attested_photo_copy && formData.attested_photo_copy!=undefined && formData.attested_photo_copy.slice(0,16)}</span>

  </div>

</div>

{/* ------------------------------------------------Complete Batch Details---------------------------------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary fs-5 mb-3'>Additional Details</h6>

                <div className="col-md-6 col-lg-4 mb-4 mt-2">
  <label for="inputState" className="form-label shadow-none">Select the Gaurdian<span className='required-field'>*</span></label>
  <select required value={formData.position} type="text" className="form-select py-3 shadow-none bg-light" id="inputPassword4" name='position' onChange={handleInputChange}>
      <option value="">Select Gaurdian</option>
      <option value="parent">Parent</option>
      <option value="gaurdian">Gaurdian</option>
      <option value="spouse">Spouse</option>
    </select>
  </div>

  <div className="col-md-6 col-lg-4 mb-4 mt-2">
    <label for="inputPassword4" className="form-label">Person's Name<span className='required-field'>*</span></label>
    <input required type="text" className="form-control py-3 shadow-none bg-light" id="inputPassword4" name="position_name" value={formData.position_name} onChange={handleInputChange}/>
  </div>

  <div className="col-md-6 col-lg-4 mb-4 mt-2">
    <label for="inputPassword4" className="form-label">Contact Number<span className='required-field'>*</span></label>
    <input required type="tel" maxLength={10} className="form-control py-3 shadow-none bg-light" id="inputPassword4" name="position_contact" value={formData.position_contact} onChange={handleInputChange}/>
  </div>

  <div className="col-md-6 col-lg-4 mb-4 mt-2">
    <label for="inputPassword4" className="form-label">Alternative Address</label>
    <input type="text" className="form-control py-3 shadow-none bg-light" id="inputPassword4" name="Alternative_Address" value={formData.Alternative_Address} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-4 mt-2">
    <label for="inputPassword4" className="form-label">Alternative Email</label>
    <input type="email" className="form-control py-3 shadow-none bg-light" id="inputPassword4" name="Alternative_Email" value={formData.Alternative_Email} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-4 mt-2">
    <label for="inputPassword4" className="form-label">Alternative Contact Number</label>
    <input type="tel" className="form-control py-3 shadow-none bg-light" maxLength={10} id="inputPassword4" name="Alternative_Contact_Number" value={formData.Alternative_Contact_Number} onChange={handleInputChange}/>
  </div>

  <div className='mt-3 col-12 d-flex align-items-center'>
    <span><svg style={{cursor:'pointer'}} onClick={()=>{
      setperforma_status('True')
    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class={`bi bi-check-square ${performa_status==='False' ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z"/>
</svg>
<svg style={{cursor:'pointer'}} onClick={()=>{
      setperforma_status('False')
    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#0D6EFD" class={`bi bi-check-square-fill ${performa_status==='True' ? '':'d-none'}`} viewBox="0 0 16 16">
  <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"/>
</svg>
</span>
<span className='ms-2'>Agree to the Performa Invoice and Terms & Conditions</span>
</div>
                </div>
                <div className="col-12 text-end mt-3">
    <button disabled={performa_status==='False' ? true:false} type="submit" className="btn btn-primary text-white fw-medium px-2 px-lg-5">{loading2 ? 'Submitting...':'Submit'}</button>
  </div>
</div>
</form>
            </div>
        </div>

{/* Successfull Message */}
<div className={`row m-0 rounded mx-auto leave-div justify-content-center py-5 shadow-sm mt-3 animate__animated animate__jello ${showstate ? '':'d-none'}`} style={{backgroundColor:'#f0f1f9'}}>
                    <div className="col-md-8 d-flex align-items-center mt-4">
                        <div className='w-100 d-flex flex-column align-items-center'>
                        <img src={require('../images/student/Group.png')} style={{width:'70%'}} alt="" />
                        <p className='fw-bold mt-5 fs-4'>Successfully Registered</p>
                        <p className='m-0' style={{fontSize:'15px'}}>We got your details!</p>
                        {/* <button className='btn text-white mt-2 px-4 py-2' style={{backgroundColor:'#57309C'}}>Back to Dashboard</button> */}
                        </div>
                    </div>
                </div>

{/* 404 Page */}
<div className={`${message ? 'd-none':''}`} style={{height:'100vh'}}>
  <div className="row m-0 h-100 align-items-center">
  <div className='col-md-8 text-center mx-auto'>
    <img src={require('../images/6325254.jpg')} style={{width:'100%'}} alt="" />
  </div>
  </div>
</div>

    </div>
  )
}

export default Registration_form