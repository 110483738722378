import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { ipaddress } from '../App'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import axios from 'axios'

const Campaign_from = () => {
    const renderTooltip4 = (props) => (
        <Tooltip id="button-tooltip" {...props}>
          Add More Skills
        </Tooltip>
      );

  const user=JSON.parse(sessionStorage.getItem('user'))

  const[availablecourses,setAvailablecourses]=useState([])
  const[lead_levels,setlead_levels]=useState([])

  const[bach_perc,setbach_perc]=useState(true)
  const[mast_perc,setmast_perc]=useState(true)

  const[bach_degrees,setbach_degrees]=useState([])
  const[bach_specific,setbach_specific]=useState([])

  const[master_degrees,setmaster_degrees]=useState([])
  const[master_specific,setmaster_specific]=useState([])

  useEffect(()=>{

    axios(`${ipaddress}/bachelor-degrees/`)
    .then((r)=>{
      console.log("Bach degrees successfully fetched",r.data)
      setbach_degrees(r.data)
    })
    .catch((err)=>{
      console.log("Bach degrees fetching Error",err)
    })

    axios(`${ipaddress}/MasterDegrees/`)
    .then((r)=>{
      console.log("Mast degrees successfully fetched",r.data)
      setmaster_degrees(r.data)
    })
    .catch((err)=>{
      console.log("Mast degrees fetching Error",err)
    })

    axios.get(`${ipaddress}/AllcoursesnameView/`)
    .then((r)=>{
      console.log("Courses successfully fetched",r.data)
      setAvailablecourses(r.data.coursename)
    })
    .catch((err)=>{
      console.log("Courses fetching Error",err)
    })

    axios.get(`${ipaddress}/Allleadlevels/`)
    .then((r)=>{
      console.log("Lead levels successfully fetched",r.data)
      setlead_levels(r.data.leadlevels)
    })
    .catch((err)=>{
      console.log("Lead levels fetching Error",err)
    })
  },[])


  const sourcedata=(e)=>{
    if(e.target.value==="walkin"){
      document.getElementById('contactedpersondiv').style.display="block"
    }
    if(e.target.value==="other"){
      document.getElementById('othersource').style.display="block"
    }
  }

  const fetchspecifications=(id)=>{
    axios(`${ipaddress}/bachelor-degrees/${id}/specifications/`)
    .then((r)=>{
      console.log("Bach specific successfully fetched",r.data)
      setbach_specific(r.data)
    })
    .catch((err)=>{
      console.log("Bach specific fetching Error",err)
    })
  }

  const fetchspecifications2=(id)=>{
    axios(`${ipaddress}/MasterDegrees/${id}/specifications/`)
    .then((r)=>{
      console.log("Mast specific successfully fetched",r.data)
      setmaster_specific(r.data)
    })
    .catch((err)=>{
      console.log("Mast specific fetching Error",err)
    })
  }

  const [formData, setFormData] = useState({
    emp_id:'',
    name: '',
    email: '',
    contact_number: '',
    gender: '',
    bachelor_degree: '',
    bachelor_specification : '',
    percentage_bachelor : '',
    master_degree: '',
    master_specification: '',
    percentage_master: '',
    course_enquired: '',
    enquiry_location: '',
    source: '',
    contacted_person_name:'',
    contacted_person_number:'',
    technical_skills_known: [],
    soft_skills_known: [],
    priority: '',
    level_lead: 'newlead',
    preferred_course:'',
    preferred_batch_type:'',
    preferred_batch_start_time:'',
    preferred_class_type:'',
    expected_registration_date:'',
    tentative_batch_start_date:'',
    created_at:''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked, value } = e.target;
    setFormData((prevState) => {
      const skills = prevState[name];
      if (checked) {
        skills.push(value);
      } else {
        const index = skills.indexOf(value);
        if (index > -1) {
          skills.splice(index, 1);
        }
      }
      return { ...prevState, [name]: skills };
    });
  };

  const navigate=useNavigate()

  const senddetails=(e)=>{
    e.preventDefault()
    formData.technical_skills_known=technical_skills
    formData.soft_skills_known=soft_skills

    // console.log(subcourses)
    axios.post(`${ipaddress}/CustomerLeadsView/`,formData)
    .then((r)=>{
      console.log("Successfully registered",r.data)
      console.log(formData)
      toast.success('Successfully sent',{
        autoClose: 2000,
        theme:'colored',
      position:'top-center'
      })

      setTimeout(()=>{
        navigate('/customerleads')
      },4000)
      
    })
    .catch((err)=>{
      console.log("Lead registration error",err)
    })
  }

  const[formstate,setformstate]=useState(false)
  const[otpstate,setotpstate]=useState(false)
  const[backendotp,setbackendotp]=useState('')
  const[otp,setotp]=useState('')

  const sendotp=()=>{
    // e.preventDefault()
    axios.post(`${ipaddress}/send-otp/`,{
      email:formData.email
    })
    .then((r)=>{
      console.log("Otp sent suceessfully",r.data)
      setbackendotp(r.data.otp)
    })
    .catch((err)=>{
      console.log("Otp sending error",err)
    })
  }

  const verifyotp=()=>{
    console.log(otp,backendotp)
    if(otp===backendotp){
      toast.success('OTP verified successfully',{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
      setotpstate(false)
      setformstate(true)
      setotp('')
    }
    else{
      toast.error('Invalid OTP',{
        autoClose:2000,
        theme:'colored',
      position:'top-center'
      })
    }
  }


const [technical_skills, settechnical_skills] = useState(['']);
  
  const handleAddsubcourses = () => {
    settechnical_skills([...technical_skills, '']);
  };
  
  const handlesubcoursesChange = (index, event) => {
    const newCourses = technical_skills.map((course, courseIndex) =>
        courseIndex === index ? event.target.value : course
    );
    settechnical_skills(newCourses);
};

const [soft_skills, setsoft_skills] = useState(['']);
  
  const handleAddsubcourses2 = () => {
    setsoft_skills([...soft_skills, '']);
  };
  
  const handlesubcoursesChange2 = (index, event) => {
    const newCourses = soft_skills.map((course, courseIndex) =>
        courseIndex === index ? event.target.value : course
    );
    setsoft_skills(newCourses);
};

return (
    <div className=' bg-light' style={{minHeight:'100vh'}}>
        <div className='container-fluid row m-0 pb-4 pt-3 animate__animated animate__fadeIn'>
          <div className='mb-2 d-flex'>
            <h5 className='text-success mx-auto'>CUSTOMER REGISTRATION FORM</h5>
          </div>
            <div className="col-12 bg-white py-3">
            <form className="" onSubmit={senddetails}>
{/* ---------------------------------PERSONAL DETAILS--------------------------------------------------------- */}
                <div className="row m-0 border-bottom pb-2">
                <h6 className='text-primary'>Personal Details</h6>
                <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputEmail4" className="form-label">Name <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputEmail4" name='name' value={formData.name} onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Contact Number <span className='required-field'>*</span></label>
    <input required type="tel" className="form-control shadow-none bg-light py-2" maxLength={10} id="inputPassword4" name='contact_number' value={formData.contact_number} onChange={handleInputChange}/>
  </div>

  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Email</label>
    <input type="email" className="form-control shadow-none bg-light py-2" id="inputPassword4" name='email' value={formData.email} onChange={handleInputChange}/>

  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Gender <span className='required-field'>*</span></label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" name='gender' value={formData.gender} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option>Male</option>
      <option>Female</option>
      <option>Transgender</option>
    </select>
  </div>
                </div>

<div className={`${formstate ? '':''}`}>
{/* ----------------------------------EDUCATIONAL DETAILS----------------------------------------------------------- */}
<div className="row m-0 py-3">
                <h6 className='text-primary'>Educational Details</h6>
                
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Bachelor Degree </label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" name='bachelor_degree' value={formData.bachelor_degree} onChange={(e)=>{
      setbach_perc(false)
      fetchspecifications(e.target.value)
      const {name,value}=e.target

      setFormData((prev)=>({
        ...prev,
        [name]:value
      }))
    }}>
      <option selected>Choose...</option>
      {bach_degrees.map((x)=>{
        return(
          <>
          <option value={x}>{x}</option>
          </>
        )
      })}
    </select> 
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Bachelor Specification</label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" onChange={(e)=>{
      if(e.target.value==="other"){
      document.getElementById('bachother').style.display="block"
      }
      else{
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      document.getElementById('bachother').style.display="none"
      }
    }}>
      <option selected>Choose...</option>
      <option value="other">Other</option>
      {bach_specific.map((x)=>{
        return(
          <>
          <option value={x}>{x}</option>
          </>
        )
      })}
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Percentage Achieved</label>
    <input disabled={bach_perc} required type="number" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.percentage_bachelor} name='percentage_bachelor' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3" id='bachother' style={{display:'none'}}>
    <label for="inputPassword4" className="form-label">Enter your Specification</label>
    <input type="text" className="form-control shadow-none bg-light" id="inputPassword4" value={formData.bachelor_specification} name='bachelor_specification' onChange={handleInputChange}/>
  </div>

                </div>
                <div className="row m-0 border-bottom pb-3">

  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Master Degree</label>
    <select id="inputState" className="form-select shadow-none bg-light py-2" onChange={(e)=>{
      setmast_perc(false)
      fetchspecifications2(e.target.value)
      const {name,value}=e.target

      setFormData((prev)=>({
        ...prev,
        [name]:value
      }))
    }}>
      <option selected>Choose...</option>
      {master_degrees.map((x)=>{
        return(
          <>
          <option value={x}>{x}</option>
          </>
        )
      })}
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
  <label for="inputState" className="form-label shadow-none">Master Specification</label>
    <select id="inputState" className="form-select shadow-none bg-light py-2" onChange={(e)=>{
      if(e.target.value==="other"){
      document.getElementById('masterother').style.display="block"
      }
      else{
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      document.getElementById('masterother').style.display="none"
      }
    }}>
      <option selected>Choose...</option>
      <option value="other">Other</option>
      {master_specific.map((x)=>{
        return(
          <>
          <option value={x}>{x}</option>
          </>
        )
      })}
    </select>
  </div>
  <div className="col-md-6 col-lg-3 mb-3">
    <label for="inputPassword4" className="form-label">Percentage Achieved</label>
    <input type="number" disabled={mast_perc} className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.percentage_master} name='percentage_master' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-3 mb-3" id='masterother' style={{display:'none'}}>
    <label for="inputPassword4" className="form-label">Enter your Specification</label>
    <input type="text" className="form-control shadow-none bg-light" id="inputPassword4" value={formData.master_specification} name='master_specification' onChange={handleInputChange}/>
  </div>
                </div>


{/* ----------COURSE DETAILS----------------------- */}
<div className="row m-0 border-bottom py-3">
                <h6 className='text-primary'>Course Details</h6>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Course Enquired <span className='required-field'>*</span></label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" value={formData.course_enquired} name='course_enquired' onChange={handleInputChange}>
      <option selected>Choose...</option>
      {availablecourses.map((x)=>{
        return(
          <>
          <option value={x}>{x}</option>
          </>
        )
      })}
    </select>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputPassword4" className="form-label">Enquiry Location <span className='required-field'>*</span></label>
    <input required type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.enquiry_location} name='enquiry_location' onChange={handleInputChange}/>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="source" className="form-label shadow-none">Source <span className='required-field'>*</span></label>
    <select required id="source" className="form-select shadow-none bg-light py-2" value={formData.source} name='source' onChange={(e)=>{
      if(e.target.value==="walkin"){
        document.getElementById('contactedpersondiv').style.display="block"
        document.getElementById('other-source').style.display="none"
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      }
      else if(e.target.value==="other"){
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
        document.getElementById('other-source').style.display="block"
        document.getElementById('contactedpersondiv').style.display="none"
      }
       else{
        document.getElementById('contactedpersondiv').style.display="none"
        document.getElementById('other-source').style.display="none"
        const {name,value}=e.target
        setFormData((prev)=>({
          ...prev,
          [name]:value
        }))
      }
    }}>
      <option value="none" selected>Choose...</option>
      <option value="other">Other</option>
      <option value="walkin">Walkin</option>
      <option value="naukri">Naukri</option>
      <option value="linkedin">Linkedin</option>
      <option value="just dial">Just Dial</option>
      <option value="whatsapp">Whatsapp</option>
      <option value="facebook">Facebook</option>
      <option value="instagram">Instagram</option>
    </select>
  </div>
  
  <div style={{display:'none'}} id='contactedpersondiv'>
  <div className="col-md-6 col-lg-4 mb-3">
    <label for="inputPassword4" className="form-label">Contacted Person's Name</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" onChange={handleInputChange} value={formData.contacted_person_name} name='contacted_person_name'/>
  </div>

  <div className="col-md-6 col-lg-4 mb-3">
    <label for="inputPassword4" className="form-label">Contacted Person's Number</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" onChange={handleInputChange} value={formData.contacted_person_number} name='contacted_person_number'/>
  </div>
  </div>
{/* Other source */}
<div className="col-md-6 col-lg-4 mb-3" id='other-source' style={{display:'none'}}>
    <label for="inputPassword4" className="form-label">Enter the source</label>
    <input type="text" className="form-control shadow-none bg-light py-2" id="inputPassword4" value={formData.source} name='source' onChange={handleInputChange}/>
  </div>
                </div>

{/* --------------------------------TECHNOLOGIES KNOWN-------------------- */}
<div className="row m-0 border-bottom py-3">
  <div className="col-12 mb-3">
  <span className='text-primary fw-medium me-4'>Technical Skills Known</span>
  {/* <div className='mb-3 border-bottom pb-3'> */}
     {/* <input type="checkbox" id="java" name="technical_skills_known" value="java" onChange={handleCheckboxChange}/>
  <label for="java" className="form-label shadow-none ms-1">Java</label>
  <input type="checkbox" className='ms-2 ms-lg-3' name="technical_skills_known" value="python" onChange={handleCheckboxChange} id="python" />
  <label for="python" className="form-label shadow-none ms-1">Python</label>
  <input type="checkbox" className='ms-2 ms-lg-3' id="React Js" name="technical_skills_known" value="react_js" onChange={handleCheckboxChange}/>
  <label for="React Js" className="form-label shadow-none ms-1">React Js</label>
  <input type="checkbox" className='ms-2 ms-lg-3' id="Javascript" name="technical_skills_known" value="javascript" onChange={handleCheckboxChange}/>
  <label for="Javascript" className="form-label shadow-none ms-1">Javascript</label> */}

<div className='row m-0 mb-2 p-0' style={{ marginBottom: '20px',borderRadius:'10px'}}>
              {technical_skills.map((q, qIndex) => (
                
                  <div className='col-lg-2 px-2' key={qIndex}>
                    <input className='add-option form-control py-2 shadow-none bg-light border-secondary-subtle my-2'
                      type="text"
                      placeholder='Type your skills...'
                      onChange={(e) => handlesubcoursesChange(qIndex, e)}
                      required
                    />
                  </div>
                
              ))}
              </div>
              <div className='d-flex justify-content-end mt-4'>
              {/* <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip4}
    > */}
      <button type="button" className='btn btn-sm btn-primary text-white d-flex align-items-center' onClick={handleAddsubcourses}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                  </svg> 
                  <span className='ms-1' style={{fontSize:'12px'}}>Add More Skills</span>
                </button>
    {/* </OverlayTrigger> */}
               </div>
  </div>
  <div className="col-12 mb-3">
  <span className='text-primary fw-medium me-5'>Soft Skills Known </span>
  <div className='row m-0 mb-2 p-0' style={{ marginBottom: '20px',borderRadius:'10px'}}>
              {soft_skills.map((q, qIndex) => (
                
                  <div className='col-lg-2 px-2' key={qIndex}>
                    <input className='add-option form-control py-2 shadow-none bg-light border-secondary-subtle my-2'
                      type="text"
                      placeholder='Type your skills...'
                      onChange={(e) => handlesubcoursesChange2(qIndex, e)}
                      required
                    />
                  </div>
                
              ))}
              </div>
              <div className='d-flex justify-content-end mt-4'>
              {/* <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 250 }}
      overlay={renderTooltip4}
    > */}
      <button type="button" className='btn btn-sm btn-primary text-white d-flex align-items-center' onClick={handleAddsubcourses2}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="white" className="bi bi-plus-lg" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
                  </svg> 
                  <span className='ms-1' style={{fontSize:'12px'}}>Add More Skills</span>
                </button>
    {/* </OverlayTrigger> */}
               </div>
  </div>


  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Priority</label>
    <select required id="inputState" className="form-select shadow-none bg-light py-2" name="priority" value={formData.priority} onChange={handleInputChange}>
      <option selected>Choose...</option>
      <option>High</option>
      <option>Medium</option>
      <option>Low</option>
    </select>
  </div>
  <div className="col-md-6 col-lg-4 mb-3">
  <label for="inputState" className="form-label shadow-none">Level of Lead <span className='required-field'>*</span></label>
    <select disabled='true' id="inputState" className="form-control shadow-none bg-light py-2" name="level_lead" value={formData.level_lead} onChange={handleInputChange}>
      <option selected>Choose...</option>
      {lead_levels.map((x)=>{
        return(
          <>
           <option value={x}>{x}</option>
          </>
        )
      })}
    </select>
  </div>
</div>


  <div className="col-12 text-end mt-3">
    <button type="submit" className="btn btn-primary text-white fw-medium px-2 px-lg-5">Submit</button>
  </div>
</div>
</form>
            </div>
        </div>

    </div>
  )
}

export default Campaign_from