import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';

const Assign_students = () => {

    const[load,setload]=useState()

    const[students,setstudents]=useState([])
    const[incomplete_batches,setincomplete_batches]=useState([])
    const[selectedstudents,setselectedstudents]=useState([])
    const[selceted_batch,setseleced_batch]=useState()
    
    useEffect(()=>{
        fetch_students()
    },[])
    
    const fetch_students=()=>{
        axios.get(`${ipaddress2}/StudentsToBeAddedToBatch/`)
        .then((r)=>{
            console.log("Students",r.data)
            setstudents(r.data)
        })
    
        axios.get(`${ipaddress2}/incomplete-batches/`)
        .then((r)=>{
            console.log("Incomplete batches",r.data)
            setincomplete_batches(r.data)
        })
    }
    
    const handleCheckboxChange = (e, student_id) => {
        if (e.target.checked) {
          setselectedstudents([...selectedstudents, student_id]);
        } 
        else {
          setselectedstudents(selectedstudents.filter(id => id !== student_id));
        }
      };
    
    const assign_students=()=>{
    setload(true)
    axios.post(`${ipaddress}/add-student-to-batch/`,{
        customer_ids:selectedstudents,
        batch:selceted_batch
    })
    .then((r)=>{
        toast.success('Students assigned successfully',{
            autoClose:1000,
            theme:'colored',
      position:'top-center'
        })
        setload(false)
       setTimeout(() => {
        window.location.reload()
       }, 2000);
    })
    }

return (
    <div className='d-flex'>
        <Sidebar activevalue={"students"}/>
        <div className="w-100">
            <Navbar/>

            <div className="bg-light p-4 main-container">
            <div className='row m-0'>
        <div className="col-md-12 px-0 px-md-5 pb-4">
        <h6 className='fs-5 text-dark m-0 pb-3'>Assign Students to Batch</h6>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-md-6' style={{height:'200px',overflowY:'scroll'}}>
                {students.map((x)=>{
                    return(
                        <div className='d-flex align-items-center mb-3'>
                            <input type="checkbox" onChange={(e)=>{
                                handleCheckboxChange(e,Number(x.id))
                            }}/>
                            <label htmlFor="" className='ms-2 fw-medium'>{x.customer_name}</label>
                        </div>
                    )
                })}
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="">Batch</label>
                <select name="" id="" value={selceted_batch} onChange={(e)=>{
                    setseleced_batch(Number(e.target.value))
                }} className='form-select shadow-none border-secondary-subtle mt-2'>
                    <option value="">Select the Batch</option>
                    {incomplete_batches.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.batch_id}</option>
                            </>
                        )
                    })}
                </select>
                </div>
<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' onClick={assign_students}><span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Assigning...</span>
<span className={`${load ? 'd-none':''}`}>Assign</span></button>
</div>
            </div>
        </div>
    </div>
            </div>
        </div>
    </div>
  )
}

export default Assign_students