import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';

const Add_activity_module = () => {

    const[load,setload]=useState()

    const[activity_module,setactivity_module]=useState('')
const[activity_name,setactivity_name]=useState('')
const[activity_id,setactivity_id]=useState()

const add_activity_module=()=>{
    setload(true)
    setTimeout(() => {
        send()
    }, 1000);
}

const send=()=>{
    axios.post(`${ipaddress}/AddModules/`,{
        module_name:activity_module
    })
    .then((r)=>{
        console.log("Activity module",r.data)
        setload(false)
        if(r.data==='Module already exists'){
            toast.error('Module Name already exists',{
                autoClose:2000
            })
        }
        else{
            toast.success('Module name added successfully',{
                autoClose:1000
            })
            setactivity_module('')
            setactivitystate(false)
            fetch_modules()
        }
    })
}

const[activitystate,setactivitystate]=useState(false)
const[show,setshow]=useState(false)
const[modules,setmodules]=useState([])

const fetch_modules=()=>{
    axios.get(`${ipaddress2}/AllModules/`)
    .then((r)=>{
        console.log("Modules",r.data)
        setmodules(r.data)
    })
}

const update_modules=()=>{
    axios.put(`${ipaddress2}/EditActivityModuleName/${activity_id}/`,{
        new_name:activity_name
    })
    .then((r)=>{
        console.log("Module name updated",r.data)
        setshow(false)
        fetch_modules()
        setactivity_name('')
        setactivity_id(0)
    })
}

return (
    <div className='d-flex'>
        <Sidebar activevalue={"module"}/>
        <div className="w-100">
            <Navbar/>

            <div className="bg-light p-4 main-container">
            <div className='row m-0'>
        <div className="col-md-8 px-0 px-md-5 py-4 mx-auto">
            <div className='d-flex justify-content-end'>
            <button onClick={()=>{
                setactivitystate(true)
            }} className='m-0 d-flex btn btn-sm text-white align-items-center mb-3 px-3' style={{backgroundColor:'#4C2987'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2"/>
</svg> <span className='ms-1'>Add Activity Module</span></button>
            </div>
            <div className="table-responsive p-2">
            <table class="table">
  <thead className='table-secondary'>
    <tr>
      <th scope="col"><p className='text-secondary text-center m-0 fw-medium'>SI No</p></th>
      <th scope="col"><p className='text-secondary text-center m-0 fw-medium'>Activity Module Name</p></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    {modules.map((x,index)=>{
        return(
            <tr>
      <td scope="row" className='py-3'><p className='text-center m-0'>{index+1}</p></td>
      <td className='py-3'><p className='text-center m-0'>{x.module_name}</p></td>
      <td className='py-3'><div className='text-center'>
      <svg style={{cursor:'pointer'}} onClick={()=>{
        setactivity_name(x.module_name)
        setactivity_id(x.id)
        setshow(true)
      }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#4C2987" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg>
        </div></td>
    </tr>
        )
    })}
  </tbody>
</table>
            </div>

        </div>
    </div>

{/* Edit activity module */}
<Modal
      show={show}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className='d-flex align-items-center justify-content-between'>
        <Modal.Title id="contained-modal-title-vcenter" className='fs-5'>
          Edit Activity Module Name
        </Modal.Title>
        <svg style={{cursor:'pointer'}} onClick={()=>{
            setshow(false)
        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </Modal.Header>
      <Modal.Body>
       <div className='row m-0 justify-content-center'>
        <p className='text-center m-0 fw-medium mb-2 fs-5'>Update the Name</p>
        <div className='col-12'>
            <input type="text" className='form-control shadow-none border-secondary-subtle py-3' value={activity_name} onChange={(e)=>{
                setactivity_name(e.target.value)
            }}/>
        </div>
        <div className='text-end mt-3'>
            <button className='btn btn-primary px-4' onClick={()=>{
            update_modules()
            }}>Update</button>
        </div>
       </div>
      </Modal.Body>
     
    </Modal>
            </div>
        </div>

        {/* Add activity modal */}
<div className={`${activitystate ? '':'d-none'}`} style={{backgroundColor:'rgba(0,0,0,0.5)',width:'100%',position:'fixed',height:'100vh',zIndex:6}}>
    <div className="m-0 row justify-content-center align-items-center h-100">
    <div className='bg-white rounded row m-0 p-4 px-4 col-lg-5 animate__animated animate__fadeIn'>

<div className='mb-3 col-md-12'>
    <div className='d-flex justify-content-between align-items-center mb-4'>
    <h5 className='fs-4 text-dark fw-medium text-center m-0'>Add Module Name</h5>
    <svg style={{cursor:'pointer'}} onClick={()=>{
        setactivitystate(false)
    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
    </div>
<input type="text" value={activity_module} onChange={(e)=>{
    setactivity_module(e.target.value)
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="" />
</div>

<div className='text-end mt-2'>
<button className='btn btn-primary px-4 py-2' disabled={activity_module.length>0 ? false:true} onClick={add_activity_module}>
<span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span>
</button>

</div>
</div>
    </div>
</div>
    </div>
  )
}

export default Add_activity_module