import React from 'react'

const Download_svg = () => {
  return (
    <div>
      <svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.9538 5.33301H9.90309C9.09481 5.33301 8.31963 5.6541 7.7481 6.22563C7.17656 6.79717 6.85547 7.57235 6.85547 8.38063V23.6187C6.85547 24.427 7.17656 25.2022 7.7481 25.7737C8.31963 26.3453 9.09481 26.6663 9.90309 26.6663H22.0936C22.9018 26.6663 23.677 26.3453 24.2486 25.7737C24.8201 25.2022 25.1412 24.427 25.1412 23.6187L25.1442 11.4282L19.049 5.33301" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M20.5725 16.1311L16.0011 20.5715M16.0011 20.5715L11.4297 16.1311M16.0011 20.5715V3.80957" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
    </div>
  )
}

export default Download_svg
