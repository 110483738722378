import { useNavigate } from 'react-router-dom';

import actImage from '../../images/5e6d597e-7432-4d37-839f-b3e2c87d8a2e 2.png';

function ActivityCards({ pathName, actName, actNo }) {
  const navigate = useNavigate();
  return (
    <div className="p-2 px-3 col-md-4 col-lg-2">
      <div
        className="py-3 py-md-4 px-2 px-lg-3 shadow-sm emp-cards"
        onClick={() => {
          // fetch_total_lead_data('total_leads');
          navigate(pathName);
        }}
      >
        <img
          src={actImage}
          className="d-block mx-auto"
          style={{ width: '46%' }}
          alt=""
        />
        <p
          className="m-0 text-center mt-2 fw-bold"
          style={{ color: '#202224', fontSize: '13px' }}
        >
          {actName}
        </p>
        <p
          className="fw-medium text-center m-0"
          style={{ fontSize: '14px', color: '#202224' }}
        >
          {actNo}
        </p>
      </div>
    </div>
  );
}

export default ActivityCards;
