import React from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate, useParams } from 'react-router-dom'
import Mode_of_payment_modal from '../invoide_and_receipt/Mode_of_payment_modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify'

const Installments = () => {

    const {id}=useParams()

    const navigate=useNavigate()

    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Check Verified
      </Tooltip>
    );
    const renderTooltip2 = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Check Not Verified
      </Tooltip>
    );
    const renderTooltip3 = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Send Receipt
      </Tooltip>
    );

    const[due_date,setdue_date]=useState()
    const[amount,setamount]=useState()

    const[loading1,setloading1]=useState()
    const user=JSON.parse(sessionStorage.getItem('user'))
    const[invoidedata,setinvoicedata]=useState([])
  const[current_invoidedata,setcurrent_invoicedata]=useState([])
  const[installmentdata,setinstallmentdata]=useState([])
  useEffect(()=>{

    axios.get(`${ipaddress2}/DisplayStudentInvoices/${user.id}/`)
    .then((r)=>{
      console.log("invoice data",r.data)
      setinvoicedata(r.data)
    })
    .catch((err)=>{
      console.log("Invoice Error",err)
    })

  },[])

  const fetchobjectdata=(id)=>{
    axios.get(`${ipaddress2}/ShowInvoice/${id}/`)
    .then((r)=>{
      console.log("particular invoice data",r.data)
      setcurrent_invoicedata(r.data)
    })
    .catch((err)=>{
      console.log("particular Invoice Error",err)
    })
  }

  const[payment_url,setpayment_url]=useState()

const make_payment=(due_date,amount)=>{
    const url=`http://localhost:3000/installments`
    // ?url=${url}
    axios.get(`${ipaddress2}/Payment/${amount}/`
        // ?invoice_id=${current_invoidedata.invoice_number}&due_date=${due_date}&url=${url}`
    //     ,{
    //     invoice_id:current_invoidedata.invoice_number,
    //     due_date:due_date,
    //     url:url,
    // }
)
    .then((r)=>{
        console.log("Payment details sent",r.data)
        setpayment_url(r.data.redirect_url)
    })
    .catch((err)=>{
        console.log(err)
    })

}

const[url,seturl]=useState('')

const payment=(amount,invoice_number)=>{
  axios.get(`${ipaddress2}/Payment/${amount}/${invoice_number}/${due_date}/`)
  .then((r)=>{
    console.log("API Triggered",r.data)
    seturl(r.data.redirect_url)
  })
  .catch((err)=>{
    console.log(err)
  })
}

return (
    <div className='d-flex'>
        <Sidebar activevalue={"fees"}/>

        <div className="w-100">
            <Navbar/>

            <div className="main-container p-3">


            <div className={`table-responsive rounded bg-white border-0 p-3`} style={{minHeight:'100vh'}}>
                {/* <h5 className='text-primary'>Installments</h5> */}
<table class={`w-100 caption-top rounded`}>
  <thead className='invoice-table'>
    <tr style={{backgroundColor:'#034592'}}>
      <th scope="col" className='py-3'><p className='fw-normal text-white m-0 ps-2'>SI No</p></th>
      <th scope="col" className=''><p className='fw-normal text-white m-0'>Invoice Number</p></th>
      <th scope="col" className=''><p className='fw-normal text-white m-0'>Grand Total</p></th>
      <th scope="col" className=''><p className='fw-normal text-white m-0'>Created on</p></th>
      <th></th>
      <th></th>
    </tr>
  </thead>
  <tbody>
   {invoidedata.map((x,index)=>{
    return(
      <tr className='table-row'>
        <td className='py-3' scope="row"><p className=' m-0 ps-2'>{index+1}</p></td>
        <td className='py-3' scope="row"><p className=' m-0'>{x.invoice_number}</p></td>
        <td className='py-3' scope="row"><p className=' m-0'>{x.grand_total}</p></td>
        <td className='py-3' scope="row"><p className=' m-0'>{x.created_at && x.created_at.slice(0,10)}</p></td>
        {/* <td><button className='btn btn-sm btn-primary px-3' onClick={()=>{
          navigate(`/invoice/${x.invoice_number}`)
        }}>View Invoice</button></td> */}
        <td>
          <button className='btns text-white py-2 px-3' onClick={()=>{
            navigate(`/installments_list/${x.invoice_number}`)
          }}>Make Payment</button>
        </td>
      </tr>
    )
   })}
  </tbody>
</table>
</div>
            </div>
        </div>

        {/* <div class="modal fade" id="payModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header d-flex align-items-center justify-content-between">
        <h1 class="modal-title fs-6" id="exampleModalLabel">Pay through UPI</h1>
        <svg style={{cursor:'pointer'}} data-bs-dismiss="modal" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
      </div>
      <div class="modal-body">
        <p><span className='text-primary'>Amount Need to Pay : </span> <span className='ms-2 fw-medium'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-currency-rupee" viewBox="0 0 16 16">
  <path d="M4 3.06h2.726c1.22 0 2.12.575 2.325 1.724H4v1.051h5.051C8.855 7.001 8 7.558 6.788 7.558H4v1.317L8.437 14h2.11L6.095 8.884h.855c2.316-.018 3.465-1.476 3.688-3.049H12V4.784h-1.345c-.08-.778-.357-1.335-.793-1.732H12V2H4z"/>
</svg>{amount}</span></p>
<div className='text-end'>
    <a className='btn btn-sm btn-primary px-3' href={payment_url}>Pay Now</a>
</div>
      </div>
    </div>
  </div>
</div> */}
</div>
  )
}

export default Installments