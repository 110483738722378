import axios from 'axios';
import React from 'react';
import { useState, useEffect } from 'react';
import { Zoom } from 'react-toastify';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import { ipaddress } from '../../App';
import { useNavigate } from 'react-router-dom';

const Walkinform = ({ show, setShow, form_value, type }) => {
  console.log('Object', form_value);

  const user = JSON.parse(sessionStorage.getItem('user'));

  const [availablecourses, setAvailablecourses] = useState([]);
  const [classtypes, setclasstypes] = useState([]);
  const [batchtypes, setbatchtypes] = useState([]);
  const [particular_lead_details, setparticular_lead_details] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress}/Allclasstypes/`).then(r => {
      console.log('Class types', r.data);
      setclasstypes(r.data.classtypes);
    });

    axios.get(`${ipaddress}/Allbatchtypes/`).then(r => {
      console.log('Batch types', r.data);
      setbatchtypes(r.data.batchtypes);
    });
  }, []);

  // To get current date
  const getCurrentDate = () => {
    const date = new Date();
    return date.toISOString().split('T')[0];
  };

  const handleDateValidation3 = (date, name) => {
    const today = new Date();
    const selectedDate = new Date(date);

    // if (selectedDate < today.setHours(0, 0, 0, 0)) {
    //   alert('You can only select today’s date or future dates.');
    //   return false;
    // }

    setwalkindata({
      ...walkindata,
      [name]: date,
    });

    return true;
  };

  const [walkindata, setwalkindata] = useState({
    customer_lead_id: '',
    client_lead_id: '',
    emp_id: user.emp_id,
    level_lead: 'walkin',
    expected_walkin_date: '',
    expected_walkin_time: '',
    Remarks: '',
    priority: '',
    direct_walkin: false,
  });

  const handlecwalkinChange = e => {
    const { name, value } = e.target;
    setwalkindata({
      ...walkindata,
      [name]: value,
    });
  };

  const handleDirectWalkinChange = e => {
    const { name, checked } = e.target;
    setwalkindata({
      ...walkindata,
      [name]: checked,
    });
  };

  const navigate = useNavigate();
  const [load, setload] = useState(false);

  const move_to_walkin = e => {
    e.preventDefault();
    setload(true);

    const data = { ...walkindata, ...form_value };

    data.emp_id = user.emp_id;

    let apiurl = '';

    if (type === 'customer') {
      apiurl = 'CustomerLeadsView';
    }
    if (type === 'client') {
      apiurl = 'ClientLeadsView';
    }

    axios
      .post(`${ipaddress}/${apiurl}/`, data)
      .then(r => {
        toast.success('Moved to Walkin', {
          autoClose: 1000,
          theme: 'colored',
          position: 'top-center',
        });
        setload(false);
        setShow(false);
        setwalkindata({
          customer_lead_id: '',
          client_lead_id: '',
          emp_id: user.emp_id,
          level_lead: 'walkin',
          expected_walkin_date: '',
          expected_walkin_time: '',
          created_at: '',
          Remarks: '',
          priority: '',
        });

        setTimeout(() => {
          if (type === 'customer') {
            navigate('/customerleads');
          }
          if (type === 'client') {
            navigate('/leads');
          }
        }, 2000);
      })
      .catch(err => {
        setload(false);
        console.log('Moved to walkin error', err);
        console.log('Moved to walkin form', walkindata);
      });
  };

  return (
    <div>
      {/* ------------------------------------FOLLOWUP FORM----------------------------------------------------------- */}

      <Modal
        size="md"
        aria-labelledby="example-modal-sizes-title-md"
        show={show}
        onHide={() => {
          setShow(false);
        }}
      >
        <Modal.Body>
          <div class="modal-body">
            <div className="d-flex justify-content-between px-3 mb-4 align-items-center">
              <h5 className="text-primary">Walkin Form</h5>
              <button
                onClick={() => {
                  setShow(false);
                }}
                className="bg-transparent border-0 ms-auto"
              >
                <i class="fa-solid fa-circle-xmark fs-5"></i>
              </button>
            </div>
            <form
              action=""
              onSubmit={move_to_walkin}
            >
              <div className="row m-0">
                <div className="col-md-6">
                  <div class={`form-floating mb-3`}>
                    <input
                      type="datetime-local"
                      class="form-control shadow-none"
                      id="floatingInput"
                      placeholder="name@example.com"
                      name="expected_walkin_date"
                      value={walkindata.expected_walkin_date}
                      onChange={e => {
                        handleDateValidation3(
                          e.target.value,
                          'expected_walkin_date',
                        );
                      }}
                    />
                    <label for="floatingInput">
                      Expected Walking Date{' '}
                      <span className="required-field">*</span>
                    </label>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <label
                    for="inputState"
                    className="form-label shadow-none"
                  >
                    Priority
                  </label>
                  <select
                    id="inputState"
                    className="form-select shadow-none bg-light py-3"
                    name="priority"
                    value={walkindata.priority}
                    onChange={handlecwalkinChange}
                  >
                    <option selected>Choose...</option>
                    <option>High</option>
                    <option>Medium</option>
                    <option>Low</option>
                  </select>
                </div>

                <div className="col-12">
                  <div class="mb-3">
                    <textarea
                      required
                      className="p-2 rounded border-secondary"
                      id=""
                      placeholder="Remarks..."
                      rows="3"
                      style={{ width: '100%' }}
                      name="Remarks"
                      value={walkindata.Remarks}
                      onChange={handlecwalkinChange}
                    ></textarea>
                  </div>
                </div>

                <div className=" flex  tw-items-center tw-justify-center ">
                  <label htmlFor="directWalkin">Direct Walk-In</label>
                  <input
                    type="checkbox"
                    className="tw-text-center tw-ml-4"
                    id="direct_walkin"
                    name="direct_walkin"
                    value={walkindata.direct_walkin}
                    onChange={e => {
                      handleDirectWalkinChange(e);
                    }}
                  />
                </div>

                <div className="col-12 text-end mt-4">
                  <button
                    type="submit"
                    disabled={load}
                    className="btn btn-sm px-5 py-2 btn-primary text-white fw-medium"
                  >
                    {load ? 'Submitting...' : 'Submit'}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Walkinform;
