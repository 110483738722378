import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ipaddress, role } from '../App'
import axios from 'axios'
import { Tooltip } from 'react-bootstrap'

const Sidebar = ({activevalue}) => {
  
  const user=JSON.parse(sessionStorage.getItem('user'))
  const designation=user.designation
  const[open,setOpen]=useState(true)
  const[value,setValue]=useState("")
  useEffect(()=>{
    setValue(activevalue)
  },[])

  // To view the assigned permissions

const[permissions,setpermissions]=useState({})

useEffect(()=>{
  axios.get(`${ipaddress}/PermissionsView/${user.emp_id}/`)
  .then((r)=>{
    console.log("Permissions",r.data)
    setpermissions(r.data)
  })
},[])

  // Logout

  const navigate=useNavigate()

  const handleLogout=()=>{
    axios.post(`${ipaddress}/User_Logout/${user.emp_id}/`)
    .then((r)=>{
      sessionStorage.removeItem("user")
      navigate('/')
    })
    .catch((err)=>{
      console.log("Logout Error",err)
    })
  }

  const renderTooltip4 = (value) => (
    <Tooltip id="button-tooltip">
      {value}
    </Tooltip>
  );
  
  return (
    <div style={{width:'80px',backgroundColor:'#002147',height:'100vh',overflowY:'scroll'}} className='shadow-sm sidebar d-none d-lg-block'>
            {/* <div className='px-2 navbar-link justify-content-center' style={{position:'relative',height:'70px'}}>
                <img className={open ? 'd-inline' : 'd-none'} src={require('../images/Logo_Blue 1.png')} style={{width:'86%'}} alt="" />
            </div> */}


<ul class={`nav flex-column ${open ? 'w-100' : ''} mx-auto mt-2`}>
  
  <li class={`nav-item py-2  w-100 ${value==="dashboard" ? 'sidebar-valid2':'sidebar-valid'}`}  >
    <Link to='/dashboard' class={`nav-link navbar-link ${value==="dashboard"  ? 'text-white' : 'text-white'}`} href="#">
   <img src={require('../images/ImporvisedUI/stash_dashboard-light.png')} alt="" />
   <span className='navbar-link-text'>Dashboard </span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${designation==='admin' ? '':'d-none'} ${value==="details" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/add_details' class={`nav-link navbar-link ${value==="details" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/formkit_add.png')} alt="" />
  <span className='navbar-link-text'>Add Details</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${designation!=='admin' && permissions.add_events ? '':'d-none'} ${value==="add_batch" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/add_batch' class={`nav-link navbar-link ${value==="add_batch" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/formkit_add.png')} alt="" />
  <span className='navbar-link-text'>Add Batch</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100  ${open ? '' : 'd-flex justify-content-center'} ${designation!=='admin' && permissions.add_events ? '':'d-none'} ${value==="events" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/add_events' class={`nav-link navbar-link ${value==="events" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/formkit_add.png')} alt="" />
  <span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Add Events</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100  ${open ? '' : 'd-flex justify-content-center'} ${designation!=='admin' && permissions.add_activity_module ? '':'d-none'} ${value==="module" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/add_activity_module' class={`nav-link navbar-link ${value==="module" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/formkit_add.png')} alt="" />
  <span className={`navbar-link-text`}>Add Activity Module</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100  ${open ? '' : 'd-flex justify-content-center'} ${user.designation!=='admin' && permissions.assign_students ? '':'d-none'} ${value==="students" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/assign_students' class={`nav-link navbar-link ${value==="students" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-up" viewBox="0 0 16 16">
  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.354-5.854 1.5 1.5a.5.5 0 0 1-.708.708L13 11.707V14.5a.5.5 0 0 1-1 0v-2.793l-.646.647a.5.5 0 0 1-.708-.708l1.5-1.5a.5.5 0 0 1 .708 0M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
  <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
</svg><span className={`navbar-link-text`}>Assign Students</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100  ${open ? '' : 'd-flex justify-content-center'} ${designation!=='admin' && permissions.create_projects ? '':'d-none'} ${value==="projects" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/add_projects' class={`nav-link navbar-link ${value==="projects" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/formkit_add.png')} alt="" />
  <span className={`navbar-link-text`}>Create Projects</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${designation==='admin' || designation==='center manager' ? '':'d-none'} ${value==="batches" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/all_batches' class={`nav-link navbar-link ${value==="batches" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/ic_round-list.png')} alt="" />
  <span className={`navbar-link-text`}>All Batches</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${designation!=='admin' && designation!=='center manager' ? '':'d-none'} ${value==="upcoming_batches" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/upcoming_batches' class={`nav-link navbar-link ${value==="upcoming_batches" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/ic_round-list.png')} alt="" />
  <span className={`navbar-link-text`}>All Batches</span> </Link>
  </li>


  <li class={`nav-item py-2 w-100  ${value==="activities" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/activities' class={`nav-link navbar-link ${value==="activities" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/iconoir_activity.png')} alt="" />
  <span className={`navbar-link-text`}>Activity</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${value==="leads" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/leads' class={`nav-link navbar-link ${value==="leads" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/b2b.png')} alt="" />
  <span className={`navbar-link-text`}>B2B Leads</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${value==="customerleads" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/customerleads' class={`nav-link navbar-link ${value==="customerleads" ? 'text-white' : 'text-white'}`} href="#">  
  <img src={require('../images/ImporvisedUI/employee.png')} alt="" />
  <span className={`navbar-link-text`}>B2C Leads</span> </Link>
  </li>

    <li class={`nav-item py-2 w-100 ${user.designation==='admin' ? '':'d-none'} ${value==="allb2cleads" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/all_leads' class={`nav-link navbar-link ${value==="allb2cleads" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/mdi_people.png')} alt="" />
  <span className={`navbar-link-text`}>All B2C Leads</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${user.designation==='admin' ? '':'d-none'} ${value==="allb2bleads" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/all_client_leads' class={`nav-link navbar-link ${value==="allb2bleads" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/leader.png')} alt="" />
  <span className={`navbar-link-text`}>All B2B Leads</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${designation===role || designation==="admin" || permissions.assign_leads ? 'd-inline' : 'd-none'} ${value==="assignedleads" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/assigned_leads' class={`nav-link navbar-link ${value==="assignedleads" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/mdi_teacher.png')} alt="" />
  <span className={`navbar-link-text`}>Assigned Leads</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${designation==="admin" ? 'd-inline' : 'd-none'} ${value==="approvals" ? 'sidebar-valid2':'sidebar-valid'}`}  >
  <Link to='/approvals' class={`nav-link navbar-link ${value==="approvals" ? 'text-white' : 'text-white'}`}>
  <img src={require('../images/ImporvisedUI/fluent_approvals-app-20-filled.png')} alt="" />
  <span className={`navbar-link-text`}>Approvals</span></Link>
  </li>


  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${designation===role ? 'd-inline' : 'd-none'} ${value==="employees" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/employees' class={`nav-link navbar-link ${value==="employees" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>Team</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${designation==="admin" ? 'd-inline' : 'd-none'} ${value==="trainers" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/trainers' class={`nav-link navbar-link ${value==="trainers" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/clarity_employee-line.png')} alt="" />
  <span className={`navbar-link-text`}>View Trainers</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${designation==="admin" ? 'd-inline' : 'd-none'} ${value==="employees" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/view_employees' class={`nav-link navbar-link ${value==="employees" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/clarity_employee-line.png')} alt="" />
  <span className={`navbar-link-text`}>View Employees</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${open ? '' : 'd-flex justify-content-center'} ${permissions.view_all_employees && designation!=="admin" ? 'd-inline' : 'd-none'} ${value==="all_employees" ? 'sidebar-valid2':'sidebar-valid'}`} >
  <Link to='/view_all_employees' class={`nav-link navbar-link ${value==="all_employees" ? 'text-white' : 'text-white'}`} href="#"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person" viewBox="0 0 16 16">
  <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"/>
</svg><span className={`ms-3 ${open ? 'd-inline' : 'd-none'}`}>View Employees</span></Link>
  </li>

  <li class={`nav-item py-2 w-100 ${value==="students_list" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/students_list' class={`nav-link navbar-link ${value==="students_list" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/icons8_student.png')} alt="" />
  <span className={`navbar-link-text`}>All Students</span> </Link>
  </li>

  <li class={`nav-item py-2 w-100 ${value==="request" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <Link to='/payments' class={`nav-link navbar-link ${value==="request" ? 'text-white' : 'text-white'}`} href="#">
  <img src={require('../images/ImporvisedUI/ic_baseline-paypal.png')} alt="" />
  <span className={`navbar-link-text`}>Payments</span> </Link>
  </li>

  <li class={`nav-item py-2 mb-3 w-100 ${value==="das" ? 'sidebar-valid2':'sidebar-valid'}`}>
  <a class={`nav-link navbar-link ${value==="das" ? 'text-white' : 'text-white'}`} target='__blank' href="https://das.meridahr.com/">
  <img src={require('../images/ImporvisedUI/clarity_list-line.png')} alt="" />
  <span className={`navbar-link-text`}>DAS</span> </a>
  </li>

</ul>


    </div>
  )
}

export default Sidebar