import React, { useState } from 'react'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import axios from 'axios'
import { useEffect } from 'react'
import { ipaddress } from '../App'
import { useNavigate } from 'react-router-dom'
import Preloader from './Preloader'

const Employess = ({designation}) => {
  const[employees,setemployees]=useState([])

  const[load,setload]=useState()

  const navigate=useNavigate()
  const user=JSON.parse(sessionStorage.getItem('user'))

  const fetchemployees=()=>{
    setload(true)
    axios.get(`${ipaddress}/SupervisorView/${user.emp_id}/`)
    .then((r)=>{
      console.log("Employees data",r.data)
      setemployees(r.data)
      setload(false)
    })
    .catch((err)=>{
      console.log(err)
    })
  }

  useEffect(()=>{
    fetchemployees()
  },[])

  return (
    <div>
      {load ? (<Preloader/>):(
        <div className='d-flex'>
    <Sidebar designation={designation} activevalue={"employees"}/>
    <div className='w-100 main-div'>
        <Navbar designation={designation} pagename={"Employees"}/>
        <div className="bg-light py-3 pt-4 main-container" style={{minHeight:'100vh'}}>
          <div className='container row m-0 d-flex justify-content-between'>
            <div className="col-md-8">
            <h5 className=''>Your Team</h5>
            </div>
          <div className='col-md-4 d-flex align-items-center justify-content-end' style={{}}>
            <span className='border-end border-secondary-subtle pe-4'>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg>
            </span>
            <span className='ps-4'>
              {/* <img src={require('../images/filter.png')} alt="" /> */}
            </span>
          </div>
          </div>

          <h6 className={`text-secondary text-center py-5 ${employees.length>0 ? 'd-none':''}`}>No Employees assigned under you...🧐</h6>
          <div className="container justify-content-center d-flex gap-4 pt-4 row m-0 mx-auto">
          {employees.map((x)=>{
            return(
              <div className={`bg-white d-flex flex-column col-md-4 col-lg-5 align-items-center py-4 shadow-sm ${employees.length>0 ? '':'d-none'}`} onClick={()=>{
                navigate(`/employee_profile/${x.emp_id}`)
              }} style={{cursor:'pointer',width:'250px',borderRadius:'14px'}}>
              <div className='pb-3'>
                {/* <img src={require('../images/skill_learning_logo-04-removebg-preview.png')} width={100} alt="" /> */}
              </div>
              <div className='d-flex flex-column align-items-center'>
                <img className='rounded-circle' src={x.image} width={100} height={100} alt="" />
                <p className='m-0 fw-medium mt-3' style={{color:'#000'}}>{x.firstname}</p>
                <span className='text-secondary mt-1' style={{fontSize:'12px'}}>{x.designation}</span>
                <span className='text-secondary mt-2' style={{fontSize:'12px'}}>{x.email}</span>
              </div>
            </div>
            )
          })}
          </div>
        </div>
    </div>
</div>
      )}
    </div>
  )
}

export default Employess