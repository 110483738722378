import React, { useEffect } from 'react'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'
import { useState } from 'react'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useParams } from 'react-router-dom'

const Log_sheet = () => {

  const {id}=useParams()
  const {batch_id}=useParams()

  const currentDate = new Date();

  // Get the current day of the month
  const currentDay = currentDate.getDate();

  // Get the number of days in the current month
  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

  // Generate an array of days in the month
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1);

  // Format the date for display in the table headers
// Format the date for display in the table headers
const formatDate = (day) => {
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Month is zero-based
  const paddedMonth = month < 10 ? `0${month}` : month;
  const paddedDay = day < 10 ? `0${day}` : day;
  return `${year}-${paddedMonth}-${paddedDay}`;
};

const students = ['Student1', 'Student2', 'Student3', 'Student4', 'Student5'];

const[syllabus,setsyllabus]=useState([])
// const[students,setstudents]=useState([])


useEffect(()=>{
  axios.get(`${ipaddress2}/SendSyllabusForCourse/by-course/${id}/`)
  .then((r)=>{
    console.log("Log sheet details",r.data)
    setsyllabus(r.data.syllabus)
  })

  axios.get(`${ipaddress2}/StudentsFromParticularBatch/${batch_id}/`)
  .then((r)=>{
    console.log("log sheet students details",r.data)
    // setstudents(r.data)
  })
},[])

const [attendance, setAttendance] = useState({});

  // Handle attendance change
  const handleAttendanceChange = (day, student, event) => {
    const value = event.target.value;
    setAttendance(prevAttendance => ({
      ...prevAttendance,
      [day]: {
        ...prevAttendance[day],
        [student]: value
      }
    }));
  };

return (
    <div className='d-flex'>
        <Sidebar activevalue={"logsheet"}/>

        <div className='w-100'>
            <Navbar/>
        
        <div className='bg-light pt-4 px-2 px-md-4 main-container' style={{minHeight:'100vh'}}>
          <div className='row m-0 bg-white shadow-sm py-3 rounded justify-content-evenly'>
            <div className="col-md-6 col-lg-3 fw-medium ps-0">Subject Name : <span></span></div>
            <div className="col-md-6 col-lg-3 fw-medium ps-0">Trainer Name : <span></span></div>
            <div className="col-md-6 col-lg-4 fw-medium ps-0">Time : </div>
          </div>

{/* Log Sheet */}
{/* <div class="table-responsive mt-4">  
<h6 className='text-primary'>Log Sheet</h6>                  
<table class="table">
  <thead>
    <tr>
      <th scope="col" className='py-3'>SI.No</th>
      <th scope="col" className='py-3'>Syllabus Covered</th>
      {students.map(student => (
                    <th scope="col" className='py-3'>{student.user.customer_name}</th>
                  ))}
    </tr>
  </thead>
  <tbody>

                   {syllabus.map((x,index)=>{
                    return(
                      <tr>
                        <td>{index+1}</td>
                      <td className='py-3'>{x.topic_name}</td>
                      {students.map(student => (
                        <td className='py-3'>
                          
                          <select
                            
                            onChange={(event) => handleAttendanceChange(student.user.id, event)}
                          >
                            <option value="">Select</option>
                            <option value="Present">Present</option>
                            <option value="Absent">Absent</option>
                          </select>
                        </td>
                      ))}
                    </tr>
                    )
                   })} 
 
  </tbody>
</table>
</div> */}


{/* Attendance Sheet */}
<div class="table-responsive mt-4">  
<h6 className='text-primary'>Log Sheet</h6>                  
<table class="table">
  <thead>
    <tr>
      <th scope="col" className='py-3'>Date</th>
      <th scope="col" className='py-3'>Syllabus Covered</th>
      {students.map(student => (
                    <th key={student} scope="col" className='py-3'>{student}</th>
                  ))}
    </tr>
  </thead>
  <tbody>
  {daysArray.map((day) => {
                  const isToday = day === currentDay;
                  return (
                    <tr className={`${isToday ? 'table-info':''}`} key={day}>
                      <td className='py-3'>{formatDate(day)}</td>
                      <td>Introduction</td>
                      {students.map(student => (
                        <td className='py-3' key={`${day}-${student}`}>
                          
                          <span className={`${isToday ? 'd-none':''}`}>Present</span>
                          
                          <select className={`${isToday ? 'form-select shadow-none border-secondary-subtle':'d-none'}`}
                            value={attendance[day]?.[student] || ''}
                            onChange={(event) => handleAttendanceChange(day, student, event)}
                          >
                            <option value="">Select</option>
                            <option value="Present">Present</option>
                            <option value="Absent">Absent</option>
                          </select>
                        </td>
                      ))}
                    </tr>
                  );
                })}
  </tbody>
</table>
</div>
        </div>
        </div>
    </div>
  )
}

export default Log_sheet