import React, { useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';

const Add_projects = () => {

    const[load,setload]=useState()
    const user=JSON.parse(sessionStorage.getItem('user'))

    const[categorystate,setcategorystate]=useState(false)
    const[categoryform1state,setcategoryform1state]=useState(false)
    const[file,setfile]=useState(null)
    const[categories,setcategories]=useState([])
    const[languages,setlanguages]=useState([])
    const[selectedlanguages,setselectedlanguages]=useState([])
    const[selectedlanguages_name,setselectedlanguages_name]=useState([])
    
    const[category,setcategory]=useState()
    const[category_name,setcategory_name]=useState('')
    
    const[project_description,setproject_description]=useState('')
    const[project_name,setproject_name]=useState('')
    const[difficulty,setdifficulty]=useState('')
    const[project_type,setproject_type]=useState('')
    const[project_cost,setproject_cost]=useState()
    
    const fetch_categories=()=>{
        axios.get(`${ipaddress2}/CategoryChoicesView/`)
        .then((r)=>{
            console.log("Categories",r.data)
            setcategories(r.data)
        })
    }
    
    const fetch_languages=(id)=>{
    axios.get(`${ipaddress2}/DisplaySubCourseBasedOnCategory/${id}/`)
    .then((r)=>{
        console.log("Languages",r.data)
        if(r.data.length>0){
            setlanguages(r.data)
        }
        else{
            setlanguages([])
            setselectedlanguages([])
            setselectedlanguages_name([])
        }
    })
    }
    
    const add_project=()=>{
        setload(true)
        const formdata=new FormData()
        formdata.append('project_name',project_name)
        formdata.append('difficulty_level',difficulty)
        formdata.append('category',category)
        formdata.append('description',project_description)
        formdata.append('document',file)
        formdata.append('document',file)
        formdata.append('languages',selectedlanguages)
        if(project_type==='premium'){
            formdata.append('premium_status',true)
        }
        if(project_type==='free'){
            formdata.append('premium_status',false)
        }
    
        formdata.append('cost',Number(project_cost))
    
        axios.post(`${ipaddress}/AddProjectsForStudents/${user.email}/`,formdata)
        .then((r)=>{
            console.log("Project added",r.data)
            setfile(null)
            setproject_description('')
            setproject_name('')
            setcategory('')
            setdifficulty('')
            setproject_cost('')
            setproject_type('')
            setselectedlanguages([])
            setselectedlanguages_name([])
            toast.success('Project added successfully',{
                autoClose:1000,
                theme:'colored',
                position:'top-center'
            })
            setload(false)
        })
        .catch((err)=>{
            console.log(err)
            setload(false)
            console.log(selectedlanguages)
    
        })
    
    }
    
    const[category_img,setcategory_img]=useState(null)
    const[cat_description,setcat_description]=useState('')
    const[cat_title,setcat_title]=useState('')
    const[created_category,setcreated_category]=useState({})
    
    const add_category=async ()=>{
        setload(true)
      
        const form=new FormData()
        form.append('category_name',category_name)
        form.append('description',cat_description)
        form.append('title',cat_title)
        form.append('image',category_img)
    
        axios.post(`${ipaddress2}/AddCategory/`,form)
        .then((r)=>{
            console.log(r.data)
            setcreated_category(r.data)
            if(r.data.detail==='Category already exists'){
                toast.warn('Category already exists',{
                    autoClose:2000,
                    theme:'colored',
                position:'top-center'
                })
                setload(false)
            }
            else{
                toast.success('Category added successfully',{
                    autoClose:1000
                })
                fetch_categories(   )
                setcat_description('')
                setcat_title('')
                setcategory_img(null)
                setcategory_name('')
                setcategoryform1state(true)
                setload(false)
            }
        })
        .catch((err)=>{
            setload(false)
            console.log(err)
            console.log(sublanguages)
        })
    }
    
    
    const[sublanguages,setsublanguages]=useState([{
        'sub_category_name':'',
        'logo':null
    }])
    
    const handleAddLanguage = () => {
        setsublanguages([...sublanguages, { sub_category_name: '', logo: null }]);
    };
    
    const handleLanguageChange = (index, field, value) => {
        const newSublanguages = [...sublanguages];
        newSublanguages[index][field] = value;
        setsublanguages(newSublanguages);
    };
    
    // Add Sublanguages
    const add_sublanguages=async ()=>{
        setload(true)
        const formData = new FormData();
            sublanguages.forEach((flashcard, index) => {
              formData.append(`flashcards[${index}].logo`, flashcard.logo);
              formData.append(`flashcards[${index}].sub_category_name`, flashcard.sub_category_name);
            });
        
            const response = await axios.post(`${ipaddress2}/Add_Sub_Category/${created_category.id}/`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data'
              }
            })
    
            if(response){
                console.log(response.data)
                setload(false)
                setsublanguages([{
                    sub_category_name:'',
                    logo:null
                }])
                toast.success('Sub languages successfully added',{
                    autoClose:1000,
                    theme:'colored',
                position:'top-center'
                })
                setcategorystate(false)
            }
    }

return (
    <div className='d-flex'>
        <Sidebar activevalue={"events"}/>
        <div className="w-100">
            <Navbar/>

            <div className="bg-light p-4 main-container">
            <div className='row m-0'>
    <div className="col-md-12 px-0 px-md-5 py-2">
        <div className='d-flex justify-content-between align-items-center pb-3'>
        <h6 className='fs-5 text-dark m-0'>Add New Project</h6>
        <button className='btn btn-sm btn-primary px-3' onClick={()=>{
            setcategorystate(true)
        }}>Add Category</button>
        </div>
            <div className='bg-white rounded row m-0 p-4 px-5'>
                <div className='mb-3 col-md-6'>
                <label htmlFor="">Project Name</label>
                <input type="text" value={project_name} onChange={(e)=>{
                    setproject_name(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 bg-light mt-2' />
                </div>

                <div className='mb-3 col-md-6'>
                <label htmlFor="">Category</label>
                <select type="date" value={category} onChange={(e)=>{
                    setcategory(e.target.value)
                    fetch_languages(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-light'>
                      <option value="">Select Category Name</option>
                    {categories.map((x)=>{
                        return(
                            <>
                            <option value={x.id}>{x.category_name}</option>
                            </>
                        )
                    })}
                    </select>
                </div>

                <div className={`col-12 mb-4 mt-3 d-flex align-items-center ${languages.length>0 ? '':'d-none'}`}>
                <h6 className='me-3 text-primary'>Available Languages : </h6>
                    {languages.map((x)=>{
                        return(
                            <>
                            <span style={{cursor:'pointer'}} onClick={()=>{
                                setselectedlanguages([...selectedlanguages,Number(x.id)])
                                setselectedlanguages_name([...selectedlanguages_name,{id:Number(x.id), name:x.subcategoryname}])
                            }} className='me-3 border rounded px-3 py-1'>{x.subcategoryname}</span>
                            </>
                        )
                    })}
                </div>

                <div className={`col-12 mb-4 mt-3 d-flex align-items-center ${selectedlanguages_name.length>0 ? '':'d-none'}`}>
                    <h6 className='me-3 text-primary'>Selected Languages : </h6>
                    {selectedlanguages_name.map((x)=>{
                        return(
                            <>
                            <span className='border py-1  px-2 rounded me-3 d-flex justify-content-between align-items-center'>
                            <span onClick={()=>{
                                setselectedlanguages([...selectedlanguages,Number(x.id)])
                            }} className='me-2'>{x.name}</span>
                            <svg style={{cursor:'pointer'}} onClick={() => {
                    setselectedlanguages_name(prev => prev.filter(item => item.name !== x.name))
                    setselectedlanguages(prev => prev.filter(item => item !== x.id))
                }} xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
</svg>
                            </span>
                            </>
                        )
                    })}
                </div>

                <div className='mb-3 col-md-6 mt-1'>
                <label htmlFor="">Difficulty Level</label>
                <select value={difficulty} onChange={(e)=>{
                    setdifficulty(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-light'>
                      <option value="">Select Difficulty Level</option>
                    <option value="beginner">Beginner</option>
                    <option value="intermediate">Intermediate</option>
                    <option value="advanced">Advances</option>
                    </select>
                </div>

                <div className='mb-3 col-md-6 mt-1'>
                <label htmlFor="">Description</label>
                <input type="text" value={project_description} onChange={(e)=>{
                    setproject_description(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 bg-light mt-2' />
                </div>

                <div className='mb-3 col-md-6 mt-1'>
                <label htmlFor="">Project Type</label>
                <select value={project_type} onChange={(e)=>{
                    setproject_type(e.target.value)
                }} className='mt-2 form-select shadow-none border-secondary-subtle py-2 bg-light'>
                      <option value="">Select Project Type...</option>
                    <option value="premium">Premium</option>
                    <option value="free">Free</option>
                    </select>
                </div>

                <div className={`mb-3 col-md-6 mt-1 ${project_type==='premium' ? '':'d-none'}`}>
                <label htmlFor="">Cost</label>
                <input type="number" value={project_cost} onChange={(e)=>{
                    setproject_cost(e.target.value)
                }} className='form-control shadow-none border-secondary-subtle py-2 bg-light mt-2' />
                </div>

                <div className='mb-3 mt-3 col-md-6'>
                <label htmlFor="upload" style={{cursor:'pointer'}} className='bg-primary text-white rounded px-4 py-2'>Upload Document</label>
                <input type="file" onChange={(e)=>{
                    setfile(e.target.files[0])
                }} id='upload' className='form-control shadow-none border-secondary-subtle py-2 bg-light mt-2' />
                <p className='text-success mt-2' style={{fontSize:'13px'}}>{file && file.name}</p>
                </div>

                

<div className='text-end mt-3'>
    <button className='btn btn-primary px-4 py-2' onClick={()=>{
       add_project()
    }}>
        <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
    <span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
    <span className={`${load ? 'd-none':''}`}>Submit</span></button>

</div>
            </div>
        </div>
    </div>
            </div>
        </div>

{/* Add category modal*/}
<div className={`${categorystate ? '':'d-none'}`} style={{backgroundColor:'rgba(0,0,0,0.5)',width:'100%',position:'fixed',height:'100vh',zIndex:6}}>
    <div className="m-0 row justify-content-center align-items-center h-100">
    <div className='bg-white rounded row m-0 p-4 px-4 col-lg-8 animate__animated animate__fadeIn'>

<div className='mb-3 col-md-12'>
    <div className={`d-flex justify-content-between align-items-center mb-4 ${categoryform1state ? 'd-none':''}`}>
    <h5 className='fs-4 text-dark fw-medium text-center m-0'>Add New Category</h5>
    <svg style={{cursor:'pointer'}} onClick={()=>{
        setcategorystate(false)
    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
    </div>

    <div className={`d-flex justify-content-between align-items-center mb-4 ${categoryform1state ? '':'d-none'}`}>
    <h5 className='fs-4 text-dark fw-medium text-center m-0'>Add Sub Languages for the Category</h5>
    <svg style={{cursor:'pointer'}} onClick={()=>{
        setcategorystate(false)
    }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-circle-fill" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z"/>
</svg>
    </div>

<div className={`row m-0 pb-4 ${categoryform1state ? 'd-none':''}`}>
    <div className="col-lg-6 ps-0 mb-4">
        <label htmlFor="">Category Name</label>
    <input type="text" value={category_name} onChange={(e)=>{
    setcategory_name(e.target.value)
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="" />
    </div>

    <div className="col-lg-6 pe-0 mb-4">
    <label htmlFor="">Title</label>
    <input type="text" value={cat_title} onChange={(e)=>{
    setcat_title(e.target.value)
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="" />
    </div>

    <div className="col-lg-6 ps-0 mb-4">
    <label htmlFor="">Description</label>
    <input type="text" value={cat_description} onChange={(e)=>{
    setcat_description(e.target.value)
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="" />
    </div>

    <div className="col-lg-6 pe-0 mb-4 d-flex align-items-end">
    <label htmlFor="cat_file" style={{cursor:'pointer'}} className='bg-primary rounded px-4 text-white py-1'>Upload Image</label>
    <input type="file" onChange={(e)=>{
    setcategory_img(e.target.files[0])
}} className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2' name="" id="cat_file" />
<p className='m-0 text-success ms-2 fw-medium'>{category_img && category_img.name}</p>
    </div>

</div>

<div className={`${sublanguages.length>1 ? 'py-4':''} ${categoryform1state ? '':'d-none'}`} style={{height:'60vh',overflowY:sublanguages.length>3 ? 'scroll':'none'}}>
    {sublanguages.map((x, index) => (
                
                    <div className="row m-0" key={index}>
                        <div className="col-lg-6 ps-0 mb-4">
                            <label htmlFor="">Language Name</label>
                            <input
                                type="text"
                                onChange={(e) => handleLanguageChange(index, 'sub_category_name', e.target.value)}
                                className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2'
                                value={x.name}
                            />
                        </div>
                        <div className="col-lg-6 pe-0 mb-4 d-flex align-items-end">
                            <label
                                htmlFor={`cat_file_${index}`}
                                style={{ cursor: 'pointer' }}
                                className='bg-primary rounded px-4 text-white py-1'
                            >
                                Upload Logo
                            </label>
                            <input
                                type="file"
                                onChange={(e) => {
                                    handleLanguageChange(index, 'logo', e.target.files[0]);
                                }}
                                className='form-control shadow-none border-secondary-subtle py-3 bg-light mt-2'
                                id={`cat_file_${index}`}
                                style={{ display: 'none' }}
                            />
                            <p className='m-0 text-success ms-2 fw-medium'>
                                {x.logo && x.logo.name}
                            </p>
                        </div>
                    </div>
            ))}
  <div className='text-end'>
            <button onClick={handleAddLanguage} className='btn btn-primary btn-sm'>Add Language</button>
            </div>
            </div>
</div>

<div className={`text-end ${categoryform1state ? 'd-none':''}`}>
<button className='btn btn-primary px-4 py-2' disabled={category_name.length>0 && cat_title.length>0 && cat_description.length>0 && category_img!=null ? false:true} onClick={add_category}>
<span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span>
</button>

</div>
<div className={`text-end ${categoryform1state ? '':'d-none'}`}>
<button className='btn btn-primary px-4 py-2' disabled={sublanguages.length>1 ? false:true} onClick={add_sublanguages}>
<span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
<span class={`ms-2 ${load ? '':'d-none'}`} role="status">Submitting...</span>
<span className={`${load ? 'd-none':''}`}>Submit</span>
</button>

</div>
</div>
    </div>
</div>
    </div>
  )
}

export default Add_projects