import React, { useEffect, useState } from 'react'
import { ipaddress2 } from '../App'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const Upcoming_batches = () => {
    const user=JSON.parse(sessionStorage.getItem('user'))

    const navigate=useNavigate()

    const[batches,setBatches]=useState([])
    const[original_data,setoriginal_data]=useState({})
    const[count,setcount]=useState(0)
    
    useEffect(()=>{
        axios.get(`${ipaddress2}/upcomingBatchesView/counts/`)
        .then((r)=>{
            console.log("Upcoming batches",r.data)
            setBatches(r.data.results)
            setoriginal_data(r.data)
        })
        .catch((err)=>{
            console.log("Upcoming batch error");
            
        })
    },[count])

    const[api,setapi]=useState('')

return (
    <div>
  <h6 className={`text-center py-3 text-secondary ${batches.length>0 ? 'd-none':''}`}>No Upcomming batches available...🧐</h6>
            <table class={`table shadow-sm ${batches.length>0 ? '':'d-none'}`}>
  <thead className=''>
    <tr>
      {/* <th scope="col" className='fw-normal text-secondary py-2'><p className='text-center m-0 fw-medium'>SI.No</p></th> */}
      <th scope="col" rowSpan={2}  className='border-0 border-end'>
        <div className='d-flex align-items-center justify-content-center' style={{height:'90px'}}>
            <p className='fs-5'>Batch Name</p>
        </div></th>

        {batches[0] && batches[0].employees && batches[0].employees.map((y)=>{
            return(
                <>
                <th scope="col" colSpan={2} className='fw-normal text-secondary' style={{width:'200px'}}><p className='py-2 text-center m-0 fw-medium'>{y.employee_name}</p></th>
                </>
            )
        })}
    </tr>
    <tr>
    {/* <th></th> */}
    {batches[0] && batches[0].employees && batches[0].employees.map((y)=>{
        return(
            <>
                <th scope="col" className='fw-normal text-secondary border-0 px-4 '><p className='py-2 text-center m-0 fw-medium'>Prospect</p></th>
                <th scope="col" className='fw-normal text-secondary border-0 px-4'><p className='py-2 text-center m-0 fw-medium'>Regsitered</p></th>
            
            </>
        )
    })}
    </tr>
  </thead>
  <tbody>
  {batches.map((x,index)=>{
      return(
        <tr className={index%2==0 ? 'table-light':''}>
            <td className='border-0'><p className='text-center m-0 py-2 fw-medium'>{x.batch_name}</p></td>
            {x.employees && x.employees.map((y)=>{
                return(
                    <>
                    <td className='border-0'><p className='text-center m-0 py-2'>{y.levels && y.levels.prospect && y.levels.prospect ? y.levels.prospect : 0}</p></td>
                    <td className='border-0'><p className='text-center m-0 py-2'>{y.levels && y.levels.registered && y.levels.registered ? y.levels.registered : 0}</p></td>
                    </>
                )
            })}
        </tr>
      )
    })}



  </tbody>
</table>

<div className={`d-flex align-items-center justify-content-between ${batches.length>0 ? '':'d-none'}`}>
                <button className={`btn btn-sm px-3 btn-success ${original_data.previous===null ? '':'d-none'}`} disabled='true'>Previous</button>

                <button className={`btn btn-sm px-3 btn-success ${original_data.previous!==null ? '':'d-none'}`} onClick={()=>{
                        setapi(original_data && original_data.previous)
                    }}>Previous</button>
                     <button className={`btn btn-sm px-3 btn-success ${original_data.next!==null ? '':'d-none'}`} onClick={()=>{
                        setapi(original_data && original_data.next)
                    }}>Next</button>
                     <button className={`btn btn-sm px-3 btn-success ${original_data.next===null ? '':'d-none'}`} disabled='true'>Next</button>
                </div>
    </div>
  )
}

export default Upcoming_batches
