import React, { useState } from 'react';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import Employee_new_leads from './Employee_leads/Employee_new_leads';
import Employee_followup_leads from './Employee_leads/Employee_followup_leads';
import Employee_prospect_leads from './Employee_leads/Employee_prospect_leads';
import Employee_closed_leads from './Employee_leads/Employee_closed_leads';
import Employee_registered_leads from './Employee_leads/Employee_registered_leads';
import Employee_all_leads from './Employee_leads/Employee_all_leads';

const Employee_leads = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  return (
    <div className="d-flex">
      <Sidebar activevalue={'employees'} />
      <div className="w-100">
        <Navbar />

        <div
          className="main-container p-3 bg-light"
          style={{ minHeight: '100vh' }}
        >
          <div>
            <Routes>
              {/* <Route path='/*' element={<Employee_all_leads/>}/> */}
              <Route
                path="/:lead_stage"
                element={<Employee_new_leads />}
              />
              {/* <Route path='/followup' element={<Employee_followup_leads/>}/>
                    <Route path='/prospect' element={<Employee_prospect_leads/>}/>
                    <Route path='/registered' element={<Employee_registered_leads/>}/>
                    <Route path='/closed' element={<Employee_closed_leads/>}/> */}
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Employee_leads;
