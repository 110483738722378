import { useEffect, useState } from 'react';
import { ipaddress } from '../../App';
import notification from '../../Audio/Tone.mp3';
import { useNavigate } from 'react-router-dom';

function MessageAlert() {
  const [message, setMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem('user'));

  // const notificationSound = new Audio(notification);
  // const [hasInteracted, setHasInteracted] = useState(true);

  useEffect(() => {
    const emp_user = sessionStorage.getItem('user');
    const emp_id = JSON.parse(emp_user).emp_id;

    // console.log('ADD', ipaddress.split('//')[1]);

    const IPADDRESS = ipaddress.split('//')[1];
    const socket = new WebSocket(
      `wss://${IPADDRESS}/ws/notifications/?emp_id=${emp_id}`,
    );

    socket.onopen = () => {
      console.log('WebSocket connection established!');
    };

    socket.onmessage = e => {
      const data = JSON.parse(e.data);
      if (data) {
        setMessage(data);

        // Play the notification sound only if the user has interacted
        playAudio();
      }
    };

    socket.onerror = e => {
      console.error('WebSocket error:', e);
    };

    socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    return () => {
      socket.close();
    };
  }, []);

  const handleIconClick = () => {
    setIsOpen(prev => !prev);
    // setHasInteracted(prev => !prev); // Set interaction flag to true on click
    navigate(
      `/view_employee_leads/${user.emp_id}/today_followup?start_date=&end_date=`,
    );
  };

  const playAudio = () => {
    let documentId = document.getElementById('Audio');
    documentId
      .play()
      .then(() => {
        console.log('Audio is playing.');
      })
      .catch(error => {
        console.error('Error playing the audio:', error);
      });
  };

  // useEffect(() => {
  //   playAudio();
  // }, []);

  return (
    <div>
      <div
        className={`${message ? 'blinking' : ''} ${
          isOpen ? 'tw-bg-slate-400 tw-border' : 'tw-bg-white'
        } tw-rounded-md tw-px-2`}
        onClick={handleIconClick}
      >
        <audio
          // ref={audioRef}
          src={notification}
          id={'Audio'}
        ></audio>
        <i className="fa-solid fa-envelope-open"></i>
      </div>
      {isOpen && (
        <div className="tw-text-red-400 tw-font-semibold tw-absolute tw-top-16 tw-right-1">
          {message.message || '0'}
        </div>
      )}
    </div>
  );
}

export default MessageAlert;
