import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar1';
import Navbar from './Navbar1';
import axios from 'axios';
import { ipaddress2 } from '../App';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

const Logsheet = () => {
  const { id, batch_id } = useParams();

  const navigate=useNavigate()

  const currentDate = new Date();

  // Get the current day of the month
  const currentDay = currentDate.getDate();

  // Get the number of days in the current month
  const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();

  // Check if a day is a weekend
  const isWeekend = (day) => {
    const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 0 || dayOfWeek === 6; // 0 is Sunday, 6 is Saturday
  };

  // Generate an array of days in the month, excluding weekends
  const daysArray = Array.from({ length: daysInMonth }, (_, i) => i + 1).filter(day => !isWeekend(day));

  // Format the date for display in the table headers
  const formatDate = (day) => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Month is zero-based
    const paddedMonth = month < 10 ? `0${month}` : month;
    const paddedDay = day < 10 ? `0${day}` : day;
    return `${year}-${paddedMonth}-${paddedDay}`;
  };

  const [students, setStudents] = useState([]);
  const [syllabus, setSyllabus] = useState([]);
  const [log_sheet, setlog_sheet] = useState([]);
  const [attendance, setAttendance] = useState({});

  useEffect(() => {
    axios.get(`${ipaddress2}/SendSyllabusForCourse/by-course/${id}/`)
      .then((r) => {
        console.log("Log sheet details", r.data);
        setSyllabus(r.data.syllabus);
      });

    axios.get(`${ipaddress2}/StudentsFromParticularBatch/${batch_id}/`)
      .then((r) => {
        console.log("log sheet students details", r.data);
        setStudents(r.data);
      });

      fetch_logsheet()
  }, [id, batch_id]);

  const user=JSON.parse(sessionStorage.getItem('user'))

  const fetch_logsheet=()=>{
    axios.get(`${ipaddress2}/LogSheetView/${batch_id}/?emp_id=${user.emp_id}`)
    .then((r) => {
      console.log("log sheet attendance details", r.data);
      setlog_sheet(r.data);
    });
  }

  // Handle attendance change
  const handleAttendanceChange = (student, event) => {
    const { value } = event.target;
    setAttendance(prevAttendance => ({
      ...prevAttendance,
      [student]: value
    }));
  };

  const[syllabus_name,setsyllabus_name]=useState("")

  // Handle form submission to send data to backend
  const handleSubmit = () => {
    const attendanceData = Object.entries(attendance).map(([studentId, status]) => ({
      student: studentId,
      attendance_status:status
    }));

    console.log(attendanceData)

    axios.post(`${ipaddress2}/LogSheetView/${batch_id}/`, {
      date: formatDate(currentDay),
      syllabus_covered:syllabus_name,
      attendance: attendanceData
    })
      .then(response => {
        console.log("Attendance submitted successfully:", response.data);
        toast.success('Submitted successfully!',{
          autoClose:1000,
          theme:'colored',
          position:'top-center  '
        })
        fetch_logsheet()
        setAttendance({})
      })
      .catch(error => {
        console.error("Error submitting attendance:", error);
      });
  };

// const user=JSON.parse(sessionStorage.getItem('user'))
//   const download_excel=()=>{
//     axios.get(`${ipaddress2}/download-logsheet/${}`)
//   }


return (
    <div className='d-flex' style={{ position: 'relative' }}>
      <Sidebar activevalue={"logsheet"} />

      <div className='w-100'>
        <Navbar />

        <div className='bg-light main-container pt-4 px-2 px-md-4' style={{ minHeight: '100vh' }}>
          <div className='row m-0 bg-white shadow-sm py-3 rounded justify-content-evenly'>
            <div className="col-md-6 col-lg-3 fw-medium ps-0">Subject Name: <span>
              {/* {students[0].batch && students[0].batch.name && students[0].batch.name.sub_course_name && students[0].batch.name.sub_course_name} */}
              </span></div>
            <div className="col-md-6 col-lg-3 fw-medium ps-0">Trainer Name: <span></span></div>
            <div className="col-md-6 col-lg-4 fw-medium ps-0">Time: </div>
          </div>

          {/* Attendance Sheet */}
          <div className="table-responsive mt-4">
           <div className='d-flex align-items-center justify-content-between mb-3'>
           <h6 className='d-flex align-items-center' style={{color:'#3C3C3C'}}><svg style={{cursor:'pointer'}} onClick={()=>{
              navigate('/batches')
            }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg> <span className='ms-2 fs-5'>Attendance Report</span></h6>

<button className='btn btn-success px-3 btn-sm'>Download</button>

           </div>
            <table className="table">
              <thead className='table-secondary'>
                <tr>
                  <th scope="col" className='py-2 fw-medium text-dark'>Date</th>
                  <th scope="col" className='py-2 fw-medium text-dark'>Syllabus Covered</th>
                  {students.map(student => (
                    <th key={student.user.id} scope="col" className='py-2 fw-medium'>{student.user.customer_name}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {log_sheet.map((x) => {
                  return (
                    <tr>
                      <td className='py-3'>{x.date}</td>
                      <td className='py-3'>{x.syllabus_covered}</td>
                      {x.students.map(student => (
                        <td className='py-3'>
                          <span>{student.attendance_status}</span>
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <svg onClick={()=>{
        navigate(`/attendance/${id}/${batch_id}`)
      }} style={{ position: 'fixed', right: '30px', bottom: '30px', cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="#4F2B8C" className="bi bi-plus-circle-fill" viewBox="0 0 16 16">
        <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z" />
      </svg>

      {/* Modal for attendance */}
      <div className="modal fade" id="attendanceModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between align-items-center">
              <h1 className="modal-title fs-5 text-primary" id="exampleModalLabel">Mark Attendance</h1>
              <svg data-bs-dismiss="modal" style={{ cursor: 'pointer' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293z" />
              </svg>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col" className='py-3'>Date</th>
                      <th scope="col" className='py-3'>Syllabus Covered</th>
                      {students.map(student => (
                        <th key={student.user.id} scope="col" className='py-3'>{student.user.customer_name}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='py-3'>{formatDate(currentDay)}</td>
                      <td className='py-3'>
                        <select name="" onChange={(e)=>{
                            setsyllabus_name(e.target.value)
                        }} id="" className='form-select w-50 shadow-none border-secondary-subtle'>
                            <option value="">Select Sylabus</option>
                            {syllabus.map((x)=>{
                                return(
                                    <>
                                    <option value={x.id}>{x.topic_name}</option>
                                    </>
                                )
                            })}
                        </select>
                      </td>
                      {students.map(student => (
                        <td className='py-3' key={`${student.user.id}`}>
                          <select className={`form-select shadow-none border-secondary-subtle`}
                            value={attendance[student.user.id] || ''}
                            onChange={(event) => handleAttendanceChange(student.user.id, event)}>
                            <option value="">Select</option>
                            <option value="Present">Present</option>
                            <option value="Absent">Absent</option>
                            <option value="Leave">Leave</option>
                          </select>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className='text-end'>
              <button data-bs-dismiss="modal" onClick={handleSubmit} className="btn btn-primary mt-3">Submit Attendance</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logsheet;
