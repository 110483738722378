import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate } from 'react-router-dom'
import Upcoming_events from './Upcoming_events'

const Feedback_page = () => {

    const[data,setdata]=useState("")
    const[trainer,settrainer]=useState("")

    const navigate=useNavigate()

    const user=JSON.parse(sessionStorage.getItem('user'))

    const[state,setstate]=useState(false)

    const send_data=()=>{
       setload(true)
       setTimeout(() => {
        send()
       }, 1000);
    }

    const send=()=>{
        axios.post(`${ipaddress2}/FacultyFeedBack/${user.email}/`,{
            trainer:trainer,
            feedback:data
        })

        .then((r)=>{
            console.log("Sent successfully",r.data)
            settrainer("")
            setdata("")
            setstate(true)
            setload(false)
        })
    }

const[trainers,settrainers]=useState([])

    useEffect(()=>{
        axios.get(`${ipaddress2}/StudentTrainers/${user.email}/`)
        .then((r)=>{
            console.log("Trainers",r.data)
            settrainers(r.data)
        })
    },[])

    const[load,setload]=useState()
return (
    <div className='d-flex animate__animated animate__fadeIn'>
        <Sidebar activevalue={"feedback"}/>
        <div className="w-100">
            <Navbar/>

            <div className="main-container p-3" style={{minHeight:'100vh',backgroundColor:'#E8E8E8'}}>
               <div className="row m-0 justify-content-between bg-white py-3">
                <div className="col-lg-8">
                <div className='d-flex flex-column align-items-center justify-content-center' style={{position:'relative',height:'150px'}}>
                    <p className='feedback-head'>FEEDBACK</p>
                    <p className='fw-bold text-center text-color' style={{fontSize:'50px',position:'absolute',top:'86px'}}>Send your Feedback!</p>
                </div>
                <div className={`row m-0 complaint-div mx-auto mt-4 w-75 ${state ? 'd-none':''}`}>

                    <div className="col-12 p-0 mt-4 pt-2">
                        <select className='form-select shadow-none py-3 bg-transparent' value={trainer} onChange={(e)=>{
                            settrainer(e.target.value)
                        }} style={{border:'1px solid #57309C'}} name="" id="">
                            <option value="">Select Trainer Name*</option>
                            {trainers.map((x)=>{
                                return(
                                    <>
                                    <option value={x.id}>{x.first_name}</option>
                                    </>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-12 p-0 mt-4 pt-2">
                        <textarea onChange={(e)=>{
                            setdata(e.target.value)
                        }} rows={5} className='form-control shadow-none py-2 bg-transparent' value={data} placeholder='Your Feedback*' style={{border:'1px solid #57309C'}} name="" id=""/>
                    </div>
                    <div className="col-12 px-0 mt-4 pt-2">
                        <button disabled={data.length>0 ? false:true} className='btn rounded-0 text-white w-100' onClick={()=>{
                            send_data()
                        }} style={{backgroundColor:'#002147',fontSize:'13px',height:'40px'}}>
                            <span class={`spinner-border spinner-border-sm ${load ? '':'d-none'}`} aria-hidden="true"></span>
                            <span role="status" className={`ms-2 ${load ? '':'d-none'}`}>Sending...</span>
                            <span className={`${load ? 'd-none':''}`}>SEND NOW</span></button>
                    </div>
                </div>

                <div className={`${state ? '':'d-none'} bg-white complaint-div2 animate__animated animate__bounceIn d-flex flex-column align-items-center justify-content-center py-5 shadow-sm mt-4 mx-auto`} style={{borderRadius:'15px'}}>
                    <img src={require('../images/Screenshot 2024-06-18 at 2.23.08 PM 1.png')} width={120} alt="" />
                    <p className='fw-bold text-center mt-2'>Your feedback has been submitted</p>
                    <p style={{color:'#8A8A8A',fontSize:'13px'}}>Thanks for sharing your feedback!</p>

                    <p className='text-decoration-underline mt-4' onClick={()=>{
                        navigate('/student_dashboard')
                    }} style={{color:'#8A8A8A',fontSize:'13px',cursor:'pointer'}}>Go to Dashboard</p>

                </div>
                </div>
                <div className="col-lg-3">
                    <Upcoming_events/>
                </div>
               </div>
            </div>
        </div>
    </div>
  )
}

export default Feedback_page