import React, { useEffect, useState } from 'react'
import Sidebar from './Sidebar1'
import Navbar from './Navbar1'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import Arrow_svg from '../Svgs/Arrow_svg'

const View_coding_question = () => {

    const {id}=useParams()
    const navigate=useNavigate()

    const[questions,setQuestions]=useState([])
    const[groups,setgroups]=useState([])

    const user=JSON.parse(sessionStorage.getItem('user'))
    
    useEffect(()=>{
        axios.get(`${ipaddress2}/QuestionsView/${id}/`)
        .then((r)=>{
            console.log("Question Paper",r.data)
            setQuestions(r.data)
        })

        axios.get(`${ipaddress2}/GetGroupView/${user.emp_id}/`)
        .then((r)=>{
            console.log("Groups",r.data)
            setgroups(r.data)
        })
    },[])
    
    const assign_question=(group_id)=>{
        axios.post(`${ipaddress2}/AssignTestPaperView/`,{
            group_id:Number(group_id),
            testpaper_id:Number(id)
        })

        .then((r)=>{
            console.log("assigned",r.data)
            toast.success(`Question assigned successfully`,{
                autoClose:1000
            })
            
            setTimeout(() => {
                window.location.reload()
            }, 2000);
        })
        .catch((err)=>{
            console.log(err)
        })
    }


return (
    <div className='d-flex'>
        <Sidebar activevalue={"questions"}/>
        <div className="w-100">
            <Navbar/>

            <div className="bg-light main-container p-3" style={{minHeight:'100vh'}}>
            <div className='d-flex justify-content-between pb-4'>
                        <h6 className='d-flex align-items-center'><span onClick={()=>{
                            navigate('/question_papers')
                        }} style={{cursor:'pointer'}}><Arrow_svg/>
</span><span className='ms-2'>Coding Question</span></h6>

<select name="" onChange={(e)=>{
    assign_question(e.target.value)
}} style={{cursor:'pointer'}} className='form-select w-25 shadow-none border-secondary-subtle' id="">
    <option value="assign">Assign Question Paper</option>
    {groups.map((x)=>{
        return(
            <>
            <option value={x.id}>{x.name}</option>
            </>
        )
    })}
</select>
                    </div>
                <div className="row m-0">
                    <div className="col-md-8 bg-white">
                        {questions.map((x,index)=>{
                            return(
                                <div className='py-4 border-bottom'>
                                    <p className='text-primary'>Question {index+1}</p>
                                    <p className='fw-medium word-break'>{x.text}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className="col-md-4" style={{height:'80vh'}}>
                        <div className='bg-white h-100 d-flex align-items-center'>
                        {/* <img src={require('../images/106520-OMXAGH-484-removebg-preview.png')} style={{width:'100%',height:'360px'}} alt="" /> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default View_coding_question