import React, { useContext, useEffect, useState } from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import axios from 'axios'
import { ipaddress } from '../App'
import Leave_payment_cash_modal from '../invoide_and_receipt/Leave_cash_payment_modal'
import { useNavigate } from 'react-router-dom'
import { Context } from './Context/Context_provider'

const Leave_request_list = () => {
    const user=JSON.parse(sessionStorage.getItem('user'))

    const {setregister_value,setcourse_id}=useContext(Context)

    const[data,setdata]=useState([])
    const[count,setcount]=useState(0)

    useEffect(()=>{
        axios.get(`${ipaddress}/SendLeaveNotificationToEmployee/${user.emp_id}/`)
        .then((r)=>{
            console.log("Leads requests data",r.data)
            setdata(r.data)
        })

        fetch_courses()
        fetch_events()
    },[count])

    const generate_performa_invoice=(email)=>{
      console.log(`${ipaddress}/invoiceGenerationForLeave/${email}/`)
      axios.post(`${ipaddress}/invoiceGenerationForLeave/${email}/`,{
        employee_id:user.emp_id,
        frontend_url:`http://localhost:3000/leave_performa_invoice/`
      })
      .then((r)=>{
        console.log("Lave performa invoice generated",r.data);
        setcount(count+1)
      })
      .catch((err)=>{
        console.log("Leave performa invoice error",err);
      })
    }

    const[email,setemail]=useState('')
    const[invoice,setinvoice]=useState('')
    const[fees,setfees]=useState()
    const[state,setstate]=useState('leave')

    const state_data=(value)=>{
      setstate(value)
    }

    const[events,setevents]=useState([])

    const fetch_events=()=>{
      axios.get(`${ipaddress}/DisplayEvents/`)
      .then((r)=>{
        console.log("Events",r.data);
        setevents(r.data)
      })
      .catch((err)=>{
        console.log("Events fetching error",err);
        
      })
    }

    const[courses,setcourses]=useState([])

    const fetch_courses=()=>{
      axios.get(`${ipaddress}/UpcomingCoursesView/`)
.then((r)=>{
  console.log("Upcoming Courses",r.data)
  setcourses(r.data)
})

    }

    const navigate=useNavigate()

    const search_evants=(value)=>{
      if(value){
        const data=events.filter(x=>x.description.toLowerCase().includes(value.toLowerCase()))
        setevents(data)
      }
      else{
        fetch_events()
      }
    }

return (
    <div className='d-flex'>
        <Sidebar activevalue={"request"}/>

        <div className="w-100">
            <Navbar/>
            <div className="main-container bg-light p-3" style={{minHeight:'100vh'}}>
                <div className='d-flex align-items-center mb-3'>
                <h6 onClick={()=>{
                  state_data('leave')
                }} className='py-2 justify-content-center px-4 d-flex align-items-center' style={{color:state==='leave' ? '#ffff':'#002147',cursor:'pointer',border:'1px solid #57309C',backgroundColor:state==='leave'?'#002147':''}}>Leave Request Payment</h6>
                <h6 onClick={()=>{
                  state_data('events')
                }} className='py-2 justify-content-center px-4 d-flex align-items-center' style={{color:state==='events' ? '#ffff':'#002147',cursor:'pointer',border:'1px solid #57309C',backgroundColor:state==='events'?'#002147':''}}>Event Registration Payment</h6>
                <h6 onClick={()=>{
                  state_data('course')
                }} className='py-2 justify-content-center px-4 d-flex align-items-center' style={{color:state==='course' ? '#ffff':'#002147',cursor:'pointer',border:'1px solid #57309C',backgroundColor:state==='course'?'#002147':''}}>Course Registration Payment</h6>
                </div>



{/* Leave payment */}
                <div className={`table-responsive ${state==='leave' ? '':'d-none'}`}>
                  <h6 className={`text-center py-4 text-secondary ${data.length>0 ? 'd-none':''}`}>No data available...🧐</h6>
                <table class={`w-100 ${data.length>0 ? '':'d-none'}`}>
  <thead className='table-secondary'>
    <tr className='text-white' style={{backgroundColor:'#034592'}}>
      <th scope="col" className='py-4'>SI.No</th>
      <th scope="col">Name</th>
      <th scope="col">Requested on</th>
      <th scope="col">Reason</th>
      <th></th>
    </tr>
  </thead>
  <tbody>
    {data.map((x,index)=>{
        return(
            <tr className='table-row'>
      <th scope="row">{index+1}</th>
      <td className='py-4'>{x.customer && x.customer.customer_name}</td>
      <td>{x.created_at && x.created_at.slice(0,10)}</td>
      <td>{x.notification}</td>
      <td>
        <div className='text-center'>
          <button className={`btn btn-sm btn-success px-2 ${x.status_of_invoice==='generate_performa_invoice' ? '':'d-none'}`} onClick={()=>{
            generate_performa_invoice(x.customer.email)
          }}>Generate Performa Invoice</button>

<p className={`text-success m-0 ${x.status_of_invoice==='mail_sent' ? '':'d-none'}`}>Performa Invoice Generated</p>

<button className={`btn btn-sm btn-primary px-2 ${x.status_of_invoice==='invoice_generated' ? '':'d-none'}`} data-bs-toggle="modal" data-bs-target="#leave_payment" onClick={()=>{
  setemail(x.customer.email)
  setinvoice(x.invoice_details && x.invoice_details.invoice_id && x.invoice_details.invoice_id)
  setfees(x.invoice_details && x.invoice_details.fees && x.invoice_details.fees)
}}>Pay Now</button>
        </div>
      </td>
    </tr>
        )
    })}
  </tbody>
</table>
                </div>

{/* Event payment */}
<div className={`table-responsive ${state==='events' ? '':'d-none'}`}>
                  <h6 className={`text-center py-4 text-secondary ${events.length>0 ? 'd-none':''}`}>No data available...🧐</h6>
                  <div className='row m-0'>
                  <div className="col-lg-4 ms-auto">
                  <div class="input-group mb-3 border rounded py-2">
  <span class="input-group-text py-2 border-0 bg-transparent" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
</svg></span>
  <input onChange={(e)=>{
    search_evants(e.target.value)
  }} type="text" class="form-control shadow-none py-2 border-0 bg-transparent" placeholder="Search Events..." aria-label="Username" aria-describedby="basic-addon1"/>
</div>
                  </div>
                  </div>
                <table class={`w-100 ${events.length>0 ? '':'d-none'}`}>
  <thead className='table-secondary'>
    <tr style={{backgroundColor:'#034592'}} className='text-white'>
      <th scope="col" className='border-0 py-3'><p className='m-0 py-2 fw-normal'>SI.No</p></th>
      <th scope="col" className='border-0'><p className='m-0 py-2 fw-normal'>Name</p></th>
      <th scope="col" className='border-0'><p className='m-0 py-2 fw-normal'>Event date</p></th>
      <th scope="col" className='border-0'><p className='m-0 py-2 fw-normal'>Total Amount</p></th>
    </tr>
  </thead>
  <tbody>
    {events.map((x,index)=>{
        return(
            <tr className='table-row'>
      <th scope="row" className='border-0 py-3'><p className='m-0 py-2'>{index+1}</p></th>
      <td className='text-primary fw-medium border-0' style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/event/registered_students/${x.id}/1`)
      }}><p className='m-0 py-2'>{x.description}</p></td>
      <td className='border-0'><p className='m-0 py-2'>{x.date_time && x.date_time.slice(0,10)}</p></td>
      <td className='border-0'><p className='m-0 py-2'>{Number(x.fees)+Number(x.gst_total)}</p></td>
    </tr>
        )
    })}
  </tbody>
</table>
                </div>

{/* Course payment */}
<div className={`table-responsive ${state==='course' ? '':'d-none'}`}>
                  <h6 className={`text-center py-4 text-secondary ${courses.length>0 ? 'd-none':''}`}>No data available...🧐</h6>
                <table class={`w-100 ${courses.length>0 ? '':'d-none'}`}>
  <thead className='table-secondary'>
    <tr style={{backgroundColor:'#034592'}} className='text-white'>
      <th scope="col" className='py-4 fw-normal'>SI.No</th>
      <th scope="col"className='fw-normal' >Name</th>
      <th className='fw-normal'>Description</th>
      <th scope="col" className='fw-normal'>Date</th>
    </tr>
  </thead>
  <tbody>
    {courses.map((x,index)=>{
        return(
            <tr className='table-row'>
      <th scope="row" className='py-4'>{index+1}</th>
      <td className='text-primary fw-medium' style={{cursor:'pointer'}} onClick={()=>{
        navigate(`/course/registered_students/${x.id}/${x.course_name}`)
      }}>{x.coursename}</td>
      <td>{x.description}</td>
      <td>{x.date_time && x.date_time.slice(0,10)}</td>
    </tr>
        )
    })}
  </tbody>
</table>
                </div>
            </div>
        </div>
        <Leave_payment_cash_modal email={email} invoice_id={invoice} amount={fees} setcount={setcount}/>

    </div>
  )
}

export default Leave_request_list