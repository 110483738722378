import axios from 'axios';
import React, { useState } from 'react';
import { ipaddress, ipaddress2 } from '../App';
import { toast } from 'react-toastify';

const Mode_of_payment_modal = ({ id,invoice_number,due_date,amount,fetchinvoice}) => {
  const [paymentType, setPaymentType] = useState("");
  const [btnStatus, setBtnStatus] = useState(false);

  // State to hold denomination data
  const [denominations, setDenominations] = useState({
    500: 0,
    200: 0,
    100: 0,
    50: 0,
    20: 0,
    10: 0,
  });

  const [totalAmount, setTotalAmount] = useState(0);

  const calculateTotalAmount = (newDenominations) => {
    return Object.entries(newDenominations).reduce((sum, [key, count]) => sum + (key * count), 0);
  };

  const handleDenominationChange = (amount, count) => {
    setDenominations((prevDenominations) => {
      const newDenominations = { ...prevDenominations, [amount]: count };
      setTotalAmount(calculateTotalAmount(newDenominations));
      return newDenominations;
    });
  };

  const[url,seturl]=useState('')

  const[transaction_id,settransaction_id]=useState('')
  const[initial_payment,setinitial_payment]=useState('')
  const[transaction_img,settransaction_img]=useState(null)

  const handlePaymentTypeChange = (e) => {
    const selectedType = e.target.value;
    setPaymentType(selectedType);
    setBtnStatus(selectedType !== "Select");

    if (selectedType !== "Cash") {
      setDenominations({ 500: 0, 200: 0, 100: 0, 50: 0, 20: 0, 10: 0 });
      setTotalAmount(0);
    }
    if(selectedType==='upi'){
      axios.get(`${ipaddress2}/Payment/${amount}/${invoice_number}/${due_date}/`)
      .then((r)=>{
        console.log("API Triggered",r.data)
        seturl(r.data.redirect_url)
      })
      .catch((err)=>{
        console.log(err)
      })
    }
  };

  const user=JSON.parse(sessionStorage.getItem('user'))

  const send_data=()=>{
    if(paymentType==='Cash'){
      axios.post(`${ipaddress2}/OfflinePayment/${invoice_number}/${due_date}/${amount}/${user.id}/`,denominations)
      .then((r)=>{
        console.log("Cash paid",r.data)
        console.log(invoice_number,due_date,amount)
        toast.success('Paid successfully',{
          autoClose:2000,
          theme:'colored',
      position:'top-center'
        })
        setDenominations({ 500: 0, 200: 0, 100: 0, 50: 0, 20: 0, 10: 0 });
        fetchinvoice(invoice_number)
      })
      .catch((err)=>{
        console.log(err)
      })
    }

    if(paymentType==='Bank_transfer'){
      const form=new FormData()
      form.append('bank_transfer',true)
      form.append('transaction_id',transaction_id)
      form.append('document_proof',transaction_img)
      axios.post(`${ipaddress2}/OfflinePayment/${invoice_number}/${due_date}/${amount}/${user.id}/`,form)
      .then((r)=>{
        console.log("Cash paid",r.data)
        console.log(invoice_number,due_date,amount)
        toast.success('Details received successfully',{
          autoClose:2000,
          theme:'colored',
      position:'top-center'
        })
        settransaction_id('')
        fetchinvoice(invoice_number)
      })
      .catch((err)=>{
        console.log(err)
      })
    }

    if(paymentType==='Cheque'){
      chequedata.invoice_id=invoice_number
      chequedata.person_name=1
      chequedata.due_date=due_date

      axios.post(`${ipaddress2}/ChequePayment/`,chequedata)
      .then((r)=>{
        console.log("Cheque data sent",r.data)
        setChequedata({
          'cheque_number':'',
          'cheque_date':'',
          'bank_name':'',
          'branch':'',
          'payee_name':'',
        })
        fetchinvoice(invoice_number)
        toast.success('Cheque is under processing',{
          autoClose:2000,
          theme:'colored',
      position:'top-center'
        })
      })
      .catch((err)=>{
        console.log("Cheque errpr",err)
      })
    }
  }

const [chequedata,setChequedata]=useState({
  'cheque_number':'',
  'cheque_date':'',
  'bank_name':'',
  'branch':'',
  'payee_name':'',
  'invoice_id':'',
  'due_date':'',
  'person_name':''
})

const handleChequedata=(e)=>{
  const {name,value}=e.target
  setChequedata((prev)=>({
    ...prev,
    [name]:value
  }))
}
  return (
    <div>
      <div className="modal fade" id="mode_of_payment" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body">
              <div className='d-flex justify-content-between px-3 mb-4 align-items-center'>
                <h5 className='text-primary'>Mode of Payment</h5>
                <button data-bs-dismiss="modal" className='bg-transparent border-0 ms-auto'><i className="fa-solid fa-circle-xmark fs-5"></i></button>
              </div>
              <form>
                <div className="row m-0">
                  <div className="col-md-6">
                    <div className="mb-3">
                      <input type="text" className="form-control shadow-none py-2 bg-light" value={id} disabled placeholder="Lead Id" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <select onChange={handlePaymentTypeChange} className='form-select py-2 bg-light shadow-none'>
                      <option value="Select">Select Payment type</option>
                      <option value="Cash">Cash</option>
                      <option value="Cheque">Cheque</option>
                      <option value="Bank_transfer">Bank Transfer</option>
                      <option value="upi">UPI</option>
                    </select>
                  </div>
                  <div className="col-12 border-top pt-2">

                    {/* Cash Denomination Layout */}
                    <div className={`row m-0 ${paymentType === "Cash" ? '' : 'd-none'}`}>
                      <h6 className='ps-0 mt-2 pb-2 text-primary'>Cash Denomination</h6>
                      <div className="col-md-10 mx-auto">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th scope="col">Rupees</th>
                              <th scope="col"></th>
                              <th scope="col">Count</th>
                              <th scope="col"></th>
                              <th scope="col">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {[500, 200, 100, 50, 20, 10].map((amount) => (
                              <tr key={amount}>
                                <td>{amount}</td>
                                <td className='text-center'><i className="bi bi-x-lg"></i></td>
                                <td>
                                  <input
                                    type="number"
                                    value={denominations[amount]}
                                    onChange={(e) => handleDenominationChange(amount, parseInt(e.target.value))}
                                    className='form-control border-0 shadow-none'
                                  />
                                </td>
                                <td className='text-center'><i className="fa-solid fa-equals"></i></td>
                                <td>{amount * denominations[amount]}</td>
                              </tr>
                            ))}
                            <tr>
                              <td colSpan="4" className='text-end'>
                                <span className='fw-medium text-primary' style={{ cursor: 'pointer' }}>
                                  Total Amount
                                </span>
                              </td>
                              <td>
                                <i className="bi bi-currency-rupee"></i> <span>{totalAmount}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {/* Cheque Layout */}
                    <div className={`row m-0 ${paymentType === "Cheque" ? '' : 'd-none'}`}>
                      <h6 className='ps-0 mt-2 pb-2 text-primary'>Cheque Payment</h6>
                      <div className="col-md-6 ps-0">
                        <div className="mb-3">
                          <label htmlFor="chequeNumber">Cheque Number</label>
                          <input type="text" value={chequedata.cheque_number} name='cheque_number' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="chequeNumber" />
                        </div>
                      </div>
                      <div className="col-md-6 pe-0">
                        <div className="mb-3">
                          <label htmlFor="bankName">Bank Name</label>
                          <input type="text" value={chequedata.bank_name} name='bank_name' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="bankName" />
                        </div>
                      </div>
                      <div className="col-md-6 ps-0">
                        <div className="mb-3">
                          <label htmlFor="bankName">Branch Name</label>
                          <input type="text" value={chequedata.branch} name='branch' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="bankName" />
                        </div>
                      </div>
                      <div className="col-md-6 pe-0">
                        <div className="mb-3">
                          <label htmlFor="chequeDate">Cheque Date</label>
                          <input type="date" value={chequedata.cheque_date} name='cheque_date' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="chequeDate" />
                        </div>
                      </div>
                      <div className="col-md-6 ps-0">
                        <div className="mb-3">
                          <label htmlFor="bankName">Payee Name</label>
                          <input type="text" value={chequedata.payee_name} name='payee_name' onChange={handleChequedata} className="form-control shadow-none py-2 bg-light" id="bankName" />
                        </div>
                      </div>
                      <div className="col-12 ps-0 mt-2">
                        <div className="mb-3 d-flex align-items-center">
                          <label htmlFor="cheque_realization" style={{fontSize:'13px'}}><span className='me-1 ms-2' style={{color:'red'}}>*</span>For Every Bounce Charges, Bank Charges Will Be Applied</label>
                        </div>
                      </div>
                    </div>

                    {/* Bank Transfer Layout */}
                    <div className={`row m-0 ${paymentType === "Bank_transfer" ? '' : 'd-none'}`}>
                      <h6 className='ps-0 mt-2 pb-2 text-primary'>Bank Transfer</h6>  

                      <div className="col-md-6 ps-0 mb-3">
                        <div className="mb-3">
                          <label htmlFor="transferBankName">Transaction ID</label>
                          <input type="text" value={transaction_id} onChange={(e)=>{
                            settransaction_id(e.target.value)
                          }} className="form-control shadow-none py-2 bg-light" id="transferBankName" />
                        </div>
                      </div>
                      <div className="col-md-6 mb-4 d-flex align-items-end justify-content-center">
  <input
                  id="fileInput"
                  type="file"
                  // accept=".pdf"
                  name='Photograph'
                  onChange={(e)=>{
                    settransaction_img(e.target.files[0])
                  }}
                  className="bg-light text-center p-3 btn"
                />
                <label
                  for="fileInput"
                  class="d-flex align-items-center custom-file-input rounded-pill border-0 text-white px-4 py-2 fw-normal" style={{backgroundColor:'#5D5FE3'}}
                >
                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="15" viewBox="0 0 21 15" fill="none">
  <path d="M4.771 14.7913C3.47159 14.7913 2.36548 14.3395 1.45269 13.4357C0.539894 12.5319 0.0834961 11.4273 0.0834961 10.1219C0.0834961 8.88789 0.508175 7.81817 1.35753 6.91272C2.20689 6.00729 3.20449 5.52051 4.35032 5.45238C4.58403 3.9353 5.28182 2.68197 6.44368 1.69238C7.60554 0.702799 8.9577 0.208008 10.5002 0.208008C12.2412 0.208008 13.7182 0.814405 14.931 2.0272C16.1438 3.24 16.7502 4.71693 16.7502 6.45801V7.49967H17.3912C18.3888 7.53172 19.2258 7.89797 19.9022 8.59842C20.5786 9.29887 20.9168 10.1479 20.9168 11.1455C20.9168 12.1671 20.5713 13.0302 19.8802 13.7346C19.1891 14.4391 18.3327 14.7913 17.3111 14.7913H11.662C11.1826 14.7913 10.7823 14.6308 10.4611 14.3096C10.1399 13.9884 9.97933 13.5881 9.97933 13.1087V7.06298L7.79183 9.23845L7.05464 8.52131L10.5002 5.07579L13.9457 8.52131L13.2085 9.23845L11.021 7.06298V13.1087C11.021 13.2689 11.0878 13.4158 11.2213 13.5494C11.3549 13.6829 11.5018 13.7497 11.662 13.7497H17.271C18.0002 13.7497 18.6165 13.4979 19.12 12.9945C19.6234 12.491 19.8752 11.8747 19.8752 11.1455C19.8752 10.4163 19.6234 9.80002 19.12 9.29655C18.6165 8.79308 18.0002 8.54134 17.271 8.54134H15.7085V6.45801C15.7085 5.01704 15.2007 3.78874 14.1851 2.77311C13.1694 1.75749 11.9411 1.24967 10.5002 1.24967C9.05919 1.24967 7.83089 1.75749 6.81527 2.77311C5.79964 3.78874 5.29183 5.01704 5.29183 6.45801H4.73094C3.76405 6.45801 2.92137 6.81391 2.2029 7.52572C1.48441 8.23752 1.12516 9.0969 1.12516 10.1038C1.12516 11.1108 1.48107 11.9702 2.19287 12.682C2.90468 13.3938 3.76405 13.7497 4.771 13.7497H7.37516V14.7913H4.771Z" fill="white"/>
</svg> <span className='ms-2'>Upload Payment Screenshot</span>
                </label>
                <span className='d-block text-success ms-2'>{transaction_img && transaction_img.name!=undefined && transaction_img.name.slice(0,16)}</span>

  </div>
                     
                    </div>
                  </div>

                  <div className="col-12 text-end mt-3">
                    <button disabled={!btnStatus} onClick={(e)=>{
                      e.preventDefault()
                      send_data()
                    }} className={`btn btn-sm px-5 py-2 btn-primary text-white fw-medium ${url===''?'':'d-none'}`}>
                      Submit
                    </button>
                    <a href={url} target='_self' className={`btn btn-sm px-5 py-2 btn-primary text-white fw-medium ${url===''?'d-none':''}`}>
                      Pay
                    </a>
                    <button className='btn btn-danger px-3 ms-3' data-bs-dismiss="modal">Close</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mode_of_payment_modal;
