import React from 'react'
import Sidebar from './Sidebar'
import Navbar from './Navbar'
import { useState,useEffect } from 'react'
import axios from 'axios'
import { ipaddress2 } from '../App'
import { useNavigate, useParams } from 'react-router-dom'
import Mode_of_payment_modal from '../invoide_and_receipt/Mode_of_payment_modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { toast } from 'react-toastify'

const Payment_page = () => {

    const {id}=useParams()

    const navigate=useNavigate()

    const renderTooltip = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Check Verified
      </Tooltip>
    );
    const renderTooltip2 = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Check Not Verified
      </Tooltip>
    );
    const renderTooltip3 = (props) => (
      <Tooltip id="button-tooltip" {...props}>
        Send Receipt
      </Tooltip>
    );

    const[due_date,setdue_date]=useState()
    const[amount,setamount]=useState()

    const[loading1,setloading1]=useState()

    const[invoidedata,setinvoicedata]=useState({})
  const[current_invoidedata,setcurrent_invoicedata]=useState({})
  const[installmentdata,setinstallmentdata]=useState([])
  useEffect(()=>{
    fetchobjectdata()

  },[])

  const fetchobjectdata=()=>{
    axios.get(`${ipaddress2}/ShowInvoice/${id}/`)
    .then((r)=>{
      console.log("particular invoice data",r.data)
      setinvoicedata(r.data)
    })
    .catch((err)=>{
      console.log("particular Invoice Error",err)
    })
  }

  const verify_cheque=(invoice_number,due_date,status)=>{
    axios.post(`${ipaddress2}/CheckBounce/${invoice_number}/${due_date}/${status}/`)
    .then((r)=>{
      console.log("Cheque verified",r.data)
      fetchobjectdata(invoice_number)
    })
  }

const send_receipt=(token)=>{
  setloading1(true)
  const url=`crmclient.skilllearningacademy.com/receipt/${token}/`
axios.post(`${ipaddress2}/ReceiptView/${token}/`,{
  url:url
})
.then((r)=>{
  setloading1(false)
  console.log("Url Shared",r.data)
  toast.success('Url shared successfully',{
    autoClose:2000,
    theme:'colored',
      position:'top-center'
  })
})
.catch((err)=>{
  setloading1(false)
  console.log("Sharing error",err,url,token)
})
}

const[url,seturl]=useState('')

const[state,setstate]=useState(false)

const payment=(amount,due_date)=>{
  axios.get(`${ipaddress2}/Payment/${amount}/${id}/${due_date}/`)
  .then((r)=>{
    console.log("API Triggered",r.data)
    window.location.href = r.data.redirect_url
  })
  .catch((err)=>{
    console.log(err)
  })
}
  return (
    <div className='d-flex'>
        <Sidebar/>

        <div className="w-100">
            <Navbar/>

            <div className="main-container p-3">
            <svg style={{cursor:'pointer'}} onClick={()=>{
             
                navigate('/installments')
              
            }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-arrow-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"/>
</svg>

            <div className={`table-responsive border-0 mt-3`} style={{minHeight:'100vh'}}>
                {/* <h5 className='text-primary'>Installments</h5> */}
<table class={`w-100 caption-top rounded`}>
  <thead className='invoice-table'>
    <tr style={{backgroundColor:'#034592'}}>
      <th scope="col" className='py-3'><p className='fw-normal text-white m-0 ps-2'>SI No</p></th>
      <th scope="col" className=''><p className='fw-normal text-white m-0'>Amount</p></th>
      <th scope="col" className=''><p className='fw-normal text-white m-0'>Due Date</p></th>
      <th scope="col" className=''></th>
    </tr>
  </thead>
  <tbody>
     {invoidedata.due_dates && invoidedata.due_dates.map((x,index)=>{
        return(
            <tr className='table-row'>
            <td className='py-3' scope="row"><p className=' m-0 ps-2'>{index+1}</p></td>
            <td className='py-3' scope="row"><p className=' m-0'>{x.fees}</p></td>
            <td className='py-3' scope="row"><p className=' m-0'>{x.due_date}</p></td>
            <td className='py-3' scope="row"><button onClick={()=>{
            payment(x.fees,x.due_date)
            }} className='btns text-white py-2 px-3'>Pay Now</button></td>

      
            </tr>
        )
     })}
  </tbody>
</table>
</div>
            </div>
        </div>

<Mode_of_payment_modal id={id} invoice_number={current_invoidedata.invoice_number} due_date={due_date} amount={amount} fetchinvoice={fetchobjectdata}/>
    </div>
  )
}

export default Payment_page