import axios from 'axios'
import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ipaddress2 } from '../App'
import { toast } from 'react-toastify'

const Trainer_login = () => {
  const [passwordtype,setPasswordtype]=useState(true)

  const[userid,setUserid]=useState("")
  const[password,setpassword]=useState("")
  const navigate=useNavigate()

  const[loading,setLoading]=useState()

  const handlesubmit=(e)=>{
    e.preventDefault()
    setLoading(true)
    console.log({
      email:userid,
      password:password
    });
    const formdata=new FormData()
    formdata.append('username',userid)
    formdata.append('password',password)
    axios.post(`${ipaddress2}/EmployeeLogin/`,{
      email:userid,
      password:password
    })
    .then((r)=>{
      console.log("Login Successfull",r.data)
      sessionStorage.setItem('user',JSON.stringify(r.data))
      navigate('/trainer_dashboard')
      setLoading(false)
    })
    .catch((error)=>{
      console.log(error);
      toast.error('Invalid Email and Password',{
        autoClose:2000
      })
      setLoading(false)
    })
  }

return (
  <div className='trainer-login p-3 p-lg-5 d-flex align-items-center justify-content-center' style={{height:'100vh'}}>
  <div className="row m-0 py-4 py-lg-5 w-100 mx-lg-5" style={{backgroundColor:'rgba(0, 33, 71,0.6)'}}>
<div className="col-lg-5 d-flex align-items-center justify-content-center">
<img src={require('../images/student/SLA_logo_White 3.png')} style={{width:'40%'}} alt="" />
</div>

<div className="col-lg-7 d-flex justify-content-center align-items-center">
<div className='login-form px-3 py-5 d-flex flex-column align-items-center justify-content-center'>
{/* <img src={require('../images/skill_learning_logo-04-removebg-preview.png')} width={130} alt="" /> */}
<h3 className='text-center mb-4 fw-bold mt-3 fs-2' style={{color:'#ffff'}}>Sign In</h3>
<form action="" className='p-2 py px-2 w-100 mt-2' onSubmit={handlesubmit}>
<div class="mb-3">
<label htmlFor="" className='text-white fw-bold'>Email</label>
<div class="input-group mb-3 mt-2 border border-secondary py-3" style={{backgroundColor:'rgba(255,255,255,0.2'}}>
<input type="text" class="text-white form-control border-0 bg-transparent shadow-none login-input" onChange={(e)=>{
setUserid(e.target.value)
}} aria-label="Username" aria-describedby="basic-addon1"/>
</div>
</div>
<div class="mb-3 mt-4">
<label htmlFor="" className='text-white fw-bold'>Password</label>
<div class="input-group mb-3 mt-2 border border-secondary py-3" style={{backgroundColor:'rgba(255,255,255,0.2'}}>
<input type={passwordtype ? 'password':'text'} onChange={(e)=>{
setpassword(e.target.value)
}} class="text-white form-control border-0 bg-transparent country-input shadow-none login-input" aria-label="Username" aria-describedby="basic-addon1"/>
<span style={{cursor:'pointer'}} onClick={()=>{
setPasswordtype(!passwordtype)
}} class="input-group-text border-0 bg-transparent" id="basic-addon1"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-eye-fill" viewBox="0 0 16 16">
<path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0"/>
<path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8m8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7"/>
</svg></span>
</div>
</div>
<div className='text-end mb-4'>
<a href="" className='fw-bold' style={{color:'#FF845D',textDecoration:'none'}} data-bs-toggle="modal" data-bs-target="#forgotpassword">Forgot Password?</a>
</div>
<div class="text-center">
<button type='submit' className='btn Login-btn btn-md py-3 fw-bold px-5 text-white fw-medium w-100' style={{backgroundColor:'#033EA3',height:'61px'}}>{loading ? 'Loading...':'Sign In'}</button>
</div>     
</form>
</div>
</div>

</div>
</div>
  )
}

export default Trainer_login